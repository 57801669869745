/**
 * @Description: 提现弹窗
 * @author 点点
 * @date 2021/6/16
 * @time 16:39
 */
import { Form, InputNumber, message, Modal, Select, Spin, Typography } from '@fle-ui/next';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import AccountStore from '@/stores/accountStore';
import FinanceStore from '@/stores/financeStore';

const { Text } = Typography;

const FormItem = Form.Item;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: () => void;
  // 保存
  onOk: () => void;
};
const WithdrawModal: FC<Props> = (props: Props) => {
  const { visible, onClose, onOk } = props;
  const { canWithdraw, withdrwaFeeRate } = FinanceStore.shopWithdrawStatistic;
  const { selectAccountList } = AccountStore;

  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!selectAccountList.length) {
      return;
    }
    const defaultBankCardAccountId = selectAccountList.filter((item) => item.isDefault)[0]?.id;
    form.setFieldsValue({ bankCardAccountId: defaultBankCardAccountId });
  }, [selectAccountList, form]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    setLoading(true);
    AccountStore.ajaxSelect({}).finally(() => setLoading(false));
  }, [visible]);
  const onHandleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const { companyId, shopId } = selectAccountList.filter(
          (item) => item.id === values.bankCardAccountId
        )[0];
        FinanceStore.ajaxGetApply({
          ...values,
          companyId,
          shopId,
          withdrawAmount: (values.withdrawAmount * 100).toFixed(0)
        }).then((res) => {
          if (res) {
            message.success('操作成功');
            onClose();
            onOk();
          } else {
            message.error('操作失败');
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={visible}
      width={700}
      destroyOnClose
      title={'提现'}
      onCancel={onClose}
      onOk={onHandleOk}
    >
      <Spin spinning={loading}>
        <Form {...layout} preserve={false} form={form}>
          <FormItem label={'可提现金额'}>
            <Text type={'danger'}>￥{canWithdraw / 100}</Text>
          </FormItem>
          <FormItem required label={'本次提现金额'}>
            <FormItem
              name={'withdrawAmount'}
              label={'本次提现金额'}
              noStyle
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ width: 300 }}
                min={0.01}
                max={canWithdraw / 100}
                precision={2}
                placeholder={'本次提现金额'}
                onChange={setPrice}
              />
            </FormItem>
            元
          </FormItem>
          <FormItem label={'收取手续费'}>
            <Text type={'danger'}>{withdrwaFeeRate * 100}%</Text>
          </FormItem>
          <FormItem label={'到账金额'}>
            <Text type={'danger'}>
              ￥
              {(
                (price * 10000 - Number((price * withdrwaFeeRate).toFixed(2)) * 10000) /
                10000
              ).toFixed(2)}
            </Text>
          </FormItem>
          <FormItem
            required
            label={'选择对公账户'}
            name={'bankCardAccountId'}
            rules={[{ required: true }]}
          >
            <Select placeholder={'选择对公账户'}>
              {selectAccountList.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.accountName}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default observer(WithdrawModal);
