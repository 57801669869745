import React, { useState, useEffect, FC, ReactElement } from "react";
import { Table, Button, Modal, message, Divider, Alert, Tabs } from "@fle-ui/next";
import { Modals } from "@/components";
import { PageContainer } from "@fle-ui/pro-layout";
import sendAddressStore from "@/stores/sendAddress";

const { confirm } = Modal;
const { StoreAddAddressModal } = Modals;

const RetLocation: FC = (): ReactElement => {
  const { addressList } = sendAddressStore;

  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("send"); // send:发货、return:退货
  const [actionItem, setActionItem] = useState({});
  const [actionName, setActionName] = useState("add"); //add | edit
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    _getData(key);
  }, [key]);

  const _getData = async (activeKey: string) => {
    setLoading(true);
    await sendAddressStore
      .getLocationList({ sourceType: 2, shippingAddress: activeKey === "send" ? 1 : 2 })
      .finally(() => setLoading(false));
  };

  const editLocation = (record: any) => {
    setActionName("edit");
    setActionItem(record);
    setShowAdd(true);
  };

  const deleteLocation = (record: any) => {
    confirm({
      title: "删除",
      content: "是否删除该收货地址？",
      okText: "删除",
      cancelText: "取消",
      centered: true,
      async onOk() {
        const res: any = await sendAddressStore.deleteLocation({
          id: record.id,
          sourceType: 2,
        });
        if (res.success) {
          message.success("删除成功！");
          _getData(key);
        }
      },
    });
  };

  const columns: any = [
    {
      title: "联系人",
      width: 150,
      align: "center",
      dataIndex: "name",
    },
    {
      title: "所在地区",
      dataIndex: "provinceName",
      align: "center",
      width: 250,
      render: (_: any, record: any) =>
        `${record.provinceName}-${record.cityName}-${record.countyName}${record.townName ? "-" + record.townName : ""}`,
    },
    {
      title: "街道",
      align: "center",
      dataIndex: "address",
    },
    {
      title: "手机号码",
      align: "center",
      dataIndex: "mobile",
    },
    {
      title: "操作",
      align: "center",
      width: 140,
      render: (text: any, record: any) => (
        <>
          <Button type="link" size="small" onClick={() => editLocation(record)}>
            编辑
          </Button>
          <Divider type="vertical"></Divider>
          <Button
            type="link"
            size="small"
            onClick={() => deleteLocation(record)}
            disabled={!!record.relatedGoodsQuantity}>
            删除
          </Button>
        </>
      ),
    },
  ];
  const renderTable = () => {
    return (
      <Table
        columns={columns}
        loading={loading}
        dataSource={addressList}
        pagination={false}
        rowKey={(record: any) => record.key}
      />
    );
  };

  return (
    <PageContainer margin={0}>
      <Tabs
        activeKey={key}
        onChange={setKey}
        items={[
          {
            label: "发货地址",
            key: "send",
          },
          {
            label: `退货地址`,
            key: "return",
          },
        ]}
      />
      <div>
        <Alert
          style={{ marginBottom: "20px" }}
          message="说明"
          showIcon
          description={
            <ul>
              <li>主要用于商品录入时说明货品发货地或退货地。</li>
              <li>如发货/退货地址已关联商品，则该地址不可删除。</li>
            </ul>
          }
        />
        <div>
          <Button
            style={{ marginBottom: 16 }}
            type="primary"
            onClick={() => {
              setShowAdd(true);
              setActionName("add");
            }}>
            新增地址
          </Button>
        </div>
        {renderTable()}

        {showAdd && (
          <StoreAddAddressModal
            visible={showAdd}
            onOk={() => {}}
            onCancel={(type: any) => {
              setShowAdd(false);
              if (type) {
                _getData(key);
              }
            }}
            type={actionName}
            item={actionItem}
            shippingAddress={key === "send" ? 1 : 2}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default RetLocation;
