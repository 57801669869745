/**
 * @Description: 交易明细  用户身份是后端根据登录用户的信息判断的 ，JD 和 普通用户共用一个接口
 * @author 点点
 * @date 2021/6/21
 * @time 09:32
 */

type Key = 'querySupplierTrade' | 'querySupplierTradeAmount' | 'exportSupplierTradeExcel' | 'getCheckOrderList';

export const TradeApis: Map<Key, string> = new Map([
  // [卖家端-象铺]查询卖家会员交易明细
  ['querySupplierTrade', '/elebuys/member/trade/queryShopTrade'],
  //[卖家端-象铺]查询卖家会员收支金额（交易明细页面需要展示）
  ['querySupplierTradeAmount', '/elebuys/member/trade/queryShopTradeAmount'],
  // [卖家端-象铺]导出卖家会员交易明细excel
  ['exportSupplierTradeExcel', '/elebuys/member/trade/exportShopTradeExcel'],
  // [卖家端-京东]获取接口对账信息分页列表
  ['getCheckOrderList', '/elebuys/member/trade/getCheckOrderList']
]);
