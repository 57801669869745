import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Space, Spin, Table, Typography } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import FinanceStore, { AuditStatus, WithdrawDepositRecordBO } from '@/stores/financeStore';
import { getPagination } from '@/tools/getPagination';
import { PageProps } from '@/types/commom';
import OrderInfoItem from '@/pages/shopManage/order/component/OrderInfoItem';
import WithdrawModal from './withdrawModal';
import { PageContainer } from '@fle-ui/pro-layout';

const { Text } = Typography;
const { Column } = Table;
type Props = {};
const SettlementAccount: FC<Props> = () => {
  const { withdrawDepositRecord, shopWithdrawStatistic } = FinanceStore;
  const { haveWaitApply, canWithdraw, withdrwaLock } = shopWithdrawStatistic;
  const { list, pageNo, pageSize, total } = withdrawDepositRecord;
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<PageProps>({ pageNo: 1, pageSize: 20 });
  useEffect(() => {
    setLoading(true);
    FinanceStore.ajaxGetWithdrawStatistics().catch();
    FinanceStore.ajaxGetPage(page).finally(() => setLoading(false));
  }, [page, refresh]);

  const [withdrawModal, setWithdrawModal] = useState(false);
  return (
    <PageContainer padding={0}>
      <Spin spinning={loading}>
        <Row>
          <Col span={12}>
            <Card>
              <Space>
                <Text>可提现金额：</Text>
                <Text style={{ color: '#3590FF', fontSize: 16 }} strong>
                  ￥{canWithdraw / 100}
                </Text>
                <WhiteSpace />
                <WhiteSpace />
                {haveWaitApply || !canWithdraw ? (
                  <Button type={'primary'} ghost size={'small'} disabled>
                    申请结算
                  </Button>
                ) : (
                  <Button
                    type={'primary'}
                    ghost
                    size={'small'}
                    onClick={() => setWithdrawModal(true)}
                  >
                    申请提现
                  </Button>
                )}
                {haveWaitApply && <Text type={'secondary'}>已有进行中的结算申请</Text>}
              </Space>
            </Card>
          </Col>

          <Col span={12}>
            <Card style={{ height: '100%' }}>
              <Space>
                <Text>提现锁定金额：</Text>
                <Text style={{ fontSize: 16 }} strong>
                  ￥{withdrwaLock / 100}
                </Text>
              </Space>
            </Card>
          </Col>
        </Row>

        <WhiteSpace />
        <Table
          loading={loading}
          dataSource={list}
          rowKey={'id'}
          title={() => <Text strong>提现记录</Text>}
          pagination={getPagination({
            total,
            current: pageNo,
            pageSize,
            onChange: (pageNo: number, pageSize: number) => setPage({ pageNo, pageSize }),
            onShowSizeChange: (pageNo: number, pageSize: number) => setPage({ pageNo: 1, pageSize })
          })}
        >
          <Column
            title={'申请提现金额'}
            align={'center'}
            dataIndex={'withdrawAmount'}
            render={(text) => (text / 100).toFixed(2)}
          />
          <Column
            title={'手续费'}
            align={'center'}
            dataIndex={'handlingFee'}
            render={(text) => (text / 100).toFixed(2)}
          />
          <Column
            title={'到账金额'}
            align={'center'}
            dataIndex={'arrivalAmount'}
            render={(text) => (text / 100).toFixed(2)}
          />
          <Column title={'开户名'} align={'center'} dataIndex={'bankAccountName'} />
          <Column title={'开户行'} align={'center'} dataIndex={'bankName'} />
          <Column title={'银行账号'} align={'center'} dataIndex={'bankAccountNo'} />
          <Column
            title={'申请时间'}
            align={'center'}
            dataIndex={'createTime'}
            render={(text) => moment(text).format('YYYY-MM-DD HH:mm:ss')}
          />
          <Column
            title={'处理时间'}
            align={'center'}
            dataIndex={'auditTime'}
            render={(text) => text && moment(text).format('YYYY-MM-DD HH:mm:ss')}
          />
          <Column
            title={'状态'}
            align={'center'}
            dataIndex={'auditStatus'}
            render={(text) => AuditStatus[text]}
          />
          <Column<WithdrawDepositRecordBO>
            title={'操作'}
            align={'center'}
            dataIndex={'id'}
            render={(_, { auditStatus, auditContent, remittanceReceipt }) => {
              if (AuditStatus.平台审核中 !== auditStatus) {
                return (
                  <Button
                    type={'link'}
                    onClick={() => {
                      Modal.info({
                        width: remittanceReceipt ? 800 : 400,
                        title: '提现详情',
                        content: (
                          <>
                            {AuditStatus.申请被驳回 === auditStatus && (
                              <OrderInfoItem label={'驳回原因'} value={auditContent} />
                            )}
                            <br />
                            {AuditStatus.资金已到账 === auditStatus && (
                              <OrderInfoItem
                                label={'汇款截图'}
                                value={<img width={600} src={remittanceReceipt} alt="" />}
                              />
                            )}
                          </>
                        )
                      });
                    }}
                  >
                    详情
                  </Button>
                );
              }
              return null;
            }}
          />
        </Table>

        <WithdrawModal
          visible={withdrawModal}
          onClose={() => setWithdrawModal(false)}
          onOk={() => {
            setRefresh((refresh) => refresh + 1);
          }}
        />
      </Spin>
    </PageContainer>
  );
};

export default observer(SettlementAccount);
