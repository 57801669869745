import useUrlState from '@ahooksjs/use-url-state';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from '@fle-ui/next';
import { OrderFormListLayout } from '@/layout/formLayout';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { GetOrderListParams } from '@/stores/orderListStore';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select;
type Props = {
  onSearch: (values: any) => void;
  orderStatsList: any[];
  isHide?: boolean
};

const OrderListForm = (props: Props) => {
  const { onSearch } = props;
  const [form] = Form.useForm();
  const [urlParams, setUrlParams] = useUrlState(
    { tab: 'default', reset: false },
    { navigateMode: 'replace' }
  );

  const onFinish = useCallback(async () => {
    try {
      const values: Partial<GetOrderListParams> = await form.validateFields();
      if (values.date) {
        const [start, end] = values.date;
        values.start = moment(start).format('YYYY-MM-DD 00:00:00');
        values.end = moment(end).format('YYYY-MM-DD 23:59:59');
      }
      if (urlParams.tab === '1,2') {
        values.orderStatus = '1,2';
      }
      onSearch(values);
    } catch (e) {}
  }, [onSearch, form, urlParams]);

  const setForm = async () => {
    form.resetFields();
    setUrlParams({ tab: 'default' });
    await onFinish();
  };

  useEffect(() => {
    if (!urlParams.tab) {
      return;
    }

    let orderStatus = urlParams.tab;
    if (urlParams.tab === 'default') {
      orderStatus = form.getFieldValue('orderStatus');
    }
    if (urlParams.tab === '1,2' || urlParams.reset === 'true') {
      orderStatus = undefined;
    }

    form.setFieldsValue({ orderStatus });
    onFinish();
  }, [urlParams, form, onFinish]);

  return (
    <div style={{ background: '#fafafa', padding: '20px 48px' }}>
      <Form form={form} {...OrderFormListLayout}>
        <Row gutter={8}>
          <Col span={8}>
            <FormItem label={'下单时间'} name={'date'}>
               {/* @ts-ignore */}
              <RangePicker style={{ width: '100%' }} />
            </FormItem>
          </Col>
          {/* <Col span={8}>
            <FormItem label="订单状态" name={'orderStatus'}>
              <Select placeholder={'请选择订单状态'}>
                {props.orderStatsList.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col> */}

          <Col span={8}>
            <FormItem label="下单账号" name={'companyName'}>
              <Input placeholder={'账号名称'} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="商品名称" name={'goodsName'} style={{ marginBottom: 0 }}>
              <Input placeholder={'商品名名称'} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <FormItem label="订单号" name={'orderNo'} style={{ marginBottom: 0 }}>
              <Input placeholder={'订单号'} />
            </FormItem>
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Space style={{ float: 'right' }}>
              <Button
                type={'primary'}
                htmlType={'submit'}
                onClick={async () => {
                  await onFinish();
                  setUrlParams({ tab: 'default', reset: false });
                }}
              >
                搜索
              </Button>
              <Button onClick={setForm}>重置</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default observer(OrderListForm);
