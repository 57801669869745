/**
 * @Description: 修改快递单号
 * @author 点点
 * @date 2021/6/7
 * @time 15:10
 */

import { OrderShipFormLayout } from '@/layout/formLayout';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Form, Input, message, Modal, Radio, Select, Space, Typography } from '@fle-ui/next';
import { RadioChangeEvent } from '@fle-ui/next/lib/radio/interface';
import ExpressStore from '@/stores/expressStore';
import OrderDeliveryStore, { VoListItemProps } from '@/stores/orderDeliveryStore';
import { replaceDoubleCharacters } from '@/tools/replaceStr';
import OrderInfoItem from '../../../component/OrderInfoItem';
import { WhiteSpace } from '@/components';

const { Text, Title } = Typography;
const { Option } = Select;
const FormItem = Form.Item;

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: () => void;
  data: VoListItemProps;
};
const ChangeShipNumberModal: FC<Props> = (props: Props) => {
  const { visible, onClose, data } = props;
  const { deliveryOrderId } = data;

  const { list } = ExpressStore.expressData;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [shipType, setShipType] = useState(1);

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (data) => {
        const params = {
          deliveryOrderId,
          itemId: props.data.id,
          ...data,
          expressNo: data.expressNo || ''
        };
        const res = await OrderDeliveryStore.ajaxChangeExpressInfo(params);
        if (res) {
          message.success('修改成功');
          onClose();
        } else {
          message.error('修改失败');
        }
       window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(false);
    if (!visible) {
      return;
    }
    getExpress(0)('');
  }, [visible]);

  const getExpress = (delay: number) =>
    _.debounce(async (value = '') => {
      await ExpressStore.ajaxGetExpress({ expressName: value, pageNo: 1, pageSize: 30 });
    }, delay);

  return (
    <Modal
      width={1000}
      title={'修改快递单号'}
      open={visible}
      onCancel={onClose}
      destroyOnClose
      confirmLoading={loading}
      onOk={handleOk}
      okText={'确认修改'}
    >
      <Title level={5}>原信息：</Title>
      <Space direction={'vertical'} style={{ marginLeft: 40 }}>
        <OrderInfoItem label={'快递公司'} value={data.expressName || data.expressCompany} />
        <OrderInfoItem label={'快递单号'} value={data.expressNo} />
      </Space>

      <WhiteSpace />
      <Title level={5}>新信息：</Title>
      <Form
        form={form}
        {...OrderShipFormLayout}
        initialValues={{ expressType: shipType }}
        preserve={false}
      >
        <FormItem label={'发货方式'} name={'expressType'} rules={[{ required: true }]}>
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              setShipType(e.target.value);
              form.setFieldsValue({ expressNo: undefined });
            }}
          >
            <Radio value={1}>快递</Radio>
            <Radio value={2}>物流</Radio>
          </Radio.Group>
        </FormItem>

        {shipType === 1 && (
          <FormItem required label={'快递公司'}>
            <Space>
              <FormItem
                noStyle
                name={'expressCode'}
                rules={[{ required: true, message: '请选择快递公司' }]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="搜索查找快递公司"
                  optionFilterProp="children"
                  onFocus={() => getExpress(0)('')}
                  onSearch={getExpress(200)}
                >
                  {list.map((item) => (
                    <Option value={item.expressCode} key={item.expressCode}>
                      {item.expressName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <Text>支持1000+快递公司信息同步，请下拉/搜索快递公司。</Text>
            </Space>
          </FormItem>
        )}

        {shipType === 2 && (
          <FormItem label={'物流公司'} name={'expressName'} rules={[{ required: true }]}>
            <Input placeholder={'请输入物流公司'} />
          </FormItem>
        )}

        <FormItem
          label={'物流单号'}
          name={'expressNo'}
          rules={[{ required: shipType === 1 }]}
          normalize={replaceDoubleCharacters}
        >
          <Input placeholder={'请输入物流单号'} maxLength={32} />
        </FormItem>

        <FormItem label={'修改原因'} name={'updateExpressReason'} rules={[{ required: true }]}>
          <Select>
            <Option value={'信息录入错误'}>信息录入错误</Option>
            <Option value={'买家没收到，重发'}>买家没收到，重发</Option>
            <Option value={'快递运输破损，重发'}>快递运输破损，重发</Option>
            <Option value={'其他原因'}>其他原因</Option>
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};
export default observer(ChangeShipNumberModal);
