/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-21 19:45:28
 */
// 账户相关
const useAccount = {
  CHANGE_COMPAY: "/elebuys/right/login/login/telephoneAuth/step2", // 切换企业
  POST_GET_USER_ACCOUNT: "/elebuys/member/employee/member-info", // 用户信息
  // POST_UPDATE_ACCOUNT: "/right/user/update",//更新用户
  POST_EDIT_PASSWORD: "/elebuys/right/login/updatePwd", // 修改账号密码
  POST_EDIT_MOBILE: "/elebuys/member/employee/mobile/validate", // 修改账号安全手机号
  GET_COMPANY_LIST: "/elebuys/member/employee/account-infos/by-mobile", // 根据手机号查询员工账户公司列表
  POST_SEND_CODE: "/basic/sms/sms-code", // 发送验证码
  CHECK_SMS_CODE: "/elebuys/basic/sms/sms/sms-code/check/stet", // 校验短信验证码（校验后验证码仍有效）
  GET_LIST: "/merchant/goods/flag/list", //获取商家列表数据
  UPDATA_SHOP_FLAG: "/merchant/goods/flag/add", // 新增商品标签
  EDIT_SHOP_FLAG: "/merchant/goods/flag/update", //更新商品标签
  DELETE_SHOP_FLAG: "/merchant/goods/flag/delete", //删除商品标签
};

export default useAccount;
