import React, { FC, ReactElement, useEffect } from "react";
// import { BasicLayout, PageContainer } from "@fle-ui/pro-layout";
import "./index.less";

const Home: FC = (): ReactElement => {
  return (
    // <BasicLayout offsetTop={50} offsetLeft={64} breadcrumb={false}>
    //   <PageContainer breadcrumb={false} padding={0} background="#EFF1F6">
        <div className="home-wrapper">
          <img src="https://oss.elebuys.com/resource/fx-peliten-basic-module/202110142127490008165209.png" alt="" />
        </div>
    //   </PageContainer>
    // </BasicLayout>
  );
};

export default Home;
