type goodsKey =
  | "get_basic_goods_list"
  | "online_shelf"
  | "get_cate"
  | "pickToShop"
  | "getShopGoodsList"
  | "offShelf"
  | "onlineShelf"
  | "remove"
  | "get_brand_list_page";

const goods: Map<goodsKey, string> = new Map([
  ["get_cate", "/elebuys/goods/category/getList"], //商品品类
  ["get_basic_goods_list", "/elebuys/goods/goods/search/getBasicGoodsList"], // 基础商品池
  ["online_shelf", "/elebuys/goods/pool/mall/onlineShelf"], // 商品上架-单个
  ["pickToShop", "/elebuys/goods/pool/mall/pickToShop"], // 挑选至象铺
  ["getShopGoodsList", "/elebuys/goods/pool/mall/getShopGoodsList"], // 象铺商品列表
  ["offShelf", "/elebuys/goods/pool/mall/offShelf"], // 商品下架 - 单个
  ["onlineShelf", "/elebuys/goods/pool/mall/onlineShelf"], // 商品上架 - 单个
  ["remove", "/elebuys/goods/pool/mall/remove"], // 移除商品 - 单个
  ["get_brand_list_page", "/elebuys/goods/brand/getListPage"], // 获取品牌分页
]);

export default goods;
