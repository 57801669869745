import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import Manage from "./manage";
import TransDetail from "../transDetail";
import Settlement from "./settlement";
import SettlementAccount from "./settlementAccount";

type Props = {};
const FinanceNormalModule: FC<Props> = (props: Props) => {
  return (
    <Switch>
      <Route path="/finance/normal/manage" exact component={Manage} />
      <Route path="/finance/normal/manage/can_settlement" exact component={SettlementAccount} />
      <Route path="/finance/normal/trans_detail" exact component={TransDetail} />
      <Route path="/finance/normal/settlement" exact component={Settlement} />
    </Switch>
  );
};

export default FinanceNormalModule;
