/**
 * @Description: 订单相关 http://192.168.20.27:21190/doc.html
 * @author 点点
 * @date 2021/6/22
 * @time 11:24
 */

type Key =
  | 'info'
  | 'getOrderList'
  | 'lossCheck'
  | 'orderStatusCount'
  | 'goodsChange'
  | 'goodsFreightChange'
  | 'getReason'
  | 'cancelOrder'
  | 'confirmReceipt'
  | 'confirmFreight'
  | 'getFreight'
  | 'deliverGoods'
  | 'modifyRecord'
  | 'changeFreightInfo'
  | 'getFreightDescription';

export const OrderApis: Map<Key, string> = new Map([
  // 订单详情
  ['info', '/merchant/orders/info'],
  // 查询订单列表
  ['getOrderList', '/merchant/seller/order/getOrderList'],
  // 订单状态角标
  ['orderStatusCount', '/merchant/seller/order/orderStatusCount'],
  // 改价亏本校验
  ['lossCheck', '/merchant/seller/order/lossCheck'],
  // 商品改价
  ['goodsChange', '/merchant/seller/order/goodsChange'],
  // 修改运费
  ['goodsFreightChange', '/merchant/seller/order/goodsFreightChange'],
  // 运费计算方法
  ['getFreight', '/merchant/seller/order/getFreight'],
  // 取消订单理由
  ['getReason', '/merchant/seller/order/getReason'],
  // 取消订单
  ['cancelOrder', '/merchant/seller/order/cancelOrder'],
  // 确认收款
  ['confirmReceipt', '/merchant/seller/order/confirmReceipt'],
  // 确认运费
  ['confirmFreight', '/merchant/seller/order/confirmFreight'],
  // 商品发货
  ['deliverGoods', '/merchant/seller/order/deliverGoods'],
  // 查看修改记录
  ['modifyRecord', '/merchant/seller/order/modifyRecord'],
  // 修改物流信息
  ['changeFreightInfo', '/merchant/seller/order/changeFreightInfo'],
  ['getFreightDescription', '/merchant/seller/order/getFreightDescription']
]);
