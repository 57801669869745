import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Card, Spin, Tabs, Typography } from '@fle-ui/next';
import OrderDeliveryStore from '@/stores/orderDeliveryStore';
import OrderListStore from '@/stores/orderListStore';
import OrderList from './OrderList';
import InvoiceManagement from './InvoiceManagement';
import useUrlState from '@ahooksjs/use-url-state';
import { PageContainer } from '@fle-ui/pro-layout';

const { TabPane } = Tabs;
const { Text } = Typography;

type Props = {};
const PurProxy: FC<Props> = () => {
  const [deliveryOrderQuantity, setDeliveryOrderQuantity] = useState(0);
  const { paymentCount } = OrderListStore.orderStatusCount;

  const [params, setParams] = useUrlState(
    { tab: 'default', parentOrderId: undefined },
    { navigateMode: 'replace' }
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    OrderDeliveryStore.ajaxGetDeliveryOrderQuantity().then((res) => setDeliveryOrderQuantity(res));
    OrderListStore.ajaxGetOrderStatusCount({ orderType: 2 })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [params.tab]);

  return (
    <PageContainer padding={0}>
      <Card>
        <Spin spinning={loading}>
          <Tabs
            defaultActiveKey={params.tab}
            activeKey={params.tab}
            type="card"
            onChange={(tab) => {
              const params = { tab, reset: false, parentOrderId: undefined };
              if (tab === 'default') {
                params.reset = true;
              }
              setParams(params);
            }}
          >
            <TabPane tab={'全部订单'} key={'default'} />
            <TabPane
              tab={
                <>
                  待付款 <Text type={'danger'}>{paymentCount || ''}</Text>
                </>
              }
              key={'2'}
            />

            <TabPane tab={'交易成功'} key={'5'} />
            <TabPane
              tab={
                <>
                  发货单管理
                  {!params.parentOrderId && (
                    <Text type={'danger'}>{deliveryOrderQuantity || ''}</Text>
                  )}
                </>
              }
              key={'InvoiceManagement'}
            />
          </Tabs>

          {params.tab === 'InvoiceManagement' ? (
            <InvoiceManagement />
          ) : (
            <OrderList deliveryOrderQuantity={deliveryOrderQuantity} />
          )}
        </Spin>
      </Card>
    </PageContainer>
  );
};
export default observer(PurProxy);
