import { OrderApis } from '@/api/orderApis';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import httpClient from "@/tools/httpClient";

export enum OrderCloseType {
  卖家超时未确认 = 1,
  买家超时未支付
}

export enum OrderStatus {
  待卖家确认 = 1,
  待买家付款,
  待卖家发货,
  待买家收货,
  交易成功,
  交易关闭
}

export enum PaymentMethod {
  预存货款扣款 = 1,
  对公汇款
}

export enum DeductionOfInventory {
  非现货采购,
  现货采购
}

export enum DeliveryStatus {
  默认状态,
  待发货,
  已发货
}

export enum ReceiptStatus {
  默认状态,
  待收货,
  已收货
}

export enum IsContainsTax {
  不含税,
  含税
}

interface OrderPayInfoProps {
  // 实际需支付费用
  actualPaymentPrice: number;
  // 运费现价（订单含待确认商品时为0，订单无需支付运费时为0）
  currentFreightPrice: number;
  // 订单现价（不含运费）
  currentOrderPrice: number;
  // 支付方式：1、预存货款扣款，2、对公汇款
  paymentMethod: 1 | 2;
  // 支付时间
  paymentTime: string;
  // 收款凭证地址(只有对公汇款会有数据)
  receivePaymentImage: string;
  // 收款备注
  receivePaymentRemark: string;
  // 创建时间
  createTime: string;
  // 支付订单号
  payBillNo: string;
}

type OrderReceivingInfoProps = {
  // 详细地址
  address: string;
  // 地区码
  areaCode: string;
  // 备注
  buyerRemark: string;
  // 省
  province: string;
  // 市
  city: string;
  // 区
  district: string;
  // 镇
  town: string;
  // 收货人
  consignee: string;
  id: '';
  // 收货联系方式
  mobileNumber: string;
};

export interface DetailGoodItemProp extends Omit<DetailGroupDetailGoodItemProp, 'goodsList'> {
  // 商品图片地址
  goodsImage: string;
  // 商品名称
  goodsName: string;
  // 商品 id
  id: string;
  // skuId
  skuId: string;
  //0、不含税，1、含税
  isContainsTax: 0 | 1;
  // 商品税率(不含税为0)
  taxRate: number;
  // 商品规格
  specList: string;
  // 购买总数
  quantity: number;
  // 0、不扣减库存 非现货，1、扣减库存 现货
  isDeductionOfInventory: 0 | 1;
  // 最终销售价 -> 小计
  finalSalePrice: number;
  // 是否改价过：0、未改价，1、已改价
  isChangePrice: 0 | 1;
  // 订单金额（最终销售价*购买数量）
  orderPrice: number;
  // 收货状态：0-默认状态，1-待收货，2-已收货
  receiptStatus: 0 | 1 | 2;
  // 发货状态：0-默认状态，1-待发货，2-已发货 ,3-售后中，4-已退款
  deliveryStatus: 0 | 1 | 2 | 3 | 4;
  // 发货方式：1、快递，2、物流
  deliveryMethod: 1 | 2;
  // 可发货数
  freeNum: number;
  // 已发货数
  deliveryNum: number;
  // 计量单位 1按个销售 2按箱销售
  measureUnit: 1 | 2;
  // 箱规
  boxNumber: number;
  // 单位
  unit: string;
  // orderNo
  orderNo: string;
  //
  shopId: '';
  //orderGoodsFreightId
  orderGoodsFreightId: string;
  // 快递号
  expressNo: string;
  expressCompany: string;

  freightRules: '';
  // 运费类型：1、运费模板运费，2、运费说明，3、卖家自定运费（卖家修改运费）
  freightType: 1 | 2 | 3 | null;
  // 运费说明
  freightDescription: string;

  rowSpan?: number;
  // 运费 id  为 null 说名未确认运费
  freightId?: string | null;
  NoPrice: boolean;
  returnStatus: number;
}

export type DetailGroupDetailGoodItemProp = {
  goodsList: DetailGoodItemProp[];
  // 最终运费
  finalFreightPrice: number;
  // 运费 ID
  id: string;
  //运费 是否改价过：0、未改价，1、已改价
  isChange: 0 | 1;

  freight: number;
};

export type OrderDetail = {
  createTime: string;
  // 下单时间
  creatTime: string;
  // 买家
  buyerCompanyName: string;
  // 订单号
  orderNo: string;
  // 订单状态：1、待卖家确认，2、待买家付款，3、待卖家发货，4、待买家收货，5、交易成功，6、交易关闭
  orderStatus: 1 | 2 | 3 | 4 | 5 | 6 | 10 | 20;
  // 订单状态描述
  orderStatusDesc: string;
  // 订单所属平台：1、普通象铺订单，2、京东象铺订单
  platform: 1 | 2;
  // 订单类型：1、集采批发订单，2、集采代发订单，3、印刷订单' 4 一键代发
  orderType: 1 | 2 | 3 | 4;
  // 卖家确认时间
  confirmTime: string;
  // 结束时间（交易成功时间或交易关闭时间）
  endTime: string;
  // 订单关闭理由（由买家或者卖家选择理由后+中括号+填写内容进行拼接）
  orderCloseReason: string;
  // 关闭类型 1.卖家超时未确认 2.买家超时未支付
  orderCloseType?: 1 | 2;
  // 订单支付信息
  orderPayInfo: OrderPayInfoProps;
  // 收货信息
  orderReceivingInfo: OrderReceivingInfoProps;
  // 代发
  goodsList: DetailGoodItemProp[];
  // 批发
  groupList: DetailGroupDetailGoodItemProp[];
  orderShipInfoVO: any
};

interface GetOrderDetailProps {
  orderNo: string;
}

interface OrderDetailStoreProps {
  orderDetail: OrderDetail;
  // 所有商品详情都用这个
  ajaxGetOrderDetail: (params: GetOrderDetailProps) => void;
}

class OrderDetailStore implements OrderDetailStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  orderDetail: OrderDetail = {
    platform: 1,
    creatTime: '',
    orderStatus: 1,
    orderType: 1,
    buyerCompanyName: '',
    confirmTime: '',
    createTime: '',
    endTime: '',
    goodsList: [],
    groupList: [],
    orderCloseReason: '',
    orderNo: '',
    orderPayInfo: {
      actualPaymentPrice: 0,
      createTime: '',
      currentFreightPrice: 0,
      currentOrderPrice: 0,
      payBillNo: '',
      paymentTime: '',
      paymentMethod: 1,
      receivePaymentImage: '',
      receivePaymentRemark: ''
    },
    orderReceivingInfo: {
      address: '',
      areaCode: '',
      buyerRemark: '',
      city: '',
      consignee: '',
      district: '',
      id: '',
      mobileNumber: '',
      province: '',
      town: ''
    },
    orderStatusDesc: '',
    orderShipInfoVO: {}
  };

  @action
  async ajaxGetOrderDetail(params: GetOrderDetailProps) {
    const res = await httpClient.get(OrderApis.get('info'), { params });
    if (res?.success) {
      runInAction(() => {
        this.orderDetail = res.data;
      });
    }
  }
}

export default new OrderDetailStore();
