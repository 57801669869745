import React, { FC, useState } from 'react';
import OrderDetailStore, { DetailGoodItemProp } from '@/stores/orderDetailStore';
import { getTimeDiff } from '@/tools/getTimeDiff';
import { flatRowSpanMerge } from '@/tools/rowSpanMerge';
import ConfirmOrderModal from '@/pages/shopManage/order/component/ConfirmOrderModal';
import Quantity from '../../component/Quantity';
import OrderStateBoard from '../../component/OrderStateBoard';
import { Button, Card, Space, Table } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import GoodDesc from '../../component/GoodDesc';
import PurchaseType from '../../component/PurchaseType';
import OrderGoodPrice from '../../component/OrderGoodPrice';
import OrderGoodShipPrice from '../../component/OrderGoodShipPrice';
import CancelOrderModal from '../../component/CancelOrderModal';
import { CancelOrderReason } from '@/tools/selectOption';
import OrderInfo from '../../component/OrderInfo';

const { Column } = Table;
type Props = {};
const WaitBuyerPay: FC<Props> = () => {
  const { groupList, confirmTime } = OrderDetailStore.orderDetail || {};

  const [ConfirmOrderModalVisible, setConfirmOrderModalVisible] = useState(false);
  const [CancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);
  return (
    <>
      <OrderStateBoard
        orderStatus={'等待买家付款'}
        orderInfo={getTimeDiff(confirmTime, 3) + '后买家未付款，订单将自动取消'}
        rightButton={
          <Space>
            <Button onClick={() => setCancelOrderModalVisible(true)}>取消订单</Button>
            <Button type={'primary'} onClick={() => setConfirmOrderModalVisible(true)}>
              确认收款
            </Button>
          </Space>
        }
      />

      <WhiteSpace />

      <OrderInfo />

      <WhiteSpace />

      <Card>
        <div
          style={{
            fontSize: 16,
            color: '#262626',
            fontWeight: 600,
            paddingBottom: 8,
            marginBottom: 20,
            borderBottom: '1px solid #EEEEEE'
          }}
        >
          商品列表
        </div>
        <Table bordered dataSource={flatRowSpanMerge(groupList)} rowKey={'id'} pagination={false}>
          <Column<DetailGoodItemProp>
            title={'商品图片'}
            
            width={70}
            render={(text, record) => {return <img src={record.goodsImage} alt="" style={{width:68}}/> }}
          />
          <Column<DetailGoodItemProp>
            title={'商品名称'}
            
            width={270}
            render={(text, record) => <GoodDesc {...record} />}
          />

          <Column
            title={'采购类型'}
            
            dataIndex={'isDeductionOfInventory'}
            render={(text) => <PurchaseType type={text} />}
          />
          <Column<DetailGoodItemProp>
            title={'数量'}
            
            dataIndex={'quantity'}
            render={(text, record) => <Quantity {...record} />}
          />
          <Column<DetailGoodItemProp>
            title={'单价'}
            
            render={(text, record) => <OrderGoodPrice {...record} />}
          />
          <Column
            title={'小计'}
            
            dataIndex={'orderPrice'}
            render={(text) => '￥' + (text / 100).toFixed(2)}
          />
          <Column<DetailGoodItemProp>
            title={'运费'}
            
            render={(text, record) => {
              return {
                children: <OrderGoodShipPrice {...record} />,
                props: { rowSpan: record.rowSpan }
              };
            }}
          />
          <Column title={'操作'} width={180}  />
        </Table>
      </Card>

      <CancelOrderModal
        cancelOrderReason={CancelOrderReason}
        visible={CancelOrderModalVisible}
        onCancel={() => setCancelOrderModalVisible(false)}
      />

      <ConfirmOrderModal
        visible={ConfirmOrderModalVisible}
        onCancel={() => {
          setConfirmOrderModalVisible(false);
        }}
      />
    </>
  );
};

export default WaitBuyerPay;
