/**
 * @Description: 商品库-商品设置 路由控制器
 * @author 点点
 * @date 2021/5/17
 * @time 13:47
 */

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import SendAddressList from "./sendAddressList";
import Freight from './freight'
import FreightAdd from './freight/add'

type Props = {};
const CommoditySettingModule: FC<Props> = () => {
  return (
    <Switch>
      <Route
        path="/commodity/setting/send_address_list"
        exact
        component={SendAddressList}
      />

      <Route
        path="/commodity/setting/freightTemplate"
        exact
        component={Freight}
      />

      <Route
        path="/commodity/setting/freightTemplate/add"
        exact
        component={FreightAdd}
      />

      <Route
        path="/commodity/setting/freightTemplate/edit"
        exact
        component={FreightAdd}
      />
    </Switch>
  );
};

export default CommoditySettingModule;
