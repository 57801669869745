/**
 * @Description: 订单管理 路由控制器
 * @author 点点
 * @date 2021/5/31
 * @time 13:47
 */

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import OnceProxy from '@/pages/shopManage/order/onceProxy';
import OnceProxyDetail from '@/pages/shopManage/order/onceProxy/detail';
import PurWholesale from './purWholesale';
import PurProxy from './purProxy';
import AfterSaleManege from './afterSaleManage';
import PurWholesaleDetail from './purWholesale/detail';
import PurProxyDetail from './purProxy/detail';
import InvoiceDetail from './purProxy/invoiceDetail';
import AfterSaleManegeDetail from './afterSaleManage/detail';
import { PageContainer } from '@fle-ui/pro-layout';
import PurWholesaleMaochao from './purWholesaleMaochao';

type Props = {};
const OrderModule: FC<Props> = () => {
  return (
      <Switch>
        <Route path="/shop_manage/order/pur_wholesale" exact component={PurWholesale} />
        <Route
          path="/shop_manage/order/pur_wholesale/detail/:id"
          exact
          component={PurWholesaleDetail}
        />

        <Route path="/shop_manage/order/pur_proxy" exact component={PurProxy} />
        <Route path="/shop_manage/order/once_proxy" exact component={OnceProxy} />
        <Route path="/shop_manage/order/after_sale" exact component={AfterSaleManege} />

        <Route path="/shop_manage/order/pur_proxy/detail/:id" exact component={PurProxyDetail} />
        <Route
          path="/shop_manage/order/pur_proxy/invoice_detail/detail/:id"
          exact
          component={InvoiceDetail}
        />
        <Route path="/shop_manage/order/once_proxy/detail/:id" exact component={OnceProxyDetail} />

        <Route
          path="/shop_manage/order/after_sale/detail/:id"
          exact
          component={AfterSaleManegeDetail}
        />
  
        <Route path="/shop_manage/order/pur_wholesaleMaochao" exact component={PurWholesaleMaochao} />

      </Switch>
  );
};

export default OrderModule;
