import moment from "moment";
import { getAppInfo } from "@fle-tools/env";
import Cookies from "js-cookie";
type envType = string | undefined;
const fle_env = getAppInfo().__fle_env__;

// @ts-ignore
export const ENV: envType = process.env.REACT_APP_API_ENV;
/**
 * 金额格式化
 * @param data
 */
type moneyType = number | string;

interface ShowMoneyType {
  (data: moneyType): string;
}
export const showMoney: ShowMoneyType = (data) => (+data / 100).toFixed(2);

/**
 * 时间格式化
 * @param data
 * @param fmtType
 */
type timeType = Date | number | string;

interface ShowTimeType {
  (data: timeType, fmtType: string): string;
}
export const showTime: ShowTimeType = (
  data: any,
  fmtType: string = "YYYY-MM-DD HH:mm:ss"
) => (data ? moment(data).format(fmtType) : "");

export type ListProps = {
  list: any[];
  total: number;
};

export function addKey(data: ListProps) {
  data.list = data.list.map((item, index) => Object.assign(item, { key: index }));
  return data;
}

export const getAppLink = (code: string) => {
  let link = "";
  switch (code) {
    case "101300":
      link = `/application/business`;
      break;
    case "100100":
      link = `/application/customer`;
      break;
    case "100500":
      link = `/application/flemarketing`;
      break;
    case "100200":
      link = `/application/programme`;
      break;
    case "100600":
      link = `/application/website`;
      break;
    case "100300":
      link = `/application/supply-chain`;
      break;
    case "10001":
      link = `/appStore`;
      break;
    case "精准推送":
      link = `/application/precise`;
      break;
    case "10002":
      link = `/application/workbench`;
      break;
    default:
      link = `/`;
  }
  return link;
};
