import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { Modal, Table } from '@fle-ui/next';
import { useParams } from 'react-router-dom';
import OrderOperateStore from '@/stores/orderOperateStore';

const { Column } = Table;

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  // id
  id: string;
  type: 1 | 2;
};
const PriceChangListModal: FC<Props> = (props: Props) => {
  const { visible, onClose, id, type } = props;
  const { id: orderNo } = useParams<{ id: string }>();
  const { modifyRecord } = OrderOperateStore;

  useEffect(() => {
    if (!visible) {
      return;
    }
    OrderOperateStore.ajaxGetModifyRecord({ orderNo, changeNo: id, changeType: type });
  }, [visible, id, orderNo, type]);

  return (
    <Modal title={'修改记录'} width={700} visible={visible} onCancel={onClose} onOk={onClose}>
      <Table dataSource={modifyRecord} rowKey={'createTime'}>
        <Column
          title={'原价'}
          dataIndex={'before'}
          render={(text) => '￥' + (text / 100).toFixed(2)}
        />
        <Column
          title={'改价'}
          dataIndex={'after'}
          render={(text) => '￥' + (text / 100).toFixed(2)}
        />
        <Column title={'改价原因'} dataIndex={'changeReason'} />
        <Column
          title={'变更时间'}
          dataIndex={'createTime'}
          render={(text) => moment(text).format('YYYY-MM-DD HH:mm:ss')}
        />
      </Table>
    </Modal>
  );
};

export default observer(PriceChangListModal);
