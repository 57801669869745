import React, { FC } from "react";
import { Space, Row, Col, Divider, Button } from "@fle-ui/next";
import { Icon } from "@/components";
import { useHistory } from "react-router-dom";

declare type RenderFunction = () => React.ReactNode;
interface CardItemPtops {
  name?: string;
  href?: string;
  disabled?: boolean;
}

interface RevenueCardProps {
  icon?: string;
  list?: CardItemPtops[];
  title?: string;
  subtitle?: string;
}

const NavigationCard: FC<RevenueCardProps> = ({ icon, list, title, subtitle }) => {
  const history = useHistory();

  return (
    <Row>
      <Col span={11}>
        <Space direction="horizontal" size="middle">
          {!!icon && (
            <div className="finace-icon-wrap">
              <Icon type={icon} />
            </div>
          )}
          <Space direction="vertical">
            <div className="finace-card-title">{title}</div>
            <div className="finace-card-subtitle">{subtitle}</div>
          </Space>
        </Space>
      </Col>
      <Col span={1} offset={1}>
        <Divider type="vertical" style={{ height: 50 }}></Divider>
      </Col>
      <Col span={11}>
        <div className="flex-center">
          <div className="fle-start-vertical">
            {list?.map(({ name, href, disabled = false }, index) => (
              <Button
                type="link"
                size="small"
                key={index}
                disabled={disabled}
                style={{ fontSize: 12 }}
                onClick={() => !!href && history.push(href)}>
                {name}
              </Button>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NavigationCard;
