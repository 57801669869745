import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DetailGoodItemProp } from '@/stores/orderDetailStore';
import { CancelOrderParams } from '@/types/commom';
import BuyerAddressInfo from '@/pages/shopManage/order/purProxy/invoiceDetail/components/BuyerAddressInfo';
import OrderStateBoard from './components/OrderStateBoard';
import { Button, Card, message, Space, Table } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import GoodDesc from '../../component/GoodDesc';
import CancelOrderModal from './components/CancelOrderModal';
import { CancelOrderReason } from '@/tools/selectOption';
import { observer } from 'mobx-react';
import OrderDeliveryStore, { RenderList, SendStatus } from '@/stores/orderDeliveryStore';
import { rowSpanMerge } from '@/tools/rowSpanMerge';
import DownLoadButton from './components/DownLoadButton';
import { getTimeDiff } from '@/tools/getTimeDiff';

const { Column } = Table;

type Props = {};
const WaitBuyerPay: FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const { itemVoList, createTime } = OrderDeliveryStore.orderDetail;

  const [CancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);

  const onOk = async (data: CancelOrderParams) => {
    const res = await OrderDeliveryStore.ajaxCancelOrderDelivery({ ...data });
    res ? message.success('操作成功') : message.error('操作失败');
    await OrderDeliveryStore.ajaxGetOrderInfo({ deliveryOrderId: id });
  };

  return (
    <>
      <OrderStateBoard
        orderStatus={'待买家支付运费'}
        orderInfo={getTimeDiff(createTime, 3) + '小时后买家未付款，订单将自动取消'}
        rightButton={
          <Space>
            <Button type={'primary'} onClick={() => setCancelOrderModalVisible(true)}>
              取消订单
            </Button>
          </Space>
        }
      />

      <WhiteSpace />

      <Card title={'订单明细'} extra={<DownLoadButton />}>
        <Table
          bordered
          dataSource={rowSpanMerge(itemVoList, 'md5ReceiverInfo')}
          rowKey={'id'}
          pagination={false}
        >
          <Column<DetailGoodItemProp>
            title={'商品/规格'}
            width={240}
            align={'center'}
            render={(text, record) => <GoodDesc {...record} />}
          />
          <Column title={'商品ID'} width={140} align={'center'} dataIndex={'skuId'} />
          <Column title={'数量'} width={60} align={'center'} dataIndex={'number'} />
          <Column
            title={'发货状态'}
            align={'center'}
            width={100}
            dataIndex={'sendStatus'}
            render={(sendStatus) => SendStatus[sendStatus]}
          />
          <Column<RenderList>
            title={'收货人信息'}
            ellipsis
            width={120}
            align={'center'}
            render={(text, record) => {
              return {
                children: <BuyerAddressInfo {...record} />,
                props: { rowSpan: record.rowSpan }
              };
            }}
          />

          <Column<RenderList>
            title={'运费金额'}
            width={100}
            align={'center'}
            dataIndex={'freight'}
            render={(text, record) => {
              return {
                children: '￥' + (text / 100).toFixed(2),
                props: { rowSpan: record.rowSpan }
              };
            }}
          />
          <Column title={'快递公司'} width={100} align={'center'} dataIndex={'expressName'} />
          <Column title={'快递单号'} width={100} align={'center'} dataIndex={'expressNo'} />
          <Column title={'操作'} width={160} align={'center'} />
        </Table>
      </Card>

      <CancelOrderModal
        id={id}
        cancelOrderReason={CancelOrderReason}
        visible={CancelOrderModalVisible}
        onOK={onOk}
        onCancel={() => setCancelOrderModalVisible(false)}
      />
    </>
  );
};

export default observer(WaitBuyerPay);
