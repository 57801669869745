import axios from "axios";
import type { AxiosResponse, AxiosRequestConfig, AxiosError, Method } from "axios";
import { notification, Modal } from "@fle-ui/next";
import { MAIN_HOST, DATA_CODE } from "@/config";
import { TOKEN_KEY } from "@/config/url";
import Cookies from "js-cookie";

interface RequestConfig extends AxiosRequestConfig {
  notice?: boolean;
}
interface PendingType {
  url?: string;
  method?: Method;
  params: any;
  data: any;
  cancel: Function;
}

// 登录失效
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const service = axios.create({
  baseURL: MAIN_HOST,
  timeout: 30000,
});

const pending: Array<PendingType> = [];

// 移除重复请求
const removePending = (config: RequestConfig) => {
  for (const key in pending) {
    const item: number = +key;
    const list: PendingType = pending[key];
    // 当前请求在数组中存在时执行函数体
    if (
      list.url === config.url &&
      list.method === config.method &&
      JSON.stringify(list.params) === JSON.stringify(config.params) &&
      JSON.stringify(list.data) === JSON.stringify(config.data)
    ) {
      // 执行取消操作
      list.cancel("操作太频繁，请稍后再试");
      // 从数组中移除记录
      pending.splice(item, 1);
    }
  }
};

service.interceptors.request.use(
  (config: RequestConfig) => {
    removePending(config);
    if(config.headers) config.headers.authority_token = Cookies.get(TOKEN_KEY) as any;
    config.url = `${config.url}?__date=${new Date().getTime()}`;

    return {
      ...config,
      cancelToken: source.token,
    };
  },
  (err: AxiosError) => {
    !!err.message &&
      notification.error({
        message: "提示",
        description: err.message,
      });
    return Promise.reject({ ...err, data: null });
  }
);

service.interceptors.response.use(
  (res: AxiosResponse) => {
    const { status, data, statusText } = res;
    return new Promise<any>((resolve, reject) => {
      if (status < 200 || status > 300) {
        notification.error({
          message: `请求错误: ${status}`,
          description: statusText,
        });
        reject({ ...data, data: null });
      } else if (!data.success) {
        if (+data.code === DATA_CODE.get("LOGIN_ERROR")) {
          // 登录失效后，取消后续的http请求
          source.cancel("登录失效");

          Modal.warning({
            title: "登录失效",
            content: "请点击确定重新登录。",
            okText: "确定",
            onOk: () => {
              window.location.href = `/login/index?redirectUrl=${encodeURIComponent(window.location.origin + window.location.pathname)}`;
            },
          });
        } else {
          notification.error({
            message: "提示",
            description: data.message,
          });
        }

        reject({ ...data, data: null });
      } else {
        resolve(data);
      }
    });
  },
  (err: AxiosError) => {
    if (err.message !== "登录失效") {
      err.message &&
        notification.error({
          message: "提示",
          description: err.message,
        });
    }
    return Promise.reject({ ...err, data: null });
  }
);

function get<T = any>(url?: string, config?: RequestConfig): Promise<T> {
  return url ? service.get(url, config) : Promise.reject("Cannot read property URL of undefined");
}

function post<T = any>(url?: string, data?: any, config?: RequestConfig): Promise<T> {
  return url ? service.post(url, data, config) : Promise.reject("Cannot read property URL of undefined");
}

const HttpClient = {
  get,
  post,
};

export default HttpClient;
