import { OrderApis } from '@/api/orderApis';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { Moment } from 'moment';
import { DetailGoodItemProp } from '@/stores/orderDetailStore';
import httpClient from "@/tools/httpClient";
import { ListDetailProps, PageProps } from '@/types/commom';

export interface GetOrderListParams extends PageProps {
  // 公司id
  companyId: string;
  // 下单账号
  createUserId: string;
  // 下单时间：起
  start: string;
  // 下单时间：止
  end: string;
  // 商品名称
  goodsName: string;
  // 订单号
  orderNo: string;

  // 订单状态：1、待卖家确认，2、待买家付款，3、待卖家发货，4、待买家收货，5、交易成功，6、交易关闭
  orderStatus: Array<1 | 2 | 3 | 4 | 5 | 6> | '1,2' | '3' | '4' | '5' | undefined | Array<[]>;
  // 用户id
  userId: '';
  // 来源：1、买家 ，2、卖家
  source?: 1 | 2;
  // 订单类型：1、集采批发订单，2、集采代发订单，3、印刷订单
  orderType: 1 | 2 | 3 | 4;

  date?: Moment[];
}

export interface OrderItem {
  // 订单创建时间
  createTime: string;
  // 下单账号
  createUserId: string;
  mallOrderGoodsVOList: DetailGoodItemProp[];
  // 订单号
  orderNo: string;
  orderId: string;
  // 买家公司名称
  buyerCompanyName: string;
  // 实际需支付费用 若有取此值和currentFreightPrice，无则取currentOrderPrice
  currentActualPaymentPrice: number;
  // 运费现价（订单含待确认商品时为空，订单无需支付运费时为0）
  currentFreightPrice: number;
  // 订单现价（不含运费）
  currentOrderPrice: number;
  orderStatus: 1 | 2 | 3 | 4 | 5 | 10 | 20;
  shopId: '';
  shopName: '';
  // 创建人姓名
  userName: '';
  // 订单确认时间
  confirmTime: '';
  // 发货单笔数
  deliveryCount: number;
}

interface OrderStatusCountParams {
  companyId?: '';
  // 订单类型：1、集采批发订单，2、集采代发订单，3、印刷订单
  orderType: 1 | 2 | 3 | 4;
  userId?: '';
}

interface OrderStatusCountResult {
  // 待付款订单数量
  paymentCount: 0;
  // 待发货订单数量
  sendCount: 0;
  // 已发货订单数量
  shippedCount: 0;
}

export interface CancelOptionProps {
  key: string;
  value: string;
}

interface OrderListStoreProps {
  orderList: ListDetailProps<OrderItem>;
  // 集采批发订单
  ajaxGetPurWholesaleOrderList: (params: Partial<GetOrderListParams>) => void;
  ajaxGetOrderStatusCount: (params: OrderStatusCountParams) => void;
  ajaxGetReason: () => Promise<CancelOptionProps[]>;
}

const formatParams = (params: Partial<GetOrderListParams>) => {
  if (typeof params.orderStatus === 'string') {
    return params.orderStatus.split(',').map((item) => Number(item)) as Array<
      1 | 2 | 3 | 4 | 5 | 6
    >;
  }
  return undefined;
};

class OrderListStore implements OrderListStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  orderList: ListDetailProps<OrderItem> = {
    list: [],
    pageNo: 1,
    pageSize: 20,
    total: 0
  };

  @action
  async ajaxGetPurWholesaleOrderList(params: Partial<GetOrderListParams>) {
    params.source = 2;
    params.orderType = 1;
    params.orderStatus = formatParams(params);
    const res = await httpClient.post(OrderApis.get('getOrderList'), params);
    if (res?.success) {
      runInAction(() => {
        this.orderList = res.data;
      });
    }
  }

  @action
  async ajaxGetPurProxyOrderList(params: Partial<GetOrderListParams>) {
    params.source = 2;
    params.orderType = 2;
    params.orderStatus = formatParams(params);
    const res = await httpClient.post(OrderApis.get('getOrderList'), params);
    if (res?.success) {
      runInAction(() => {
        this.orderList = res.data;
      });
    }
  }

  @action
  async ajaxGetOnceProxyOrderList(params:  Partial<GetOrderListParams>) {
    params.source = 2;
    params.orderType = 4;
    params.orderStatus = params.orderStatus
    const res = await httpClient.post(OrderApis.get('getOrderList'), params);
    if (res?.success) {
      runInAction(() => {
        this.orderList = res.data;
      });
    }
  }

  @observable
  orderStatusCount: OrderStatusCountResult = {
    paymentCount: 0,
    sendCount: 0,
    shippedCount: 0
  };

  @action
  async ajaxGetOrderStatusCount(params: OrderStatusCountParams) {
    const res = await httpClient.post(OrderApis.get('orderStatusCount'), params);
    if (res?.success) {
      runInAction(() => {
        this.orderStatusCount = res.data;
      });
    }
  }

  @action
  async ajaxGetReason(): Promise<CancelOptionProps[]> {
    const res = await httpClient.get(OrderApis.get('getReason'), {});
    return res?.success && res.data;
  }
}

export default new OrderListStore();
