/**
 * @Description: 商品库 路由控制器
 * @author 点点
 * @date 2021/5/17
 * @time 13:47
 */

import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import OnSaleList from './onSaleList';
import OffSaleList from './offSaleList'

type Props = {};
const GoodModule: FC<Props> = () => {
  return (
    <Switch>
      <Route path="/shop_manage/good/on_sale" exact component={OnSaleList} />

      <Route path="/shop_manage/good/off_sale" exact component={OffSaleList} />

      <Redirect exact from={'/shop_manage'} to={'/shop_manage/good/on_sale'} />
    </Switch>
  );
};

export default GoodModule;
