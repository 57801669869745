/**
 * @Description: 卖家发货单
 * @author 点点
 * @date 2021/6/17
 * @time 17:55
 */

import { CancelOrderParams, ListDetailProps, PageProps } from '@/types/commom';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import httpClient from "@/tools/httpClient";
import { OrderDeliveryApis } from '@/api/orderDelivery';
import { Column } from '@/tools/rowSpanMerge';
import { ExportFile } from '@/tools/exportFile';

export enum DeliveryOrderStates {
  待买家支付运费 = 1,
  待卖家发货,
  待买家确认收货,
  已完成,
  已取消
}

export enum SendStatus {
  未发货,
  已发货
}

export type statusProps = 1 | 2 | 3 | 4 | 5;


export type OrderDeliveryItem = {
  // 父订单号
  parentOrderNo: number;
  // 地址数量
  addressNumber: number;
  // 创建时间
  createTime: number;
  // 发货单号
  deliveryOrderNo: string;
  // 运费
  freight: number;
  // 发货单id
  id: string | number;
  // 商品数量
  skuNumber: number;
  // 发货单状态 0-草稿 1-代付款 2-待卖家发货 3-待买家确认收货 4-已完成 5-已取消
  status: statusProps;
};

export interface ajaxGetOrderDeliveryParams extends PageProps {
  // 发货单号
  deliveryOrderNo?: '';
  // 父订单号
  parentOrderNo?: '';
  // 发货单状态 0-草稿 1-代付款 2-待卖家发货 3-待买家确认收货 4-已完成 5-已取消
  status?: DeliveryOrderStates;
}

type DeliveryOrderInfoParams = {
  // 发货单id
  deliveryOrderId: string;
};

export interface VoListItemProps {
  // 详细地址
  address: string;
  // 地址 code
  areaCode: string;
  // 市名称
  city: string;
  // 公司 id
  companyId: string;
  // 收货人
  consignee: string;
  // 发货单 ID
  deliveryOrderId: string | number;
  // 区名称
  district: string;
  // 快递名称
  expressName: string;
  // expressCompany
  expressCompany: string;
  // 快递单号
  expressNo: string;
  /** 1快递2物流 2的时候 不用调接口 */
  expressType: 1 | 2;
  // 运费
  freight: number;
  // 商品名称
  goodsName: string;
  // id
  id: number;
  // 根据这个字段合并表格
  md5ReceiverInfo: string;
  //收货人手机号
  mobile: string;
  // 商品数量
  number: number;
  // 省名称
  province: string;
  // 发货状态 1-已发货 0-未发货
  sendStatus: 1 | 0;
  // 收货状态 1-已收货 0-未收货
  receiveStatus: 0 | 1;
  // 短地址
  shortAddress: string;
  // sku
  skuId: string;
  // 属性规格数组（Json）
  specList: string;
  // 第三方sku
  thirdSkuId: string;
  // 县名称
  town: string;
  validResult: number;
  validResultDesc: string;
  // 库存扣减类型 1-优先扣减现货库存 2-不扣减现货库存
  stockType?: 1 | 2;
}

export interface RenderList extends VoListItemProps, Column {}

type DeliveryOrderInfo = {
  createTime: string;
  // 发货单数[不同地址数]
  addressNumber: 0;
  // 运费
  freight: number;
  // 发货单 ID
  id: '';
  // 是否已确认 1-已确认 0-未确认
  isConfirmed: 1 | 0;
  // 订单明细列表
  itemVoList: VoListItemProps[];
  // 发货商品数
  skuNumber: 0;
  // 发货单状态 0-草稿 1-代付款 2-待卖家发货 3-待买家确认收货 4-已完成 5-已取消
  status: statusProps;
  cancelReason: string;
  cancelReasonDetail?: string;
};

export type ConsignParams = {
  // 发货单id
  deliveryOrderId: string;
  // 物流公司code
  expressCode: number;
  // 物流单号
  expressNo: string;
  // 物流公司
  expressName: string;
  //发货类型 1-快递 2-物流
  expressType: 1 | 2;
  // 明细id order_delivery_item表主键
  itemId: number;
  // 库存扣减类型 1-优先扣减现货库存 2-不扣减现货库存
  stockType: 1 | 2;
};

export interface SearchOrderParams {
  // 地址code
  areaCode: string;
  // 收货人姓名
  consignee: string;
  // 发货单id
  deliveryOrderId: string;
  // 收货人手机号
  mobile: string;
  // 收货状态 1-已收货 0-未收货
  receiveStatus: 0 | 1;
  // 发货状态 1-已发货 0-未发货
  sendStatus: 0 | 1;
}

interface OrderDeliveryProps {
  OrderDeliveryList: ListDetailProps<OrderDeliveryItem>;
  orderDetail: DeliveryOrderInfo;
  ajaxGetOrderDeliveryList: (params: ajaxGetOrderDeliveryParams) => void;
  ajaxCancelOrderDelivery: (params: CancelOrderParams) => Promise<boolean>;
  ajaxGetOrderInfo: (params: DeliveryOrderInfoParams) => void;
  ajaxDownload: (params: DeliveryOrderInfoParams) => void;
  ajaxSearchOrderItem: (params: Partial<ConsignParams>) => void;
  ajaxGetDeliveryOrderQuantity: () => Promise<number>;
  ajaxCosign: (params: ConsignParams) => Promise<boolean>;
  ajaxChangeExpressInfo: (params: ConsignParams) => Promise<boolean>;
  ajaxGetGoodsStock: (params: Pick<ConsignParams, 'itemId'>) => Promise<number>;
}

class OrderDeliveryStore implements OrderDeliveryProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  OrderDeliveryList: ListDetailProps<OrderDeliveryItem> = {
    list: [],
    pageNo: 1,
    pageSize: 20,
    total: 0
  };

  @action
  ajaxGetOrderDeliveryList = async (params: ajaxGetOrderDeliveryParams) => {
    const data = await httpClient.post(OrderDeliveryApis.get('pageQuery'), params);
    if (data?.success) {
      runInAction(() => {
        this.OrderDeliveryList = data.data;
      });
    }
  };

  @action
  async ajaxCancelOrderDelivery(params: CancelOrderParams): Promise<boolean> {
    const data = await httpClient.post(OrderDeliveryApis.get('cancel'), params);
    return data?.success;
  }

  @observable
  orderDetail: DeliveryOrderInfo = {
    cancelReason: '',
    createTime: '',
    addressNumber: 0,
    freight: 0,
    id: '',
    isConfirmed: 1,
    itemVoList: [],
    skuNumber: 0,
    status: 2
  };

  @action
  async ajaxGetOrderInfo(params: DeliveryOrderInfoParams) {
    const data = await httpClient.post(OrderDeliveryApis.get('getOrderInfo'), params);
    if (data?.success) {
      runInAction(() => {
        this.orderDetail = data.data;
      });
    }
  }

  @action
  ajaxDownload(params: DeliveryOrderInfoParams): void {
    ExportFile(
      OrderDeliveryApis.get('download'),
      'POST',
      params,
      '交易明细',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      () => {},
      () => {}
    );
  }

  @action
  async ajaxSearchOrderItem(params: Partial<SearchOrderParams>) {
    const data = await httpClient.post(OrderDeliveryApis.get('searchOrderItem'), params);
    if (data?.success) {
      runInAction(() => {
        this.orderDetail.itemVoList = data.data;
      });
    }
  }

  @action
  async ajaxGetDeliveryOrderQuantity(): Promise<number> {
    try {
      const res = await httpClient.post(OrderDeliveryApis.get('getDeliveryOrderQuantity'), {});
      return res?.success && res.data;
    } catch (e) {
      return 0;
    }
  }

  @action
  async ajaxCosign(params: ConsignParams): Promise<boolean> {
    const res = await httpClient.post(OrderDeliveryApis.get('consign'), params);
    return res?.success;
  }

  @action
  async ajaxChangeExpressInfo(params: ConsignParams): Promise<boolean> {
    const res = await httpClient.post(OrderDeliveryApis.get('changeExpressInfo'), params);
    return res?.success;
  }

  @action
  async ajaxGetGoodsStock(params: Pick<ConsignParams, 'itemId'>): Promise<number> {
    const res = await httpClient.post(OrderDeliveryApis.get('getGoodsStock'), params);
    return res?.success && res.data;
  }
}

export default new OrderDeliveryStore();
