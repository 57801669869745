import React, { useState, useEffect } from "react";
import { withRouter, RouterProps } from "react-router-dom";
import { Button, Modal, Row, Col, message, Divider, Spin, Card } from "@fle-ui/next";

import { formatTime, showMoney } from "@/tools/helpers";
import { freightStore } from "@/stores";
import "./index.less";
import { WhiteSpace } from "../../../../components";
import { useHistory } from "react-router-dom";

const { confirm, warning } = Modal;

type RuleProps = {
  isDefault: number | null;
  provinceNames: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
  firstNumber: number;
  firstPrice: string | number;
  nextNumber: number;
  nextPrice: string | number;
};

type FreightItemProps = {
  freightTemplateId: number;
  goodsNumber: number;
  isFreeShipping: true;
  key: number;
  modifyTime: number;
  rules: RuleProps[];
  templateName: string;
  tradeType: 1 | null;
  noDeliveryRegionNames: string | null;
};

function Freight(props: RouterProps) {
  const [dataList, setDataList] = useState<FreightItemProps[]>([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    _getData();
    localStorage.removeItem("freightArea");
  }, []);

  const _getData = async () => {
    setLoading(true);
    const res = await freightStore.getFreightList({});
    setDataList(res);
    setLoading(false);
  };

  const goToAdd = async () => {
    props.history.push({
      pathname: "/commodity/setting/freightTemplate/add",
    });
  };

  return (
    <Card>
      <Spin spinning={loading}>
        <div className="freight_temp_list_container">
          <div className="add_line_container">
            <Button type="primary" disabled={dataList.length >= 10} onClick={goToAdd}>
              新建运费模板
            </Button>
            {dataList.length >= 10 && <span className="add_line_tip">最多可添加10个运费模板</span>}
          </div>
          <WhiteSpace />
          <div className="temp_list_container">
            {dataList.map((item) => (
              <FreightCard key={item.key} item={item} onChange={() => _getData()} />
            ))}
          </div>
        </div>
      </Spin>
    </Card>
  );
}

type FreightCardProps = {
  item: FreightItemProps;
  onChange: Function;
};
const FreightCard = function (props: FreightCardProps) {
  const { item, onChange } = props;

  const history = useHistory();

  const editFreightTemp = () => {
    history.push(`/commodity/setting/freightTemplate/edit?id=${item.freightTemplateId}`);
  };

  const showDelConfirm = () => {
    if (!!item.goodsNumber) {
      warning({
        title: "删除失败",
        content: "运费模版正在被使用，无法删除！",
      });
    } else {
      confirm({
        title: "确认删除运费模板？",
        async onOk() {
          const res = await freightStore.deleteFreight({
            templateId: item.freightTemplateId,
          });
          if (res.success) {
            message.success("删除成功！");
            onChange();
          }
        },
      });
    }
  };

  return (
    <div className="temp_container">
      <div className="temp_head_container">
        <div className="head_info">
          <p className="temp_name">{item.templateName}</p>
        </div>
        <div className="fx-flexbox">
          <p className="temp_used">{`(已被${+item.goodsNumber}个商品使用)`}</p>
          <p className="temp_time">最后编辑时间：{formatTime(item.modifyTime)}</p>
          {/* <Button type="link" size="small" onClick={goToGoodsList}>查看商品</Button> */}
          {/* <Divider type="vertical"></Divider> */}
          <Button type="link" size="small" onClick={editFreightTemp}>
            编辑
          </Button>
          <Divider type="vertical" />
          <Button type="link" size="small" onClick={showDelConfirm}>
            删除
          </Button>
        </div>
      </div>
      <div className="temp_content_container">
        <Row className="temp_content_title">
          <Col span={8}>运送至</Col>
          <Col span={9}>
            费率(
            {item.isFreeShipping ? "卖家承担运费" : `按${getUnit(item.tradeType, true)}计费`})
          </Col>
          <Col span={7}>不可配送区域</Col>
        </Row>
        {item.rules.map((itemIn, indexIn: number) => (
          <Row className="temp_content_info" key={indexIn}>
            <Col span={8}>
              <>{itemIn.isDefault === 1 || itemIn.isDefault === null ? "全国" : itemIn.provinceNames}</>
            </Col>

            <Col span={9}>
              {item.isFreeShipping
                ? "包邮"
                : `${item.tradeType === 1 ? itemIn.firstNumber : itemIn.firstNumber / 1e6}${getUnit(
                    item.tradeType
                  )}以内${showMoney(itemIn.firstPrice)}元；每增加${
                    item.tradeType === 1 ? itemIn.nextNumber : itemIn.nextNumber / 1e6
                  }${getUnit(item.tradeType)}，增加运费${showMoney(itemIn.nextPrice)}元`}
            </Col>
            <Col span={7}>{indexIn === 0 && (item.noDeliveryRegionNames || "无")}</Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

const getUnit = (type: 1 | null, unitType?: boolean) => (type === 1 ? "件" : unitType ? "重" : "公斤");

export default withRouter(Freight);
