import { CSSProperties } from 'react';

export const OrderListStyle: { [key: string]: CSSProperties } = {
  orderListHeader: {
    color: '#262626',
    fontWeight: 600,
    padding: '16px 0',
    textAlign: 'center'
  },

  boxWrap: {
    border: '1px solid #eeeeee',
    marginBottom: 10
  },

  orderDetailHeader: {
    background: '#fafafa',
    color: '#262626',
    padding: '10px 0',
    paddingLeft: 20,
    margin:'0',
    borderBottom: '1px solid #eeeeee'
  },

  orderType: { display: 'flex', alignItems: 'center', justifyContent: 'start' },

  goodQuantity: { display: 'flex', alignItems: 'center', justifyContent: 'start' },

  goodPrice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center'
  },

  orderStatus: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  tableVerticalLeft: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column'
  }
};
