import { Card, Col, Row, Space, Typography } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useState } from 'react';
import OrderDetailStore, { OrderStatus, PaymentMethod } from '@/stores/orderDetailStore';
import OrderInfoItem from './OrderInfoItem';

const { Title, Text } = Typography;

const OrderInfo: FC = () => {
  const { orderStatus, groupList, orderType, orderShipInfoVO } = OrderDetailStore.orderDetail;
  let {
    buyerCompanyName,
    createTime,
    confirmTime,
    endTime,
    orderNo,
    orderPayInfo,
    orderReceivingInfo
  } = OrderDetailStore.orderDetail;
  const { consignee, province, city, district, town, address, mobileNumber, buyerRemark } =
    orderReceivingInfo;

  let {
    actualPaymentPrice,
    currentFreightPrice,
    currentOrderPrice,
    paymentMethod,
    paymentTime,
    receivePaymentImage,
    receivePaymentRemark,
    payBillNo
  } = orderPayInfo;

  const [big, setBig] = useState(false);

  return (
    <Card>
      <Title level={5}>订单信息</Title>
      <div style={{ borderBottom: '1px solid #eee', marginBottom: 20 }} />
      <Space size={[160, 10]} wrap>
        {consignee && <OrderInfoItem label={'收货人'} value={consignee} />}
        <OrderInfoItem label={'订单号'} value={orderNo} />
        {orderType === 1 && (
          <OrderInfoItem
            label={'卖家确认时间'}
            value={confirmTime && moment(confirmTime).format('YYYY-MM-DD HH:mm:ss')}
          />
        )}
        {OrderStatus.交易成功 === orderStatus && (
          <OrderInfoItem
            label={'交易完成时间'}
            value={endTime && moment(endTime).format('YYYY-MM-DD HH:mm:ss')}
          />
        )}
        {OrderStatus.交易关闭 === orderStatus && (
          <OrderInfoItem
            label={'订单关闭时间'}
            value={endTime && moment(endTime).format('YYYY-MM-DD HH:mm:ss')}
          />
        )}

        <OrderInfoItem label={'订单备注'} value={buyerRemark} />

        <OrderInfoItem label={'联系方式'} value={mobileNumber} />
        <OrderInfoItem label={'买家'} value={buyerCompanyName} />
        <OrderInfoItem
          label={'买家支付时间'}
          value={paymentTime && moment(paymentTime).format('YYYY-MM-DD HH:mm:ss')}
        />

        <OrderInfoItem
          label={'收货地址'}
          value={[province, city, district, town, address].filter((item) => item).join(' ')}
        />
        <OrderInfoItem
          label={'下单时间'}
          value={createTime && moment(createTime).format('YYYY-MM-DD HH:mm:ss')}
        />

        {[OrderStatus.交易成功, OrderStatus.待买家收货, OrderStatus.待卖家发货].includes(
          orderStatus
        ) && <OrderInfoItem label={'支付流水号'} value={payBillNo} />}
        {
          orderShipInfoVO?.shipType === 1 && <OrderInfoItem label={"配送方式"} value={'快递'} />
        }
        {
          orderShipInfoVO?.shipType === 1 && <OrderInfoItem label={"预定发货时间"} value={orderShipInfoVO?.expectShipTime} />
        }
        {
          orderShipInfoVO?.shipType === 2 && <OrderInfoItem label={"配送方式"} value={'商家配送'} />
        }
        {
          orderShipInfoVO?.shipType === 2 && <OrderInfoItem label={"期望配送时间"} value={orderShipInfoVO?.shipDate + ' ' + orderShipInfoVO?.shipTime} />
        }
        {
          orderShipInfoVO?.shipType === 3 && <OrderInfoItem label={"配送方式"} value={'门店自提'} />
        }
        {
          orderShipInfoVO?.shipType === 3 && <OrderInfoItem label={"自提门店"} value={orderShipInfoVO?.shopName} />
        }
        {
          orderShipInfoVO?.shipType === 3 && <OrderInfoItem label={"自提门店地址"} value={orderShipInfoVO?.shopAddress} />
        }
        {
          orderShipInfoVO?.shipType === 3 && <OrderInfoItem label={"自提时间"} value={orderShipInfoVO?.shipDate + ' ' + orderShipInfoVO?.shipTime} />
        }
      </Space>
    </Card>
  );
};

export default observer(OrderInfo);
