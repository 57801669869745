import React, { FC } from 'react';
import { DeductionOfInventory } from '@/stores/orderDetailStore';

type Props = {
  type: 0 | 1;
};
const PurchaseType: FC<Props> = (props: Props) => {
  const { type } = props;
  return <> {DeductionOfInventory[type]}</>;
};

export default PurchaseType;
