import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { Form, Input, InputNumber, message, Modal } from '@fle-ui/next';
import OrderDetailStore, { DetailGoodItemProp } from '@/stores/orderDetailStore';
import OrderOperateStore from '@/stores/orderOperateStore';
import PriceConfig from '@/tools/priceConfig';

const FormItem = Form.Item;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: () => void;
  goodDetail: DetailGoodItemProp;
};
const ChangeShipPriceModal: FC<Props> = (props: Props) => {
  const { visible, onClose, goodDetail } = props;

  const { finalFreightPrice, freightId, orderNo } = goodDetail;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onHandleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      OrderOperateStore.ajaxGoodsFreightChange({
        ...values,
        orderNo,
        goodsFreightId: freightId,
        changePrice: values.changePrice * 100
      })
        .then(async (res) => {
          if (!res) {
            message.error('改价失败');
            return;
          }
          message.success('改价成功');
          OrderDetailStore.ajaxGetOrderDetail({ orderNo }).finally(onClose);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Modal
      title={'修改运费'}
      open={visible}
      onCancel={onClose}
      destroyOnClose
      onOk={onHandleOk}
      confirmLoading={loading}
    >
      <Form {...layout} form={form} preserve={false}>
        <FormItem label={'原价'}>￥{(finalFreightPrice / 100).toFixed(2)}</FormItem>
        <FormItem
          label={'改价'}
          required
          name={'changePrice'}
          rules={[{ required: true, message: '请输入运费' }]}
        >
          <InputNumber
            style={{ width: 200 }}
            type={'number'}
            placeholder={'输入金额'}
            min={0}
            max={PriceConfig.Max}
          />
        </FormItem>
        <FormItem name={'changeReason'} label={'修改原因'}>
          <Input.TextArea maxLength={50} showCount />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default observer(ChangeShipPriceModal);
