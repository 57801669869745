import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Button, Descriptions, Modal, Spin } from '@fle-ui/next';
import OrderOperateStore from '@/stores/orderOperateStore';
import GetShipRule from '@/pages/shopManage/order/component/GetShipRule';

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  // id
  id: string;
};
const ShipCalculationMethod: FC<Props> = (props: Props) => {
  const { visible, onClose, id } = props;
  const { finalFreightPrice, freightRules, freightTypeDesc, freightType } =
    OrderOperateStore.goodsFreightDescVO;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!visible) {
      return;
    }
    setLoading(true);
    id &&
      OrderOperateStore.ajaxGetFreight({ orderGoodsFreightId: id as string }).finally(() =>
        setLoading(false)
      );
  }, [visible, id]);

  return (
    <Modal
      width={700}
      title={'运费计算方法'}
      open={visible}
      destroyOnClose
      footer={
        <Button type={'primary'} onClick={onClose}>
          确认
        </Button>
      }
      onCancel={onClose}
      onOk={onClose}
    >
      <Spin spinning={loading}>
        <Descriptions column={1}>
          <Descriptions.Item label="运费收取方式">{freightTypeDesc}</Descriptions.Item>
          <Descriptions.Item label="运费规则">
            {freightType === 1 ? <GetShipRule freightRules={freightRules} /> : freightRules}
          </Descriptions.Item>
          <Descriptions.Item label="运费金额">
            ￥{(finalFreightPrice / 100).toFixed(2)}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal>
  );
};

export default observer(ShipCalculationMethod);
