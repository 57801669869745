import React, { useRef, useState, useEffect } from "react";
import { Select, Button, Cascader, message, Divider } from "@fle-ui/next";
import type { ProColumns, ActionType } from "@fle-ui/pro-table";
import { GoodInfo, OncePrice, PurchasePrice, SaleType, Stock, GoodsAvailableStatus } from "@/components/TableColumn";
import ProTable from "@fle-ui/pro-table";
import { observer } from "mobx-react";
import { useRequest } from "ahooks";

import { goodsStore, CommonStore, dgssStore } from "@/stores";
import { saleType } from "@/tools/selectOption";
import { useHistory } from "react-router-dom";
import type { GoodItemProps } from "@/types/store/goods";
import { getGoodsUrl } from "@/config/url";

// type paramsType = {
//   brand_id?: string;
//   cities?: string;
//   keyword?: string;
//   pageNo: number;
//   pageSize?: number;
//   isOnce?: number;
//   isPurchase?: number;
//   categoryTree?: number;
// };

const initParams = { pageNo: 1, pageSize: 10 };

const PubList: React.FC = () => {
  const history = useHistory();
  const actionRef = useRef<ActionType>();

  const { dgssCityNameList } = dgssStore;
  const { brandListPage, getBrandListPage } = goodsStore;
  const { accountInfo } = CommonStore;

  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getMoreCate = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let arr = await goodsStore.getCategory({
      parentId: targetOption.value,
    });
    if (arr.length) {
      targetOption.children = arr.map((item: any) => ({
        label: item.categoryName,
        value: item.categoryId,
        isLeaf: true,
      }));
    }

    targetOption.loading = false;
    setCategoryData([...categoryData]);
  };

  const initCategory = async () => {
    let categoryData;
    let cate1 = await goodsStore.getCategory({
      parentId: 0,
    });

    categoryData = cate1?.map((item: any) => ({
      label: item.categoryName,
      value: item.categoryId,
      isLeaf: false,
    }));
    setCategoryData(categoryData);
  };

  const { run } = useRequest(getBrandListPage, {
    debounceWait: 300,
    manual: true,
  });

  useEffect(() => {
    accountInfo.isDGSS && dgssStore.getDgssCityList();
    accountInfo.isDGSS && run(initParams);
    !categoryData.length && initCategory();
  }, [accountInfo]);

  const columns: ProColumns<GoodItemProps>[] = [
    {
      title: "商品信息",
      dataIndex: "goodsInfo",
      key: "keyword",
      width: 300,
      fieldProps: {
        placeholder: "输入商品名称/商品ID",
        allowClear: true,
      },
      render: GoodInfo,
    },
    {
      title: "商品类目",
      dataIndex: "category",
      key: "categoryTree",
      hideInTable: true,
      renderFormItem: () => {
        return <Cascader placeholder="请选择分类" options={categoryData} loadData={getMoreCate} />;
      },
      render: GoodInfo,
    },
    {
      title: "商品品牌",
      dataIndex: "brandName",
      key: "brandId",
      align: "center",
      hideInTable: !accountInfo.isDGSS,
      hideInSearch: !accountInfo.isDGSS,
      renderFormItem: () => {
        return (
          <Select
            allowClear
            showSearch
            placeholder="请选择商品品牌"
            options={brandListPage}
            filterOption={(input, option) =>
              (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
            onSearch={(keyword) => run({ keyword, pageNo: 1, pageSize: 20 })}
          />
        );
      },
    },
    {
      title: "销售类型",
      dataIndex: "saleType",
      key: "saleType",
      align: "center",
      render: SaleType,
      initialValue: "",
      renderFormItem: () => {
        return (
          <Select
            options={[
              {
                label: "全部",
                value: "",
              },
              {
                label: "集采批发/代发、一件代发",
                value: "0",
              },
              {
                label: "集采批发/代发",
                value: "1",
              },
              {
                label: "一件代发",
                value: "2",
              },
            ]}
          />
        );
      },
    },
    {
      title: "集采价",
      dataIndex: "purchasePrice",
      key: "purchasePrice",
      width: 100,
      align: "center",
      hideInSearch: true,
      hideInTable: accountInfo.isDGSS,
      render: PurchasePrice,
    },
    {
      title: "代发价",
      dataIndex: "oncePrice",
      key: "oncePrice",
      align: "center",
      hideInSearch: true,
      render: OncePrice,
    },
    {
      title: "现货库存",
      dataIndex: "stock",
      key: "stock",
      align: "center",
      hideInSearch: true,
      hideInTable: accountInfo.isApiSupplier,
      render: Stock,
    },
    {
      title: "售卖城市",
      dataIndex: "saleableCity",
      key: "cities",
      width: 300,
      align: "center",
      fieldProps: {
        placeholder: "请选择售卖城市",
      },
      hideInTable: !accountInfo.isDGSS,
      hideInSearch: !accountInfo.isDGSS,
      renderFormItem: () => {
        return (
          <Select
            mode="multiple"
            allowClear
            showSearch
            options={dgssCityNameList}
            filterOption={(input, option) =>
              (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
      render: (_, record) => (
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}>
          {record.saleableCity}
        </div>
      ),
    },
    {
      title: "可售状态",
      dataIndex: "goodsAvailableStatus",
      key: "goodsAvailableStatus",
      align: "center",
      hideInSearch: true,
      render: GoodsAvailableStatus,
    },
    {
      title: "操作",
      dataIndex: "option",
      key: "option",
      width: 170,
      align: "center",
      valueType: "option",
      render: (_, { goodsId, goodsStatus }) => (
        <>
          <Button
            type={"link"}
            size="small"
            onClick={() => history.push(getGoodsUrl(accountInfo.source, "edit", goodsId))}>
            编辑
          </Button>
          <Divider type="vertical" />
          {accountInfo?.shopFlag && (
            <Button
              disabled={goodsStatus === 20}
              type={"link"}
              size="small"
              onClick={async () => {
                try {
                  const success = await goodsStore.pickToShop(goodsId);
                  if (success) {
                    message.success("上架成功");
                    setLoading(true);
                    const timeoutId = setTimeout(() => {
                      actionRef.current?.reload();
                      clearTimeout(timeoutId);
                      setLoading(false);
                    }, 1500);
                  } else {
                    message.error("上架失败");
                  }
                } catch (error) {}
              }}>
              上架至象铺
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <ProTable<GoodItemProps>
      columns={columns}
      actionRef={actionRef}
      resizable={false}
      bordered={true}
      scroll={{ x: 1300 }}
      loading={loading}
      request={async (params = {}) => {
        const _params = {
          ...params,
          pageNo: params.current,
          ...(params.saleType === undefined ? { isOnce: null, isPurchase: undefined } : saleType[params.saleType]),
          categoryTree: params.categoryTree ? params.categoryTree.filter((item: any) => item).reverse()[0] : undefined,
          saleableCity: params.cities?.reduce(
            (str: string, id: number, index: number) => `${str}${!!index ? "," : ""}${id}`,
            ""
          ),
        };
        setLoading(true);

        const res = await goodsStore.getPubList(_params);

        setLoading(false);
        return res;
      }}
      rowKey="goodsId"
      search={{
        labelWidth: "auto",
      }}
      pagination={{
        pageSize: 10,
      }}
      dateFormatter="string"
      options={{ setting: false, fullScreen: true }}
    />
  );
};

export default observer(PubList);
