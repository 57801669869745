import { Typography } from '@fle-ui/next';
import React, { FC } from 'react';
import { VoListItemProps } from 'stores/OrderDeliveryStore';

const BuyerAddressInfo: FC<VoListItemProps> = (props) => {
  const { address, consignee, mobile } = props;
  return (
    <div style={{ textAlign: 'left' }}>
      {consignee} <br />
      {mobile} <br />
      <Typography.Text ellipsis style={{ width: '100%' }}>
        {address}
      </Typography.Text>
      <br />
    </div>
  );
};

export default BuyerAddressInfo;
