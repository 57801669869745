import React, { useState, useEffect } from 'react';
import {
  Card,
  InputNumber,
  Input,
  Row,
  Col,
  Button,
  Radio,
  message,
  Typography
} from '@fle-ui/next';
import lodash from 'lodash';
import { withRouter } from 'react-router-dom';
import { Modals } from '@/components';
import { getQuery, showMoney } from '@/tools/helpers';
import { freightStore } from '@/stores';
import './index.less';

const { StoreChooseAreaModal } = Modals;

function Add(props: any) {
  const [pageType, setPageType] = useState<'add' | 'edit'>('add');
  const [name, setName] = useState(''); //名称
  const [freeShip, setFreeShip] = useState(0); //包邮
  const [chargeType, setChargeType] = useState(1); //计费方式
  const [showFreightAreaModal, setShowFreightAreaModal] = useState(false); //选择区域弹窗
  const [changeAreaIndex, setChangeAreaIndex] = useState(0); //选择区域弹窗
  const [freightAreaType, setFreightAreaType] = useState('');
  const [areaModalType, setAreaModalType] = useState('able');

  const [freightInput, setFreightInput] = useState<any[]>([
    {
      firstNum: '',
      firstMoney: '',
      continueNum: '',
      continueMoney: ''
    }
  ]); //运费规则

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    localStorage.removeItem('freightArea');
    let query = getQuery();
    if (query.id) {
      setPageType('edit');
      _initEditInfo(query.id);
    } else {
      setPageType('add');
    }
  }, []);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  const _initEditInfo = async (id: any) => {
    const freightInfo = await freightStore.getFreightDetail({ templateId: id });
    // 名称
    setName(freightInfo.templateName);
    // 包邮
    setFreeShip(+freightInfo.isFreeShipping);

    if (!freightInfo.isFreeShipping) {
      // 计费方式
      setChargeType(freightInfo.tradeType);
      // 计费规则
      setFreightInput(
        freightInfo.rules && freightInfo.rules.length
          ? freightInfo.rules.map((item: any) => ({
              firstNum:
                Number(freightInfo.tradeType) === 1
                  ? item.firstNumber
                  : item.firstNumber / 1e6,
              firstMoney: showMoney(item.firstPrice),
              continueNum:
                Number(freightInfo.tradeType) === 1
                  ? item.nextNumber
                  : item.nextNumber / 1e6,
              continueMoney: showMoney(item.nextPrice)
            }))
          : [
              {
                firstNum: '',
                firstMoney: '',
                continueNum: '',
                continueMoney: ''
              }
            ]
      );
      //获取区域数据
      await freightStore.getFreightArea({ dictType: 'freightArea' });
      // 取出所有已选的省
      let area =
        freightInfo.rules && freightInfo.rules.length
          ? lodash.flatten(
              freightInfo.rules.map((item: { province: string }, index: any) =>
                item.province.split(',').map((itemIn) => ({
                  index,
                  id: itemIn
                }))
              )
            )
          : [];
      let disabledArea = freightInfo.noDeliveryRegion
        ? freightInfo.noDeliveryRegion
            .split(',')
            .map((i: string | number) => +i)
        : [];
      let areaArr = JSON.parse(localStorage.getItem('freightArea') as string);

      areaArr =
        areaArr && areaArr.length
          ? areaArr.map((item: { children: any[]; freightIndex: any }) => {
              if (!item.children) {
                item.children = []
                return item
              }
              item.children = item.children.map((itemIn) => {
                let obj: any = area.find(
                  (area: any) => area.id == itemIn.dictKey
                );
                let disObj = disabledArea.find(
                  (id: any) => id == itemIn.dictKey
                );
                if (obj) {
                  itemIn.freightIndex = obj.index;
                }
                if (disObj) {
                  itemIn.freightIndex = -1;
                }
                return itemIn;
              });
              let freightIndex = item.children[0]?.freightIndex;
              // 判断父节点是否需要设置freightIndex
              if (freightIndex) {
                let flag = item.children.every(
                  (itemIn) => itemIn.freightIndex === freightIndex
                );
                if (flag) {
                  item.freightIndex = freightIndex;
                }
              }
              return item;
            })
          : [];
      localStorage.setItem('freightArea', JSON.stringify(areaArr));
      setRefresh(true);
    }
  };

  const getAreaNames = (index: any) => {
    return (
      localStorage.getItem('freightArea')
        ? lodash.flatten(
            JSON.parse(localStorage.getItem('freightArea') as string).map(
              (item: any) => {
                if (item?.children) {
                  return item.children
                } else {
                  return []
                }
              }
            )
          )
        : []
    )
      .filter((itemIn: any) => itemIn.freightIndex === index)
      .map((itemIn: any) => itemIn.dictValue)
      .join('，');
  };

  const changeInput = (val: any, index: any, type: any) => {
    freightInput[index][type] = val;
    setFreightInput(freightInput);
    setRefresh(true);
  };

  const addAreaFreight = (type: any) => {
    if (type === 'disabled') {
      setChangeAreaIndex(-1);
      setShowFreightAreaModal(true);
      setFreightAreaType(getAreaNames(-1) ? 'edit' : 'add');
      setAreaModalType('disabled');
    } else {
      freightInput.push({
        firstNum: '',
        firstMoney: '',
        continueNum: '',
        continueMoney: ''
      });

      setFreightInput(freightInput);
      setChangeAreaIndex(freightInput.length - 1);
      setShowFreightAreaModal(true);
      setFreightAreaType('add');
      setAreaModalType('');
    }
  };

  const editAreaFreight = (index: any) => {
    setChangeAreaIndex(index);
    setShowFreightAreaModal(true);
    setFreightAreaType('edit');
  };

  const deleteAreaFreight = (index: any) => {
    let area = JSON.parse(localStorage.getItem('freightArea') as string);

    area = area.map((item: any) => {
      if (item.freightIndex === index) {
        item.freightIndex = 0;
      } else if (item.freightIndex > index) {
        item.freightIndex -= 1;
      }
      item.children = item.children.map((itemIn: any) => {
        if (itemIn.freightIndex === index) {
          itemIn.freightIndex = 0;
        } else if (itemIn.freightIndex > index) {
          itemIn.freightIndex -= 1;
        }
        return itemIn;
      });
      return item;
    });

    localStorage.setItem('freightArea', JSON.stringify(area));
    freightInput.splice(index, 1);
    setFreightInput(freightInput);
    setRefresh(true);
  };

  const saveFreightTemp = async () => {
    if (!name) {
      message.error('请输入模板名称');
      return;
    }
    let params = {
      templateName: name,
      freeShipping: !!freeShip
    };
    if (!freeShip) {
      let boolArr = freightInput
        .filter((item, index) => {
          return !(
            // 表单沒值
            (
              Object.values(item).every((item) => !item) &&
              // 没有选地方
              getAreaNames(index).length === 0
            )
          );
        })
        .map((item) => item.firstNum && item.firstMoney && item.continueNum && item.continueMoney);

      let area = JSON.parse(localStorage.getItem('freightArea') as string);
      let areaArr = area
        ? lodash.flatten(area.map((item: { children: any }) => item.children))
        : [];
      if (!boolArr.every((item) => item)) {
        message.error('请输入区域计费方式');
        return;
      }
      if (boolArr.length > 1) {
        let numArr = Array.from(new Array(boolArr.length).keys()).slice(1);
        let flag = numArr.every(
          (item) => areaArr.findIndex((itemIn: any) => itemIn.freightIndex === item) > -1
        );
        if (!flag) {
          return message.error('请选择计费方式的区域');
        }
      }
      let rules = freightInput
        .filter((item, index) => {
          return !(
            // 表单沒值
            (
              Object.values(item).every((item) => !item) &&
              // 没有选地方
              getAreaNames(index).length === 0
            )
          );
        })
        .map((item, index) => {
          let province = '';
          if (index > 0) {
            province = areaArr
              .filter((area: any) => area.freightIndex === index)
              .map((area: any) => area.dictKey)
              .join(',');
          }
          return {
            firstNumber: chargeType === 1 ? item.firstNum : item.firstNum * 1e6,
            firstPrice: item.firstMoney * 1e2,
            nextNumber: chargeType === 1 ? item.continueNum : item.continueNum * 1e6,
            nextPrice: item.continueMoney * 1e2,
            isDefault: +!(index ^ 0),
            province
          };
        });
      let noDeliveryRegion = areaArr
        .filter((area: any) => area.freightIndex === -1)
        .map((area: any) => area.dictKey)
        .join(',');

      params = Object.assign(params, {
        tradeType: chargeType,
        noDeliveryRegion: noDeliveryRegion || undefined,
        rules
      });
    }

    // 请求接口
    let res;
    if (pageType === 'edit') {
      let query = getQuery();
      params = Object.assign(params, {
        freightTemplateId: query.id
      });
      res = await freightStore.updateFreight(params);
      if (res.success) {
        message.success('修改成功');
        setTimeout(() => {
          props.history.push({
            pathname: '/commodity/setting/freightTemplate'
          });
        }, 500);
      }
    } else {
      res = await freightStore.addFreight(params);
      if (res.success) {
        message.success('添加成功');
        setTimeout(() => {
          props.history.push({
            pathname: '/commodity/setting/freightTemplate'
          });
        }, 500);
      }
    }
  };
  return (
    <Card>
      <div className="freight_edit_container">
        <h4 className="freight_title">{`${pageType === 'add' ? '新增' : '编辑'}运费模版`}</h4>
        <Row>
          <Col
            span={3}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 20
            }}
          >
            <span className="line_title">
              <Typography.Text type={'danger'}>*</Typography.Text> 模板名称:
            </span>
          </Col>
          <Col span={19}>
            <div className="line_content">
              <Input
                style={{ width: 250 }}
                placeholder="请输入模板名称"
                allowClear
                maxLength={20}
                onChange={(e) => setName(e.target.value.trim())}
                value={name}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            span={3}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 20
            }}
          >
            <span className="line_title">
              <Typography.Text type={'danger'}>*</Typography.Text> 是否包邮:
            </span>
          </Col>
          <Col span={19}>
            <div className="line_content">
              <Radio.Group onChange={(e) => setFreeShip(e.target.value)} value={freeShip}>
                <Radio value={0} key={0}>
                  自定义运费
                </Radio>
                <Radio value={1} key={1}>
                  卖家承担运费
                </Radio>
              </Radio.Group>
            </div>
          </Col>
        </Row>
        {!+freeShip && (
          <>
            <Row>
              <Col
                span={3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: 20
                }}
              >
                <span className="line_title">
                  <Typography.Text type={'danger'}>*</Typography.Text> 计费方式:
                </span>
              </Col>
              <Col span={20}>
                <div className="line_content">
                  <Radio.Group
                    disabled={pageType === 'edit'}
                    onChange={(e) => setChargeType(e.target.value)}
                    value={chargeType}
                  >
                    <Radio value={1} key={1}>
                      按件数
                    </Radio>
                    <Radio value={2} key={2}>
                      按重量
                    </Radio>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={19} offset={3}>
                <div className="freight_input_container">
                  <div className="global_freight_container">
                    {chargeType === 1 ? (
                      <div className="global_freight">
                        <span>默认运费</span>
                        <InputNumber
                          size="small"
                          className="global_input"
                          step={1}
                          min={1}
                          precision={0}
                          onChange={(e) => changeInput(e, 0, 'firstNum')}
                          value={freightInput[0].firstNum}
                          placeholder="首件"
                        />
                        <span>件内,</span>
                        <InputNumber
                          size="small"
                          className="global_input"
                          step={1}
                          min={0}
                          precision={2}
                          onChange={(e) => changeInput(e, 0, 'firstMoney')}
                          value={freightInput[0].firstMoney}
                          placeholder="首费"
                        />
                        <span> 元, 每增加</span>
                        <InputNumber
                          size="small"
                          className="global_input"
                          step={1}
                          min={1}
                          precision={0}
                          onChange={(e) => changeInput(e, 0, 'continueNum')}
                          value={freightInput[0].continueNum}
                          placeholder="续件"
                        />
                        <span>件,增加运费</span>
                        <InputNumber
                          size="small"
                          className="global_input"
                          step={1}
                          min={0}
                          precision={2}
                          onChange={(e) => changeInput(e, 0, 'continueMoney')}
                          value={freightInput[0].continueMoney}
                          placeholder="续费"
                        />
                        <span>元</span>
                      </div>
                    ) : (
                      <div className="global_freight">
                        <span>默认运费</span>
                        <InputNumber
                          size="small"
                          className="global_input"
                          step={1}
                          min={0.0001}
                          precision={4}
                          onChange={(e) => changeInput(e, 0, 'firstNum')}
                          value={freightInput[0].firstNum}
                          placeholder="首重"
                        />
                        <span>公斤内,</span>
                        <InputNumber
                          size="small"
                          className="global_input"
                          step={1}
                          min={0}
                          precision={2}
                          onChange={(e) => changeInput(e, 0, 'firstMoney')}
                          value={freightInput[0].firstMoney}
                          placeholder="首费"
                        />
                        <span>元, 每增加</span>
                        <InputNumber
                          size="small"
                          className="global_input"
                          step={1}
                          min={0.0001}
                          precision={4}
                          onChange={(e) => changeInput(e, 0, 'continueNum')}
                          value={freightInput[0].continueNum}
                          placeholder="续重"
                        />
                        <span>公斤,增加运费</span>
                        <InputNumber
                          size="small"
                          className="global_input"
                          step={1}
                          min={0}
                          precision={2}
                          onChange={(e) => changeInput(e, 0, 'continueMoney')}
                          value={freightInput[0].continueMoney}
                          placeholder="续费"
                        />
                        <span>元</span>
                      </div>
                    )}
                    <div className="global_tip">* 除指定地区外，其余地区的运费采用“默认运费”</div>
                  </div>

                  {freightInput.length > 1 ? (
                    <div className="part_freight_container">
                      <div className="part_freight_title">
                        {chargeType === 1 ? (
                          <Row style={{ backgroundColor: '#f0f0f0' }}>
                            <Col span={10} className="part_freight_title_text">
                              运送到
                            </Col>
                            <Col span={3} className="part_freight_title_text">
                              首件(件)
                            </Col>
                            <Col span={3} className="part_freight_title_text">
                              首费(元)
                            </Col>
                            <Col span={3} className="part_freight_title_text">
                              续件(件)
                            </Col>
                            <Col span={3} className="part_freight_title_text">
                              续费(元)
                            </Col>
                            <Col span={2} className="part_freight_title_text">
                              操作
                            </Col>
                          </Row>
                        ) : (
                          <Row style={{ backgroundColor: '#f0f0f0' }}>
                            <Col span={10} className="part_freight_title_text">
                              运送到
                            </Col>
                            <Col span={3} className="part_freight_title_text">
                              首重(公斤)
                            </Col>
                            <Col span={3} className="part_freight_title_text">
                              首费(元)
                            </Col>
                            <Col span={3} className="part_freight_title_text">
                              续重(公斤)
                            </Col>
                            <Col span={3} className="part_freight_title_text">
                              续费(元)
                            </Col>
                            <Col span={2} className="part_freight_title_text">
                              操作
                            </Col>
                          </Row>
                        )}
                      </div>
                      {chargeType === 1 ? (
                        <div className="part_freight_content">
                          {freightInput.map((item, index) => {
                            if (index > 0) {
                              if (
                                // 表单沒值
                                Object.values(item).every((item) => !item) &&
                                // 没有选地方
                                getAreaNames(index).length === 0
                              ) {
                                return null;
                              }
                              return (
                                <Row className="content_row" key={index}>
                                  <Col span={10} className="content_cell">
                                    <div className="area_container">
                                      <div className="area_names">{getAreaNames(index)}</div>
                                      <span
                                        onClick={() => editAreaFreight(index)}
                                        className="area_edit_btn"
                                      >
                                        编辑
                                      </span>
                                    </div>
                                  </Col>
                                  <Col span={3} className="content_cell">
                                    <InputNumber
                                      size="small"
                                      className="cell_input"
                                      step={1}
                                      min={1}
                                      precision={chargeType === 1 ? 0 : 4}
                                      onChange={(e) => changeInput(e, index, 'firstNum')}
                                      value={item.firstNum}
                                      placeholder={chargeType === 1 ? '件数' : '重量'}
                                    />
                                  </Col>
                                  <Col span={3} className="content_cell">
                                    <InputNumber
                                      size="small"
                                      className="cell_input"
                                      step={1}
                                      min={0}
                                      precision={2}
                                      onChange={(e) => changeInput(e, index, 'firstMoney')}
                                      value={item.firstMoney}
                                      placeholder="金额"
                                    />
                                  </Col>
                                  <Col span={3} className="content_cell">
                                    <InputNumber
                                      size="small"
                                      className="cell_input"
                                      step={1}
                                      min={1}
                                      precision={chargeType === 1 ? 0 : 4}
                                      onChange={(e) => changeInput(e, index, 'continueNum')}
                                      value={item.continueNum}
                                      placeholder={chargeType === 1 ? '件数' : '重量'}
                                    />
                                  </Col>
                                  <Col span={3} className="content_cell">
                                    <InputNumber
                                      size="small"
                                      className="cell_input"
                                      step={1}
                                      min={0}
                                      precision={2}
                                      onChange={(e) => changeInput(e, index, 'continueMoney')}
                                      value={item.continueMoney}
                                      placeholder="金额"
                                    />
                                  </Col>
                                  <Col span={2} className="content_cell">
                                    <span
                                      className="table_action_btn"
                                      onClick={() => deleteAreaFreight(index)}
                                    >
                                      删除
                                    </span>
                                  </Col>
                                </Row>
                              );
                            } else {
                              return '';
                            }
                          })}
                        </div>
                      ) : (
                        <div className="part_freight_content">
                          {freightInput.map((item, index) => {
                            if (index > 0) {
                              if (
                                // 表单沒值
                                Object.values(item).every((item) => !item) &&
                                // 没有选地方
                                getAreaNames(index).length === 0
                              ) {
                                return null;
                              }
                              return (
                                <Row className="content_row" key={index}>
                                  <Col span={10} className="content_cell">
                                    <div className="area_container">
                                      <div className="area_names">{getAreaNames(index)}</div>
                                      <span
                                        onClick={() => editAreaFreight(index)}
                                        className="area_edit_btn"
                                      >
                                        编辑
                                      </span>
                                    </div>
                                  </Col>
                                  <Col span={3} className="content_cell">
                                    <InputNumber
                                      size="small"
                                      className="cell_input"
                                      step={1}
                                      min={0.0001}
                                      precision={chargeType === 1 ? 0 : 4}
                                      onChange={(e) => changeInput(e, index, 'firstNum')}
                                      value={item.firstNum}
                                      placeholder={chargeType === 1 ? '件数' : '重量'}
                                    />
                                  </Col>
                                  <Col span={3} className="content_cell">
                                    <InputNumber
                                      size="small"
                                      className="cell_input"
                                      step={1}
                                      min={0}
                                      precision={2}
                                      onChange={(e) => changeInput(e, index, 'firstMoney')}
                                      value={item.firstMoney}
                                      placeholder="金额"
                                    />
                                  </Col>
                                  <Col span={3} className="content_cell">
                                    <InputNumber
                                      size="small"
                                      className="cell_input"
                                      step={0.0001}
                                      min={1}
                                      precision={chargeType === 1 ? 0 : 4}
                                      onChange={(e) => changeInput(e, index, 'continueNum')}
                                      value={item.continueNum}
                                      placeholder={chargeType === 1 ? '件数' : '重量'}
                                    />
                                  </Col>
                                  <Col span={3} className="content_cell">
                                    <InputNumber
                                      size="small"
                                      className="cell_input"
                                      step={1}
                                      min={0}
                                      precision={2}
                                      onChange={(e) => changeInput(e, index, 'continueMoney')}
                                      value={item.continueMoney}
                                      placeholder="金额"
                                    />
                                  </Col>
                                  <Col span={2} className="content_cell">
                                    <span
                                      className="table_action_btn"
                                      onClick={() => deleteAreaFreight(index)}
                                    >
                                      删除
                                    </span>
                                  </Col>
                                </Row>
                              );
                            } else {
                              return '';
                            }
                          })}
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <Button
                    type="link"
                    style={{ lineHeight: '40px', width: 200 }}
                    onClick={addAreaFreight}
                  >
                    + 为指定地区设置运费
                  </Button>

                  <div
                    className="global_freight_container"
                    style={{
                      lineHeight: '40px',
                      marginTop: 8
                    }}
                  >
                    <Button type="link" onClick={() => addAreaFreight('disabled')}>
                      + 设置不配送地区
                    </Button>
                    <p className="global_tip">
                      * 设置不配送地区，用户下单时，收货地址选择该区域时，无法提交订单
                    </p>
                  </div>
                  <div className="disabled_area_container">{getAreaNames(-1)}</div>
                </div>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col span={21} offset={3}>
            <Button
              type="primary"
              style={{ width: 100, marginTop: 20, marginBottom: 20 }}
              onClick={saveFreightTemp}
            >
              保 存
            </Button>
          </Col>
        </Row>

        {showFreightAreaModal && (
          <StoreChooseAreaModal
            visible={showFreightAreaModal}
            modalType={areaModalType}
            type={freightAreaType}
            freightIndex={changeAreaIndex}
            onOk={() => {}}
            onCancel={() => {
              setShowFreightAreaModal(false);
            }}
          />
        )}
      </div>
    </Card>
  );
}

export default withRouter(Add);
