import { makeAutoObservable, runInAction, computed, observable, action } from "mobx";
import commonApi from "@/api/common";
import basicApi from "@/api/basic";
import request from "@/tools/httpClient";
import { isJD, isDGSS, isMAOCHAO } from "@/config/author";
import { commodityMenu, shopManageMenu, settingsManageMenu, financeManageMenu } from "@/config/routers";
import type { CommonTypes } from "@/types/store/common.interface";

import type { StateProps } from "@/types/common/store";

class CommonStore implements CommonTypes {
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }

  updateState = (params: StateProps) => {
    for (let [key, value] of Object.entries(params)) {
      this[key] = value;
    }
  };
  @observable
  accountInfo: any = {
    shopFlag: false,
    isJD: false,
    isJN: false,
    isDGSS: false,
    isApiSupplier: false,
    isMAOCHAO: false,
    companyId: "",
    companyName: "",
    name: "",
    source: "seller", // 供应商来源，jd、dgss、seller
  };

  @action
  getMemberInfo = async (params: any = {}) => {
    try {
      // @ts-ignore
      const res = await request.get(commonApi.memberInfo, { params });
      if (res && res.success) {
        runInAction(() => {
          const companyId = res.data.companyId;
          this.accountInfo = {
            ...res.data,
            isJD: isJD(companyId),
            isDGSS: isDGSS(companyId),
            isMAOCHAO: isMAOCHAO(companyId),
            isApiSupplier: isJD(companyId) || isDGSS(companyId) || isMAOCHAO(companyId),
            source: isJD(companyId) ? "jd" : isDGSS(companyId) ? "dgss" : isMAOCHAO(companyId) ? "maochao" : "seller"
          };
        });
      }
      return this.accountInfo;
    } catch (ex) {
      console.warn(ex);
    }
  };

  // @ts-ignore
  logout = async () => await request.post(commonApi.logout);

  shopId = "";

  getShopId = async () => {
    if (this.shopId) {
      return this.shopId;
    }
    try {
      const res = await request.get(commonApi.getShopByCompanyId);
      if (res?.success) {
        runInAction(() => (this.shopId = res?.data?.id));
        return res?.data?.id;
      }
    } catch (error) {}
  };

  // 鉴权后的路由列表
  @computed get commodityMenu() {
    const { isApiSupplier, isDGSS, isJD, isMAOCHAO } = this.accountInfo;

    return commodityMenu({ isApiSupplier, isDGSS, isJD, isMAOCHAO   });
  }

  @computed get shopManageMenu() {
    const { isApiSupplier, isDGSS, isJD, isMAOCHAO } = this.accountInfo;
    return shopManageMenu({ isApiSupplier, isDGSS, isJD, isMAOCHAO  });
  }

  @computed get financeManageMenu() {
    const { isApiSupplier, isDGSS, isJD, isMAOCHAO } = this.accountInfo;

    return financeManageMenu({ isApiSupplier, isDGSS, isJD, isMAOCHAO  });
  }

  @computed get settingsManageMenu() {
    return settingsManageMenu();
  }



  /**
   * 获取地址
   */
  getAddress = async (params: any) => {
    let res = await request.post(basicApi.get("get_address"), params);

    if (res && res.success) {
      return res.data;
    }
  };

  //上传图片
  upload = async (params: any) => {
    const data = await request.post(basicApi.get("upload_single_file"), params);
    return data;
  };

  /**
   * 上传单个文件
   */
  uploadSingleFile = async (params: any) => {
    let { data } = await request.post(basicApi.get("upload_single_file"), params, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    return data;
  };
}

export default new CommonStore();
