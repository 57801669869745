import React, { useEffect, useState, FC } from "react";
import { Modal, Input, Form, message } from "@fle-ui/next";
import PropTypes from "prop-types";
import AddressSelection from '@fle-ui/pro-address-selection';
import sendAddressStore from '@/stores/sendAddress';

const { Item } = Form;

const AddAddress: FC<any> = (props) => {
  const { visible, onOk, onCancel, type, item, shippingAddress } = props;
  const [refresh, setRefresh] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue({
        name: item.name,
        location: !!item.townCode
          ? [item.provinceCode, item.cityCode, item.countyCode, item.townCode]
          : [item.provinceCode, item.cityCode, item.countyCode],
        address: item.address,
        mobile: item.mobile,
      });
    }
  }, []);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  const confirm = () => {
    form
      .validateFields()
      .then(async (values) => {
        let params = {
          name: values.name.trim(),
          countyCode: values.location[values?.location?.length - 1],
          address: values.address.trim(),
          mobile: values.mobile,
          // landlineNumber: values.tel,
          // tag: values.tag ? values.tag.length : 0,
          shippingAddress,
          sourceType: 2,
        };
        console.log(params);
        let res: any =
          type === "edit"
            ? await sendAddressStore.updateLocation(
                Object.assign(params, { id: item.id })
              )
            : await sendAddressStore.addLocation(params);
        if (res.success) {
          message.success(type === "edit" ? "修改成功！" : "增加成功！");
          onCancel(true);
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  return (
    <Modal
      open={visible}
      centered
      onOk={() => confirm()}
      title={type === "add" ? "新增地址" : "编辑地址"}
      onCancel={onCancel}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form}>
        <Item
          label="联系人"
          name="name"
          rules={[
            { required: true, message: "请输入联系人", whitespace: true },
            {
              type: "string",
              min: 2,
              max: 12,
              message: "联系人限制2-12字符",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="请输入联系人" maxLength={12} />
        </Item>
        <Item
          label="所在地区"
          name="location"
          rules={[{ required: true, type: "array", message: "请选择地区" }]}
        >
          <AddressSelection placeholder="请选择地区" />
        </Item>
        <Item
          label="详细地址"
          name="address"
          rules={[
            { required: true, message: "请输入详细地址", whitespace: true },
            {
              type: "string",
              min: 4,
              max: 80,
              message: "详细地址限制4-80字符",
              whitespace: true,
            },
          ]}
        >
          <Input.TextArea placeholder="详细地址限制4-80字符" maxLength={80} />
        </Item>
        <Item
          label="手机号"
          // dependencies={['tel']}
          name="mobile"
          required
          rules={[
            { pattern: /^1[0-9]{10}$/, message: "请输入正确的手机号" },
            () => ({
              validator(rule, value) {
                // if (value || getFieldValue('tel')) {
                //   return Promise.resolve();
                // }
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject("请输入手机号");
              },
            }),
          ]}
        >
          <Input placeholder="请输入手机号" maxLength={11} />
        </Item>
      </Form>
    </Modal>
  );
};

AddAddress.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["add", "edit"]),
  item: PropTypes.object,
};

export default AddAddress;
