import { OrderItem } from '@/stores/afterSaleListStore';
import { Col, Space } from '@fle-ui/next';
import React, { FC } from 'react';

type Props = {
  // 删格化布局
  span: number;
  afterSalesMethod: number;
  thirdRefund: number;
};
const OrderType: FC<Props & OrderItem> = (props) => {
  const { span, afterSalesMethod, thirdRefund, afterSalesType, refundAmount } = props;
  
  return (
    <>
      <Col
        span={span}
        className="table_vertical_left"
      >
        <Space direction={'vertical'} align="start">
          {afterSalesMethod == 10 && <span>退货</span>}
          {afterSalesMethod == 20 && <span>换货</span>}
          {afterSalesMethod == 30 && <span>维修</span>}
          {afterSalesMethod == 40 && <span>退款</span>}
          {afterSalesType !== 0 && (
            <>
              {(thirdRefund || thirdRefund == 0) && (
                <span style={{ fontSize: 12, color: '#909090' }}>
                  退款总额：￥ {(thirdRefund / 100).toFixed(2)}
                </span>
              )}
            </>
          )}
          {afterSalesType === 0 && (
            <>
              {refundAmount && (
                <span style={{ fontSize: 12, color: '#909090' }}>
                  退款总额：￥ {(refundAmount / 100).toFixed(2)}
                </span>
              )}
            </>
          )}
        </Space>
      </Col>
    </>
  );
};

export default OrderType;
