import React, { useRef, useState, useEffect, useCallback } from "react";
import { Select, Button, Typography, Tag } from "@fle-ui/next";
import type { ProColumns, ActionType } from "@fle-ui/pro-table";
import ProTable from "@fle-ui/pro-table";
import { observer } from "mobx-react";

import { CommonStore, dgssStore } from "@/stores";
import { showMoney, formartNum } from "@/tools/filters";
import RangeInput from "@fle-ui/pro-range-input";
import { useHistory } from "react-router-dom";
import { getGoodsUrl } from "@/config/url";

type ColumnsItem = {
  cityNameList: string[];
  product_dgss_brand_id: number;
  product_dgss_brand_name: string;
  product_dgss_cat_id: number;
  product_dgss_description: string;
  product_dgss_id: number;
  product_dgss_image_path: string;
  product_dgss_market_price: number;
  product_dgss_price: number;
  product_dgss_product_id: number;
  product_dgss_select_source: string;
  product_dgss_select_status: number;
  product_dgss_status: number;
  product_dgss_title: string;
  selectTime: number;
};

const CateMap = new Map([
  [1, { color: "processing", text: "蛋糕" }],
  [5, { color: "warning", text: "零食" }],
  [8, { color: "error", text: "鲜花" }],
]);

const styles = {
  subText: { fontSize: 12, color: "#999" },
};

const { Paragraph } = Typography;

const PickDGSS: React.FC = () => {
  const history = useHistory();
  const actionRef = useRef<ActionType>();

  const { dgssCityList, dgssBranchList } = dgssStore;

  useEffect(() => {
    dgssStore.getDgssBranchList();
    dgssStore.getDgssCityList();
  }, []);

  const columns: ProColumns<ColumnsItem>[] = [
    {
      title: "商品信息",
      dataIndex: "goodsInfo",
      key: "context",
      width: 300,
      fieldProps: {
        placeholder: "输入商品名称/商品ID",
        allowClear: true,
      },
      render: (_, record) => (
        <div style={{ display: "inline-flex", width: "100%", alignItems: "center" }}>
          <img width={80} src={record.product_dgss_image_path} alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: 80,
              marginLeft: 10,
            }}>
            <Paragraph
              ellipsis={{ rows: 2, tooltip: true }}
              style={{ color: record.product_dgss_select_status === 2 ? "#2a8ff7" : 'rgb(153, 153, 153)', margin: 0, cursor: "pointer" }}
              onClick={() =>
                record.product_dgss_select_status === 2 && window.open(`/home${getGoodsUrl(CommonStore.accountInfo.source, "detail", record.product_dgss_product_id)}`)
              }>
              {record.product_dgss_title}
            </Paragraph>
            <span style={{ ...styles.subText }}>第三方 ID：{record.product_dgss_product_id}</span>
          </div>
        </div>
      ),
    },
    {
      title: "商品品牌",
      dataIndex: "product_dgss_brand_name",
      key: "brand_id",
      align: "center",
      fieldProps: {
        placeholder: "请选择商品品牌",
      },
      renderFormItem: (_, { type, defaultRender, ...rest }) => {
        return (
          <Select
            mode="multiple"
            allowClear
            showSearch
            options={dgssBranchList}
            filterOption={(input, option) =>
              (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: "商品类型",
      dataIndex: "product_dgss_cat_id",
      key: "cat_id",
      width: 100,
      align: "center",
      initialValue: "",
      renderFormItem: (_, { type, defaultRender, ...rest }) => {
        return (
          <Select
            options={[
              {
                label: "全部",
                value: "",
              },
              {
                label: "蛋糕",
                value: 1,
              },
              {
                label: "零食",
                value: 5,
              },
              {
                label: "鲜花",
                value: 8,
              },
            ]}
          />
        );
      },
      render: (_, record) => (
        <Tag color={CateMap.get(record.product_dgss_cat_id)?.color || "processing"}>
          {CateMap.get(record.product_dgss_cat_id)?.text}
        </Tag>
      ),
    },
    {
      title: "市场价",
      dataIndex: "product_dgss_market_price",
      key: "product_dgss_market_price",
      align: "center",
      hideInSearch: true,
      render: (_, record) => `¥${showMoney(record.product_dgss_market_price)}`,
    },
    {
      title: "供应价",
      dataIndex: "product_dgss_price",
      key: "product_dgss_price",
      align: "center",
      renderFormItem: () => <RangeInput placeholder={["起始", "结束"]} addonBefore="¥" />,
      render: (_, record) => `¥${showMoney(record.product_dgss_price)}`,
    },
    {
      title: "售卖城市",
      dataIndex: "cityNameList",
      key: "cities",
      width: 300,
      align: "center",
      fieldProps: {
        placeholder: "请选择售卖城市",
      },
      renderFormItem: (_, { type, defaultRender, ...rest }) => {
        return (
          <Select
            mode="multiple"
            allowClear
            showSearch
            options={dgssCityList}
            filterOption={(input, option) =>
              (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
      render: (_, record) => (
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}>
          {record.cityNameList?.reduce(
            (str: string, item: string, index: number) => `${str}${!!index ? "、" : ""}${item}`,
            ""
          )}
        </div>
      ),
    },
    {
      title: "挑选状态",
      dataIndex: "product_dgss_select_status",
      key: "select_status",
      align: "center",
      width: 100,
      initialValue: "",
      renderFormItem: (_, { type, defaultRender, ...rest }) => {
        return (
          <Select
            options={[
              {
                label: "全部",
                value: "",
              },
              {
                label: "已挑选",
                value: 1,
              },
              {
                label: "未挑选",
                value: 2,
              },
            ]}
          />
        );
      },
      render: (_, record) =>
        record.product_dgss_select_status === 1 ? <Tag color="green">已挑选</Tag> : <Tag color="default">未挑选</Tag>,
    },
    {
      title: "操作",
      dataIndex: "option",
      key: "option",
      width: 100,
      align: "center",
      valueType: "option",
      render: (_, record: any) => (
        <Button
          type="link"
          disabled={record.product_dgss_select_status === 1}
          onClick={() => history.push("/module/goods/uncleCake/entry/" + record.product_dgss_product_id)}>
          {record.product_dgss_select_status === 1 ? "已挑选" : "挑选"}
        </Button>
      ),
    },
  ];

  return (
    <ProTable<ColumnsItem>
      columns={columns}
      actionRef={actionRef}
      resizable={false}
      bordered={true}
      scroll={{ x: 1300 }}
      request={async (params = {}, sort, filter) => {
        const _params = {
          ...params,
          // select_source: "pangu",
          pageNo: params.current,
          price_min: params.product_dgss_price?.[0] ? formartNum(params.product_dgss_price?.[0]) : null,
          price_max: params.product_dgss_price?.[1] ? formartNum(params.product_dgss_price?.[1]) : null,
          cities: params.cities?.reduce(
            (str: string, id: number, index: number) => `${str}${!!index ? "," : ""}${id}`,
            ""
          ),
          brand_id: params.brand_id?.reduce(
            (str: string, id: number, index: number) => `${str}${!!index ? "," : ""}${id}`,
            ""
          ),
        };
        return await dgssStore.getDgssGoodsList(_params);
      }}
      rowKey="product_dgss_product_id"
      search={{
        labelWidth: "auto",
        defaultCollapsed: false
      }}
      pagination={{
        pageSize: 10,
      }}
      dateFormatter="string"
      options={{ setting: false, fullScreen: true }}
    />
  );
};

export default observer(PickDGSS);
