/**
 * @Description:  http://47.111.246.133:21190/doc.html 卖家发货单
 * @author 点点
 * @date 2021/6/17
 * @time 17:54
 */
type Key =
  'pageQuery'
  | 'cancel'
  | 'getOrderInfo'
  | 'download'
  | 'consign'
  | 'searchOrderItem'
  | 'changeExpressInfo'
  | 'getDeliveryOrderQuantity' |
  'getGoodsStock';

export const OrderDeliveryApis: Map<Key, string> = new Map([
  //发货单分页列表
  ['pageQuery', '/merchant/order/seller/order-delivery/pageQuery'],
  // 发货单取消
  ['cancel', '/merchant/order/seller/order-delivery/cancel'],
  // 获取订单详情
  ['getOrderInfo', '/merchant/order/seller/order-delivery/getOrderInfo'],
  //下载明细
  ['download', '/merchant/order/seller/order-delivery/download'],
  // 发货
  ['consign', '/merchant/order/seller/order-delivery/consign'],
  // 搜索订单明细记录
  ['searchOrderItem', '/merchant/order/seller/order-delivery/searchOrderItem'],
  // 修改物流信息
  ['changeExpressInfo', '/merchant/order/seller/order-delivery/changeExpressInfo'],
  // 发货
  ['consign', '/merchant/order/seller/order-delivery/consign'],
  // 获取发货单数量
  ['getDeliveryOrderQuantity', '/merchant/order/seller/order-delivery/getDeliveryOrderQuantity'],
  // 获取发货单单个商品库存[卖家]
  ['getGoodsStock', '/merchant/order/seller/order-delivery/getGoodsStock']
]);
