/**
 * @Description: 日期范围
 * @author 点点
 * @date 2021/6/17
 * @time 10:41
 */

 import moment from 'moment';

 export const dataList = [
   {
     label: '今日',
     value: [moment(), moment()]
   },
   {
     label: '近7日',
     value: [moment().subtract(6, 'days'), moment()]
   },
   {
     label: '近15日',
     value: [moment().subtract(14, 'days'), moment()]
   },
   {
     label: '近30日',
     value: [moment().subtract(29, 'days'), moment()]
   }
 ];
 
 export const rangesData = dataList.reduceRight(
   (pre, cur) => Object.assign({ [cur.label]: cur.value }, pre),
   {}
 );
 