import React, { FC, useEffect, useState } from 'react';
import { CommonStore } from '@/stores';

type Props = {
  freightRules: any;
};
const GetShipRule: FC<Props> = (props: any) => {
  const { isJD } = CommonStore.accountInfo;
  const { freightRules } = props;
  const getUnit = (type?: any, unitType?: any) => (type === 1 ? '件' : unitType ? '重' : '公斤');
  const showMoney = (data: any) => (+data / 100).toFixed(2);

  const [_freightRules, set_freightRules] = useState({
    noDeliveryRegionNames: '',
    isFreeShipping: '',
    tradeType: 1,
    rules: [],
    JD: ''
  });

  const { isFreeShipping, tradeType, rules, noDeliveryRegionNames } = _freightRules;

  useEffect(() => {
    if (!freightRules) {
      return;
    }
    isJD
      ? set_freightRules({
          isFreeShipping: '',
          tradeType: 1,
          rules: [],
          JD: freightRules,
          noDeliveryRegionNames: ''
        })
      : set_freightRules({
          isFreeShipping: '',
          tradeType: 1,
          rules: [],
          JD: '123',
          ...JSON.parse(freightRules)
        });
  }, [freightRules, isJD]);

  if (isJD) {
    return <> {_freightRules.JD}</>;
  }

  if (isFreeShipping) {
    return <>卖家包邮</>;
  }

  return (
    <div>
      <div>按{getUnit(tradeType)}计算运费</div>
      {rules.map((itemIn: any, indexIn: any) => {
        return (
          <div key={indexIn}>
            <>
              <>{(itemIn.provinceNames || '全国') + ':'}</>
              {`${tradeType === 1 ? itemIn.firstNumber : itemIn.firstNumber / 1e6}
                ${getUnit(tradeType)}以内${showMoney(itemIn.firstPrice)}元；每增加
                ${tradeType === 1 ? itemIn.nextNumber : itemIn.nextNumber / 1e6}${getUnit(
                tradeType
              )}
                ，增加运费${showMoney(itemIn.nextPrice)}元`}
            </>
          </div>
        );
      })}
      {noDeliveryRegionNames && <>不可配送区域：{noDeliveryRegionNames}</>}
    </div>
  );
};

export default GetShipRule;
