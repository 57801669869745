import { makeAutoObservable, runInAction } from "mobx";
import commonApi from "@/api/common";
import request from "@/tools/httpClient";

import type { StateProps } from "@/types/common/store";

class HomeStore {
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }

  updateState = (params: StateProps) => {
    for (let [key, value] of Object.entries(params)) {
      this[key] = value;
    }
  };

  leftChange = false;

  usableInfo: any = {};
  getUsableInfo = async (params: any = {}) => {
    try {
      // @ts-ignore
      const res = await request.get(commonApi.getUsableInfo);
      if (res && res.success) {
        runInAction(() => {
          this.usableInfo = res?.data || [];
        });
      }
      return res;
    } catch (ex) {
      console.warn(ex);
    }
  };

  getHomeEvent = async (params: any = {}) => {
    try {
      // @ts-ignore
      const res = await request.get(commonApi.homeEvent, { params });
      return res;
    } catch (ex) {
      console.warn(ex);
    }
  };

  getCompanyInfo = async (params: any = {}) => {
    try {
      // @ts-ignore
      const res = await request.get(commonApi.companyInfo, { params });
      return res;
    } catch (ex) {
      console.warn(ex);
    }
  };

  appBaseCode: any = [];
  appList: any = [];
  getAppList = async (params: any = {}) => {
    try {
      // @ts-ignore
      const res = await request.post(commonApi.appList, { params });
      if (res && res.success) {
        runInAction(() => {
          this.appList = res?.data?.openedUtilities || [];
          this.appBaseCode = this.appList.map((item: any) => item.baseCode);
        });
      }
      return res;
    } catch (ex) {
      console.warn(ex);
    }
  };

  // 获取商机信息
  getBusinessTitle = async (params: any = {}) => {
    try {
      // @ts-ignore
      const res = await request.get(commonApi.businessTitle, { params });
      return res;
    } catch (ex) {
      console.warn(ex);
    }
  };

  // 客户总数
  getBusinessAllCustomer = async (time: string, timeType: string) => {
    // @ts-ignore
    return request.post(commonApi.ALLCUSTOMERBUSINESSDATA, {
      time,
      timeType,
    });
  };

  // 跟进客户数
  getBusinessFollowCustomer = async (time: string, timeType: string) => {
    // @ts-ignore
    return request.post(commonApi.FOLLOWCUSTOMERBUSINESSDATA, {
      time,
      timeType,
    });
  };

  // 跟进客户次数
  getBusinessFollowCustomerCount = async (time: string, timeType: string) => {
    // @ts-ignore
    return request.post(commonApi.FOLLOWCUSTOMERCOUNTBUSINESSDATA, {
      time,
      timeType,
    });
  };

  // 新增合同数
  getBusinessAddContract = async (time: string, timeType: string) => {
    // @ts-ignore
    return request.post(commonApi.ADDCONTRACTBUSINESSDATA, {
      time,
      timeType,
    });
  };

  // 新增合同金额
  getBusinessAddContractSum(time: string, timeType: string) {
    // @ts-ignore
    return request.post(commonApi.ADDCONTRACTSUMBUSINESSDATA, {
      time,
      timeType,
    });
  }

  // 客户折线图
  getBusinessContractLine(startTime: string, endTime: string) {
    // @ts-ignore
    return request.post(commonApi.CONTRACTLINEBUSINESSDATA, {
      startTime,
      endTime,
    });
  }

  // 查询商机数量首页
  getBusinessNum(startTime: string, endTime: string) {
    // @ts-ignore
    return request.post(commonApi.queryBusinessNum, {
      startTime,
      endTime,
    });
  }

  // 获取应用服务
  getAppInfo() {
    // @ts-ignore
    return request.get(commonApi.appInfo);
  }
}

export default new HomeStore();
