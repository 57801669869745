import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input, message, Modal, Radio } from '@fle-ui/next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import OrderListStore from '@/stores/afterSaleListStore';
/**
 *
 * 三级菜单 售后处理弹窗
 */

const OrderDealOfDispose = (props: any) => {
  const { isModalVisibleOfDispose, setIsModalVisibleOfDispose } = props;
  const { handleAfterSales } = OrderListStore;
  const [form] = Form.useForm();
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  const handleOkOfDispose = () => {
    setIsModalVisibleOfDispose(false);
    Modal.confirm({
      title: '确认同意退款',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          当前退款金额为
          <span style={{ color: 'red' }}>
            ¥
            {((props?.finalSalePrice * props?.afterSalesNum + props?.freightPrice) / 100).toFixed(
              2
            )}
          </span>
          ，其中商品金额为
          <span style={{ color: 'red' }}>
            ¥{((props?.finalSalePrice * props?.afterSalesNum) / 100).toFixed(2)}
          </span>
          ，
          {!checkboxValue ? (
            <>
              运费为
              <span style={{ color: 'red' }}>¥{(props?.freightPrice / 100).toFixed(2)}</span>
            </>
          ) : (
            <>不退运费</>
          )}
          。是否确认同意退款？
        </>
      ),
      async onOk() {
        const params: any = {
          afterSalesNo: props?.afterSalesNo,
          handleResult: true,
          isReturnFreight: !checkboxValue
        };
        const res = await handleAfterSales(params);
        if (res?.success) {
          message.success('处理成功');
        } else {
          message.error('处理失败');
        }
      },
      onCancel() {}
    });
  };
  const handleCancelOfDispose = () => {
    setIsModalVisibleOfDispose(false);
    Modal.confirm({
      title: '确认拒绝退款',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Form form={form}>
            <Form.Item
              name="refuseReason"
              label={<span style={{ color: '#787878' }}>拒绝原因</span>}
              rules={[{ required: true, message: '请输入拒绝原因' }]}
            >
              <Input.TextArea showCount maxLength={200} />
            </Form.Item>
          </Form>
        </>
      ),
      async onOk() {
        const formData = await form.validateFields();
        const params: any = {
          afterSalesNo: props?.afterSalesNo,
          handleResult: false,
          isReturnFreight: !checkboxValue,
          refuseReason: formData.refuseReason
        };

        const res = await handleAfterSales(params);
        if (res?.success) {
          message.success('处理成功');
          props?.reloadPage();
        } else {
          message.error('处理失败');
        }
      },
      onCancel() {}
    });
  };
  const onFinishOfDispose = (values: any) => {
    console.log(values);
  };
  const checkboxCallback = (e: any) => {
    setCheckboxValue(e.target.checked);
  };

  return (
    <div className="OrderDealOfDispose">
      <Modal
        title="售后处理"
        width={600}
        open={isModalVisibleOfDispose}
        onCancel={() => setIsModalVisibleOfDispose(false)}
        className="audit-form-modal"
        footer={[
          <Button key="back" onClick={handleCancelOfDispose}>
            拒绝退款
          </Button>,
          <Button key="submit" type="primary" onClick={handleOkOfDispose}>
            同意退款
          </Button>
        ]}
      >
        <Form {...layout} name="nest" onFinish={onFinishOfDispose} className="audit-form">
          <Form.Item label={<span style={{ color: '#787878' }}>商品名称</span>}>
            {props?.goodsName}
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>会员单价</span>}>
            ¥{(props?.finalSalePrice / 100).toFixed(2)}元
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>商品数量</span>}>
            {props?.afterSalesNum}
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>退款总额</span>}>
            <Form.Item style={{ display: 'inline-block' }}>
              <span style={{ color: '#F42D2D' }}>
                {' '}
                ¥
                {(
                  (props?.finalSalePrice * props?.afterSalesNum + props?.freightPrice) /
                  100
                ).toFixed(2)}
                元
                {props?.freightPrice !== 0 &&
                  `（含运费¥${(props?.freightPrice / 100).toFixed(2)}）`}
              </span>
            </Form.Item>
            <Form.Item style={{ display: 'inline-block' }} name="returnMoney" noStyle>
              <Checkbox onChange={checkboxCallback} style={{ marginTop: 5, marginLeft: 8 }}>
                不退运费
              </Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>售后类型</span>}>
            {props?.afterSalesMethod == 10 && <span>退货</span>}
            {props?.afterSalesMethod == 20 && <span>换货</span>}
            {props?.afterSalesMethod == 30 && <span>维修</span>}
            {props?.afterSalesMethod == 40 && <span>退款</span>}
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>发货状态</span>}>
            {props?.afterSalesBasicType == 1 && <>待发货</>}
            {props?.afterSalesBasicType == 2 && <>已发货</>}
          </Form.Item>
        </Form>
        {props?.afterSalesMethod === 10 && (
          <div
            style={{ fontSize: 14, color: '#F42D2D', position: 'absolute', bottom: 21, left: 24 }}
          >
            同意申请后将会从账户中扣除商品金额
          </div>
        )}
      </Modal>
    </div>
  );
};

export default OrderDealOfDispose;
