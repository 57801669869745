import React, { FC, useState } from 'react';
import { Button, Card, message, Table, Modal } from '@fle-ui/next';
import { observer } from 'mobx-react';
import { goodsStore } from '@/stores';
import { useDebounceEffect } from 'ahooks';
import { getPagination } from '@/tools/getPagination';
import { GoodItemProps } from '@/types/store/goods';
import {
  GoodInfo,
  GoodsAvailableStatus,
  OncePrice,
  PurchasePrice,
  SaleType,
  Stock,
  PoolMallAvailableStatus
} from '@/components/TableColumn';
import AddGoodButton from '../components/AddGoodButton';
import { PageContainer } from '@fle-ui/pro-layout';

const { Column } = Table;

type Props = {};
const OnSaleList: FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useDebounceEffect(
    () => {
      setLoading(true);
      goodsStore.getOnSale({ pageNo, pageSize }).finally(() => setLoading(false));
    },
    [pageNo, pageSize, refresh],

    { wait: 0 }
  );

  const changePageSize = (current: number, size: number) => {
    setPageNo(1);
    setPageSize(size);
  };

  return (
    <PageContainer padding={0}>
      <Card>
        <AddGoodButton />

        <Table
          loading={loading}
          dataSource={goodsStore.on_sale.list}
          pagination={getPagination({
            total: goodsStore.on_sale.total,
            current: pageNo,
            pageSize,
            onChange: setPageNo,
            onShowSizeChange: changePageSize
          })}
          rowKey={'goodsId'}
        >
          <Column<GoodItemProps>
            width={300}
            title={'商品信息'}
            dataIndex={'goodsId'}
            render={GoodInfo}
          />
          <Column<GoodItemProps>
            title={'销售支持'}
            width={140}
            dataIndex={'goodsId'}
            render={SaleType}
          />

          <Column<GoodItemProps> title={'集采价'} dataIndex={'goodsId'} render={PurchasePrice} />

          <Column<GoodItemProps> title={'代发价'} dataIndex={'goodsId'} render={OncePrice} />

          <Column<GoodItemProps> title={'现货库存'} dataIndex={'stock'} render={Stock} />

          <Column<GoodItemProps>
            title={'可售状态'}
            align={'center'}
            dataIndex={'goodsId'}
            render={PoolMallAvailableStatus}
          />

          <Column
            title={'操作'}
            align={'center'}
            dataIndex={'goodsId'}
            render={(goodsId) => (
              <Button
                type={'link'}
                onClick={() => {
                  Modal.confirm({
                    content: '确认下架吗',
                    onOk: async () => {
                      setLoading(true);
                      const res = await goodsStore.offShelf(goodsId);
                      res
                        ? message.success('下架成功', 1, () => setRefresh(refresh + 1))
                        : message.error('下架失败', 1, () => setRefresh(refresh + 1));
                    }
                  });
                }}
              >
                下架
              </Button>
            )}
          />
        </Table>
      </Card>
    </PageContainer>
  );
};

export default observer(OnSaleList);
