import { Card, Col, Row, Space, Typography } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useState } from 'react';
import OrderDetailStore, { OrderStatus, PaymentMethod } from '@/stores/orderDetailStore';
import OrderInfoItem from './OrderInfoItem';

const { Title, Text } = Typography;

const ApplyInfo: FC = () => {
  const { orderStatus, groupList, orderType } = OrderDetailStore.orderDetail;
  let {
    buyerCompanyName,
    createTime,
    confirmTime,
    endTime,
    orderNo,
    orderPayInfo,
    orderReceivingInfo
  } = OrderDetailStore.orderDetail;
  const { consignee, province, city, district, town, address, mobileNumber, buyerRemark } =
    orderReceivingInfo;

  let {
    actualPaymentPrice,
    currentFreightPrice,
    currentOrderPrice,
    paymentMethod,
    paymentTime,
    receivePaymentImage,
    receivePaymentRemark,
    payBillNo
  } = orderPayInfo;

  const [big, setBig] = useState(false);

  return (
    <Card>
      <Title level={5}>付款信息</Title>
      <div style={{ borderBottom: '1px solid #eee', marginBottom: 20 }} />
      <Space size={[160, 10]} wrap>
        <OrderInfoItem label={'货品总额'} value={(currentOrderPrice / 100).toFixed(2) + '元'} />

        <OrderInfoItem label={'付款方式'} value={PaymentMethod[paymentMethod]} />

        {[1, 4].includes(orderType) && (
          <OrderInfoItem
            label={<Text strong>运费总计</Text>}
            value={
              <Text>
                {groupList.some((item) => !item.id)
                  ? '待确认'
                  : (currentFreightPrice / 100).toFixed(2) + '元'}
              </Text>
            }
          />
        )}

        <OrderInfoItem label={'收款备注'} value={receivePaymentRemark} />

        <OrderInfoItem
          label={<Text strong>应付总额</Text>}
          value={
            <Text type={'danger'}>
              {actualPaymentPrice ? actualPaymentPrice / 100 + '元' : '待确认'}
            </Text>
          }
        />

        {receivePaymentImage && (
          <OrderInfoItem
            label={'收款凭证'}
            value={
              <img
                width={80}
                src={receivePaymentImage}
                alt=""
                onClick={() => setBig((big) => !big)}
              />
            }
          />
        )}
        {big && receivePaymentImage && (
          <img
            style={{
              zIndex: 99999,
              height: 800,
              position: 'fixed',
              top: 100,
              left: 0,
              right: 0,
              margin: 'auto',
              boxShadow: 'rgb(175 175 175) -4px 5px 12px 6px'
            }}
            src={receivePaymentImage}
            alt=""
            onClick={() => setBig((big) => !big)}
          />
        )}
      </Space>
    </Card>
  );
};

export default observer(ApplyInfo);
