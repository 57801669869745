/**
 * @Description: 物流状态
 * @author 点点
 * @date 2021/6/7
 * @time 14:32
 */

import React, { FC } from "react";

type Props = {
  // 物流状态
  returnStatus: number;
  receiptStatus: number;
  deliveryStatus: number;
};
const ShipStatus: FC<Props> = (props: Props) => {
  const { returnStatus, receiptStatus, deliveryStatus } = props;
  const shipStatusCallback = () => {
    if (returnStatus !== 0) {
      if (returnStatus === 1) {
        return "售后中";
      } else if (returnStatus === 2) {
        return "已退款";
      }
    } else if (receiptStatus !== 0) {
      if (receiptStatus === 1) {
        return "待收货";
      } else if (receiptStatus === 2) {
        return "已收货";
      }
    } else if (deliveryStatus !== 0) {
      if (deliveryStatus === 1) {
        return "待发货";
      } else if (deliveryStatus === 2) {
        return "已发货";
      }
    }
  };
  return <>{shipStatusCallback()}</>;
};

export default ShipStatus;
