import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { ListDetailProps } from '@/types/commom';
import httpClient from "@/tools/httpClient";
import { ExpressApis } from '@/api/expressApis';

type GetExpressParams = {
  // 物流公司名称
  expressName?: string;
  pageNo: number;
  pageSize: number;
};

type ExpressItem = {
  // 物流公司code
  expressCode: string;
  // 物流公司名称
  expressName: string;
};

type LogisticByExpressNoParams = {
  expressNo: string;
};

type ExpressInfo = {
  // 内容
  context: string;
  // 操作人
  operator: string;
  // 时间
  time: string;
};

type LogisticByExpressNoProps = {
  contactNumber: string;
  expressCode: string;
  expressInfo: ExpressInfo[];
  expressName: string;
  expressNo?: string;
  expressNoStore?: string
};

interface ExpressStoreProps {
  expressData: ListDetailProps<ExpressItem>;
  LogisticByExpressNo: LogisticByExpressNoProps;
  ajaxGetExpress: (params: GetExpressParams) => void;
  ajaxGetLogisticByExpressNo: (params: LogisticByExpressNoParams) => void;
}

class ExpressStore implements ExpressStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  expressData: ListDetailProps<ExpressItem> = {
    list: [],
    total: 0,
    pageSize: 20,
    pageNo: 1
  };

  @action
  async ajaxGetExpress(params: GetExpressParams) {
    const res = await httpClient.post(ExpressApis.get('pageQuery'), params);
    if (res?.success) {
      runInAction(() => {
        this.expressData = res.data;
      });
    }
  }

  @observable
  LogisticByExpressNo: LogisticByExpressNoProps = {
    contactNumber: '',
    expressCode: '',
    expressInfo: [],
    expressName: '',
    expressNoStore: ''
  };

  resetLogisticByExpressNo() {
    this.LogisticByExpressNo = {
      contactNumber: '',
      expressCode: '',
      expressInfo: [],
      expressName: '',
      expressNoStore: ''
    };
  }

  @action
  async ajaxGetLogisticByExpressNo(params: LogisticByExpressNoParams) {
    this.resetLogisticByExpressNo()
    const res = await httpClient.get(ExpressApis.get('queryLogisticByExpressNo'), { params });
    if (res?.success) {
      runInAction(() => {
        this.LogisticByExpressNo = res.data;
        this.LogisticByExpressNo.expressNoStore = res.data.expressNo
      });
    }
  }
}

export default new ExpressStore();
