import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Modal, Table, Radio, Space, InputNumber, message } from '@fle-ui/next';
import OrderDetailStore, { DetailGoodItemProp } from '@/stores/orderDetailStore';
import OrderOperateStore, { ConfirmFreightParams } from '@/stores/orderOperateStore';
import { delay } from '@/tools/delay';
import PriceConfig from '@/tools/priceConfig';
import { flatRowSpanMerge } from '@/tools/rowSpanMerge';
import OrderGoodPrice from '@/pages/shopManage/order/component/OrderGoodPrice';
import GoodDesc from './GoodDesc';
import { RadioChangeEvent } from '@fle-ui/next/lib/radio/interface';
import { WhiteSpace } from '@/components';
import PurchaseType from './PurchaseType';

const getAll = (formValue: DetailGoodItemProp[]) =>
  formValue.reduce((pre, cur) => pre + cur.freight, 0);

const { Column } = Table;

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: () => void;
  // 确认改价
  onOk: (data: any) => void;
  // id
  id: string;
};
const ConfirmOrderShipModal: FC<Props> = (props: Props) => {
  const { visible, onClose, onOk, id } = props;
  const { orderNo } = OrderDetailStore.orderDetail || { groupList: [] };

  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState<DetailGoodItemProp[]>([]);
  const [allValue, setAllValue] = useState<undefined | number>(undefined);
  const [shipType, setShipType] = useState<'each' | 'all'>('each');

  useEffect(() => {
    if (!visible) {
      return () => {
        setShipType('each');
        setAllValue(undefined);
      };
    }
    setLoading(true);
    OrderDetailStore.ajaxGetOrderDetail({ orderNo: id })
      .then(() => {
        const { groupList } = OrderDetailStore.orderDetail || { groupList: [] };
        setFormValue(flatRowSpanMerge(groupList).filter((item) => !item.freightId));
      })
      .finally(() => setLoading(false));
  }, [id, visible]);

  const onChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setShipType(value);
    if (value === 'all') {
      setFormValue(formValue.map((item) => ({ ...item, price: '' })));
      return;
    }
    if (value === 'each') {
      setAllValue(undefined);
      return;
    }
  };

  const handleOk = () => {
    const goodsFreightList = formValue.map((item) => ({
      freight: item.freight * 100,
      skuId: item.skuId
    }));

    const params: ConfirmFreightParams = {
      orderNo: id || orderNo
    };
    if (shipType === 'each') {
      params.goodsFreightList = goodsFreightList;
    } else {
      params.totalFreight = allValue && allValue * 100;
    }

    Modal.confirm({
      title: '确认运费金额？',
      content: '确认订单后，买家即可按最新订单金额支付订单',
      async onOk() {
        const res = await OrderOperateStore.ajaxConfirmFreight(params);
        if (res) {
          message.success('操作成功');
          await delay();
          onOk(formValue);
          onClose();
        } else {
          message.error('操作失败');
        }
        OrderDetailStore.ajaxGetOrderDetail({ orderNo }).finally(() => setLoading(false));
      }
    });
  };

  const getDisable = () => {
    if (shipType === 'each') {
      return formValue.some((item) => item.freight === undefined);
    }
    if (shipType === 'all') {
      return allValue === undefined;
    }
    return false;
  };

  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, [formValue]);

  return (
    <Modal
      width={1200}
      title={'以下商品需确认运费：'}
      open={visible}
      onCancel={onClose}
      destroyOnClose
      onOk={handleOk}
      okButtonProps={{ disabled: getDisable() }}
    >
      <Table dataSource={formValue} pagination={false} loading={loading} rowKey={'id'}>
        <Column<DetailGoodItemProp>
          title={'商品'}
          render={(text, record) => {
            return <GoodDesc {...record} />;
          }}
        />

        <Column
          title={'采购类型'}
          align={'center'}
          dataIndex={'isDeductionOfInventory'}
          render={(text) => <PurchaseType type={text} />}
        />
        <Column title={'数量'} align={'center'} dataIndex={'quantity'} />
        <Column<DetailGoodItemProp>
          title={'单价'}
          align={'center'}
          render={(text, record) => <OrderGoodPrice {...record} NoPrice={true} />}
        />
        <Column
          title={'小计'}
          align={'center'}
          dataIndex={'orderPrice'}
          render={(text) => '￥' + (text / 100).toFixed(2)}
        />
        <Column
          title={'运费'}
          align={'center'}
          dataIndex={'id'}
          render={(id, record: any, index) => {
            return (
              <InputNumber
                type={'number'}
                min={0}
                max={PriceConfig.Max}
                value={shipType === 'all' ? undefined : record.freight}
                disabled={shipType === 'all'}
                placeholder={'请输入运费金额'}
                onChange={(e) => {
                  const val = e === 0 ? 0 : undefined;
                  formValue[index].freight = e || val;
                  setFormValue([...formValue]);
                }}
              />
            );
          }}
        />
      </Table>

      <WhiteSpace />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          运费计算方式：
          <Radio.Group onChange={onChange} value={shipType}>
            <Radio value={'each'}>按商品设定运费</Radio>
            <Radio value={'all'}>设定总运费</Radio>
          </Radio.Group>
        </Space>

        <Space style={{ paddingRight: 18 }}>
          总运费：
          <InputNumber
            placeholder={'运费合计'}
            type={'number'}
            min={0}
            max={PriceConfig.Max}
            value={shipType === 'each' ? getAll(formValue) : allValue}
            onChange={(value) => setAllValue(value || (value === 0 ? 0 : undefined))}
            disabled={shipType === 'each'}
          />
        </Space>
      </div>
    </Modal>
  );
};

export default observer(ConfirmOrderShipModal);
