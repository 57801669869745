/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-04-19 16:30:00
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-20 00:17:38
 */
import { makeAutoObservable, configure, action, runInAction } from 'mobx';
import httpClient from "@/tools/httpClient";
import type { sendAddressProps } from '@/types/store/address';
import { OrderApis } from '@/api/afterSaleApis';

configure({ enforceActions: 'observed' });

class sendAddress implements sendAddressProps {
  constructor() {
    makeAutoObservable(this);
  }

  public addressList = [];
  public defaultLocation = {};

  /**
   * 获取地址列表-不分页
   */
  @action
  getLocationList = async (params: any) => {
    const res = await httpClient.post(OrderApis.get('GET_lOCATION_LIST'), params);
    if (res.success) {
      runInAction(() => {
        this.addressList = res.data.map((item: any, index: number) =>
          Object.assign(item, { key: index })
        );
      });
    }
    return [];
  };

  /**
   * 获取地址列表-分页
   * @param params
   */
  @action
  getDefaultLocation = async (params: any) => {
    const res = await httpClient.post(OrderApis.get('GET_lOCATION_LIST_PAGES'), params);
    if (res?.success) {
      runInAction(() => {
        this.defaultLocation = res?.data;
      });
      return res?.data;
    }
  };

  /**
   * 新增地址
   * @param params
   */
  @action
  addLocation = async (params: any) => {
    return await httpClient.post(OrderApis.get('POST_ADD_lOCATION'), params);
  };

  /**
   * 修改地址
   * @param params
   */
  @action
  updateLocation = async (params: any) => {
    return await httpClient.post(OrderApis.get('POST_UPDATE_lOCATION'), params);
  };

  /**
   * 删除地址
   * @param params
   */
  @action
  deleteLocation = async (params: any) => {
    return await httpClient.post(OrderApis.get('POST_DELETE_lOCATION'), params);
  };

  /**
   * 设置默认地址
   * @param params
   */
  @action
  setDefaultLocation = async (params: any) => {
    return await httpClient.post(OrderApis.get('POST_SET_DEFAULT_LOCATION'), params);
  };
}

export default new sendAddress();
