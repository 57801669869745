import React, { FC, useEffect, useState } from 'react';
import { Modal, Row, Col, Checkbox } from '@fle-ui/next';
import { freightStore } from '@/stores';
import './index.less';
import { CheckboxChangeEvent } from '@fle-ui/next/lib/checkbox';

type ChooseAreaProps = {
  visible: any;
  onCancel: any;
  freightIndex: any;
  type: any;
  modalType: any;
  [key: string]: any;
};

const ChooseArea: FC<ChooseAreaProps> = (props) => {
  const { visible, onCancel, freightIndex, type, modalType } = props;

  const [area, setArea] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);
  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    let areaStr: string = localStorage.getItem('freightArea') as string;
    if (!areaStr) {
      await freightStore.getFreightArea({ dictType: 'freightArea' });
      areaStr = localStorage.getItem('freightArea') as string;
    }
    let area: any[] = JSON.parse(areaStr);
    if (type === 'edit') {
      area = area.map((item) => {
        if (item.freightIndex === freightIndex) {
          item.check = true;
        }
        item.children = item.children.map((itemIn: any) => {
          if (itemIn.freightIndex === freightIndex) {
            itemIn.check = true;
          }
          return itemIn;
        });
        return item;
      });
    }

    setArea(area);
  };

  const modalOk = () => {
    area.map((item) => {
      // 被勾选的替换成传入的index，去掉勾的把index置为0，原本非传入的index的项不变
      item.freightIndex = item.check
        ? freightIndex
        : item.freightIndex === freightIndex
        ? 0
        : item.freightIndex;
      //所有的勾去掉
      item.check = false;
      item.children = item.children.map((itemIn: { freightIndex: any; check: boolean }) => {
        itemIn.freightIndex = itemIn.check
          ? freightIndex
          : itemIn.freightIndex === freightIndex
          ? 0
          : itemIn.freightIndex;
        itemIn.check = false;
        return itemIn;
      });
      return item;
    });
    setArea(area);
    localStorage.setItem('freightArea', JSON.stringify(area));
    onCancel();
  };

  const renderArea = () => {
    return area.map((item, index) => (
      <Row key={index}>
        <Col key={item.id} span={4} className={'area_cell'}>
          <Checkbox checked={item.check} onChange={(e) => checkChange(e, item, index, -1)}>
            <span
              style={{
                color:
                  item.freightIndex > 0 ? '#DB211D' : item.freightIndex < 0 ? '#1890FF' : '#3c3c3c'
              }}
            >
              {item.dictValue}
            </span>
          </Checkbox>
        </Col>
        {item.children.map(
          (
            itemIn: {
              id: string | number;
              check: boolean | undefined;
              freightIndex: number;
              dictValue: string | number | boolean;
            },
            indexIn: any
          ) => (
            <Col key={itemIn.id} span={4} className={'area_cell'}>
              <Checkbox
                checked={itemIn.check}
                onChange={(e) => checkChange(e, itemIn, index, indexIn)}
              >
                <span
                  style={{
                    color:
                      itemIn.freightIndex > 0
                        ? '#DB211D'
                        : itemIn.freightIndex < 0
                        ? '#1890FF'
                        : '#3c3c3c'
                  }}
                >
                  {itemIn.dictValue}
                  {item.freightIndex > 0}
                </span>
              </Checkbox>
            </Col>
          )
        )}
      </Row>
    ));
  };

  const checkChange = (
    e: CheckboxChangeEvent,
    item: {
      id: string | number;
      check: boolean | undefined;
      freightIndex: number;
      dictValue: string | number | boolean;
    },
    index: number,
    indexIn: number
  ) => {
    let value = e.target.checked;
    if (indexIn === -1) {
      area[index].check = value;
      area[index].children = area[index].children.map((obj: { check: boolean }) => {
        obj.check = value;
        return obj;
      });
    } else {
      area[index].children[indexIn].check = value;
      area[index].check = area[index].children.every((obj: { check: any }) => obj.check);
    }
    setArea(area);
    setRefresh(true);
  };

  return (
    <Modal
      open={visible}
      title={modalType === 'disabled' ? '设置不可配送区域' : '为指定地区设置运费'}
      centered
      onOk={modalOk}
      onCancel={onCancel}
      width={660}
    >
      <p className={'modal_body_tip'}>
        红色文字表示在其他指定地区中有设置运费
        {modalType === 'disabled' && ',再次勾选将会移入不可配送区域'}
        ,蓝色文字表示在不可配送区域中。
      </p>
      <div className={'area_container'}>{renderArea()}</div>
    </Modal>
  );
}

export default ChooseArea;
