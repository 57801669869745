import React, { FC, useEffect } from "react";
import { Modal, Input, Button, Form, message } from "@fle-ui/next";
import type { AddressItemProps, AjaxAddressParams } from "@/types/store/address";
import SiteSelect from "@fle-ui/pro-address-selection";

const { Item } = Form;

type Props = {
  visible: boolean;
  onOk: Function;
  onCancel: () => void;
  type: "add" | "edit";
  item?: Partial<AddressItemProps>;
  whichAddress: "shippingAddress" | "billAddress";
};

const AddressModal: FC<Props> = (props) => {
  const { visible, type, item, onCancel, onOk, whichAddress } = props;
  const { address, cityCode, countyCode, mobile, name, provinceCode, townCode } = item as AddressItemProps;

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      if (type === "edit") {
        form.setFieldsValue({
          name,
          address,
          mobile,
          location: townCode ? [provinceCode, cityCode, countyCode, townCode] : [provinceCode, cityCode, countyCode],
        });
      }
    }

    return () =>
      form.setFieldsValue({
        name: "",
        address: "",
        mobile: "",
        location: [],
      });
  }, [visible, name, address, mobile, provinceCode, cityCode, countyCode, form, type, townCode]);

  const confirm = () => {
    form
      .validateFields()
      .then(async (values) => {
        let params = {
          address: values.address.trim(),
          code: values.location[3] || values.location[2],
          defaultFlag: 1,
          mobile: values.mobile,
          name: values.name.trim(),
          type: 5,
          // shippingAddress: whichAddress === 'shippingAddress' ? 1 : 0,
          // billAddress: whichAddress === 'billAddress' ? 1 : 0,
        };
        await onOk({ ...item, ...params });
        message.success(type === "edit" ? "修改成功！" : "增加成功！");
        onCancel();
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  return (
    <Modal
      destroyOnClose
      forceRender
      open={visible}
      centered
      footer={
        <>
          <Button style={{ width: 100 }} onClick={() => onCancel()}>
            取消
          </Button>
          <Button type="primary" style={{ width: 100 }} onClick={() => confirm()}>
            确认
          </Button>
        </>
      }
      title={type === "add" ? "新增地址" : "编辑地址"}
      onCancel={onCancel}>
      <Form preserve={false} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form}>
        <Item
          label="收件人"
          name="name"
          rules={[
            { required: true, message: "请输入收件人", whitespace: true },
            {
              type: "string",
              min: 2,
              max: 12,
              message: "收件人限制2-12字符",
              whitespace: true,
            },
          ]}>
          <Input placeholder="请输入" maxLength={12} />
        </Item>
        <Item
          label="联系方式"
          name="mobile"
          required
          rules={[
            { pattern: /^1[0-9]{10}$/, message: "请输入正确的联系方式" },
            () => ({
              validator(rule, value) {
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject("请输入联系方式");
              },
            }),
          ]}>
          <Input placeholder="请输入" maxLength={11} />
        </Item>
        <Item
          label="地区"
          name="location"
          normalize={(val) => {
            console.log(val);
            return val;
          }}
          rules={[{ required: true, type: "array", message: "请选择地区" }]}>
          <SiteSelect level={4} placeholder="请选择地址" />
        </Item>
        <Item
          label="详细地址"
          name="address"
          rules={[
            { required: true, message: "请输入详细地址", whitespace: true },
            {
              type: "string",
              min: 4,
              max: 80,
              message: "详细地址限制4-80字符",
              whitespace: true,
            },
          ]}>
          <Input.TextArea placeholder="详细地址限制4-80字符" maxLength={80} />
        </Item>
      </Form>
    </Modal>
  );
};

AddressModal.defaultProps = {
  item: {},
};

export default AddressModal;
