/**
 * @Description: 合并表格
 * @author 点点
 * @date 2021/6/18
 * @time 15:39
 */
import { DetailGoodItemProp, DetailGroupDetailGoodItemProp } from '@/stores/orderDetailStore';

export interface Column {
  rowSpan: number;
}

// 合并相同项
export const rowSpanMerge = <T, S extends Column>(data: T[], key: keyof T): S[] => {
  return data
    .reduce((result, item) => {
      const val = item[key];
      //首先将name字段作为新数组result取出
      if (result.indexOf(val) < 0) {
        result.push(val);
      }
      return result;
    }, [] as T[keyof T][])
    .reduce((result: any[], name: any) => {
      //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
      const children = data.filter((item) => item[key] === name);
      result = result.concat(
        children.map((item, index) => ({
          ...item,
          rowSpan: index === 0 ? children.length : 0 //将第一行数据添加rowSpan字段
        }))
      );
      return result;
    }, [] as S[]);
};

// 例子
// const data = [
//   {
//     key: '1',
//     name: 'A',
//     age: 32,
//     tel: '0571-22098909'
//   },
//   {
//     key: '2',
//     name: 'A',
//     age: 12,
//     tel: '0571-22098333'
//   },
//   {
//     key: '3',
//     name: 'B',
//     age: 32,
//     tel: '0575-22098909'
//   },
//   {
//     key: '4',
//     name: 'B',
//     age: 18,
//     tel: '0575-22098909'
//   },
//   {
//     key: '5',
//     name: 'B',
//     age: 18,
//     tel: '0575-5'
//   },
//   {
//     key: '6',
//     name: 'C',
//     tel: '0571-6',
//     age: 12
//   },
//   {
//     key: '7',
//     name: 'C',
//     tel: '0571-7',
//     age: 19
//   }
// ];
//
// console.log(rowSpanMerge(data, 'name'));

export const flatRowSpanMerge = (data: DetailGroupDetailGoodItemProp[]) => {
  let res: DetailGoodItemProp[] = []
  data?.forEach(({ goodsList, id, ...rest }) => {
    goodsList.forEach((item, index) => {
      res.push({ freightId: id, ...rest, ...item, rowSpan: index === 0 ? goodsList.length : 0 });
    });
  });
  return res
};
