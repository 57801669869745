import { OrderApis } from '@/api/afterSaleApis';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { Moment } from 'moment';
import { DetailGoodItemProp } from '@/stores/afterSaleDetailStore';
import httpClient from "@/tools/httpClient";
import { ListDetailProps, PageProps } from '@/types/commom';

export interface GetOrderListParams extends PageProps {
  // 公司id
  companyId: string;
  // 下单账号
  createUserId: string;
  // 下单时间：起
  start: string;
  // 下单时间：止
  end: string;
  // 商品名称
  goodsName: string;
  // 订单号
  orderNo: string;

  // 订单状态：1、待卖家确认，2、待买家付款，3、待卖家发货，4、待买家收货，5、交易成功，6、交易关闭
  orderStatus: Array<1 | 2 | 3 | 4 | 5 | 6> | '1,2' | '3' | '4' | '5';
  // 用户id
  userId: '';
  // 来源：1、买家 ，2、卖家
  source?: 1 | 2;
  // 订单类型：1、集采批发订单，2、集采代发订单，3、印刷订单
  orderType: 1 | 2 | 3 | 4;

  date?: Moment[];
}

export interface OrderItem {
  afterSalesType: number;
  afterSalesApplicant: string;
  afterSalesApplicantName: string;
  afterSalesMethod: number;
  afterSalesNo: string;
  afterSalesNum: number;
  afterSalesProcessor: string;
  afterSalesProcessorName: string;
  afterSalesSource: number;
  afterSalesStatus: number;
  createTime: string;
  finalSalePrice: number;
  freightPrice: number;
  goodsId: string;
  goodsImage: string;
  goodsName: string;
  goodsNo: string;
  isContainsTax: number;
  orderNo: string;
  orderType: number;
  pickUpMethod: number;
  refundAmount: number;
  shopId: string;
  shopName: string;
  siteId: string;
  siteName: string;
  skuId: string;
  spuId: string;
  successfulAfterSalesNum: number;
  taxRate: number;
  thirdSalePrice: number;
  thirdRefund: number;
  userAfterSalesNo: string;
  userOrderNo: string;
}

interface OrderStatusCountParams {
  companyId?: '';
  // 订单类型：1、集采批发订单，2、集采代发订单，3、印刷订单
  orderType: 1 | 2 | 3 | 4;
  userId?: '';
}

interface OrderStatusCountResult {
  // 待审核数量
  waitReviewCount: 0;
  // 待收货数量
  waitReceivingCount: 0;
  // 待处理数量
  pendingCount: 0;
}

export interface CancelOptionProps {
  key: string;
  value: string;
}

interface OrderListStoreProps {
  orderList: ListDetailProps<OrderItem>;
  // 集采批发订单
  ajaxGetPurWholesaleOrderList: (params: Partial<GetOrderListParams>) => void;
  ajaxGetOrderStatusCount: (params: OrderStatusCountParams) => void;
  ajaxGetReason: () => Promise<CancelOptionProps[]>;
}

const formatParams = (params: Partial<GetOrderListParams>) => {
  if (typeof params.orderStatus === 'string') {
    return params.orderStatus.split(',').map((item) => Number(item)) as Array<
      1 | 2 | 3 | 4 | 5 | 6
    >;
  }
  return undefined;
};

class OrderListStore implements OrderListStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  orderList: ListDetailProps<OrderItem> = {
    list: [],
    pageNo: 1,
    pageSize: 20,
    total: 0
  };

  @action
  async ajaxGetPurWholesaleOrderList(params: Partial<GetOrderListParams>) {
    params.source = 2;
    params.orderType = 1;
    params.orderStatus = formatParams(params);
    const res = await httpClient.post(OrderApis.get('getOrderList'), params);
    if (res?.success) {
      runInAction(() => {
        this.orderList = res.data;
      });
    }
  }

  @action
  async ajaxGetPurProxyOrderList(params: Partial<GetOrderListParams>) {
    params.source = 2;
    params.orderType = 2;
    params.orderStatus = formatParams(params);
    const res = await httpClient.post(OrderApis.get('getOrderList'), params);
    if (res?.success) {
      runInAction(() => {
        this.orderList = res.data;
      });
    }
  }

  @action
  async ajaxGetOnceProxyOrderList(params: Partial<GetOrderListParams>) {
    params.source = 2;
    params.orderType = 4;
    params.orderStatus = formatParams(params);
    const res = await httpClient.post(OrderApis.get('getOrderList'), params);
    if (res?.success) {
      runInAction(() => {
        this.orderList = res.data;
      });
    }
  }

  @observable
  orderStatusCount: OrderStatusCountResult = {
    waitReviewCount: 0,
    waitReceivingCount: 0,
    pendingCount: 0
  };

  @action
  async ajaxGetOrderStatusCount(params:any) {
    const res = await httpClient.post('/merchant/seller/afterSales/afterSalesCount', params);
    if (res?.success) {
      runInAction(() => {
        this.orderStatusCount = res.data;
      });
    }
  }

  @action
  async ajaxGetReason(): Promise<CancelOptionProps[]> {
    const res = await httpClient.get(OrderApis.get('getReason'), {});
    return res?.success && res.data;
  }

  //  会员名称模糊搜索
  @action
  async getVipName(params: any): Promise<any> {
    const res: any = await httpClient.post('/admin/member/company/company-names', params);
    return res?.success && res.data;
  }

  // 审核
  @action
  async reviewAfterSalesReq(params: any): Promise<any> {
    const res: any = await httpClient.post(
      '/merchant/seller/afterSales/reviewAfterSalesReq',
      params
    );
    return res?.success && res;
  }

  // 处理
  @action
  async handleAfterSales(params: any): Promise<any> {
    const res: any = await httpClient.post('/merchant/seller/afterSales/handleAfterSales', params);
    return res?.success && res;
  }

  // 收货
  @action
  async receivingAfterSales(params: any): Promise<any> {
    const res: any = await httpClient.post(
      '/merchant/seller/afterSales/receivingAfterSales',
      params
    );
    return res?.success && res;
  }
}

export default new OrderListStore();
