import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { PageContainer } from "@fle-ui/pro-layout";
import financeStore from '@/stores/financeStore';
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Statistic,
  Tooltip,
  Tag,
  Menu,
  Dropdown,
  DatePicker
} from '@fle-ui/next';
import type { ProColumns, ColumnsKey } from '@fle-ui/pro-table';
import ProTable from '@fle-ui/pro-table';
import { showTime } from '@/tools/common'
import './index.less';
interface MainProps {
  [key: string]: any;
}

const { RangePicker } = DatePicker;

const columns: ProColumns<ColumnsKey>[] = [
  {
    title: '余额明细ID',
    dataIndex: 'balanceId',
    copyable: true,
    ellipsis: true,
    align: 'center',
    hideInSearch: true
  },
  {
    title: '蛋糕叔叔订单号',
    dataIndex: 'orderNo',
    copyable: true,
    ellipsis: true,
    align: 'center',
    fieldProps: {
      placeholder: "输入蛋糕叔叔订单号搜索",
      allowClear: true,
    },
    formItemProps: {
      label: '订单号'
    }
  },
  {
    title: '渠道方订单号',
    dataIndex: 'outOrderNo',
    copyable: true,
    ellipsis: true,
    align: 'center',
    hideInSearch: true
  },
  {
    title: '金额变动类型',
    dataIndex: 'moneyType',
    align: 'center',
    hideInSearch: true
  },
  {
    title: '订单类型',
    dataIndex: 'orderType',
    align: 'center',
    hideInSearch: true
  },
  {
    title: '金额',
    dataIndex: 'money',
    align: 'center',
    hideInSearch: true,
    render: (v) => {
      return <Statistic
      value={v / 100}
      precision={2}
      prefix={'￥'}
      valueStyle={{ color: '#3590FF' }}
    />
    }
  },
  {
    title: '余额变动时间',
    dataIndex: 'createdAt',
    align: 'center',
    hideInSearch: true
  },
  {
    title: '余额变动日期',
    align: 'center',
    hideInTable: true,
    key: "dateRange",
    fieldProps: {
      placeholder: ['开始日期', '结束日期'],
      allowClear: true,
    },
    renderFormItem: (_, { type, defaultRender, ...rest }) => {
      return (
        <RangePicker />
      );
    },
  },

];

// 蛋糕叔叔渠道预付款
const DgssManage: React.FC<MainProps> = (props) => {

  // 渠道预付款余额
  const [balance, setBalance] = useState<number>(0);

  const getDgssBalance = async () => {
    try {
      const res = await financeStore.getDgssBalance()
      if (res?.success) {
        setBalance(res.data || 0)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getDgssBalance()
  }, []);

  return (
    <PageContainer>
      <div className='finace-dgss-manage'>
        <div className="finace-dgss-manage-balance">
          <div className='finace-dgss-manage-balance-key'>渠道预付款余额：</div>
          <Statistic
            value={balance / 100}
            precision={2}
            prefix={'￥'}
            valueStyle={{ color: '#3590FF', fontWeight: 600, fontSize: 20 }}
          />
        </div>

        <ProTable<any>
          columns={columns}
          resizable={true}
          bordered={true}
          loading={financeStore.financeDgssLoading}
          scroll={{ x: 1120 }}
          request={async (params = {}, sort, filter) => {
            const _params: any = {
              ...params,
              pageNo: params.current,
            };
            if (_params.dateRange) {
              _params.startTime = showTime(_params.dateRange[0], 'YYYY-MM-DD') + ' 00:00:00'
              _params.endTime = showTime(new Date(_params.dateRange[1]).getTime() + 1000 * 60 * 60 * 24, 'YYYY-MM-DD') + ' 00:00:00'
            }
            delete _params.current
            delete _params.dateRange

            return await financeStore.getDgssBalanceChange(_params);
          }}
          rowKey="balanceId"
          search={{
            labelWidth: 'auto',
          }}
          pagination={{
            pageSize: 10,
          }}
          options={false}
        />
      </div>
    </PageContainer>

  );
};
export default observer(DgssManage);