/**
 * @Description:  [卖家端-象铺]查询卖家会员交易明细
 * @author 点点
 * @date 2021/6/21
 * @time 09:58
 */
import { TradeApis } from '@/api/tradeApis';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { Moment } from 'moment';
import { CommonStore } from '@/stores/index';
import { ExportFile } from '@/tools/exportFile';
import { ListDetailProps, PageProps } from '@/types/commom';
import httpClient from "@/tools/httpClient";

export enum AccountType {
  飞象结算账户 = 10,
  对公结算账户 = 11
}

// export enum BizType {
//   销售 = 101001,
//   账户充值 = 102001,
//   结算 = 103001,
//   退款 = 104001,
//   服务费 = 105001,
//   商品采购 = 106001,
//   支付运费 = 106005,
//   转账 = 109001,
//   提现 = 101101
// }

export const BizType: any = {
  '106001': '销售',
  '106005': '销售',
  '101101': '提现',
  '105001': '服务费',
  '104001': '退款' ,//（暂时不查）
  '101001': '销售',
};

export enum Flag {
  收入,
  支出
}

export enum FlagSymbol {
  '+',
  '-'
}

export enum FeeType {
  货款 = 501001,
  发货单服务费 = 501010,
  提现款 = 501020,
  充值款 = 501030,
  短信费 = 501040,
  会员套餐 = 501050,
  提现手续费 = 504001,
  结算手续费 = 504010
}

export enum JDOrderState {
  新建,
  妥投,
  拒收
}

export enum JDInvoiceState {
  未开票,
  已开票,
  售后中,
  已退款
}

export interface QuerySupplierTradeParams extends PageProps {
  shopId?: string;
  // 账户类型，10-飞象结算账户，11-对公结算账户
  accountType: 10 | 11;
  // 交易类型，101001-销售 104001-退款 105001-服务费 101101-提现
  bizType: 101001 | 104001 | 105001 | 101101;
  // 费用科目
  feeType: string;
  // 记账类型，0-收入，1-支出,可用值:0,1
  flag: 0 | 1;
  // 查询依据，1-根据交易流水号（精准），2-根据订单号（精准）,可用值:1,2
  keyType: 1 | 2;
  // 关键字，如交易流水号、订单号
  keyword: string;
  // 入账结束时间（用于范围查询，格式yyyy-MM-dd）
  payTimeEnd: string;
  // 入账起始时间（用于范围查询，格式yyyy-MM-dd）
  payTimeStart: string;
  date?: [Moment, Moment];
}

export interface TradeItemProps {
  // 交易账户，10-飞象结算账户 11-对公结算账户
  accountType: 10 | 11;
  // 交易金额（单位：分），前端需要根据flag自适应显示 +/-
  amount: number;
  // 交易类型，101001-销售 104001-退款 105001-服务费 101101-提现
  bizType: 101001 | 104001 | 105001 | 101101;
  // 交易备注
  description: string;
  // 费用科目
  feeType: string;
  // 收支类型，0-收入 1-支出
  flag: 0 | 1;
  // 订单号，例如：订单号、结算单号、退款单号都有可能，必须按照bizType区分
  orderNo: string;
  // 交易时间，毫秒
  payTime: number;
  // 账户余额（单位：分）
  restAmount: number;
  // 交易流水号，account_serial的id属性
  serialNo: string;
  // 支付来源信息
  source: '';
}

export type SupplierTradeAmount = {
  // 支出（单位：分）
  expense: number;
  // 支出总条数
  expenseCount: number;
  // 收入（单位：分）
  income: number;
  // 收入总条数
  incomeCount: number;
};

export interface CheckOrderListParams {
  shopId?: string;
  // 截止日期 格式必须：yyyy-MM-dd
  endDate: string;
  // 开始日期，必填 格式必须：yyyy-MM-dd
  startDate: string;
  // 京东订单Id，如果超过一万条数据，pageNo就不起作用了，需要通过订单Id作为偏移量，返回结果不包含当前订单
  jdOrderIdIndex: number;
  // 京东订单号
  orderId: number;
  // 订单物流状态，必填 0-新建 1-妥投 2-拒收
  orderState: 0 | 1 | 2;
  pageNo: number;
  pageSize: number;
  date?: Moment[];
}

export interface CheckOrderItemProps {
  // 挂起状态
  hangUpState: number;
  // 开票状态0未开票 1已开票 2售后中 3已退款
  invoiceState: 0;
  // 订单号
  jdOrderId: string;
  // 订单金额
  orderPrice: 0;
  // 订单状态
  state: 0;
  // 余额变动日期
  time: string;
}

interface TradeStoreProps {
  tradeList: ListDetailProps<TradeItemProps>;
  supplierTradeAmount: SupplierTradeAmount;
  checkOrderList: ListDetailProps<CheckOrderItemProps>;
  ajaxGetTradeList: (params: Partial<QuerySupplierTradeParams>) => void;
  ajaxGetSupplierTradeAmount: (params: Partial<QuerySupplierTradeParams>) => void;
  exportSupplierTradeExcel: (params: Partial<QuerySupplierTradeParams>) => void;
  ajaxGetCheckOrderList: (params: CheckOrderListParams) => void;
}

class TradeStore implements TradeStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  supplierTradeAmount: SupplierTradeAmount = {
    expense: 0,
    expenseCount: 0,
    income: 0,
    incomeCount: 0
  };

  @action
  async ajaxGetSupplierTradeAmount(params: Partial<QuerySupplierTradeParams>) {
    params.shopId = await CommonStore.getShopId();
    const data = await httpClient.post(TradeApis.get('querySupplierTradeAmount'), params);
    if (data?.success) {
      runInAction(() => {
        this.supplierTradeAmount = data.data;
      });
    }
  }

  @observable
  tradeList: ListDetailProps<TradeItemProps> = {
    list: [],
    total: 0,
    pageSize: 20,
    pageNo: 1
  };

  @action
  async ajaxGetTradeList(params: Partial<QuerySupplierTradeParams>) {
    params.shopId = await CommonStore.getShopId();
    const data = await httpClient.post(TradeApis.get('querySupplierTrade'), params);
    if (data?.success) {
      runInAction(() => {
        this.tradeList = data.data;
      });
    }
  }

  @action
  async exportSupplierTradeExcel(params: Partial<QuerySupplierTradeParams>) {
    params.shopId = await CommonStore.getShopId();
    if (params?.date) {
      const [start, end] = params.date;
      params.payTimeStart = start.format('YYYY-MM-DD');
      params.payTimeEnd = end.format('YYYY-MM-DD');
    }
    delete params.date;
    ExportFile(
      TradeApis.get('exportSupplierTradeExcel'),
      'GET',
      params,
      '交易明细',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      () => {},
      () => {}
    );
  }

  @observable
  checkOrderList: ListDetailProps<CheckOrderItemProps> = {
    list: [],
    total: 0,
    pageNo: 1,
    pageSize: 20
  };

  @action
  async ajaxGetCheckOrderList(params: Partial<CheckOrderListParams>) {
    params.shopId = await CommonStore.getShopId();
    const data = await httpClient.post(TradeApis.get('getCheckOrderList'), params);
    if (data?.success) {
      runInAction(() => {
        this.checkOrderList = data.data;
      });
    }
  }
}

export default new TradeStore();
