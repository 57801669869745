/**
 * @Description: 选项卡选项
 * @author 点点
 * @date 2021/6/2
 * @time 14:32
 */

export const saleType = [
  { isOnce: 1, isPurchase: 1, isPurchaseShip: 1 },
  { isOnce: 0, isPurchase: 1, isPurchaseShip: 1 },
  { isOnce: 1, isPurchase: 0, isPurchaseShip: 0 }
];

// 集采 批 发订单订单状态
export const PurWholesaleOrderStates = [
  { value: '1', label: '等待卖家确认订单' },
  { value: '2', label: '等待买家付款' },
  { value: '3', label: '等待卖家发货' },
  { value: '4', label: '等待买家确认收货' },
  { value: '5', label: '交易成功' },
  { value: '6', label: '交易关闭' }
];

// 集采 代 发订单状态
export const PurProxyOrderStates = [
  { value: '2', label: '等待买家付款' },
  { value: '5', label: '交易成功' },
  { value: '6', label: '交易关闭' }
];

// 集采 批 发订单订单状态
export const OnceProxyOrderStates = [
  { value: '2', label: '等待买家付款' },
  { value: '3', label: '等待卖家发货' },
  { value: '4', label: '等待买家确认收货' },
  { value: '5,10', label: '交易成功' },
  { value: '6,20', label: '交易关闭' }
];

// 发货单管理状态
export const InvoiceManagementStates = [
  { value: 1, label: '待买家支付运费' },
  { value: 2, label: '待卖家发货' },
  { value: 3, label: '待买家确认收货' },
  { value: 4, label: '已完成' },
  { value: 5, label: '已取消' }
];

// 集采 批 发订单取消原因
export const CancelOrderReason = [
  { value: '买家不想要了', label: '买家不想要了' },
  { value: '买家填写信息错误，重新买', label: '买家填写信息错误，重新买' },
  { value: '库存不足，无法发货', label: '库存不足，无法发货' },
  { value: '已联系买家单独交易', label: '已联系买家单独交易' },
  { value: '其他原因', label: '其他原因' }
];

// 集采 代 发订单取消原因
export const InvoiceCancelOrderReason = [
  { value: '暂时不用发货', label: '暂时不用发货' },
  { value: '填写信息错误，重新填', label: '填写信息错误，重新填' },
  { value: '卖家缺货', label: '卖家缺货' },
  { value: '运费/包装费太高', label: '运费/包装费太高' },
  { value: '其他原因', label: '其他原因' }
];
