import React, { useEffect, useState, useLayoutEffect } from "react";
import ConsoleHeader from "@fle-ui/pro-console-header";
import { getAppInfo } from "@fle-tools/env";
import Cookies from "js-cookie";
import { AppRouter, AppRoute } from "@ice/stark";
import { Loading, ErrorPage, Icon } from "@/components";
import { ProMenu } from "@fle-ui/pro-layout";
import type { LeftMenuItem } from "@fle-ui/pro-layout/lib/components/proMenu";
import { useHistory } from "react-router-dom";
import ProResult from "@fle-ui/pro-result";
import { CommonStore } from "@/stores";
import { store } from "@ice/stark-data";
import { observer } from "mobx-react";
import type { CompatibleAppConfig } from "@ice/stark/lib/AppRoute";
import { getLeftMenu, getRouterList } from "./leftMenu";

import "./index.less";

const fle_env = getAppInfo().__fle_env__;

// const __router = routerLoader(routerConfig);

const RouterPack: React.FC = () => {
  const { accountInfo } = CommonStore;

  const appRouteList = getRouterList();

  const [userInfo, setUserInfo] = useState<any>();
  const [offsetLeft, setOffsetLeft] = useState(64);
  const [selectKey, setSelectKey] = useState([0]);
  const [leftMenu, setLeftMenu] = useState<any[]>([]);
  const [checkApp, setCheckApp] = useState<CompatibleAppConfig>();

  const history = useHistory();

  // 退出登录
  const handleLoginOut = async () => {
    const res = await CommonStore.logout();
    if (res?.success) {
      Cookies.remove("token");
      window.location.href = `/login/index`;
    }
  };

  // 获取左侧菜单app位置
  const getAppIndex = (name: string) => {
    const m = new Map();
    for (let key = 0; key < leftMenu.length; key++) {
      m.set(leftMenu[key].name, key);
    }
    return m.get(name);
  };

  // 回到首页
  const handleHome = () => {
    history.push("/");
  };

  const onFastApply = (type?: string) => {
    if (type === "download") {
      history.push("/module/common/download");
      setSelectKey([getAppIndex("设置管理")]);
    } else if (type === "channel") {
      history.push("/module/common/record");
      setSelectKey([getAppIndex("设置管理")]);
    }
  };

  const handleLeftMenuApply = (info?: LeftMenuItem | undefined) => !!info?.path && history.push(info.path);

  const getmemberInfo = async () => {
    try {
      const data = await CommonStore.getMemberInfo();
      setUserInfo({
        id: data.id,
        name: data.companyName,
        logoName: data.name,
        logo: data.logoImgUrl,
      });
      store.set("userInfo", data);
      store.set("companyConfig", data);
    } catch (ex) {
      console.warn(ex);
    }
  };

  useLayoutEffect(() => {
    getmemberInfo();
  }, []);

  useEffect(() => {
    if (!!accountInfo.companyId) {
      store.set("offsetLeft", offsetLeft);

      CommonStore.getShopId();

      const { isApiSupplier, isDGSS, isJD, isMAOCHAO} = accountInfo;

      let _leftMenu = getLeftMenu({ isApiSupplier, isDGSS, isJD, isMAOCHAO });

      _leftMenu = _leftMenu.filter((m) => m.show);

      setLeftMenu(_leftMenu);
      setDefaultKey(_leftMenu);
    }
  }, [accountInfo]);

  const setDefaultKey = (menu: any) => {
    const m = new Map();
    for (let key = 0; key < menu.length; key++) {
      m.set(menu[key].key, key);
    }
    setSelectKey([m.get(checkApp?.name)]);
  };

  useEffect(() => {
    !!leftMenu.length && setDefaultKey(leftMenu);
  }, [checkApp]);

  return (
    <>
      <ProMenu
        defaultSelectedKey={selectKey}
        styleType="left"
        onLeftMenuApply={handleLeftMenuApply}
        leftMenuItems={leftMenu}
      />
      <ConsoleHeader
        isFixed
        style={{ left: offsetLeft, transition: "0.2s all", width: "auto" }}
        env={fle_env}
        applyLogo={"https://oss.elebuys.com/resource/20220627/202206271715080000016310.png"}
        userInfo={userInfo}
        isLoading={false}
        fastApply={["channel", "download"]}
        onExit={handleLoginOut}
        onMainApply={handleHome}
        onFastApply={onFastApply}
        theme="light"
        showFastApplyName
      />
      <div className="fx__base-wrap">
        <AppRouter
          onAppEnter={setCheckApp}
          ErrorComponent={ErrorPage}
          LoadingComponent={Loading}
          NotFoundComponent={<ProResult type="404" />}
          onAppLeave={() => {
            // @ts-ignore
            window.webpackJsonp = [];
          }}>
          {appRouteList.map((app, index) => (
            <AppRoute {...app} key={index} />
          ))}
        </AppRouter>
      </div>
    </>
  );
};

export default observer(RouterPack);
