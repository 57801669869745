/**
 * @Description: 商品库-商品管理 路由控制器
 * @author 点点
 * @date 2021/5/17
 * @time 13:47
 */

import React, { FC, useEffect, useState } from 'react';
import { Route, Switch,Redirect } from 'react-router-dom';
import PubList from './pubList';
import DraftList from './draftList'
import PickJD from "./pickJD";
import PickDGSS from "./pickDGSS";
import PickMAOCHAO from "./pickMC";
import { isMAOCHAO } from '@/config/author';
import { CommonStore } from '@/stores';




type Props = {};
const CommodityManageModule: FC<Props> = () => {

const { accountInfo } = CommonStore;

  return (
    <Switch>
      <Route path="/commodity/manage/pub_list" exact component={PubList} />

      <Route path="/commodity/manage/draft_list" exact component={DraftList} />

      <Route path="/commodity/manage/pickJD" exact component={PickJD} />

      <Route path="/commodity/manage/pickDGSS" exact component={PickDGSS} />
      {/* 猫超 */}
      <Route path="/commodity/manage/pickMC" exact component={PickMAOCHAO} /> 

      <Redirect exact from={'/commodity'} to={'/commodity/manage/pub_list' }/>
    </Switch>
  );
};

export default CommodityManageModule;
