/**
 * @Description: 商品库 路由控制器
 * @author 点点
 * @date 2021/5/17
 * @time 13:47
 */

import React, { FC, useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import CommoditySettingModule from "./setting";
import CommodityManageModule from "./manage";
import { BasicLayout, PageContainer } from "@fle-ui/pro-layout";
// import { commodityMenu } from 'config/routers';
import { CommonStore } from "@/stores";
import { observer } from "mobx-react";

type Props = {
  location: any;
};
const CommodityModule: FC<Props> = (props) => {
  const [menu, setMenu]  = useState<any[]>([])

  const { commodityMenu, accountInfo } = CommonStore;

  useEffect(() => {
    if (!!accountInfo.companyId) {
      setMenu(commodityMenu)
    }
  }, [accountInfo])

  return (
    <BasicLayout
      offsetTop={50}
      offsetLeft={64}
      location={props.location}
      menuList={menu}
      breadcrumb={false}
      minWidth={1156}>
      <PageContainer padding={0}>
        <Switch>
          <Route>
            <CommodityManageModule />
            <CommoditySettingModule />
          </Route>
        </Switch>
      </PageContainer>
    </BasicLayout>
  );
};

export default withRouter(observer(CommodityModule));
