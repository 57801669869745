/**
 * @Description: 财务概览
 * @author 点点
 * @date 2021/6/24
 * @time 17:00
 */

export const FinanceApis: Map<string, string> = new Map([
  // 象铺日收支 - 月收支
  ['payment', '/elebuys/member/shop/account/payment'],
  //  提现金额统计（可提现、提现锁定金额、是否已有正在进行的提现申请、提现手续费费率）
  ['statistics', '/elebuys/member/shop/account/statistics'],
  // 提现金额统计
  ['withdraw-statistics', '/elebuys/shop/finance/withdraw-statistics'],
  //分页查询提现记录
  ['page', '/elebuys/shop/finance/page'],
  // 申请提现
  ['apply', '/elebuys/shop/finance/apply'],
  // 蛋糕叔叔余额
  ['getDgssBalance', '/merchant/supplier/dgss/balance/get'],
  // 蛋糕叔叔余额变动明细
  ['getDgssBalanceChange', '/merchant/supplier/dgss/getBalanceDetail'],
]);
