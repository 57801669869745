import React, { FC, useState } from 'react';
import { Button, Card, message, Modal, Table } from '@fle-ui/next';
import { useDebounceEffect } from 'ahooks';
import { goodsStore } from '@/stores';
import { getPagination } from '@/tools/getPagination';
import { GoodItemProps } from '@/types/store/goods';
import {
  GoodInfo,
  OncePrice,
  PurchasePrice,
  SaleType,
  Stock,
  PoolMallAvailableStatus
} from '../../../../components/TableColumn';
import { observer } from 'mobx-react';
import AddGoodButton from '../components/AddGoodButton';
import { PageContainer } from '@fle-ui/pro-layout';

const { Column } = Table;

type Props = {};
const OffSaleList: FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useDebounceEffect(
    () => {
      setLoading(true);
      goodsStore.getOffSale({ pageNo, pageSize }).finally(() => setLoading(false));
    },
    [pageNo, pageSize, refresh],
    { wait: 0 }
  );

  const changePageSize = (current: number, size: number) => {
    setPageNo(1);
    setPageSize(size);
  };

  return (
    <PageContainer padding={0}>
      <Card>
        <AddGoodButton />

        <Table
          loading={loading}
          dataSource={goodsStore.off_sale.list}
          pagination={getPagination({
            total: goodsStore.off_sale.total,
            current: pageNo,
            pageSize,
            onChange: setPageNo,
            onShowSizeChange: changePageSize
          })}
          rowKey={'goodsId'}
        >
          <Column<GoodItemProps>
            width={300}
            title={'商品信息'}
            dataIndex={'goodsId'}
            render={GoodInfo}
          />
          <Column<GoodItemProps>
            align={'center'}
            title={'销售支持'}
            dataIndex={'goodsId'}
            width={140}
            render={SaleType}
          />

          <Column<GoodItemProps>
            title={'集采价'}
            align={'center'}
            dataIndex={'goodsId'}
            render={PurchasePrice}
          />

          <Column<GoodItemProps>
            title={'代发价'}
            align={'center'}
            dataIndex={'goodsId'}
            render={OncePrice}
          />

          <Column<GoodItemProps>
            title={'现货库存'}
            align={'center'}
            dataIndex={'stock'}
            render={Stock}
          />
          <Column<GoodItemProps>
            title={'可售状态'}
            align={'center'}
            dataIndex={'goodsId'}
            render={PoolMallAvailableStatus}
          />
          <Column
            title={'操作'}
            align={'center'}
            dataIndex={'goodsId'}
            render={(goodsId) => (
              <>
                <Button
                  type={'link'}
                  onClick={async () => {
                    setLoading(true);
                    try {
                      const res = await goodsStore.onlineShelf(goodsId);
                      res
                        ? message.success('操作成功', 1, () => setRefresh(refresh + 1))
                        : message.error('操作失败', 1, () => setRefresh(refresh + 1));
                    } catch (error) {
                      console.log(error);
                      setLoading(false);
                    }
                  }}
                >
                  上架
                </Button>
                -
                <Button
                  type={'link'}
                  onClick={() => {
                    Modal.confirm({
                      content: '确认移除吗',
                      onOk: async () => {
                        setLoading(true);
                        try {
                          const res = await goodsStore.remove(goodsId);
                          res
                            ? message.success('操作成功', 1, () => setRefresh(refresh + 1))
                            : message.error('操作失败', 1, () => setRefresh(refresh + 1));
                        } catch (error) {
                          console.log(error);
                          setLoading(false);
                        }
                      }
                    });
                  }}
                >
                  移除
                </Button>
              </>
            )}
          />
        </Table>
      </Card>
    </PageContainer>
  );
};

export default observer(OffSaleList);
