/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:55:37
 */
// 审批相关
const certification = {
  GET_CERTIFICATION_INFO: "/elebuys/member/company/certification-info", // 查询认证信息
  SAVE_CERTIFICATION_INFO: "/elebuys/member/company/certification-info/save", // 保存认证信息
  UPDATE_CERTIFICATION_INFO:
    "/elebuys/member/company/certification-info/update", // 更新认证信息
  CANCEL_CERTIFICATION: "/elebuys/member/company/certification-info/cancel", // 取消认证
  GET_COMPANY_INFO: "/elebuys/member/company/extra-info", // 查询公司信息
  UPDATE_COMPANY_INFO: "/elebuys/member/company/extra-info/udpate", // 更新公司信息
  ADD_COMPANY: "/elebuys/member/company/add", // 添加企业
}

export default certification;