import useUrlState from '@ahooksjs/use-url-state';
import { useDebounceEffect } from 'ahooks';
import { Button, Col, Empty, Pagination, Row, Space, Spin } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IsContainsTax, OrderStatus } from '@/stores/orderDetailStore';
import OrderListStore, { GetOrderListParams } from '@/stores/orderListStore';
import { getPagination } from '@/tools/getPagination';
import { CancelOrderReason, PurProxyOrderStates } from '@/tools/selectOption';
import { PageProps } from '@/types/commom';
import OrderListForm from '@/pages/shopManage/order/component/OrderListForm';
import Quantity from '@/pages/shopManage/order/component/Quantity';

import { OrderListStyle } from '@/pages/shopManage/order/orderListStyle';
import CancelOrderModal from '../component/CancelOrderModal';
import ConfirmOrderModal from '../component/ConfirmOrderModal';
import { getUnit } from '../component/GetUnit';
import GoodDesc from '../component/GoodDesc';
import OrderPrice from '../component/OrderPrice';
import OrderState from '../component/OrderState';

type Props = {
  deliveryOrderQuantity: number;
};
const OrderList: FC<Props> = (props) => {
  const history = useHistory();
  const { list, pageNo, pageSize, total } = OrderListStore.orderList;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<PageProps>({ pageNo: 1, pageSize: 20 });
  const [formData, setFormData] = useState<Partial<GetOrderListParams>>({ ...page });
  const [curOrderNo, setCurOrderNo] = useState('');
  const [refresh, setRefresh] = useState(0);

  const [CancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);
  const [ConfirmOrderModalVisible, setConfirmOrderModalVisible] = useState(false);
  const [params] = useUrlState({ tab: 'default' }, { navigateMode: 'replace' });

  useDebounceEffect(
    () => {
      setLoading(true);
      OrderListStore.ajaxGetPurProxyOrderList({ ...formData, ...page }).finally(() =>
        setLoading(false)
      );
    },
    [formData, page, refresh],
    { wait: 10 }
  );
  useEffect(() => {
    setPage({ pageNo: 1, pageSize: 20 });
  }, [params]);

  return (
    <>
      <OrderListForm onSearch={setFormData} orderStatsList={PurProxyOrderStates} />

      <Spin spinning={loading}>
        <Row style={OrderListStyle.orderListHeader}>
          <Col span={2} style={{ ...OrderListStyle.tableVerticalLeft, paddingLeft: 20 }}>
            商品图片
          </Col>
          <Col span={4} style={{ ...OrderListStyle.tableVerticalLeft }}>
            商品名称
          </Col>
          <Col span={3} style={OrderListStyle.tableVerticalLeft}>
            单价（元）
          </Col>
          <Col span={3} style={OrderListStyle.tableVerticalLeft}>
            数量
          </Col>
          <Col span={3} style={OrderListStyle.tableVerticalLeft}>
            应付金额（元）
          </Col>
          <Col span={3} style={OrderListStyle.tableVerticalLeft}>
            发货单笔数
          </Col>
          <Col span={3} style={OrderListStyle.tableVerticalLeft}>
            订单状态
          </Col>
          <Col span={3} style={OrderListStyle.tableVerticalLeft}>
            操作
          </Col>
        </Row>

        {list.map((order, key) => (
          <div key={order.orderNo + '_' + key} style={OrderListStyle.boxWrap}>
            <Row gutter={8} style={OrderListStyle.orderDetailHeader}>
              <Col>
                <Space size={'large'}>
                  <span style={{ color: '#262626' }}>订单号：{order.orderNo}</span>
                  <span style={{ color: '#262626' }}>买家：{order.buyerCompanyName}</span>
                  <span style={{ color: '#a9a9a9' }}>{order.createTime}</span>
                </Space>
              </Col>
            </Row>
            <Row style={{ textAlign: 'left' }}>
              <Col span={11}>
                {order.mallOrderGoodsVOList.map((good, index) => (
                  <Row
                    key={good.id + '_' + index}
                    style={{
                      padding: '25px 0',
                      textAlign: 'left'
                    }}
                  >
                    <Col span={4}>
                      <img src={good?.goodsImage} alt="" style={{ marginLeft: 20, width: 61 }} />
                    </Col>
                    <Col span={8} style={{ textAlign: 'left' }}>
                      <GoodDesc {...good} />
                    </Col>
                    <Col span={6} style={OrderListStyle.goodPrice}>
                      <div>
                        {(good.finalSalePrice / 100).toFixed(2)}/{getUnit(good)}（
                        {IsContainsTax[good.isContainsTax]}) <br />
                      </div>
                      <div>
                        {IsContainsTax.含税 === good.isContainsTax && (
                          <span style={{ color: '#a9a9a9' }}>
                            含税点：￥
                            {(
                              ((good.finalSalePrice / 100 / (1 + good.taxRate / 100)) *
                                good.taxRate) /
                              100
                            ).toFixed(2)}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col span={6} style={OrderListStyle.goodQuantity}>
                      <Quantity {...good} />
                    </Col>
                  </Row>
                ))}
              </Col>

              <OrderPrice span={3} {...order} />

              <Col
                span={3}
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <span>共{order.deliveryCount}笔</span>
              </Col>

              <OrderState span={3} {...order} />

              <Col span={3} style={OrderListStyle.orderStatus}>
                <Space align="start">
                  {order.orderStatus === OrderStatus.待买家付款 && (
                    <Button
                      type={'link'}
                      size={'small'}
                      onClick={() => {
                        setCurOrderNo(order.orderNo);
                        setConfirmOrderModalVisible(true);
                      }}
                    >
                      确认收款
                    </Button>
                  )}
                  {[OrderStatus.待卖家确认, OrderStatus.待买家付款].includes(order.orderStatus) && (
                    <Button
                      type={'link'}
                      size={'small'}
                      onClick={() => {
                        setCurOrderNo(order.orderNo);
                        setCancelOrderModalVisible(true);
                      }}
                      style={{ margin: 0, padding: 0 }}
                    >
                      取消订单
                    </Button>
                  )}
                  {!!props.deliveryOrderQuantity && OrderStatus.交易成功 === order.orderStatus && (
                    <Button
                      type={'link'}
                      size={'small'}
                      onClick={() =>
                        history.push(
                          '/shop_manage/order/pur_proxy?tab=InvoiceManagement&parentOrderId=' +
                            order.orderId
                        )
                      }
                      style={{ margin: 0, padding: 0 }}
                    >
                      发货单管理
                    </Button>
                  )}
                  <Button
                    type={'link'}
                    size={'small'}
                    onClick={() =>
                      window.open('/home/shop_manage/order/pur_proxy/detail/' + order.orderNo)
                    }
                    style={{ margin: 0, padding: 0 }}
                  >
                    详情
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        ))}
      </Spin>

      <WhiteSpace />
      {total > 0 && (
        <Row justify={'end'}>
          <Col>
            <Pagination
              {...getPagination({
                total,
                current: pageNo,
                pageSize,
                onChange: (pageNo: number, pageSize: number) => setPage({ pageNo, pageSize }),
                onShowSizeChange: (pageNo: number, pageSize: number) =>
                  setPage({ pageNo: 1, pageSize })
              })}
            />
          </Col>
        </Row>
      )}

      {total === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

      <CancelOrderModal
        cancelOrderReason={CancelOrderReason}
        visible={CancelOrderModalVisible}
        onCancel={() => {
          setCancelOrderModalVisible(false);
          setCurOrderNo('');
          setRefresh((refresh) => refresh + 1);
        }}
        orderNo={curOrderNo}
      />

      <ConfirmOrderModal
        visible={ConfirmOrderModalVisible}
        onCancel={() => {
          setConfirmOrderModalVisible(false);
          setCurOrderNo('');
          setRefresh((refresh) => refresh + 1);
        }}
        orderNo={curOrderNo}
      />
    </>
  );
};

export default observer(OrderList);
