/**
 * @Description: 发货单订单详情
 * @author 点点
 * @date 2021/6/18
 * @time 14:39
 */

import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WaitBuyerPay from './WaitBuyerPay';
import WaitSellerShip from './WaitSellerShip';
import WaitBuyerConfirmReceipt from './WaitBuyerConfirmReceipt';
import OrderSuccess from './OrderSuccess';
import OrderFail from './OrderFail';
import OrderDeliveryStore, { DeliveryOrderStates, statusProps } from '@/stores/orderDeliveryStore';
import { Spin } from '@fle-ui/next';
import { observer } from 'mobx-react';
import { PageContainer } from '@fle-ui/pro-layout';

type Props = {};
const PurWholesaleDetail: FC<Props> = () => {
  const { status } = OrderDeliveryStore.orderDetail || {};

  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    OrderDeliveryStore.ajaxGetOrderInfo({ deliveryOrderId: id }).finally(() => setLoading(false));
  }, [id]);

  const CurView = (status: statusProps) => {
    switch (status) {
      case DeliveryOrderStates.待买家支付运费:
        return <WaitBuyerPay />;

      case DeliveryOrderStates.待卖家发货:
        return <WaitSellerShip />;

      case DeliveryOrderStates.待买家确认收货:
        return <WaitBuyerConfirmReceipt />;

      case DeliveryOrderStates.已完成:
        return <OrderSuccess />;

      case DeliveryOrderStates.已取消:
        return <OrderFail />;
    }
  };

  return (
    <PageContainer padding={0} vesselStyle={{ background: '#eff1f6' }} background="#eff1f6">
      <Spin spinning={loading}>{CurView(status)}</Spin>
    </PageContainer>
  );
};

export default observer(PurWholesaleDetail);
