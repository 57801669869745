import useUrlState from '@ahooksjs/use-url-state';
import { useDebounceEffect } from 'ahooks';
import { Button, Card, Col, Empty, Pagination, Row, Space, Spin, Tabs } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import OrderListStore, { GetOrderListParams } from '@/stores/afterSaleListStore';
import { getPagination } from '@/tools/getPagination';
import { formatTime } from '@/tools/helpers';
import { OnceProxyOrderStates } from '@/tools/selectOption';
import { PageProps } from '@/types/commom';
import OrderListForm from './components/OrderListForm';
import CancelOrderModal from '../component/CancelOrderModal';
import GoodDesc from './components/GoodDesc';
import OrderPrice from './components/OrderPrice';
import OrderState from './components/OrderState';
import OrderType from './components/OrderType';
import { PageContainer } from '@fle-ui/pro-layout';
import OrderDeal from './components/OrderDeal';

const { TabPane } = Tabs;

type Props = {};

const OnceProxy: FC<Props> = () => {
  const { list, pageNo, pageSize, total } = OrderListStore.orderList;
  const { waitReviewCount, waitReceivingCount, pendingCount } = OrderListStore.orderStatusCount;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<PageProps>({ pageNo: 1, pageSize: 20 });
  const [formData, setFormData] = useState<Partial<GetOrderListParams>>({ ...page });
  const [refresh, setRefresh] = useState(0);
  const [curOrderNo, setCurOrderNo] = useState('');
  const [params, setParams] = useUrlState(
    { tab: 'default', reset: false },
    { navigateMode: 'replace' }
  );
  const conditionRef: any = useRef(null);

  useDebounceEffect(
    () => {
      setLoading(true);
      OrderListStore.ajaxGetOnceProxyOrderList({ ...formData, ...page }).finally(() =>
        setLoading(false)
      );
    },
    [formData, page, refresh],
    { wait: 100 }
  );

  useEffect(() => {
    setPage({ pageNo: 1, pageSize: 20 });
    OrderListStore.ajaxGetOrderStatusCount({});
  }, [params]);

  const reloadPage = () => {
    setRefresh((refresh) => refresh + 1);
    OrderListStore.ajaxGetOrderStatusCount({});
  };

  const [CancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);

  return (
    <PageContainer >
      {/* <Card className="after_sale_order"> */}
      <div className='after_sale_order'>
        <Spin spinning={loading}>
          <Tabs
            defaultActiveKey={params.tab}
            activeKey={params.tab}
            type="card"
            onChange={(tab) => {
              const params = { tab, reset: false };
              if (tab === 'default') {
                params.reset = true;
              }
              setParams(params);
              tab !== params.tab && setLoading(true);
              conditionRef.current.reset();
            }}
          >
            <TabPane tab={'全部订单'} key={'default'} />
            <TabPane
              tab={
                <>
                  待审核 <span style={{ color: 'red' }}>{waitReviewCount || ''}</span>
                </>
              }
              key={'10'}
            />
            <TabPane
              tab={
                <>
                  待收货 <span style={{ color: 'red' }}>{waitReceivingCount || ''}</span>
                </>
              }
              key={'20'}
            />
            <TabPane
              tab={
                <>
                  待处理 <span style={{ color: 'red' }}>{pendingCount || ''}</span>
                </>
              }
              key={'30'}
            />
          </Tabs>

          <OrderListForm
            page={page}
            setPage={setPage}
            onSearch={setFormData}
            orderStatsList={OnceProxyOrderStates}
            ref={conditionRef}
          />

          <WhiteSpace height={8} />
          <Row className="order_table_header">
            <Col span={6} style={{ paddingLeft: 28 }}>
              <Row>
                <Col span={8} className="table_vertical_left">
                  商品图片
                </Col>
                <Col span={2} />
                <Col span={14} className="table_vertical_left" style={{ paddingRight: 16 }}>
                  商品名称
                </Col>
              </Row>
            </Col>
            <Col span={3} className="table_vertical_left">
              销售单价
            </Col>
            <Col span={3} className="table_vertical_left">
              申请数量
            </Col>
            <Col span={params.tab === 'default' ? 3 : 4} className="table_vertical_left">
              应付金额
            </Col>
            {params.tab === 'default' && (
              <Col span={params.tab === 'default' ? 3 : 4} className="table_vertical_left">
                售后状态
              </Col>
            )}
            <Col span={params.tab === 'default' ? 3 : 4} className="table_vertical_left">
              售后类型
            </Col>
            <Col span={params.tab === 'default' ? 3 : 4} className="table_vertical_left">
              操作
            </Col>
          </Row>
          <WhiteSpace height={8} />

          {list.map((order: any, index) => (
            <div key={order.orderNo + '_' + index} className="order_table_div">
              {/* {console.log(order,'order')} */}
              <Row
                gutter={12}
                className="order_table_list_header"
                style={{
                  background: '#FAFAFA',
                  color: '#262626',
                  padding:'13px 28px',
                  textAlign: 'center',
                  border: '1px solid #eeeeee'
                }}
              >
                <Space align="start" size="large">
                  <span className="table_vertical_left">售后单号：{order.afterSalesNo}</span>
                  <span className="table_vertical_left">订单号：{order.orderNo}</span>
                  <span className="table_vertical_left">
                    会员：{order.afterSalesApplicantName}（{order.afterSalesApplicant}）
                  </span>
                  <span className="table_vertical_left header_time">
                    申请时间：{formatTime(order.createTime)}
                  </span>
                </Space>
              </Row>

              <Row className="order_table_box row-con">
                <Col span={6}>
                  <GoodDesc {...order} />
                </Col>

                <Col span={3} className="table_vertical_left">
                  <div>￥{(order.costPrice / 100).toFixed(2)}</div>
                </Col>
                <Col span={3} className="table_vertical_left">
                  {order.afterSalesNum}个
                </Col>

                <OrderPrice span={params.tab === 'default' ? 3 : 4} {...order} />

                {params.tab === 'default' && <OrderState span={3} {...order} />}

                <OrderType span={params.tab === 'default' ? 3 : 4} {...order} />
                <OrderDeal
                  span={params.tab === 'default' ? 3 : 4}
                  {...order}
                  reloadPage={reloadPage}
                />
              </Row>
            </div>
          ))}

          <WhiteSpace />
          <WhiteSpace />
          {total > 0 && (
            <Row justify={'end'}>
              <Col>
                <Pagination
                  {...getPagination({
                    total,
                    current: pageNo,
                    pageSize,
                    onChange: (pageNo: number, pageSize: number) => setPage({ pageNo, pageSize }),
                    onShowSizeChange: (pageNo: number, pageSize: number) =>
                      setPage({ pageNo: 1, pageSize })
                  })}
                />
              </Col>
            </Row>
          )}

          {total === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

          <CancelOrderModal
            onCancel={async () => {
              setCancelOrderModalVisible(false);
              setCurOrderNo('');
              setRefresh((refresh) => refresh + 1);
            }}
            orderNo={curOrderNo}
            visible={CancelOrderModalVisible}
          />
        </Spin>
      </div>
      {/* </Card> */}

    </PageContainer>
  );
};

export default observer(OnceProxy);
