import { Col, Row, Space, Typography } from '@fle-ui/next';
import React, { FC } from 'react';
import { DetailGoodItemProp } from 'stores/AfterSaleDetailStore';

const GoodDesc: FC<DetailGoodItemProp & { paddingLeft?: number }> = (props) => {
  const { goodsImage, goodsName, paddingLeft } = props;
  const { Paragraph } = Typography;
  return (
    <Row style={{ paddingLeft: 28 }}>
      <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
        <img width={60} src={goodsImage} alt="" style={{ borderRadius: 4 }} />
      </Col>
      <Col span={2}></Col>
      <Col span={14} style={{ display: 'flex', alignItems: 'center', paddingRight: 16 }}>
        <Paragraph
          ellipsis={{
            rows: 2,
            tooltip: <>{goodsName}</>
          }}
          style={{ width: '100%', paddingTop: 14, color: '#262626', textAlign: 'left' }}
        >
          {goodsName}
        </Paragraph>
      </Col>
    </Row>
  );
};

export default GoodDesc;
