import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { Button, Space, Typography } from '@fle-ui/next';
import { CommonStore } from '@/stores';
import ShipInfoModal from '../../../component/ShipInfoModal';
import ChangeShipNumberModal from './ChangeShipNumberModal';
import OrderShipModal from './OrderShipModal';
import { VoListItemProps } from '@/stores/orderDeliveryStore';

const ShipOperate: FC<VoListItemProps> = (props) => {
  const { sendStatus, receiveStatus } = props;
  const [shipInfoModalVisible, setShipInfoModalVisible] = useState(false);
  const [ChangeShipNumberModalVisible, setChangeShipNumberModalVisible] = useState(false);
  const [orderShipModal, setOrderShipModal] = useState(false);
  return (
    <>
      <Space direction={'vertical'}>
        {sendStatus === 1 && (
          <>
            <Button type={'link'} onClick={() => setShipInfoModalVisible(true)}>
              查看物流
            </Button>
            {receiveStatus === 0 && !CommonStore.accountInfo.isJD && (
              <Button type={'link'} onClick={() => setChangeShipNumberModalVisible(true)}>
                修改快递单号
              </Button>
            )}
            <Typography.Text type={'secondary'}>
              {receiveStatus === 0 ? '待买家确认收货' : '买家已确认收货'}
            </Typography.Text>
          </>
        )}

        {sendStatus === 0 && (
          <Button type={'link'} onClick={() => setOrderShipModal(true)}>
            发货
          </Button>
        )}
      </Space>

      <ShipInfoModal
        visible={shipInfoModalVisible}
        onCancel={() => setShipInfoModalVisible(false)}
        expressNo={props.expressNo}
        expressName={props.expressName}
        expressType={props.expressType}
      />

      <ChangeShipNumberModal
        visible={ChangeShipNumberModalVisible}
        onClose={() => {
          setChangeShipNumberModalVisible(false);
        }}
        data={props}
      />

      <OrderShipModal
        visible={orderShipModal}
        data={props}
        onClose={() => setOrderShipModal(false)}
      />
    </>
  );
}

export default observer(ShipOperate);
