import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select } from '@fle-ui/next';
import { useParams } from 'react-router-dom';
import OrderDetailStore from '@/stores/orderDetailStore';
import OrderListStore, { CancelOptionProps } from '@/stores/orderListStore';
import OrderOperateStore from '@/stores/orderOperateStore';
import { CancelOrderParams } from '@/stores/orderOperateStore';
import { delay } from '@/tools/delay';

const FormItem = Form.Item;
const { Option } = Select;

type Props = {
  visible: boolean;
  onCancel: () => void;
  orderNo?: string;
  [key: string]: any;
};
const CancelOrderModal: FC<Props> = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const { visible, onCancel } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [reasonList, setReasonList] = useState<CancelOptionProps[]>([]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    OrderListStore.ajaxGetReason().then((res) => {
      setReasonList(res);
    });
  }, [visible]);

  const onHandleOk = () => {
    form
      .validateFields()
      .then(async (data: CancelOrderParams) => {
        try {
          setLoading(true);
          OrderOperateStore.ajaxCancelOrder({ ...data, orderNo: props.orderNo || id }).then(
            async (res) => {
              if (res) {
                message.success('取消成功');
                await delay();
                onCancel();
              } else {
                message.error('取消失败');
              }

              id &&
                OrderDetailStore.ajaxGetOrderDetail({ orderNo: id }).finally(() =>
                  setLoading(false)
                );
            }
          );
          setLoading(false);
        } catch (e) {
          // 服务器挂了，走这个
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      confirmLoading={loading}
      title="取消订单"
      open={visible}
      onOk={onHandleOk}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form layout="vertical" preserve={false} form={form}>
        <FormItem
          name="key"
          label={'请选择取消订单的理由'}
          rules={[{ required: true, message: '请选择取消理由' }]}
        >
          <Select placeholder={'请选择取消理由'}>
            {reasonList?.map((item) => (
              <Option value={item.key} key={item.key}>
                {item.value}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem name="reason" label={'取消订单的原因'}>
          <Input.TextArea maxLength={50} showCount />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default observer(CancelOrderModal);
