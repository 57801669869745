import {
  Spin,
  Tabs,
  Timeline,
  Table,
  Space,
  Tag,
  Button,
  Card,
  Col,
  Row,
  Modal,
  message,
  Image,
  Typography
} from '@fle-ui/next';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './index.less';
import OrderListStore from '@/stores/afterSaleListStore';
import OrderDetailStore, { OrderStatus } from '@/stores/afterSaleDetailStore';
import { unstable_batchedUpdates } from 'react-dom';
import { ThirdType, OrderCloseType, PaymentMethod, OrderCardInfos } from '../types';
import { formatTime, getQuery, showMoney, showTime } from '@/tools/helpers';
import { toJS } from 'mobx';
import { statusMap } from '../types';
import GoodDesc from '../components/GoodDesc';
import OrderUnitPrice from '../components/OrderUnitPrice';
import { PageContainer } from '@fle-ui/pro-layout';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import OrderDealOfAudit from '../components/tableBar/OrderDealOfAudit';
import OrderDealOfDispose from '../components/tableBar/OrderDealOfDispose';

export const prefixCls = 'mall-order-detail';
const { Paragraph, Text } = Typography;
type Props = {};

const AfterSaleDetail: FC<Props> = (props: any) => {
  const query = getQuery();
  const { id } = useParams<{ id: string }>();
  //订单详情
  const [loading, setLoading] = useState<any>(false);
  const [isModalVisibleOfAudit, setIsModalVisibleOfAudit] = useState<boolean>(false);
  const [isModalVisibleOfDispose, setIsModalVisibleOfDispose] = useState<boolean>(false);

  const orderDetail = OrderDetailStore.orderDetail;
  const { afterSalesGoodsResp, afterSalesProgressRespList, afterSalesResp } = orderDetail;

  const { receivingAfterSales } = OrderListStore;
  useEffect(() => {
    getDetail();
  }, [id]);

  console.log(toJS(orderDetail), 'orderDetail');

  const getDetail = () => {
    setLoading(true);
    OrderDetailStore.ajaxGetOrderDetail({ afterSalesNo: id }).finally(() => setLoading(false));
  };

  const formStatusMap = new Map([
    [10, [11]],
    [20, [21, 22, 23, 24]],
    [30, [31, 32, 33]],
    [40, [41, 42, 43, 44, 45]],
    [50, [51, 52]],
    [60, [61, 62, 63]],
    [90, [91, 92, 93, 94, 95, 96]]
  ]);

  // 售后工单类型
  const goodsInfoTypeCallback = (goodsInfoType: any, isOrder: boolean) => {
    if (goodsInfoType == 1) {
      return `商城${isOrder ? '订单' : '售后单'}`;
    } else if (goodsInfoType == 2) {
      return `平台${isOrder ? '订单' : '售后单'}`;
    } else if (goodsInfoType == 3) {
      return `应用${isOrder ? '订单' : '售后单'}`;
    } else if (goodsInfoType == 4) {
      return `会员自有${isOrder ? '订单' : '售后单'}`;
    } else if (goodsInfoType == 5) {
      return `会员自有供应商${isOrder ? '订单' : '售后单'}`;
    }
  };

  // 售后类型逻辑
  const payMethodCallback = (params: any) => {
    if (params == 10) {
      return '退货';
    } else if (params == 20) {
      return '换货';
    } else if (params == 30) {
      return '维修';
    } else if (params == 40) {
      return '退款';
    } else {
      return '---';
    }
  };

  // 取件方式
  const freightWayCallback = (params: any) => {
    if (params == 4) {
      return '上门取件';
    } else if (params == 7) {
      return '客户送货';
    } else if (params == 40) {
      return '客户发货';
    } else {
      return '---';
    }
  };

  // 优惠内容
  const preferentialShare = (good: any) => {
    const { discountWayList } = good;
    let priceArry: number[] = [];
    discountWayList?.forEach((item: any, index: any) => {
      priceArry.push(item?.value);
    });
    if (afterSalesResp?.afterSalesMethod == 10 || afterSalesResp?.afterSalesMethod == 40) {
      return (
        <div style={{ padding: '0 22px' }}>
          <div>
            优惠总额：
            {priceArry.length !== 0
              ? showMoney(
                  priceArry.reduce(function (prev, cur, index, arr) {
                    return prev + cur;
                  })
                )
              : '---'}
          </div>
          {discountWayList?.map((item: any, index: any) => {
            return (
              <div key={item.id + '_' + index}>
                {item.name}：-￥{showMoney(item.value)}
              </div>
            );
          })}
        </div>
      );
    } else {
      return '---';
    }
  };

  // 退款方式逻辑
  const returnPaymentInfosCallback = (good: any) => {
    const { returnPaymentInfos, refundAmount } = good;
    if (
      afterSalesResp?.afterSalesMethod == 10 ||
      afterSalesResp?.afterSalesMethod == 40 ||
      refundAmount !== null
    ) {
      return (
        <div style={{ padding: '0 22px' }}>
          <div>退款总额：{refundAmount !== null ? '￥' + showMoney(refundAmount) : '---'}</div>
          {returnPaymentInfos?.map((item: any, index: any) => {
            return (
              <div key={item.type + '_' + index}>
                <div>{item.type == 1 && <>余额：￥{showMoney(item.value)}</>}</div>
                <div>{item.type == 2 && <>微信：￥{showMoney(item.value)}</>}</div>
                <div>
                  {item.type == 3 && (
                    <>
                      {item.name}：￥{showMoney(item.value)}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return '---';
    }
  };

  const afterSalesFatherStatus = (fatherStatus: number, afterSalesStatus: number) => {
    if (
      formStatusMap.get(fatherStatus)?.some(function (x) {
        return x === afterSalesStatus;
      })
    ) {
      return fatherStatus;
    } else {
      // 子状态和父状态不匹配
      return false;
    }
  };

  // 审核
  const auditCallback = () => {
    setIsModalVisibleOfAudit(true);
  };

  // 确认收货
  const confirmReceiptCallback = () => {
    Modal.confirm({
      title: '确认收货',
      icon: <ExclamationCircleOutlined />,
      content: '是否已确认收到货物',
      async onOk() {
        return new Promise((resolve, reject) => {
          const res = receiptGoods(resolve, reject);
          return res;
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {}
    });
  };
  const receiptGoods = async (resolve: any, reject: any) => {
    const params: any = {
      afterSalesNo: afterSalesResp?.afterSalesNo
    };
    receivingAfterSales(params).then((res) => {
      resolve(); // 接口响应成功，返回 promise 时 resolve 后自动关闭询问弹窗
      if (res.success) {
        message.success('收货成功');
        getDetail();
        // 做一些你需要的操作
      } else {
        message.error('收货失败');
      }
    });
  };
  // 处理
  const disposeCallback = () => {
    setIsModalVisibleOfDispose(true);
  };
  //商品售后展示逻辑
  const goodsShowCallback = (goodsInfoType: number) => {
    console.log(goodsInfoType,'goodsInfoType1111');
    console.log(afterSalesResp?.afterSalesType,'afterSalesResp?.afterSalesType');
    
    if (
      (goodsInfoType == 1 || goodsInfoType == 4 || goodsInfoType == 5) &&
      afterSalesResp?.afterSalesType == 1
    ) {
      return true;
    } else if (
      goodsInfoType == 2 &&
      (afterSalesResp?.afterSalesType == 2 || afterSalesResp?.afterSalesType == 3 || afterSalesResp?.afterSalesType == 4 || afterSalesResp?.afterSalesType == 5 || afterSalesResp?.afterSalesType == 6)
    ) {
      return true;
    } else {
      return false;
    }
  };
  console.log(afterSalesResp?.afterSalesMethod,'afterSalesResp?.afterSalesMethod');
  
  return (
    <PageContainer padding={0} vesselStyle={{ background: '#eff1f6' }} background="#eff1f6">
      <Spin spinning={loading}>
        <div className="after_sale_manage_detail">
          <Card>
            <div className="order_header">
              <div className="order_detail">
                <p className="order_detail_status">
                  <span
                    style={{
                      color: statusMap.get(afterSalesResp?.afterSalesStatus)?.color,
                      fontSize: 20
                    }}
                  >
                    {statusMap.get(afterSalesResp?.afterSalesStatus)?.icon}
                    &nbsp;&nbsp;
                    {statusMap.get(afterSalesResp?.afterSalesStatus)?.parentText}
                  </span>
                </p>
                <p className="order_detail_tips">
                  {statusMap.get(afterSalesResp?.afterSalesStatus)?.text}
                </p>
              </div>
              <div className="order_deal">
                {(afterSalesResp?.afterSalesType == 1 || afterSalesResp?.afterSalesType == 5) &&
                  afterSalesFatherStatus(10, afterSalesResp?.afterSalesStatus) && (
                    <Button type={'primary'} onClick={auditCallback}>
                      审核
                    </Button>
                  )}
                {afterSalesFatherStatus(30, afterSalesResp?.afterSalesStatus) && (
                  <Button type={'primary'} onClick={() => disposeCallback()}>
                    处理
                  </Button>
                )}
                {afterSalesResp?.afterSalesStatus === 22 && (
                  <Button type={'primary'} onClick={() => confirmReceiptCallback()}>
                    确认收货
                  </Button>
                )}
              </div>
            </div>
          </Card>

          <Card>
            <div className="order_infor">
              <div className="order_infor_tit">
                <h4>工单信息</h4>
              </div>
              <div className="order_infor_con_wrap">
                <div className="order_infor_con order_infor_con_left">
                  <p>
                    <span>申请人账号：</span>
                    {afterSalesResp?.afterSalesApplicant}
                  </p>
                  <p>
                    <span>
                      {goodsInfoTypeCallback(
                        afterSalesGoodsResp?.goodsInfos?.[0]?.goodsInfoType,
                        false
                      )}
                      ：
                    </span>

                    {afterSalesResp?.afterSalesNo}
                  </p>
                  <p>
                    <span>
                      {goodsInfoTypeCallback(
                        afterSalesGoodsResp?.goodsInfos?.[0]?.goodsInfoType,
                        true
                      )}
                      ：
                    </span>
                    {afterSalesResp?.orderNo}
                  </p>
                  <p>
                    <span>联系人：</span>
                    {afterSalesResp?.consignee ? afterSalesResp?.consignee : '---'}
                  </p>
                </div>
                <div className="order_infor_con order_infor_con_center">
                  <p>
                    <span>联系地址：</span>
                    {afterSalesResp?.province ? (
                      <>
                        {afterSalesResp?.province}
                        {afterSalesResp?.city}
                        {afterSalesResp?.district}
                        {afterSalesResp?.town}
                        {afterSalesResp?.address}
                      </>
                    ) : (
                      '---'
                    )}
                  </p>
                  <p>
                    <span>售后类型：</span>
                    {payMethodCallback(afterSalesResp?.afterSalesMethod)}
                  </p>
                  <p>
                    <span>售后申请时间：</span>
                    {formatTime(afterSalesResp?.createTime)}
                  </p>
                  <p>
                    <span>商品收货时间：</span>
                    {afterSalesGoodsResp?.receivingTime
                      ? formatTime(afterSalesGoodsResp?.receivingTime)
                      : '---'}
                  </p>
                </div>
                <div className="order_infor_con order_infor_con_right">
                  <p>
                    <span>联系方式：</span>
                    {afterSalesResp?.mobileNumber ? afterSalesResp?.mobileNumber : '---'}
                  </p>
                  <p>
                    <span>取件方式：</span>
                    {freightWayCallback(afterSalesResp?.pickUpMethod)}
                  </p>
                  <p>
                    <span>申请原因：</span>
                    {afterSalesResp?.afterSalesReason}
                  </p>
                </div>
              </div>
              <div className="order_infor_con_desc">
                <span>售后描述：</span>
                {afterSalesResp?.afterSalesDescription}
              </div>
              <div className="order_infor_con_img">
                {afterSalesResp?.afterSalesCertificate?.map((item: any, index: any) => {
                  return (
                    <div className="img">
                      <Image width={90} src={item} alt="" key={item + '_' + index} />
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>

          <Card>
            <div className="goods_infor">
              <div className="goods_infor_tit">
                <h4>商品信息</h4>
              </div>
              <div className="table">
                <Row className="order_table_header">
                  <Col span={11}>
                    <Row>
                      <Col span={4} className="table_vertical_left">
                        商品图片
                      </Col>
                      <Col span={8} className="table_vertical_left">
                        商品名称
                      </Col>
                      <Col span={6} className="table_vertical_left">
                        申请数量
                      </Col>
                      <Col span={6} className="table_vertical_left">
                        售后类型
                      </Col>
                    </Row>
                  </Col>
                  <Col span={13}>
                    <Row>
                      <Col span={5} className="table_vertical_left">
                        销售单价
                      </Col>
                      <Col span={5} className="table_vertical_left">
                        应付金额
                      </Col>
                      <Col span={7} className="table_vertical_left">
                        优惠内容
                      </Col>
                      <Col span={7} className="table_vertical_left">
                        退款方式
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="order_table_box">
                  <Col span={11}>
                    <Row
                      className="order_table_list"
                      style={{ height: '100%', alignItems: 'center' }}
                    >
                      {/* 商品描述 */}
                      <Col span={4} className="table_vertical_center_top">
                        <img
                          width={60}
                          src={afterSalesGoodsResp?.goodsImage}
                          alt=""
                          style={{ borderRadius: 4 }}
                        />
                      </Col>
                      <Col span={8} className="table_vertical_center_top">
                        <Paragraph
                          ellipsis={{
                            rows: 3,
                            tooltip: <>{afterSalesGoodsResp?.goodsName}</>
                          }}
                          style={{
                            color: '#262626'
                          }}
                        >
                          {afterSalesGoodsResp?.goodsName}
                        </Paragraph>
                      </Col>
                      {/* 申请数量 */}
                      <Col span={6} className="table_vertical_center_top">
                        {afterSalesGoodsResp?.afterSalesNum}
                      </Col>
                      {/* 售后类型 */}
                      <Col span={6} className="table_vertical_center_top">
                        {payMethodCallback(afterSalesResp?.afterSalesMethod)}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={13}>
                    {afterSalesGoodsResp?.goodsInfos.map((good: any, index: any) => {
                      if (goodsShowCallback(good.goodsInfoType)) {
                        return (
                          <Row
                            className="order_table_list_right"
                            key={good.salePrice + '_' + index}
                          >
                            {/* 销售单价 */}
                            <Col span={5} style={{ borderBottom: 'none' }}>
                              <OrderUnitPrice
                                afterSalesMethod={afterSalesResp?.afterSalesMethod}
                                finalSalePrice={good.salePrice}
                                isContainsTax={afterSalesGoodsResp?.isContainsTax}
                                taxRate={afterSalesGoodsResp?.taxRate}
                              />
                            </Col>
                            {/* 应付金额 */}

                            <Col
                              span={5}
                              style={{ borderBottom: 'none' }}
                              className="table_vertical_left"
                            >
                              {afterSalesResp?.afterSalesMethod == 10 ||
                              afterSalesResp?.afterSalesMethod == 40 || 
                              afterSalesResp?.afterSalesMethod == 50 ? (
                                <Space direction={'vertical'} style={{ padding: '0 22px' }}>
                                  <span>￥{showMoney(good.amountPayable)}</span>
                                  {good.freight !== 0 && (
                                    <span style={{ color: '#999999' }}>
                                      含运费：￥{showMoney(good.freight)}
                                    </span>
                                  )}
                                </Space>
                              ) : (
                                '---'
                              )}
                            </Col>
                            {/* 优惠内容 */}
                            <Col span={7} style={{ borderBottom: 'none' }}>
                              <Space direction={'vertical'} align={'start'}>
                                {preferentialShare(good)}
                              </Space>
                            </Col>
                            {/* 退款方式 */}
                            <Col span={7} style={{ borderBottom: 'none', borderRight: 'none' }}>
                              {returnPaymentInfosCallback(good)}
                            </Col>
                          </Row>
                        );
                      }
                    })}
                  </Col>
                </Row>
              </div>
            </div>
          </Card>

          <Card>
            <div className="after_sale_progress">
              <div className="after_sale_progress_tit">
                <h4>售后进度</h4>
              </div>
              {afterSalesProgressRespList?.length > 0 ? (
                <>
                  <div className="track_con">
                    <Timeline>
                      {afterSalesProgressRespList?.map((item: any, index: any) => (
                        <Timeline.Item key={item.afterSaleStatus + '_' + index}>
                          <div style={{ color: '#262626' }}>
                            {statusMap.get(item.afterSalesStatus)?.text}
                          </div>
                          <div style={{ color: '#a9a9a9' }}>{formatTime(item.createTime)}</div>
                          {item.afterSalesProgressAdditionalInfo !== null &&
                            item?.afterSalesStatus == 22 && (
                              <div className="sendInfo">
                                <div className="row first">
                                  <span>
                                    {item?.afterSalesProgressAdditionalInfo?.sendExpressCompany}
                                  </span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>
                                    {item?.afterSalesProgressAdditionalInfo?.sendExpressNo}
                                  </span>
                                </div>
                                <div className="row second">
                                  <span>
                                    {formatTime(
                                      item?.afterSalesProgressAdditionalInfo?.deliveryTime
                                    )}
                                    &nbsp; 寄出
                                  </span>
                                  <span>
                                    运费：￥
                                    {showMoney(item?.afterSalesProgressAdditionalInfo?.sendFreight)}
                                    元
                                  </span>
                                </div>
                              </div>
                            )}
                          {item.afterSalesProgressAdditionalInfo !== null &&
                            item?.afterSalesStatus == 23 && (
                              <div className="sendInfoAddress">
                                <div className="row first">
                                  <span>
                                    预约取件时间上：
                                    {formatTime(
                                      item?.afterSalesProgressAdditionalInfo?.pickUpTimeUp
                                    )}
                                  </span>
                                  <span>
                                    预约取件时间下：
                                    {formatTime(
                                      item?.afterSalesProgressAdditionalInfo?.pickUpTimeDown
                                    )}
                                  </span>
                                </div>
                                <div className="row second">
                                  <span>
                                    预约取件地址：
                                    {item?.afterSalesProgressAdditionalInfo?.addressInfo?.consignee}
                                    &nbsp;&nbsp;&nbsp;
                                    {
                                      item?.afterSalesProgressAdditionalInfo?.addressInfo
                                        ?.mobileNumber
                                    }
                                  </span>
                                </div>
                                <div className="row thirdly">
                                  {item?.afterSalesProgressAdditionalInfo?.addressInfo?.province}

                                  {item?.afterSalesProgressAdditionalInfo?.addressInfo?.city}

                                  {item?.afterSalesProgressAdditionalInfo?.addressInfo?.district}

                                  {item?.afterSalesProgressAdditionalInfo?.addressInfo?.town}

                                  {item?.afterSalesProgressAdditionalInfo?.addressInfo?.address}
                                </div>
                              </div>
                            )}

                          {afterSalesResp?.afterSalesMethod == 10 && item?.afterSalesStatus == 21 && (
                            <div className="sendInfo">
                              <div className="row first">
                                <span style={{ fontSize: 14, color: '#262626' }}>退货地址</span>
                              </div>
                              <div className="row second">
                                <span>
                                  {item?.afterSalesProgressAdditionalInfo?.addressInfo?.consignee}
                                  &nbsp;&nbsp;&nbsp;
                                  {
                                    item?.afterSalesProgressAdditionalInfo?.addressInfo
                                      ?.mobileNumber
                                  }
                                </span>
                              </div>
                              <div className="row thirdly" style={{ marginLeft: 0 }}>
                                {item?.afterSalesProgressAdditionalInfo?.addressInfo?.province}

                                {item?.afterSalesProgressAdditionalInfo?.addressInfo?.city}

                                {item?.afterSalesProgressAdditionalInfo?.addressInfo?.district}

                                {item?.afterSalesProgressAdditionalInfo?.addressInfo?.town}

                                {item?.afterSalesProgressAdditionalInfo?.addressInfo?.address}
                              </div>
                            </div>
                          )}

                          {(item?.afterSalesStatus == 91 || item?.afterSalesStatus == 96) && (
                            <div>{afterSalesResp?.closeReason}</div>
                          )}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                </>
              ) : (
                <div>暂无进度！</div>
              )}
            </div>
          </Card>

          <OrderDealOfAudit
            isModalVisibleOfAudit={isModalVisibleOfAudit}
            setIsModalVisibleOfAudit={setIsModalVisibleOfAudit}
            afterSalesBasicType={afterSalesResp?.afterSalesBasicType}
            afterSalesMethod={afterSalesResp?.afterSalesMethod}
            finalSalePrice={afterSalesGoodsResp?.goodsInfos?.[0]?.salePrice}
            afterSalesNum={afterSalesGoodsResp?.afterSalesNum}
            freightPrice={afterSalesGoodsResp?.goodsInfos?.[0]?.freight}
            goodsName={afterSalesGoodsResp?.goodsName}
            afterSalesNo={afterSalesResp?.afterSalesNo}
            reloadPage={getDetail}
          />

          <OrderDealOfDispose
            isModalVisibleOfDispose={isModalVisibleOfDispose}
            setIsModalVisibleOfDispose={setIsModalVisibleOfDispose}
            afterSalesBasicType={afterSalesResp?.afterSalesBasicType}
            afterSalesMethod={afterSalesResp?.afterSalesMethod}
            finalSalePrice={afterSalesGoodsResp?.goodsInfos?.[0]?.salePrice}
            afterSalesNum={afterSalesGoodsResp?.afterSalesNum}
            freightPrice={afterSalesGoodsResp?.goodsInfos?.[0]?.freight}
            goodsName={afterSalesGoodsResp?.goodsName}
            afterSalesNo={afterSalesResp?.afterSalesNo}
            reloadPage={getDetail}
          />
        </div>
      </Spin>
    </PageContainer>
  );
};

export default observer(AfterSaleDetail);
