/**
 * @Description: 结算账户
 * @author 点点
 * @date 2021/6/24
 * @time 10:11
 */

type key = 'create' | 'select' | 'update' | 'default' | 'delete';
export const AccountApis: Map<key, string> = new Map([
  // 新增结算账户
  ['create', '/elebuys/account/public/create'],
  // 查询结算账户
  ['select', '/elebuys/account/public/select'],
  // 更新结算账户
  ['update', '/elebuys/account/public/update'],
  //  设置默认账户，之前的默认账户自动更新为非默认账户
  ['default', '/elebuys/account/public/default'],
  // 删除结算账户
  ['delete', '/elebuys/account/public/delete']
]);
