export default {
  memberInfo: "/elebuys/member/employee/member-info",
  logout: "/elebuys/right/login/logout",
  getUsableInfo: "/fleshopy/merchant/site/siteDecoration/getUsableInfo",
  homeEvent: "/elebuys/precision/home-event/task/list",
  companyInfo: "/elebuys/lmm/home/company/info",

  appList:"/elebuys/utility/list", // 应用信息

  getShopByCompanyId: '/elebuys/member/shop/detail/by-company-id', // 查询象铺信息-根据企业id查询
};
