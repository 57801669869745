/**
 * @Description: 鉴定权限
 * @author 点点
 * @date 2021/6/11
 * @time 14:35
 */
type EnvType = "dev" | "qa" | "prod";

// @ts-ignore
const ENV: EnvType = process.env.REACT_APP_API_ENV as any;

// 京东渠道：华南（废弃）、华东（废弃）、锦鸿（3.0落库用）、源宏（4.0落库用）、法助通（下单用）、福利plus（下单用）
// 京东唯一在用象铺账号信息（qa/dev）
// 账号：fxqfjdxp
// 密码：a888888
// 手机号：18888888888
enum JD_COMPANY_ID {
  "dev" = "202203291434220001214716",
  "qa" = "202203291425000000040932",
  "prod" = "202204141630410002573912",
}

// 蛋糕叔叔唯一在用 dev、qa环境账号密码
// 账户：dangaoshushu
// 密码：123456a
// 手机号：18888888887
enum DGSS_COMPANY_ID {
  "dev" = "202206271356210009105465",
  "qa" = "202206271406030000015711",
  "prod" = "202206271408400006174378",
}


// 测试环境：
// 账号maochao  
//密码fx123456 

// 开发环境：
// 手机号：18888888886
// 密码：123456a
// 账号：mcgyl

//线上环境
// 账号： maochao
//密码：fx123456
enum MAOCHAO_COMPANY_ID {
  "dev" = "202209141606540009826172",
  "qa" = "202209151030060000207532",
  "prod" = "202210111013300001570421",
}

// 开通京东象铺的ID
export const isJD = (id: string) => id === JD_COMPANY_ID[ENV];

export const isDGSS = (id: string) => id === DGSS_COMPANY_ID[ENV];

export const isMAOCHAO = (id: string ) => id === MAOCHAO_COMPANY_ID[ENV];

