import React, { FC, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import FinanceNormalModule from "./normal/index";
import FinanceDgssModule from "./dgss/index";
import { BasicLayout, PageContainer } from "@fle-ui/pro-layout";
import { observer } from "mobx-react";
import { CommonStore } from "@/stores";

type Props = {
  location: any;
};

const SettingManageModule: FC<Props> = (props) => {
  const [menu, setMenu] = useState<any[]>([]);

  const { financeManageMenu, accountInfo } = CommonStore;

  useEffect(() => {
    if (!!accountInfo.companyId) {
      setMenu(financeManageMenu);
    }
  }, [accountInfo]);

  return (
    <BasicLayout
      offsetTop={50}
      offsetLeft={64}
      location={props.location}
      menuList={menu}
      breadcrumb={false}
      minWidth={1156}>
      <FinanceNormalModule />
      <FinanceDgssModule />
    </BasicLayout>
  );
};

export default withRouter(observer(SettingManageModule));
