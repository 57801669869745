import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  message,
  Statistic,
  Divider,
  Typography,
  Descriptions,
  InputNumber
} from "@fle-ui/next";
import { PageContainer } from "@fle-ui/pro-layout";
import { observer } from "mobx-react";
import commonStore from "@/stores/common";
import { accountStore, certificationStore } from "@/stores/setting";
import _isString from "lodash/isString";
import { PlusOutlined } from "@ant-design/icons";
import AddressSelection from "@fle-ui/pro-address-selection";
import "@fle-ui/next/es/upload/style/index.less";
import "./index.less";

const { Option } = Select;
const businessDurationMap = new Map([
  [1, "3年以内"],
  [2, "5年以内"],
  [3, "10年以内"],
  [4, "10年以上"],
]);
const scaleMap = new Map([
  [1, "10人以下"],
  [2, "11-30人"],
  [3, "31-60人"],
  [4, "61-100人"],
  [5, "101-200人"],
  [6, "201-500人"],
  [7, "501-1000人"],
  [8, "1000人以上"],
]);

const { Title } = Typography;

const Edit: React.FC = () => {
  // store
  const { companyInfo } = certificationStore;

  // state
  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileBase64, setFileBase64] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getCompanyInfo();
  }, []);

  useEffect(() => {
    companyInfo && setEdit(!companyInfo.provinceCode);
  }, [companyInfo]);

  const getCompanyInfo = async () => {
    setLoading(true);
    await certificationStore.getCompanyInfo();
    setLoading(false);
  };

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: any) => {
    const fileTypeMap = new Map([
      ["image/jpeg", true],
      ["image/png", true],
      ["image/jpg", true],
      ["image/gif", true],
      ["image/bmp", true],
    ]);
    const isAllowFiletype = fileTypeMap.get(file.type);
    const isLt2M = file.size / 1024 / 1024 < 8;
    if (!isAllowFiletype) {
      message.error("请上传.jpg .jpeg .bmp .gif .png格式的图片！");
    } else if (!isLt2M) {
      message.error("图片大小不得超过8MB！");
    } else {
      setUploadLoading(true);
      // setFields({ pic: {} }) // 重置错误信息
      getBase64(file, (imageUrl: any) => {
        setFileBase64(imageUrl);
        setUploadLoading(false);
      });
    }

    return false;
  };

  const handleSubmit = async (val: any) => {
    setSubmitLoading(true);
    let formVal = { ...val, countyCode: val.countyCode[2], id: companyInfo.id };
    if (!_isString(val.logoImgUrl)) {
      let formdata = new FormData(); // FormData 对象
      formdata.append("file", val.logoImgUrl.file);
      const logoImgUrl = await commonStore.uploadSingleFile(formdata);
      formVal = { ...formVal, logoImgUrl: logoImgUrl };
    }

    const data = await certificationStore.updateCompanyInfo(formVal);

    setSubmitLoading(false);
    if (data?.success) {
      await certificationStore.getCompanyInfo();
      setEdit(false);
      message.success("提交成功！");
      accountStore.getUserAccount();
    }
  };

  return (
    <PageContainer loading={loading}>
      <div className="company-info-wrap">
        <Typography>
          <Title level={4}>企业信息</Title>
        </Typography>

        <Divider />

        {isEdit ? (
          <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
            <Form.Item label="企业Logo" required>
              <Form.Item
                noStyle
                name="logoImgUrl"
                valuePropName="filelist"
                initialValue={companyInfo ? companyInfo.logoImgUrl : undefined}
                rules={[{ required: true, message: "请上传企业logo" }]}
                validateTrigger="onBlur">
                <Upload
                  name="logoImgUrl"
                  accept=".jpg,.jpeg,.png,.bmp,.pdf"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUpload}>
                  {fileBase64 || companyInfo.logoImgUrl ? (
                    <img
                      src={fileBase64 || companyInfo.logoImgUrl}
                      alt="businessLicenseImg"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <div>
                      <PlusOutlined type={uploadLoading ? "loading" : "plus"} style={{ fontSize: 24 }} />
                      <div className="ant-upload-text">上传</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item noStyle>
                <div className="upload-img-extra">
                  <div>推荐尺寸：200px*200px</div>
                  <div>支持格式：jpg、jpeg、bmp、gif、png</div>
                  <div>大小限制：不超过500KB</div>
                </div>
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="企业名称"
              name="name"
              initialValue={companyInfo ? companyInfo.name : undefined}
              rules={[{ required: true, message: "请输入企业名称" }]}
              validateTrigger="onBlur">
              <Input type="text" disabled placeholder="请输入企业名称" />
            </Form.Item>
            <Form.Item
              label="所在地区"
              name="countyCode"
              initialValue={
                companyInfo.provinceCode ? [companyInfo.provinceCode, companyInfo.cityCode, companyInfo.countyCode] : []
              }
              rules={[{ required: true, message: "请选择所在地区" }]}>
              <AddressSelection level={3} placeholder="请选择地址" />
            </Form.Item>
            <Form.Item
              label="街道地址"
              name="address"
              initialValue={companyInfo.address ? companyInfo.address : undefined}
              rules={[{ required: true, message: "请输入街道地址" }]}
              validateTrigger="onBlur">
              <Input type="text" placeholder="请输入街道地址" />
            </Form.Item>
            <Form.Item
              label="营业年限"
              name="businessDuration"
              initialValue={companyInfo.businessDuration ? companyInfo.businessDuration : undefined}
              rules={[{ required: true, message: "请选择营业年限" }]}
              validateTrigger="onBlur">
              <Select placeholder="请选择营业年限">
                {[...businessDurationMap.entries()].map(([val, text]) => (
                  <Option key={val} value={val}>
                    {text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="企业规模"
              name="scale"
              initialValue={companyInfo.scale ? companyInfo.scale : undefined}
              rules={[{ required: true, message: "请选择企业规模" }]}
              validateTrigger="onBlur">
              <Select placeholder="请选择企业规模">
                {[...scaleMap.entries()].map(([val, text]) => (
                  <Option key={val} value={val}>
                    {text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="年营收额"
              name="annualRevenue"
              normalize={(val) => {
                return Number(val);
              }}
              initialValue={companyInfo.annualRevenue ? companyInfo.annualRevenue : undefined}
              rules={[
                { required: true, message: "请输入年营收额" },
                { max: 100000000, message: "请输入小于1万亿的数字", type: "number" },
              ]}>
              <InputNumber
                type="number"
                autoComplete="off"
                placeholder="请输入年营收额"
                addonAfter="万元"
                style={{width: "100%"}}
              />
            </Form.Item>
            <Form.Item
              name="option"
              wrapperCol={{ ...formItemLayout.wrapperCol, offset: formItemLayout.labelCol.span }}>
              <Button type="primary" style={{ marginRight: 20 }} htmlType="submit" loading={submitLoading}>
                {submitLoading ? "正在提交中" : "保存"}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Descriptions
            column={1}
            style={{ padding: "0 60px" }}
            labelStyle={{ paddingBottom: 15 }}
            contentStyle={{ paddingBottom: 15 }}>
            <Descriptions.Item label="企业Logo">
              <img className="logoImg" src={companyInfo?.logoImgUrl} alt="企业Logo" />
            </Descriptions.Item>
            <Descriptions.Item label="企业名称">{companyInfo?.name}</Descriptions.Item>
            <Descriptions.Item label="所在地区">
              {companyInfo?.provinceName
                ? `${companyInfo?.provinceName}-${companyInfo?.cityName}-${companyInfo?.countyName}`
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="街道地址">{companyInfo?.address}</Descriptions.Item>
            <Descriptions.Item label="营业年限">
              {businessDurationMap.get(companyInfo?.businessDuration || 1)}
            </Descriptions.Item>
            <Descriptions.Item label="企业规模">{scaleMap.get(companyInfo?.scale || 1)}</Descriptions.Item>
            <Descriptions.Item label="年营收额">
              <Statistic value={companyInfo?.annualRevenue} precision={2} suffix="万元" />
            </Descriptions.Item>
            <Descriptions.Item>
              <Button icon={<PlusOutlined type="edit" />} onClick={() => setEdit(!isEdit)}>
                {isEdit ? "取消" : "编辑"}
              </Button>
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
    </PageContainer>
  );
};
export default observer(Edit);
