import React from "react";
import { Icon } from "@/components";
import CommodityModule from "@/pages/commodity";
import ShopManageModule from "@/pages/shopManage";
import FinanceManageModule from "@/pages/finance";
import SettingManageModule from "@/pages/settings";
import Home from "@/pages/dashboard";

const ORIGIN = window.location.origin;



type authorProps = {
  // 当前登陆账号是否为Api类型供应商账号/
  isApiSupplier?: boolean;
  isDGSS?: boolean;
  isJD?: boolean;
  isMAOCHAO?:boolean
};

export const getLeftMenu = ({ isApiSupplier, isDGSS, isJD, isMAOCHAO }: authorProps) => {  
  console.log(isMAOCHAO,'isMAOCHAO');
  
  return [
    {
      initials: "首页",
      name: "首页",
      key: "dashboard",
      icon: <Icon type="icon-shouye1" />,
      path: "/dashboard",
      code: "1",
      show: true,
    },
    {
      initials: "商品",
      name: "商品管理",
      key: "commodity",
      icon: <Icon type="icon-liwu" />,
      path: "/commodity/manage/pub_list",
      code: "商品",
      show: true,
    },
    {
      initials: "象铺",
      name: "象铺管理",
      key: "shop",
      icon: <Icon type="icon-xiangpu" />,
      path: "/shop_manage/good/on_sale",
      code: "象铺",
      show: true,
    },
    {
      initials: "财务",
      name: "财务管理",
      key: "finance",
      icon: <Icon type="icon-caiwu" />,
      path: `${isDGSS ? '/finance/dgss/manage' : '/finance/normal/manage'}`,
      code: "财务",
      show: !isJD && !isMAOCHAO,
    },
    {
      initials: "设置",
      name: "设置管理",
      key: "setting",
      icon: <Icon type="icon-shezhi" />,
      path: "/settings/company/account",
      code: "设置",
      show: true,
    },
  ];
};


export const getRouterList = () => {
  return [
    {
      name: "commodity",
      activePath: "/home/commodity",
      render: () => <CommodityModule />,
    },
    {
      name: "shop",
      activePath: "/home/shop_manage",
      render: () => <ShopManageModule />,
    },
    {
      name: "finance",
      activePath: "/home/finance",
      render: () => <FinanceManageModule />,
    },
    {
      name: "setting",
      activePath: "/home/settings",
      render: () => <SettingManageModule />,
    },
    {
      sandbox: true,
      umd: true,
      cached: false,
      name: "goods",
      activePath: "/home/module/goods",
      entry: `${ORIGIN}/goods`,
      props: {
        platformType: 41001,
        offsetLeft: 84,
        offsetTop: 50,
      },
    },
    {
      sandbox: true,
      umd: true,
      cached: false,
      name: "common",
      activePath: "/home/module/common",
      entry: `${ORIGIN}/common`,
      props: {
        platformType: 41001,
        tokenKey: "seller_token",
        offsetLeft: 64,
        offsetTop: 50,
      },
    },
    {
      sandbox: true,
      umd: true,
      cached: false,
      name: "business",
      activePath: "/home/application/business",
      entry: `${ORIGIN}/business`,
      props: {
        platformType: 41001,
        tokenKey: "seller_token",
      },
    },
    {
      sandbox: true,
      umd: true,
      cached: false,
      name: "activity",
      activePath: "/home/application/activity",
      entry: `${ORIGIN}/activity`,
      props: {
        platformType: 41001,
        tokenKey: "seller_token",
      },
    },
    // 默认匹配首页
    {
      name: "dashboard",
      activePath: "*",
      render: () => <Home />,
    },
  ];
};
