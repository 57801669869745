import React, { FC } from 'react';
import { Space, Col } from '@fle-ui/next';
import { OrderItem } from '@/stores/afterSaleListStore';
import { getTimeDiff } from '@/tools/getTimeDiff';
import { PurWholesaleOrderStates } from '@/tools/selectOption';
import { statusMap } from '../types';
type Props = {
  // 删格化布局
  span: number;
};
const OrderState: FC<Props & OrderItem> = (props) => {
  const { span, afterSalesStatus } = props;
  return (
    <Col span={span} className="table_vertical_left" style={{ paddingRight: 12 }}>
      <Space direction={'vertical'} align={'start'}>
        <div style={{ color: statusMap.get(Number(afterSalesStatus))?.color }}>
          {statusMap.get(Number(afterSalesStatus))?.parentText}
        </div>

        <div style={{ fontSize: 14, color: '#a9a9a9', textAlign: 'left' }}>
          {statusMap.get(Number(afterSalesStatus))?.text}
        </div>
      </Space>
    </Col>
  );
};

export default OrderState;
