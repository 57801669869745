/**
 * @Description: 结算账户
 * @author 点点
 * @date 2021/6/24
 * @time 10:14
 */
import { AccountApis } from '@/api/accountApis';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import httpClient from "@/tools/httpClient";

export interface CreatAccountParams {
  // 开户名
  accountName: string;
  // 银行卡号
  bankCode: string;
  // 开户行
  bankName: string;
  // 是否默认账户，0-否；1-是
  isDefault?: 0 | 1;
  //象铺 ID
  shopId?: string;
  //来源：1-会员；2-供应商；3-象铺；默认值 = 1
  type?: 1 | 2 | 3;
  //
  companyId: string;
}

export interface SelectAccountParams {
  // 是否默认账户，0-否；1-是
  isDefault?: 0 | 1;
  // 象铺ID
  shopId?: '';
  // 来源：1-会员；2-供应商；3-象铺；
  type?: 1 | 2 | 3;
}

export interface SelectAccountItemProps extends Required<CreatAccountParams> {
  // 主键ID
  id: number;
}

export interface UpdateAccountProps extends CreatAccountParams {
  // 主键ID
  id: number;
}

interface AccountStoreProps {
  selectAccountList: SelectAccountItemProps[];
  ajaxCreate: (params: CreatAccountParams) => Promise<boolean>;
  ajaxSelect: (params: SelectAccountParams) => void;
  ajaxUpdate: (params: UpdateAccountProps) => Promise<boolean>;
  ajaxDefault: (params: { id: number }) => Promise<boolean>;
  ajaxDelete: (params: { id: number }) => Promise<boolean>;
}

class AccountStore implements AccountStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @action
  async ajaxCreate(params: CreatAccountParams): Promise<boolean> {
    params.type = 3;
    const res = await httpClient.post(AccountApis.get('create'), params);
    return res?.success;
  }

  @observable
  selectAccountList: SelectAccountItemProps[] = [];

  @action
  async ajaxSelect(params: SelectAccountParams) {
    const res = await httpClient.post(AccountApis.get('select'), params);
    if (res?.success) {
      runInAction(() => (this.selectAccountList = res.data));
    }
  }

  @action
  async ajaxUpdate(params: UpdateAccountProps): Promise<boolean> {
    const res = await httpClient.post(AccountApis.get('update'), params);
    return res?.success;
  }

  @action
  async ajaxDefault(params: { id: number }): Promise<boolean> {
    const res = await httpClient.post(AccountApis.get('default'), params);
    return res?.success;
  }

  @action
  async ajaxDelete(params: { id: number }): Promise<boolean> {
    const res = await httpClient.post(AccountApis.get('delete'), params);
    return res?.success;
  }
}

export default new AccountStore();
