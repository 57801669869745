import React, { FC, useState, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import GoodModule from "./good";
// import FinanceNormalModule from './Finance/Normal';
import OrderModule from "./order";
// import FinanceJDlModule from './Finance/JD';
import { BasicLayout, PageContainer } from "@fle-ui/pro-layout";
import { observer } from "mobx-react";
import { CommonStore } from "@/stores";

type Props = {
  location: any;
};

const ShopManageModule: FC<Props> = (props) => {
  const [menu, setMenu] = useState<any[]>([]);

  const { shopManageMenu, accountInfo } = CommonStore;

  useEffect(() => {
    if (!!accountInfo.companyId) {
      setMenu(shopManageMenu);
    }
  }, [accountInfo]);

  return (
    <BasicLayout
      offsetTop={50}
      offsetLeft={64}
      location={props.location}
      menuList={menu}
      breadcrumb={false}
      minWidth={1156}>
      <GoodModule />
      <OrderModule />
    </BasicLayout>
  );
};

export default withRouter(observer(ShopManageModule));
