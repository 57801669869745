/*
 * @Author: your name
 * @Date: 2021-12-08 11:34:52
 * @LastEditTime: 2021-12-08 11:35:14
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /fx-seller-admin/src/views/ShopManage/Order/AfterSaleManage/components/OrderUnitPrice.tsx
 */
import React, { FC } from 'react';
import { Space } from '@fle-ui/next';
import { showMoney } from '@/tools/helpers';

type Props = {
  [key: string]: any;
};
const OrderUnitPrice: FC<Props> = (props: Props) => {
  const {
    finalSalePrice,
    isContainsTax, //是否含税：0、不含税，1、含税
    taxRate,
    afterSalesMethod
  } = props;

  return <div className="table_vertical_left">￥{showMoney(finalSalePrice)}</div>;
};

export default OrderUnitPrice;
