/*
 * @Author: your name
 * @Date: 2021-11-30 17:10:28
 * @LastEditTime: 2022-01-06 10:58:45
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /fle-cli/packages/creation-app/template/react-ts-pro/src/index.tsx
 */

import React from "react";
import ReactDOM from "react-dom";
import Router from "@/router";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "@fle-ui/next";
import locale from "@fle-ui/next/lib/locale-provider/zh_CN";
import moment from "moment";
import * as stores from "@/stores";
import { Provider } from "mobx-react";
import "moment/dist/locale/zh-cn";

moment.locale("zh-cn");

import "@/style/index.less";

ReactDOM.render(
  // @ts-ignore
  <BrowserRouter basename="/home/">
    <ConfigProvider locale={locale}>
      <Provider {...stores}>
        <Router />
      </Provider>
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
