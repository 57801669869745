/**
 * @Description: 发货弹窗
 * @author 点点
 * @date 2021/6/7
 * @time 15:45
 */

import { OrderShipFormLayout } from '@/layout/formLayout';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Modal, Radio, Space, Table, Typography, Select, message } from '@fle-ui/next';
import { useDebounceEffect } from 'ahooks';
import ExpressStore from '@/stores/expressStore';
import OrderDetailStore, { DeliveryStatus, DetailGoodItemProp } from '@/stores/orderDetailStore';
import OrderOperateStore from '@/stores/orderOperateStore';
import { flatRowSpanMerge } from '@/tools/rowSpanMerge';
import GoodDesc from './GoodDesc';
import { WhiteSpace } from '@/components';
import OrderInfoItem from './OrderInfoItem';
import { RadioChangeEvent } from '@fle-ui/next/lib/radio/interface';
import { replaceDoubleCharacters } from '@/tools/replaceStr';
import ShipStatus from './ShipStatus';

const { Paragraph, Text, Title } = Typography;
const { Column } = Table;
const { Option } = Select;
const FormItem = Form.Item;

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  // 确认改价
  onOk: (data: any) => void;
};
const OrderShipModal: FC<Props> = (props: Props) => {
  const { list } = ExpressStore.expressData;

  const { groupList, orderReceivingInfo, orderNo } = OrderDetailStore.orderDetail || {};
  const { consignee, address, province, city, district, town, mobileNumber } = orderReceivingInfo;

  const { visible, onClose, onOk } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState<DetailGoodItemProp[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [shipType, setShipType] = useState<1 | 2>(1);

  useEffect(() => {
    setLoading(false);
    setSelectedRowKeys([]);
    setSelectedRow([]);
    if (!visible) {
      return;
    }
    getExpress(0)('');
  }, [visible]);

  const getExpress = (delay: number) =>
    _.debounce(async (value = '') => {
      await ExpressStore.ajaxGetExpress({ expressName: value, pageNo: 1, pageSize: 30 });
    }, delay);

  const handleOk = () => {
    if (selectedRow.length === 0) {
      return message.error('请选择发货商品');
    }
    form
      .validateFields()
      .then((data) => {
        setLoading(true);
        OrderOperateStore.ajaxDeliverGoods({
          expressCompany: list?.filter((item) => item.expressCode === data.expressCompanyCode)[0]
            ?.expressName,
          ...data,
          orderNo,
          skuId: selectedRow.map((item) => item.skuId)
        })
          .then(async (res) => {
            if (res) {
              message.success('发货成功');
              onOk(data);
            } else {
              message.error('发货失败');
            }
            OrderDetailStore.ajaxGetOrderDetail({ orderNo }).finally(() => setLoading(false));
          })
          .finally(() => setLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounceEffect(
    () => {
      setLoading(false);
      if (!visible) {
        return;
      }
    },
    [visible],
    { wait: 0 }
  );

  const rowSelection = {
    selectedRowKeys,
    onChange(selectedRowKeys: any[], selectedRows: DetailGoodItemProp[]) {
      setSelectedRow(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps(record: DetailGoodItemProp) {
      const { returnStatus } = record;
      if (returnStatus !== 0) {
        return {
          disabled: true
        };
      } else {
        return {
          disabled: record.deliveryStatus === DeliveryStatus.已发货
        };
      }
    }
  };

  return (
    <Modal
      width={1000}
      title={'发货操作：（可分批勾选商品发不同快递）'}
      open={visible}
      onCancel={onClose}
      destroyOnClose
      onOk={handleOk}
      okText={'确认发货'}
    >
      <Table
        dataSource={flatRowSpanMerge(groupList)}
        rowSelection={rowSelection}
        pagination={false}
        loading={loading}
        rowKey={'id'}
      >
        <Column<DetailGoodItemProp>
          title={'商品名称/规格'}
          render={(text, record) => <GoodDesc {...record} />}
        />

        <Column title={'数量'} align={'center'} dataIndex={'quantity'} />
        <Column title={'快递公司'} dataIndex={'expressCompany'} />
        <Column title={'快递单号'} dataIndex={'expressNo'} />
        <Column
          title={'状态'}
          dataIndex={'deliveryStatus'}
          render={(text, record: any) => (
            <ShipStatus
              returnStatus={record.returnStatus}
              receiptStatus={record.receiptStatus}
              deliveryStatus={record.deliveryStatus}
            />
          )}
        />
      </Table>

      <WhiteSpace />
      <Paragraph>
        <Text>
          本次发货已勾选
          <Text type={'danger'}>{selectedRow.length}</Text> 种商品，共
          <Text type={'danger'}>{selectedRow.reduce((pre, cur) => cur.quantity + pre, 0)}</Text>
          件，请选择发货快递公司、填写快递单号。
        </Text>
      </Paragraph>

      <Form
        form={form}
        preserve={false}
        {...OrderShipFormLayout}
        initialValues={{ deliveryMethod: shipType }}
      >
        <FormItem label={'发货方式'} name={'deliveryMethod'} rules={[{ required: true }]}>
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              setShipType(e.target.value);
              form.setFieldsValue({ expressNo: undefined });
            }}
            value={shipType}
          >
            <Radio value={1}>快递</Radio>
            <Radio value={2}>物流</Radio>
          </Radio.Group>
        </FormItem>

        {shipType === 1 && (
          <FormItem label={'快递公司'} required>
            <Space>
              <FormItem
                noStyle
                name={'expressCompanyCode'}
                rules={[{ required: true, message: '请选择快递公司' }]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="搜索查找快递公司"
                  optionFilterProp="children"
                  onFocus={() => getExpress(0)('')}
                  onSearch={getExpress(200)}
                >
                  {list?.map((item) => (
                    <Option value={item.expressCode} key={item.expressCode}>
                      {item.expressName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <Text>支持1000+快递公司信息同步，请下拉/搜索快递公司。</Text>
            </Space>
          </FormItem>
        )}

        {shipType === 2 && (
          <FormItem label={'物流公司'} name={'expressCompany'} rules={[{ required: true }]}>
            <Input placeholder={'请输入物流公司'} />
          </FormItem>
        )}

        <FormItem
          label={'物流单号'}
          name={'expressNo'}
          rules={[{ required: shipType === 1 }]}
          normalize={replaceDoubleCharacters}
        >
          <Input placeholder={'请输入物流单号'} maxLength={32} />
        </FormItem>
      </Form>

      <Title level={5}>请核对用户收货地址：</Title>
      <Space direction={'vertical'}>
        <OrderInfoItem label={'收货人'} value={consignee} />
        <OrderInfoItem
          label={'收货地址'}
          value={[province, city, district, town, address].filter((item) => item).join(' ')}
        />
        <OrderInfoItem label={'手机'} value={mobileNumber} />
      </Space>
    </Modal>
  );
};

export default observer(OrderShipModal);
