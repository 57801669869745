import { action, makeAutoObservable, observable } from "mobx";
import httpClient from "@/tools/httpClient";
import { addKey } from "@/tools/common";
import type { ListProps } from "@/tools/common";
import { MAOCHAOApis as shop } from "@/api/maochaoApis";

class JdStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable jdGoodsList: ListProps = { list: [], total: 0 };

  /**
   * 获取猫超商品列表
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  @action
  getJdGoodsList = async (params: any) => {
    let res = await httpClient.post(shop.POST_GET_JD_GOODS_LIST, params);
    if (res?.success) {
      this.jdGoodsList = addKey(res.data);
    }
    return this.jdGoodsList;
  };

  /**
   * 获取猫超商品列表，供应链改造新接口
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  @action
  getNewMCGoodsList = async (params: any) => {
    let res = await httpClient.post(shop.POST_GET_NEW_MC_GOODS_LIST, params);
    if (res?.success) {
      return {
        total: res.data.total,
        data: res.data.list,
        success: res.success,
      };
    }
    return {
      success: false,
    };
  };

  @action
  updateJdGoodsListMarketPrice = async (params: { skuList: any[] }) => {
    return await httpClient.post(shop.POST_UPDATE_JD_GOODS_LIST_MARKET_PRICE, params);
  };
  /**
   * 同步猫超商品市场价
   * @param {} params
   */
  @action
  listCategory = async (params:any) => {
    return await httpClient.get(shop.GET_LIST_CATEGORY, {params});
  };
  /**
   * 同步猫超商品市场价，新接口
   * @param {} params
   */
  @action
  updateNewJdGoodsListMarketPrice = async (params: { skuIdList: any[] }) => {
    return await httpClient.post(shop.POST_UPDATE_NEW_JD_GOODS_LIST_MARKET_PRICE, params);
  };

  /**
   * 获取猫超商品分类
   */
  @action
  getJdCategory = async (params: { parentId: any }) => {
    const res = await httpClient.get(shop.GET_JD_CATEGORY, { params });
    if (res?.success) {
      return res.data;
    }
  };
  /**
   * 获取猫超商品分类，改造新接口
   */
  @action
  getNewJdCategory = async (params: { parentId: any }) => {
    const res = await httpClient.post(shop.GET_NEW_JD_CATEGORY, params);
    if (res?.success) {
      return res.data;
    }
  };

  /**
   * 猫超商品地区
   */
  @action
  getJdLocation = async (params: { parentCode: any }) => {
    const res = await httpClient.get(shop.GET_JD_LOCATION, { params });
    if (res?.success) {
      return res.data;
    }
  };
  /**
   * 猫超商品地区，改造新接口
   */
  @action
  getNewJdLocation = async (params: { parentCode: any }) => {
    const res = await httpClient.post(shop.GET_NEW_JD_LOCATION, params);
    if (res?.success) {
      return res.data;
    }
  };

  /**
   * 猫超商品批量挑选
   */
  @action
  jdBatchChecks = async (params: { invoiceType: any; categoryId: any; skuList: any[] }) => {
    return await httpClient.post(shop.POST_JD_BATCH_CHECK, params);
  };
}

export default new JdStore();
