/**
 * @Description: 集采价
 * @author 点点
 * @date 2021/6/10
 * @time 09:14
 */
import React from 'react';
import { GoodItemProps } from '@/types/store/goods';

const getBoxPrice = (price: number, boxNumber: number, unit: string) =>
  '￥' + ((price / 100) * boxNumber).toFixed(2) + '/' + unit;

const getOnePrice = (record: GoodItemProps, _unit: string) =>
  record.purchaseInfo.stairList.map((item, index) => {
    // 草稿箱，商品未填写集采价，代发价，应显示为空
    if (!item.minNum || !item.maxNum || !item.salePrice) {
      return null;
    }

    return (
      <div key={index}>
        ￥{(item.salePrice / 100).toFixed(2)}/{_unit}(
        {item.maxNum === 999999999 ? '≥' + item.minNum : item.minNum + '-' + item.maxNum})
      </div>
    );
  });

const PurchasePrice = (_: any, record: GoodItemProps) => {
  if (record.isPurchase === 0) {
    return null;
  }

  if (record.isPurchase === 1) {
    const _unit = record.purchaseInfo.measureUnit === 2 ? '箱' : record.unit;
    const number = 1;

    return (
      <>
        <>
          {/* 阶梯定价 */}
          {record.purchaseInfo.salesType === 1 && getOnePrice(record, _unit)}
        </>

        <>
          {/* 规格定价 */}
          {record.purchaseInfo.salesType === 2 &&
            (record.purchaseInfo.salePriceMin === record.purchaseInfo.salePriceMax
              ? getBoxPrice(record.purchaseInfo.salePriceMin, number, _unit)
              : getBoxPrice(record.purchaseInfo.salePriceMin, number, _unit) +
                '-' +
                getBoxPrice(record.purchaseInfo.salePriceMax, number, _unit))}
        </>
      </>
    );
  }
};;

export default PurchasePrice;
