import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { Form, Input, Modal, Select } from '@fle-ui/next';
import { InvoiceCancelOrderReason } from '@/tools/selectOption';
import { CancelOrderParams, OptionProps } from '@/types/commom';

const FormItem = Form.Item;
const { Option } = Select;

type Props = {
  // 取消订单的理由
  cancelOrderReason: OptionProps[];
  visible: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
  onOK: (data: CancelOrderParams) => void;
  id: number | string;
};
const CancelOrderModal: FC<Props> = (props: Props) => {
  const { visible, onCancel, onOK, id } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onHandleOk = () => {
    form
      .validateFields()
      .then(async (data: CancelOrderParams) => {
        try {
          setLoading(true);
          await onOK({ ...data, id });
          setLoading(false);
        } catch (e) {
          // 服务器挂了，走这个
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      confirmLoading={loading}
      title="取消订单"
      open={visible}
      onOk={onHandleOk}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form layout="vertical" preserve={false} form={form}>
        <FormItem
          name="cancelReason"
          label={'请选择取消订单的理由'}
          rules={[{ required: true, message: '请选择取消理由' }]}
        >
          <Select placeholder={'请选择取消理由'}>
            {InvoiceCancelOrderReason.map((item) => (
              <Option value={item.value} key={item.label}>
                {item.value}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem name="cancelReasonDetail" label={'取消订单的原因'}>
          <Input.TextArea maxLength={50} showCount />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default observer(CancelOrderModal);
