import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, Modal, message, Alert, Typography, Popconfirm, Divider } from "@fle-ui/next";
import { observer } from "mobx-react";
import { certificationStore } from "@/stores/setting";
import { CommonStore } from "@/stores";
import { PageContainer } from "@fle-ui/pro-layout";
import { PlusOutlined } from "@ant-design/icons";
import commonStore from "@/stores/common";
import AddressSelection from "@fle-ui/pro-address-selection";

import "@fle-ui/next/es/upload/style/index.less";
import "./index.less";

const { Title, Paragraph } = Typography;

const Certification: React.FC = () => {
  const { certificationInfo: certificationInfoList } = certificationStore;
  const { accountInfo } = CommonStore;
  const certificationInfo = certificationInfoList?.[0] || null;

  // state
  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filePreviewVisible, setFilePreviewVisible] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isAgain, setAgain] = useState(false); //是否重新认证
  const [businessLicenseImgUrl, setBusinessLicenseImgUrl] = useState<string>("");

  const [form] = Form.useForm();

  const getCertificationInfo = async () => {
    setLoading(true);
    await certificationStore.getCertificationInfo().then((res) => {
      !res && setEdit(true);
      setLoading(false);
    });
  };

  const beforeUpload = async (file: any) => {
    let formData = new FormData();
    formData.append("file", file);
    const fileTypeMap = new Map([
      ["image/jpeg", true],
      ["image/png", true],
      ["image/jpg", true],
      ["image/gif", true],
    ]);

    const isAllowFiletype = fileTypeMap.get(file.type);
    const isLt = file.size / 1024 / 1024 < 8;
    if (!isAllowFiletype) {
      message.error("请上传.jpg .jpeg .bmp .gif .png格式的图片！");
    } else if (!isLt) {
      message.error("图片大小不得超过8MB！");
    } else {
      setUploadLoading(true);
      const { data } = await commonStore.upload(formData);
      setBusinessLicenseImgUrl(data);
      form.setFieldsValue([
        {
          businessLicenseImgUrl: [
            {
              uid: "-1",
              url: data,
            },
          ],
        },
      ]);
      setUploadLoading(false);
    }
    
    return false;
  };

  const cancelCertification = async () => {
    if (certificationInfo) {
      try {
        setSubmitLoading(true);
        const data = await certificationStore.cancelCertification({
          id: certificationInfo.id,
        });
        setSubmitLoading(false);
        if (data?.success) {
          message.success("撤回成功！");
          await getCertificationInfo(); // 重新请求
        }
      } catch (error) {}
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    const e = await form.validateFields();

    let formVal = { ...e, countyCode: e.countyCode[2], type: 1, businessLicenseImgUrl };

    if (isEdit && !!certificationInfo) {
      formVal = { ...formVal, id: certificationInfo.id };
    }

    const { success } =
      !certificationInfo || isAgain
        ? await certificationStore.saveCertificationInfo(formVal)
        : await certificationStore.updateCertificationInfo(formVal);

    setSubmitLoading(false);
    if (success) {
      message.success("提交成功！");
      await getCertificationInfo(); // 重新请求
      setEdit(false);
      setAgain(false);
    }
  };

  useEffect(() => {
    getCertificationInfo();
  }, []);

  useEffect(() => {
    if (!!certificationInfo) {
      setBusinessLicenseImgUrl(certificationInfo.businessLicenseImgUrl);
      form.setFieldsValue({
        companyName: certificationInfo.companyName,
        businessLicenseImgUrl: certificationInfo.businessLicenseImgUrl,
        countyCode: [certificationInfo.provinceCode, certificationInfo.cityCode, certificationInfo.countyCode] || [],
        registryNumber: certificationInfo.registryNumber,
        contactName: certificationInfo.contactName,
        contactWay: certificationInfo.contactWay,
      });
    } else {
      form.setFieldsValue({
        companyName: accountInfo?.companyName
      });
    }
  }, [certificationInfo, accountInfo]);

  return (
    <PageContainer loading={loading}>
      <div className="certification-wrap">
        <Typography>
          <Title level={4}>企业认证</Title>
        </Typography>
        <Divider />
        {!!certificationInfo && !isEdit && certificationInfo?.auditStatus !== 1 && (
          <Alert
            message={certificationInfo.auditStatus === 2 ? "审核未通过" : "审核中"}
            description={
              certificationInfo.auditStatus === 2 ? certificationInfo.notPassReason : "您的认证已提交，正在审核中！"
            }
            type="warning"
            className="type-tips"
            showIcon
          />
        )}
        {isEdit || !certificationInfo ? (
          <Alert
            message="注意："
            description={
              <Typography>
                <Paragraph>
                  <ul>
                    <li>企业认证直接影响账号的权限。</li>
                    <li>企业具备团队采购和组织架构管理功能，且具备商家开店的功能。</li>
                    <li>实名认证信息对提现和获取发票都可能会产生影响。</li>
                  </ul>
                </Paragraph>
              </Typography>
            }
            type="info"
            showIcon
          />
        ) : (
          ""
        )}

        <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
          <Form.Item
            label="企业名称"
            name="companyName"
            rules={[{ required: true, message: "请输入企业名称" }]}>
            <Input type="text" placeholder="请输入企业名称" disabled />
          </Form.Item>

          <Form.Item
            label="所在地区"
            name="countyCode"
            rules={[{ required: true, message: "请选择所在地区" }]}>
            <AddressSelection level={3} disabled={!isEdit} placeholder="请选择所在地区" />
          </Form.Item>

          <Form.Item label="营业执照" required>
            {isEdit ? (
              <Form.Item
                name="businessLicenseImgUrl"
                valuePropName="filelist"
                rules={[{ required: true, message: "请上传营业执照" }]}
                noStyle>
                <Upload
                  name="businessLicenseImgUrl"
                  accept=".jpg,.jpeg,.png,.bmp,.pdf"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUpload}>
                  {businessLicenseImgUrl ? (
                    <img src={businessLicenseImgUrl} alt="businessLicenseImgUrl" style={{ width: "100%" }} />
                  ) : (
                    <div>
                      <PlusOutlined type={uploadLoading ? "loading" : "plus"} style={{ fontSize: 24 }} />
                      <div className="ant-upload-text">上传</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            ) : (
              <div className="businessLicense-img">
                <img src={certificationInfo?.businessLicenseImgUrl} alt="logoImg" />
              </div>
            )}
            {isEdit && (
              <div className="upload-img-extra">
                <div>
                  上传：营业执照复印件
                  <Button size="small" type="link" onClick={() => setFilePreviewVisible(true)}>
                    点击查看模板
                  </Button>
                </div>
                <div>照片所有信息需清晰可见，内容真实有效，不得做任何修改。</div>
                <div>照片支持.jpg .jpeg .gif .png格式，大小不超过8M。</div>
              </div>
            )}
          </Form.Item>

          <Form.Item label="营业执照号" name="registryNumber" rules={[{ required: true, message: "请输入营业执照号" }]}>
            <Input placeholder="请输入营业执照号" disabled={!isEdit} />
          </Form.Item>

          <Form.Item label="联系人" name="contactName" rules={[{ required: true, message: "请输入联系人" }]}>
            <Input placeholder="请输入联系人" disabled={!isEdit} />
          </Form.Item>

          <Form.Item
            label="联系方式"
            name="contactWay"
            rules={[
              { required: true, message: "请输入联系方式" },
              {
                pattern: /^1[0-9]{10}$/,
                message: "请输入正确的手机号！",
              },
            ]}>
            <Input placeholder="请输入联系方式" disabled={!isEdit} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: formItemLayout.wrapperCol.span, offset: formItemLayout.labelCol.span }}>
            {isEdit || !certificationInfo ? (
              <Button type="primary" onClick={handleSubmit} loading={submitLoading}>
                {submitLoading ? "正在提交中" : "提交认证"}
              </Button>
            ) : certificationInfo?.auditStatus === 2 ? (
              <Button onClick={() => setEdit(true)}>重新提交</Button>
            ) : certificationInfo?.auditStatus === 0 ? (
              <Popconfirm
                placement="top"
                title="是否确认撤回？"
                onConfirm={cancelCertification}
                okText="确定"
                cancelText="取消">
                <Button type="primary" loading={submitLoading}>
                  {submitLoading ? "正在撤回中" : "撤回认证"}
                </Button>
              </Popconfirm>
            ) : certificationInfo.auditStatus === 1 ? (
              <Button
                type="primary"
                onClick={() => {
                  setAgain(true);
                  setEdit(true);
                }}>
                重新认证
              </Button>
            ) : (
              ""
            )}
          </Form.Item>
        </Form>

        {/* 预览文件 */}
        <Modal
          title="营业执照模版"
          open={filePreviewVisible}
          closable
          footer={null}
          onCancel={() => setFilePreviewVisible(false)}>
          <img
            alt="file"
            style={{ width: "100%" }}
            src="https://img01.fxqifu.com/6bbff95af7d1e5bca7fc7c9fc1f7ed5e?x-oss-process=image/quality,q_80"
          />
        </Modal>
      </div>
    </PageContainer>
  );
};
export default observer(Certification);
