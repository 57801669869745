import { Spin } from '@fle-ui/next';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrderDetailStore, { OrderStatus } from '@/stores/orderDetailStore';
import { WhiteSpace } from '@/components';
import WaitBuyerPay from './WaitBuyerPay';
import OrderSuccess from './OrderSuccess';
import OrderFail from './OrderFail';
import ApplyInfo from '../../component/ApplyInfo';
import { PageContainer } from '@fle-ui/pro-layout';

type Props = {};
const PurProxyDetail: FC<Props> = () => {
  const { orderStatus } = OrderDetailStore.orderDetail || {};

  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    OrderDetailStore.ajaxGetOrderDetail({ orderNo: id }).finally(() => setLoading(false));
  }, [id]);

  return (
    <PageContainer padding={0} vesselStyle={{ background: '#eff1f6' }} background="#eff1f6">
      <Spin spinning={loading}>
        {orderStatus === OrderStatus.待买家付款 && <WaitBuyerPay />}
        {orderStatus === OrderStatus.交易成功 && <OrderSuccess />}
        {orderStatus === OrderStatus.交易关闭 && <OrderFail />}

        <WhiteSpace />

        <ApplyInfo />
      </Spin>
    </PageContainer>
  );
};

export default observer(PurProxyDetail);
