import Cookies from "js-cookie";

type envType = string | undefined;

export const TOKEN_KEY = "seller_token";

// @ts-ignore
export const ENV: envType = process.env.REACT_APP_API_ENV;
const API_HOST: Map<envType, string> = new Map([
  ["dev", "https://dev-gateway.fxqifu.net/pangu"],
  ["qa", "https://qa-gateway.fxqifu.net/pangu"],
  ["prod", "https://gateway.fxqifu.net/pangu"],
]);
const MAIN_HOST = API_HOST.get(ENV);

export const getPayHost = () => {
  const LOGIN_HOST: Map<envType, string> = new Map([
    ["dev", `http://dev-pay.elebuys.com/pay`],
    ["qa", `http://qa-pay.elebuys.com/pay`],
    ["prod", `https://pay.elebuys.com/pay`],
  ]);
  return LOGIN_HOST.get(ENV);
};

export const getECommerceSaas = () => {
  const LOGIN_HOST: Map<envType, string> = new Map([
    ["dev", `http://member.koudaiqifu.cn/manage/index`], //dev环境,  3.0没有dev环境
    ["qa", `http://member.koudaiqifu.cn/manage/index`], // qa环境
    ["prod", `https://member.fxqifu.com/manage/index?token=xxx`], //线上环境
  ]);
  const cookie = Cookies.get("token");
  const origin = window.location.origin;
  const pathname = window.location.pathname;

  return `${LOGIN_HOST.get(ENV)}?token=${cookie}&origin=${origin}&pathname=${pathname}`;
};

export const getMallUrl = () => {
  let url = "";
  const cookie = Cookies.get("token");
  switch (ENV) {
    case "dev":
      url = `https://dev-www.elebuys.com/myMall`;
      break;
    case "qa":
      url = `https://qa-www.elebuys.com/myMall`;
      break;
    default:
      url = `https://mall.fxqifu.com/myMall`;
  }
  return `${url}?token=${cookie}`;
};

export const getGoodsUrl = (source: "jd" | "dgss" | "seller"|"maochao", type: "add" | "edit" | "detail", id?: number | string) => {
  const goodsUrlMap = {
    jd: {
      add: "/module/goods/jd/entry/",
      edit: "/module/goods/jd/edit/",
      detail: "/module/goods/jd/detail/",
    },
    dgss: {
      add: "/module/goods/uncleCake/entry/",
      edit: "/module/goods/uncleCake/edit/",
      detail: "/module/goods/uncleCake/detail/",
    },
    seller: {
      add: "/module/goods/seller/entry",
      edit: "/module/goods/seller/edit/",
      detail: "/module/goods/seller/detail/",
    },
    maochao: {
      add: "/module/goods/mc/entry",
      edit: "/module/goods/mc/edit/",
      detail: "/module/goods/mc/detail/",
    },
  };

  return `${goodsUrlMap[source][type]}${id}`;
};

const ORIGIN = window.location.origin;
// const ORIGIN = "http://dev-www.peliten.com";

export { MAIN_HOST, ORIGIN };
