import React from 'react';
import { GoodItemProps } from '@/types/store/goods';

const Stock = (_: any, record: GoodItemProps) => {
  if (!record.stock) {
    return null;
  }

  // 不支持现货
  if (record.isSupportSpot === 0) {
    return null;
  }

  return (
    <>
      {record.isPurchase === 1 && record.purchaseInfo.measureUnit === 2
        ? `${Math.floor(record.stock / record.boxNumber)}箱(${record.stock}${record.unit})`
        : record.stock + record.unit}
    </>
  );
};

export default Stock;
