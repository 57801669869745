import axios from 'axios';
import { MAIN_HOST } from '../config';
import Cookies from 'js-cookie';
import { notification, message } from '@fle-ui/next';
import { formatTime } from './helpers';

/**
 * 导出文件
 * @param url 接口地址
 * @param method
 * @param params
 * @param name
 * @param docType
 * @param handle
 * @param  handle2
 * @returns {*|string}
 */
export function ExportFile(url, method, params, name, docType, handle, handle2 = () => {}) {
  axios({
    method,
    baseURL: MAIN_HOST,
    headers: {
      authority_token: Cookies.get('seller_token') || localStorage.getItem('seller_token')
    },
    url,
    params,
    data: params,
    responseType: 'blob'
  })
    .then((data) => {
      const blob = new Blob([data.data], {
        type: docType
      });
      const fileReader = new FileReader();
      fileReader.readAsText(blob);
      fileReader.onload = function () {
        try {
          const result = JSON.parse(this.result);
          if (result && result.success === false) {
            notification.error({
              message: '提示',
              description: result.message
            });
            handle2();
          } else {
            // reject();
            handle2();
          }
        } catch (e) {
          if (data.status === 200) {
            // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
            const FileName = decodeURI(data.headers['content-disposition'].split("''")[1]);
            let fileName = `${FileName ? FileName : name + formatTime(new Date())}`;
            // 将`blob`对象转化成一个可访问的`url`
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success('导出成功');
            handle();
          }
        }
      };
    })
    .catch(() => {
      message.error('导出失败');
    });
}
