import React, { FC } from 'react';
import { Card, Space, Typography } from '@fle-ui/next';
import OrderInfoItem from '../../../component/OrderInfoItem';
import { observer } from 'mobx-react';
import OrderDeliveryStore from '@/stores/orderDeliveryStore';

type Props = {
  // 当前订单状态
  orderStatus: string;
  // 订单备注
  orderInfo?: string | React.ReactNode;
  // 右侧操作按钮
  rightButton?: React.ReactNode;
};
const OrderStateBoard: FC<Props> = (props: Props) => {
  const { orderStatus, orderInfo, rightButton } = props;
  const { addressNumber, freight, skuNumber, cancelReason, cancelReasonDetail } =
    OrderDeliveryStore.orderDetail;

  return (
    <Card bordered={false}>
      <Space align={'baseline'}>
        <Typography.Title level={5}>状态：{orderStatus}</Typography.Title>
        {cancelReason && <Typography.Text type={'secondary'}>【{cancelReason}】</Typography.Text>}
        {cancelReasonDetail && (
          <Typography.Text type={'secondary'}>{cancelReasonDetail}</Typography.Text>
        )}
        {orderInfo && <Typography.Text type={'secondary'}>{orderInfo}</Typography.Text>}
      </Space>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Space size={40}>
          <OrderInfoItem
            label={'发货单数'}
            value={<Typography.Text type={'danger'}>{addressNumber}笔</Typography.Text>}
          />
          <OrderInfoItem
            label={'发货商品数'}
            value={<Typography.Text type={'danger'}>{skuNumber || 0}件</Typography.Text>}
          />
          <OrderInfoItem
            label={'运费总金额'}
            value={
              <Typography.Text type={'danger'}>￥{(freight / 100).toFixed(2) || 0}</Typography.Text>
            }
          />
        </Space>
        <div style={{ marginLeft: 'auto' }}>{rightButton}</div>
      </div>
    </Card>
  );
};

export default observer(OrderStateBoard);
