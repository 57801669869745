/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:57:02
 */
import { observable, configure, action, makeObservable, runInAction } from "mobx";
import { GoodsType } from "../types/store/goods";
import { goods } from "@/api";
import httpClient from "@/tools/httpClient";

configure({ enforceActions: "observed" });

class GoodsStore implements GoodsType {
  constructor() {
    makeObservable(this);
  }

  // 已发布商品列表
  @action
  getPubList = async (params: any) => {
    params.goodsStatusList = [10, 20];
    const res = await httpClient.post(goods.get("get_basic_goods_list"), params);
    if (res?.success) {
      return {
        total: res.data.total,
        data: res.data.list,
        success: res.success,
      };
    }
    return {
      success: false,
    };
  };

  // 草稿箱商品列表
  @observable
  draft = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };

  @action
  getDraftList = async (params: any) => {
    params.goodsStatus = -20;
    const res = await httpClient.post(goods.get("get_basic_goods_list"), params);
    if (res?.success) {
      runInAction(() => {
        this.draft = res.data;
      });
    }
  };

  @action
  pickToShop = async (goodsId: string) => {
    const res = await httpClient.post(goods.get("pickToShop"), { goodsId, status: 10 });
    if (res?.success) {
      return res?.success;
    }
  };

  @observable
  on_sale = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };

  @action
  getOnSale = async (params: any) => {
    params.poolMallStatus = 10;
    const res = await httpClient.post(goods.get("getShopGoodsList"), params);
    if (res?.success) {
      runInAction(() => {
        this.on_sale = res.data;
      });
    }
  };

  @action
  offShelf = async (goodsId: string) => {
    const res = await httpClient.post(goods.get("offShelf"), { goodsId });
    if (res?.success) {
      return res?.success;
    }
  };

  @observable
  off_sale = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };

  @action
  getOffSale = async (params: any) => {
    params.poolMallStatus = -10;
    const res = await httpClient.post(goods.get("getShopGoodsList"), params);
    if (res?.success) {
      runInAction(() => {
        this.off_sale = res.data;
      });
    }
  };

  @action
  onlineShelf = async (goodsId: string) => {
    const res = await httpClient.post(goods.get("onlineShelf"), { goodsId });
    if (res?.success) {
      return res?.success;
    }
  };

  @action
  remove = async (goodsId: string) => {
    const res = await httpClient.post(goods.get("remove"), { goodsId });
    if (res?.success) {
      return res?.success;
    }
  };

  @action
  getCategory = async (params: { parentId: any }) => {
    const res = await httpClient.post(goods.get("get_cate"), params);
    if (res?.success) {
      return res.data;
    }
  };

  @observable brandListPage: any[] = [];
  @action
  getBrandListPage = async (params: any) => {
    const res = await httpClient.post(goods.get("get_brand_list_page"), params);
    if (res?.success) {
      runInAction(() => {
        this.brandListPage = res.data.list.map((item: any) => ({
          label: item.brandName,
          value: String(item.brandId),
          key: item.brandId,
        }));
      });
    }
  };
}

export default new GoodsStore();
