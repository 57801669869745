import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Modal, Form, Input } from '@fle-ui/next';
import AccountStore, { CreatAccountParams, SelectAccountItemProps } from '@/stores/accountStore';

const layout = {
  wrapperCol: { span: 12 },
  labelCol: { span: 4 }
};

const FormItem = Form.Item;

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: () => void;
  // 保存
  onOk: (data: any) => void;
  // id 为 -1 说明是新增
  id: number;
  item: Partial<SelectAccountItemProps>;
};
const CountModal: FC<Props> = (props: Props) => {
  const { visible, id, onClose, onOk, item } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('添加提现账户');
  const [type, setType] = useState<'add' | 'edit'>('add');

  useEffect(() => {
    if (!visible) {
      return;
    }
    const _title = id === -1 ? '添加提现账户' : '编辑提现账户';
    const _type = id === -1 ? 'add' : 'edit';
    setTitle(_title);
    setType(_type);
    form.setFieldsValue(item);
  }, [id, visible, item, form]);

  const onHandleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (data: CreatAccountParams) => {
        try {
          let res: boolean = false;
          type === 'add' && (res = await AccountStore.ajaxCreate(data));
          type === 'edit' && (res = await AccountStore.ajaxUpdate({ ...data, id }));
          setLoading(false);
          res && onClose();
          await onOk(res);
        } catch (e) {
          setLoading(false);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      destroyOnClose
      title={title}
      onCancel={onClose}
      onOk={onHandleOk}
    >
      <Form {...layout} preserve={false} form={form}>
        <FormItem
          required
          label={'开户名'}
          name={'accountName'}
          rules={[{ required: true, type: 'string' }]}
        >
          <Input placeholder={'输入开户名'} />
        </FormItem>
        <FormItem
          required
          label={'银行卡号'}
          name={'bankCode'}
          rules={[{ required: true, type: 'string' }]}
        >
          <Input placeholder={'输入银行卡号'} />
        </FormItem>
        <FormItem
          required
          label={'开户行'}
          name={'bankName'}
          rules={[{ required: true, type: 'string' }]}
        >
          <Input placeholder={'填写开户行名称'} />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default observer(CountModal);
