/**
 * @Description: 发货单管理列表
 * @author 点点
 * @date 2021/6/18
 * @time 09:56
 */
import useUrlState from '@ahooksjs/use-url-state';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Space, Table } from '@fle-ui/next';
import { InvoiceCancelOrderReason, InvoiceManagementStates } from '@/tools/selectOption';
import CancelOrderModal from './invoiceDetail/components/CancelOrderModal';
import { CancelOrderParams, PageProps } from '@/types/commom';
import OrderDeliveryStore, {
  ajaxGetOrderDeliveryParams,
  DeliveryOrderStates,
  OrderDeliveryItem
} from '@/stores/orderDeliveryStore';
import { useHistory } from 'react-router-dom';
import { getPagination } from "@/tools/getPagination";
import moment from "moment";

const FormItem = Form.Item;
const { Option } = Select;
const { Column } = Table;

type Props = {};
const InvoiceManagement: FC<Props> = () => {
  const history = useHistory();
  const { list, pageNo, pageSize, total } = OrderDeliveryStore.OrderDeliveryList;
  const [form] = Form.useForm();
  const [page, setPage] = useState<PageProps>({ pageNo: 1, pageSize: 20 });
  const [formData, setFormData] = useState<ajaxGetOrderDeliveryParams>({ ...page });
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [urlParams] = useUrlState(
    { tab: 'InvoiceManagement', parentOrderId: undefined },
    { navigateMode: 'replace' }
  );

  // curId 为 -1 是 不展示弹窗
  const [curId, setCurId] = useState(-1);

  useEffect(() => {
    setLoading(true);
    const params = { ...formData, ...page, ...urlParams };
    OrderDeliveryStore.ajaxGetOrderDeliveryList(params).finally(() => {
      setLoading(false);
    });
  }, [formData, page, refresh, urlParams]);

  const onFinish = (values: ajaxGetOrderDeliveryParams) => {
    setFormData(values);
  };

  const onReset = () => {
    form.resetFields();
    setFormData({ pageSize: 20, pageNo: 1 });
  };

  const onOk = async (data: CancelOrderParams) => {
    const res = await OrderDeliveryStore.ajaxCancelOrderDelivery({ ...data });
    res ? message.success('操作成功') : message.error('操作失败');
    setRefresh((refresh) => !refresh);
    setCurId(-1);
  };

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={8} justify={'end'}>
          <Col span={5}>
            <FormItem name={'status'}>
              <Select placeholder={'状态'}>
                {InvoiceManagementStates.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem name={'parentOrderNo'}>
              <Input placeholder={'搜索父单号'} />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem name={'deliveryOrderNo'}>
              <Input placeholder={'搜索发货单号'} />
            </FormItem>
          </Col>
          <Col>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                搜索
              </Button>
              <Button onClick={onReset}>重置</Button>
            </Space>
          </Col>
        </Row>
      </Form>

      <Table<OrderDeliveryItem>
        scroll={{ x: 800 }}
        loading={loading}
        dataSource={list}
        rowKey={'id'}
        pagination={getPagination({
          total,
          current: pageNo,
          pageSize,
          onChange: (pageNo: number, pageSize: number) => setPage({ pageNo, pageSize }),
          onShowSizeChange: (pageNo: number, pageSize: number) => setPage({ pageNo: 1, pageSize })
        })}
      >
        <Column width={250} title={'父订单号'} align={'center'} dataIndex={'parentOrderNo'} />
        <Column width={250} title={'发货单号'} align={'center'} dataIndex={'deliveryOrderNo'} />
        <Column width={80} title={'商品数'} dataIndex={'skuNumber'} align={'center'} />
        <Column width={80} align={'center'} title={'地址数'} dataIndex={'addressNumber'} />
        <Column
          width={150}
          align={'center'}
          title={'运费总金额'}
          dataIndex={'freight'}
          render={(text) => '￥' + (text / 100).toFixed(2)}
        />
        <Column<OrderDeliveryItem>
          align={'center'}
          width={200}
          title={'创建时间'}
          dataIndex={'createTime'}
          render={(createTime) => moment(createTime).format('YYYY-MM-DD HH:mm:ss')}
        />
        <Column<OrderDeliveryItem>
          width={150}
          align={'center'}
          title={'状态'}
          dataIndex={'status'}
          render={(status) => DeliveryOrderStates[status]}
        />
        <Column<OrderDeliveryItem>
          width={200}
          align={'center'}
          fixed={'right'}
          dataIndex={'id'}
          title={'操作'}
          render={(id, record) => {
            return (
              <>
                {DeliveryOrderStates.待买家支付运费 === record.status && (
                  <>
                    <Button type={'link'} size={'small'} onClick={() => setCurId(id)}>
                      取消订单
                    </Button>
                    -
                  </>
                )}

                <Button
                  type={'link'}
                  size={'small'}
                  onClick={() =>
                    history.push('/shop_manage/order/pur_proxy/invoice_detail/detail/' + id)
                  }
                >
                  详情
                </Button>
              </>
            );
          }}
        />
      </Table>

      <CancelOrderModal
        id={curId}
        cancelOrderReason={InvoiceCancelOrderReason}
        visible={curId !== -1}
        onOK={onOk}
        onCancel={() => setCurId(-1)}
      />
    </>
  );
};

export default observer(InvoiceManagement);
