import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, message, Modal, Radio } from '@fle-ui/next';
import React, { useState, useEffect } from 'react';
import TableBar from './TableBar';
import OrderListStore from '@/stores/afterSaleListStore';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
/**
 *
 * 三级菜单 售后审核弹窗
 */

const OrderDealOfAudit = (props: any) => {
  const { isModalVisibleOfAudit, setIsModalVisibleOfAudit, afterSaleAddress, afterSalesBasicType } =
    props;
  const { defaultLocation } = afterSaleAddress;
  const { reviewAfterSalesReq } = OrderListStore;
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState<number>(1);
  const [radioAddress, setRadioAddress] = useState<any>({});
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(5); //子组件tableBar的pageSize
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  };

  //   useMount(() => form.resetFields(),[])

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (radioValue === 0 || pageSize === 5) {
      //pageSize首次渲染列表执行
      setRadioAddress(toJS(defaultLocation?.list?.[0]));
    }
  }, [defaultLocation, radioValue]);

  // 审核弹窗
  const handleOkOfAudit = async () => {
    if (radioValue === 0 || props?.afterSalesMethod === 10) {
      const formData = await form.validateFields();
      let preParams = {
        address: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        townCode: '',
        name: '',
        mobile: ''
      };
      if (!radioAddress?.provinceCode && toJS(radioAddress?.[0]?.provinceCode)) {
        //mobx有问题，初始值取不到，写了个判断
        const radioAddressObject = toJS(radioAddress?.[0]);
        const { address, provinceCode, cityCode, countyCode, townCode, name, mobile } =
          radioAddressObject;
        preParams = { address, provinceCode, cityCode, countyCode, townCode, name, mobile };
      }

      if (props?.afterSalesMethod === 10 && radioValue === 1) {
        if (!preParams?.provinceCode && !radioAddress?.provinceCode) {
          message.error('请选择退货地址！');
          return;
        }
      }
      console.log(toJS(radioAddress), 'PARAMSradioAddress');
      setLoading(true);
      console.log(formData, 'formData');
      const params: any = {
        afterSalesNo: props?.afterSalesNo,
        address: radioAddress?.address || preParams?.address || '',
        areaCode: !radioAddress?.provinceCode
          ? preParams?.provinceCode + '_' + preParams?.cityCode + '_' + preParams?.countyCode
          : radioAddress?.provinceCode +
            '_' +
            radioAddress?.cityCode +
            '_' +
            radioAddress?.countyCode,
        consignee: radioAddress?.name || preParams?.name || '',
        mobileNumber: radioAddress?.mobile || preParams?.mobile || '',
        reviewOpinion: formData?.reviewOpinion || '',
        isReturnFreight: !checkboxValue,
        reviewResult: radioValue ? true : false
      };

      if (preParams?.townCode) {
        params.areaCode = params.areaCode + '_' + preParams.townCode;
      } else if (radioAddress?.townCode) {
        params.areaCode = params?.areaCode + '_' + radioAddress?.townCode;
      }

      if (
        props?.afterSalesBasicType === 1 ||
        (props?.afterSalesBasicType === 2 && props?.afterSalesMethod !== 40)
      ) {
        delete params.isReturnFreight;
      }
      if (!radioAddress?.provinceCode && !preParams?.provinceCode) {
        delete params.areaCode;
      }
      if (props?.afterSalesMethod == 40) {
        delete params.address;
        delete params.areaCode;
        delete params.consignee;
        delete params.mobileNumber;
      }
      if (params?.reviewOpinion === '') {
        delete params.reviewOpinion;
      }

      console.log(params, 'params');
      // setLoading(false);
      reviewAfterSalesReq(params).then((res) => {
        if (res.success) {
          setLoading(false);
          setIsModalVisibleOfAudit(false);
          message.success('审核成功');
          props?.reloadPage();
          // 做一些你需要的操作
        } else {
          setLoading(false);
          message.error('审核失败');
        }
      });
    } else {
      await form.validateFields();
      setIsModalVisibleOfAudit(false);
      Modal.confirm({
        title: '确认退款',
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            当前退款金额为
            <span style={{ color: 'red' }}>
              ¥
              {((props?.finalSalePrice * props?.afterSalesNum + props?.freightPrice) / 100).toFixed(
                2
              )}
            </span>
            ，其中商品金额为
            <span style={{ color: 'red' }}>
              ¥{((props?.finalSalePrice * props?.afterSalesNum) / 100).toFixed(2)}
            </span>
            ，
            {!checkboxValue ? (
              <>
                运费为
                <span style={{ color: 'red' }}>
                  ¥{Number(props?.freightPrice / 100).toFixed(2)}
                </span>
              </>
            ) : (
              <>不退运费</>
            )}
            。是否确认退款？
          </>
        ),
        async onOk() {
          const formData = await form.validateFields();
          const params: any = {
            afterSalesNo: props?.afterSalesNo,
            reviewOpinion: formData?.reviewOpinion || '',
            isReturnFreight: !checkboxValue,
            reviewResult: radioValue ? true : false
          };
          if (
            props?.afterSalesBasicType === 1 ||
            (props?.afterSalesBasicType === 2 && props?.afterSalesMethod !== 40)
          ) {
            delete params.isReturnFreight;
          }
          if (props?.afterSalesMethod == 40) {
            delete params.address;
            delete params.areaCode;
            delete params.consignee;
            delete params.mobileNumber;
          }
          if (params?.reviewOpinion === '') {
            delete params.reviewOpinion;
          }
          const res = await reviewAfterSalesReq(params);
          if (res?.success) {
            message.success('审核成功');
            props?.reloadPage();
          } else {
            message.error('审核失败');
          }
        },
        onCancel() {
          console.log('no');
        }
      });
    }
  };
  const handleCancelOfAudit = () => {
    setIsModalVisibleOfAudit(false);
  };
  const onFinishOfAudit = (values: any) => {
    console.log(values);
  };
  const onRadioChange = (e: any) => {
    console.log('radio checked', e.target.value);
    setRadioValue(e.target.value);
    if (e.target.value === 0) {
      setSelectedRowKeys([defaultLocation?.list?.[0]?.id]);
    }
  };
  //是否退运费
  const checkboxCallback = (e: any) => {
    setCheckboxValue(e.target.checked);
  };
 
  return (
    <div className="OrderDealOfAudit">
      <Modal
        title="售后审核"
        width={600}
        open={isModalVisibleOfAudit}
        onOk={handleOkOfAudit}
        onCancel={handleCancelOfAudit}
        className="audit-form-modal"
        confirmLoading={loading}
      >
        <Form
          {...layout}
          form={form}
          name="nest"
          onFinish={onFinishOfAudit}
          className="audit-form"
          initialValues={{ isAgree: 1 }}
        >
          <Form.Item label={<span style={{ color: '#787878' }}>商品名称</span>}>
            {props?.goodsName}
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>会员单价</span>}>
            ¥{(props?.finalSalePrice / 100).toFixed(2)}元
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>商品数量</span>}>
            {props?.afterSalesNum}
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>退款总额</span>}>
            <Form.Item style={{display: 'inline-block'}}>
              <span style={{ color: '#F42D2D' }}>
                ¥
                {(
                  (props?.finalSalePrice * props?.afterSalesNum + props?.freightPrice) /
                  100
                ).toFixed(2)}
                元
                {props?.freightPrice !== 0 &&
                  `（含运费¥${(props?.freightPrice / 100).toFixed(2)}）`}
              </span>
            </Form.Item>
            {props?.afterSalesBasicType === 2 && props?.afterSalesMethod === 40 && (
              <Form.Item name="returnMoney" style={{display: 'inline-block'}} noStyle>
                <Checkbox onChange={checkboxCallback} style={{ marginTop: 5, marginLeft: 8 }}>
                  不退运费
                </Checkbox>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>售后类型</span>}>
            {props?.afterSalesMethod == 10 && <span>退货</span>}
            {props?.afterSalesMethod == 20 && <span>换货</span>}
            {props?.afterSalesMethod == 30 && <span>维修</span>}
            {props?.afterSalesMethod == 40 && <span>退款</span>}
          </Form.Item>
          <Form.Item label={<span style={{ color: '#787878' }}>发货状态</span>}>
            {props?.afterSalesBasicType == 1 && <>待发货</>}
            {props?.afterSalesBasicType == 2 && <>已发货</>}
          </Form.Item>
          <Form.Item
            name="isAgree"
            label={<span style={{ color: '#787878' }}>审核结果</span>}
            rules={[{ required: true }]}
          >
            <Radio.Group onChange={onRadioChange} value={radioValue}>
              <Radio value={1}>同意</Radio>
              <Radio value={0}>不同意</Radio>
            </Radio.Group>
          </Form.Item>
          {props?.afterSalesMethod === 10 && radioValue === 1 && (
            <Form.Item
              label={<span style={{ color: '#787878' }}>退货地址</span>}
              rules={[{ required: true }]}
            >
              <TableBar
                setRadioAddress={setRadioAddress}
                radioAddress={radioAddress}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRowKeys={selectedRowKeys}
              />
            </Form.Item>
          )}
          {radioValue !== 1 && (
            <Form.Item
              name="reviewOpinion"
              label={<span style={{ color: '#787878' }}>审核意见</span>}
              rules={[{ required: true, message: '请输入审核意见' }]}
              style={{ marginBottom: 60 }}
            >
              <Input.TextArea showCount maxLength={200} />
            </Form.Item>
          )}
          &nbsp;
        </Form>
        {props?.afterSalesMethod === 40 && radioValue === 1 && (
          <div
            style={{ fontSize: 14, color: '#F42D2D', position: 'absolute', bottom: 21, left: 24 }}
          >
            同意申请后将会从账户中扣除商品金额
          </div>
        )}
      </Modal>
    </div>
  );
};

export default inject('afterSaleAddress')(observer(OrderDealOfAudit));
