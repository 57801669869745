import React, { FC } from 'react';
import { CommonStore } from '@/stores';
import { Button } from '@fle-ui/next';
import { PlusOutlined } from '@ant-design/icons';
import { appHistory } from '@ice/stark-app';
import { WhiteSpace } from '@/components';

type Props = {};
const AddGoodButton: FC<Props> = (props: Props) => {
  return CommonStore.accountInfo.isJD ? null : (
    <>
      <Button
        type={'primary'}
        icon={<PlusOutlined />}
        onClick={() => appHistory.push('/home/module/goods/seller/entry')}
      >
        添加商品
      </Button>
      <WhiteSpace />
    </>
  );
};

export default AddGoodButton;
