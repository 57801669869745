export const MAOCHAOApis = {
  POST_GET_JD_GOODS_LIST: '/merchant/supplier/jd/listSelectProduct', //获取猫超商品列表
  POST_GET_NEW_MC_GOODS_LIST:'/merchant/supplier/ali/searchPageList',//新接口，获取猫超商品列表
  POST_UPDATE_JD_GOODS_LIST_MARKET_PRICE: '/merchant/supplier/jd/syncMarketPrice', //同步猫超商品市场价
  POST_UPDATE_NEW_JD_GOODS_LIST_MARKET_PRICE: '/merchant/jdchannel/jd/updateMarketPrice', //同步猫超商品市场价,商品改造新接口
  GET_JD_CATEGORY: '/merchant/supplier/jd/listCategory', //猫超商品分类
  GET_NEW_JD_CATEGORY: '/merchant/jdchannel/jd/listCategory', //猫超商品分类，改造新接口
  GET_JD_LOCATION: '/merchant/supplier/jd/listArea', //猫超商品地区
  GET_NEW_JD_LOCATION: '/merchant/jdchannel/jd/listArea', //猫超商品地区，改造新接口
  POST_JD_BATCH_CHECK: '/elebuys/goods/standard/jdBatch', //批量挑选
  GET_LIST_CATEGORY: '/merchant/supplier/ali/listCategory', //获取类别
};
