import React, { useState, useEffect, FC, useRef } from 'react';
import { message, Modal, Space, Spin, Table, Tooltip } from '@fle-ui/next';
import { DeleteOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import AddressModal from './AddressModal';
import { sendAddressProps, AddressItemProps, AjaxAddressParams } from '@/types/store/address';
import { inject, observer } from 'mobx-react';
import { useMount } from 'ahooks';
import './TableBar.less';
/**
 *
 * 鼠标滚动加载数据table组件
 */

type Props = {
  afterSaleAddress: sendAddressProps;
};

interface DataType {
  name: string;
  mobile: number;
  address: string;
}

const TableBar = (props: any) => {
  const {
    afterSaleAddress,
    setRadioAddress,
    pageSize,
    setPageSize,
    selectedRowKeys,
    setSelectedRowKeys
  } = props;
  const { defaultLocation, getDefaultLocation, deleteLocation, addLocation, updateLocation } =
    afterSaleAddress;
  const [actionItem, setActionItem] = useState({});
  const [actionName, setActionName] = useState<'add' | 'edit'>('add');
  const [addressModalVisible, setAddressModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  // const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const height = defaultLocation.total < 5 ? defaultLocation.total * 55 : 200;

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      width: 80
    },
    {
      title: 'mobile',
      dataIndex: 'mobile',
      width: 126
    },
    {
      title: 'address',
      dataIndex: 'address',
      ellipsis: {
        showTitle: false
      },
      render: (text: any, record: any) => {
        const { provinceName, cityName, countyName, townName, address } = record;
        return (
          <Tooltip
            placement="topLeft"
            title={
              <>
                {provinceName}
                {cityName}
                {countyName}
                {townName && townName}
                {address}
              </>
            }
          >
            {provinceName}
            {cityName}
            {countyName}
            {townName && townName}
            {address}
          </Tooltip>
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: 64,
      render: (text: any, record: any) => {
        return (
          <>
            <a onClick={() => handleEdit(record)}>
              <FormOutlined />
            </a>
            &nbsp;&nbsp;&nbsp;
            {record.id !== selectedRowKeys[0] && (
              <a onClick={() => handleDelete(record)}>
                <DeleteOutlined />
              </a>
            )}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    getData();
  }, [pageNo, pageSize]);

  useEffect(() => {
    if (pageSize === 5) {
      setSelectedRowKeys([defaultLocation?.list?.[0]?.id]);
    }
  }, [defaultLocation]);

  console.log(selectedRowKeys, 'selectedRowKeys');

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: DataType[]) => {
      setRadioAddress(selectedRows);
      console.log(selectedRows, 'selectedRows');
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  const onScrollEvent = (e: any) => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      console.info('到底了！');
      // 这里去做你的异步数据加载
      setLoading(true);
      if (defaultLocation.total < defaultLocation.pageNo * defaultLocation.pageSize) {
        message.info('到底了！');
        setLoading(false);
      } else {
        setPageSize(pageSize + 5);
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    await getDefaultLocation({ type: 5, pageInfo: { pageNo, pageSize } });
    setLoading(false);
  };

  const handleEdit = (record: AddressItemProps) => {
    console.log(record, 'edit');

    setActionName('edit');
    setActionItem(record);
    setAddressModalVisible(true);
  };

  const handleAdd = () => {
    setActionName('add');
    setActionItem({});
    setAddressModalVisible(true);
  };

  const onAddAddressOk = async (values: AjaxAddressParams) => {
    console.log('await add');
    actionName === 'add' ? await addLocation(values) : await updateLocation(values);
    await getData();
  };

  const handleDelete = (record: AddressItemProps) => {
    console.log(record, 'delete');

    Modal.confirm({
      title: '是否确认删除地址',
      okText: '删除',
      cancelText: '取消',
      centered: true,
      async onOk() {
        const res = await deleteLocation({ id: record?.id, type: 5 });
        if (res.success) {
          message.success('删除成功！');
          await getData();
        }
      }
    });
  };
  return (
    <>
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: height
        }}
      >
        <div
          className="table-bar"
          onScrollCapture={(e) => onScrollEvent(e)}
          style={{
            maxHeight: 200,
            overflowY: 'scroll',
            position: 'absolute',
            right: -17,
            overflowX: 'hidden'
          }}
        >
          <Table
            pagination={false}
            showHeader={false}
            bordered={false}
            rowSelection={{
              type: 'radio',
              ...rowSelection
            }}
            columns={columns}
            dataSource={defaultLocation.list}
            loading={loading}
            rowKey="id"
          />
        </div>
      </div>

      <div
        className="add-address"
        style={{ marginTop: 12, color: '#2C68FF', cursor: 'pointer', width: '100px' }}
        onClick={handleAdd}
      >
        <PlusOutlined />
        添加地址
      </div>
      <AddressModal
        visible={addressModalVisible}
        item={actionItem}
        onOk={onAddAddressOk}
        onCancel={() => setAddressModalVisible(false)}
        type={actionName}
        whichAddress={'shippingAddress'}
      />
    </>
  );
};

export default inject('afterSaleAddress')(observer(TableBar));
