/**
 * @Description: 已发布商品列表
 * @author 点点
 * @date 2021/5/17
 * @time 13:59
 */

import React, { FC, useState } from 'react';
import { Form, Select, Input, Button, Space, Row, Table, Cascader, Card } from '@fle-ui/next';
import WhiteSpace from '@/components/WhiteSpace';
import { useHistory } from 'react-router-dom';
import { goodsStore } from '@/stores';
import { useDebounceEffect, useMount } from 'ahooks';
import { getPagination } from '@/tools/getPagination';
import { GoodItemProps } from '@/types/store/goods';
import { GoodInfo, OncePrice, PurchasePrice, SaleType, Stock } from '@/components/TableColumn';
import { saleType } from '@/tools/selectOption';
import { observer } from 'mobx-react';

const FormItem = Form.Item;
const { Option } = Select;
const { Column } = Table;

type Props = {};

const DraftList: FC<Props> = () => {
  const history = useHistory();

  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState<any>(undefined);
  const getMoreCate = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let arr = await goodsStore.getCategory({
      parentId: targetOption.value
    });
    if (arr.length) {
      targetOption.children = arr.map((item: any) => ({
        label: item.categoryName,
        value: item.categoryId,
        isLeaf: false
      }));
    } else {
      targetOption.isLeaf = true;
    }
    targetOption.loading = false;
    setCategoryData([...categoryData]);
  };
  const _initCategory = async () => {
    let categoryData;
    let cate1 = await goodsStore.getCategory({
      parentId: 0
    });

    categoryData = cate1?.map((item: any) => ({
      label: item.categoryName,
      value: item.categoryId,
      isLeaf: +item.level >= 2
    }));
    setCategoryData(categoryData);
  };

  useMount(() => {
    _initCategory();
  });

  const [loading, setLoading] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [categoryId, setCategoryId] = useState(undefined);
  const [keyword, setKeyword] = useState<any>(undefined);
  const [type, setType] = useState<0 | 1 | 2 | undefined>(undefined);

  const [params, setParams] = useState({
    categoryTree:categoryId,
    keyword,
    isOnce: undefined,
    isPurchase: undefined
  });
  useDebounceEffect(
    () => {
      setLoading(true);
      goodsStore.getDraftList({ pageNo, pageSize, ...params }).finally(() => setLoading(false));
    },
    [pageNo, pageSize, params],
    { wait: 0 }
  );

  const changePage = (page: number) => {
    setPageNo(page);
  };

  const changePageSize = (current: number, size: number) => {
    setPageNo(1);
    setPageSize(size);
  };

  const search = () => {
    const _categoryId = category ? category.filter((item: any) => item).reverse()[0] : undefined;
    setPageNo(1);
    setCategoryId(_categoryId);
    const _param: any =
      type === undefined ? { isOnce: undefined, isPurchase: undefined } : saleType[type];

    setParams({
      ..._param,
      categoryTree: _categoryId,
      keyword
    });
  };

  const reset = () => {
    setCategory(undefined);
    setPageNo(1);
    setKeyword(undefined);
    setType(undefined);
    setParams({
      isOnce: undefined,
      isPurchase: undefined,
      categoryTree: undefined,
      keyword: undefined
    });
  };

  return (
    <Card>
      <Row justify="end">
        <Form layout={'inline'}>
          <FormItem>
            <Select
              placeholder={'销售支持'}
              style={{ width: 220 }}
              value={type}
              onChange={(e) => setType(e)}
              allowClear
            >
              <Option value="0">集采批发/代发，一件代发</Option>
              <Option value="1">集采批发/代发</Option>
              <Option value="2">一件代发</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Cascader
              placeholder="分类"
              style={{ marginRight: 16 }}
              options={categoryData}
              loadData={getMoreCate}
              onChange={(value:any) => {
                setCategory(value);
              }}
              value={category}
              changeOnSelect
            />
          </FormItem>
          <FormItem>
            <Input
              placeholder="商品名称/ID"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              allowClear
            />
          </FormItem>
          <FormItem>
            <Space>
              <Button type={'primary'} onClick={search}>
                查询
              </Button>
              <Button onClick={reset}>重置</Button>
            </Space>
          </FormItem>
        </Form>
      </Row>

      <WhiteSpace />

      <Table
        loading={loading}
        dataSource={goodsStore.draft.list}
        pagination={getPagination({
          total: goodsStore.draft.total,
          current: pageNo,
          pageSize,
          onChange: changePage,
          onShowSizeChange: changePageSize
        })}
        rowKey={'goodsId'}
      >
        <Column<GoodItemProps>
          width={300}
          title={'商品信息'}
          dataIndex={'goodsId'}
          render={GoodInfo}
        />
        <Column<GoodItemProps>
          title={'销售支持'}
          align={'center'}
          dataIndex={'goodsId'}
          width={140}
          render={SaleType}
        />
        <Column<GoodItemProps>
          title={'集采价'}
          align={'center'}
          dataIndex={'goodsId'}
          render={PurchasePrice}
        />
        <Column<GoodItemProps>
          title={'代发价'}
          align={'center'}
          dataIndex={'goodsId'}
          render={OncePrice}
        />
        <Column<GoodItemProps>
          title={'现货库存'}
          align={'center'}
          dataIndex={'stock'}
          render={Stock}
        />
        <Column<GoodItemProps>
          width={80}
          title={'操作'}
          align={'center'}
          dataIndex={'goodsId'}
          render={(goodsId) => (
            <>
              <Button
                type={'link'}
                onClick={() => history.push('/module/goods/seller/edit/' + goodsId)}
              >
                继续编辑
              </Button>
            </>
          )}
        />
      </Table>
    </Card>
  );
};

export default observer(DraftList);
