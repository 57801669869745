/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-22 13:57:03
 */
const once: {[key: string]: string} = {
  // 运费模板部分
  POST_FREIGHT_LIST: '/merchant/basic/memberFreightTemplate/templates', //运费模板列表
  GET_FREIGHT_SIMPLE_LIST: '/merchant/basic/memberFreightTemplate/templates-simple', //运费模板简单列表
  POST_FREIGHT_DETAIL: '/merchant/basic/memberFreightTemplate/get', //获取运费模板详情
  POST_ADD_FREIGHT: '/merchant/basic/memberFreightTemplate/save', //增加运费模板
  POST_UPDATE_FREIGHT: '/merchant/basic/memberFreightTemplate/update', //修改运费模板
  POST_DELETE_FREIGHT: '/merchant/basic/memberFreightTemplate/delete', //删除运费模板
  GET_FREIGHT_AREA: '/basic/dict/dictionaries', //获取运费模板区域

  //地址管理部分
  // GET_lOCATION_LIST: '/merchant/person/customerAddress/addressList', //获取地址列表
  GET_lOCATION_LIST: '/fleshopy/person/customer-adress/list', //获取地址列表
  POST_ADD_lOCATION: '/fleshopy/person/customer-adress/add', //新增地址
  POST_UPDATE_lOCATION: '/fleshopy/person/customer-adress/update', //修改地址
  POST_DELETE_lOCATION: '/fleshopy/person/customer-adress/delete', //删除地址
};

export default once;