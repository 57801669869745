import { action, makeAutoObservable, runInAction } from "mobx";
import httpClient from "@/tools/httpClient";
import { addKey } from "@/tools/common";
import type { ListProps } from "@/tools/common";
import { DgssApis } from "@/api/dgssApis";

class dgssStore {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 获取蛋糕叔叔商品列表
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  @action
  getDgssGoodsList = async (params: any) => {
    let res = await httpClient.post(DgssApis.GET_DGSS_GOODS_LIST, params);
    if (res?.success) {
      return {
        total: res.data.total,
        data: res.data.list,
        success: res.success,
      };
    }
    return {
      success: false,
    };
  };

  /**
   * 获取蛋糕叔叔品牌列表
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  public dgssBranchList: any[] = [];
  public dgssBranchNameList: any[] = [];
  @action
  getDgssBranchList = async () => {
    let res = await httpClient.post(DgssApis.GET_DGSS_BRANCH_LIST);
    if (res?.success) {
      let brandNameList: any[] = [];
      const brandList = res.data.map((item: any) => {
        brandNameList.push({ label: item.name, value: item.name, key: item.id });
        return { label: item.name, value: item.id, key: item.id };
      });
      this.dgssBranchList = brandList;
      this.dgssBranchNameList = brandNameList;
    }
  };

  /**
   * 获取蛋糕叔叔所有城市列表
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  public dgssCityList: any[] = [];
  public dgssCityNameList: any[] = [];
  @action
  getDgssCityList = async () => {
    let res = await httpClient.post(DgssApis.GET_DGSS_CITY_LIST);
    if (res?.success) {
      runInAction(() => {
        let cityNameList: any[] = [];
        const cityList = res.data.map((item: any) => {
          cityNameList.push({
            label: item.name,
            value: item.name,
            key: item.dgssCityId,
          });
          return {
            label: item.name,
            value: item.dgssCityId,
            key: item.dgssCityId,
          };
        });
        this.dgssCityList = cityList;
        this.dgssCityNameList = cityNameList;
      });
    }
  };
}

export default new dgssStore();
