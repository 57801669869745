import React, { FC, useState } from 'react';
import { Button, Space } from '@fle-ui/next';
import { observer } from 'mobx-react';
import OrderDetailStore, { DetailGoodItemProp, OrderStatus } from '@/stores/orderDetailStore';
import PriceChangListModal from '@/pages/shopManage/order/component/PriceChangListModal';
import ChangeShipPriceModal from './ChangeShipPriceModal';
import ShipCalculationMethod from './ShipCalculationMethod';
import ShipPriceInstruction from './ShipPriceInstruction';

const OrderGoodShipPrice: FC<DetailGoodItemProp> = (props: DetailGoodItemProp) => {
  const {
    finalFreightPrice,
    isChange,
    freightId,
    orderGoodsFreightId,
    freightType,
    NoPrice = false
  } = props;
  const { orderStatus } = OrderDetailStore.orderDetail || {};
  const [changeShipPriceModalVisible, setChangeShipPriceModalVisible] = useState(false);
  const [shipPriceInstruction, setShipPriceInstruction] = useState(false);
  const [shipCalculationMethodVisible, setShipCalculationMethodVisible] = useState(false);
  const [priceChangListModalVisible, setPriceChangListModalVisible] = useState(false);

  return (
    <>
      <>
        {freightType !== null && (
          <div>
            {freightId && <>￥{(finalFreightPrice / 100).toFixed(2)} </>}
            {[OrderStatus.待卖家确认, OrderStatus.待买家付款].includes(orderStatus) &&
              freightId &&
              !NoPrice && (
                <Button type={'link'} onClick={() => setChangeShipPriceModalVisible(true)}
                style={{margin: 0, padding: 0 }}>
                  修改
                </Button>
              )}
          </div>
        )}
        <div>
          {freightId && freightType === 1 && (
            <Button type={'link'} onClick={() => setShipCalculationMethodVisible(true)}
            style={{margin: 0, padding: 0 }}
            >
              计算方法
            </Button>
          )}
          {freightId && freightType === 2 && (
            <Button type={'link'} onClick={() => setShipPriceInstruction(true)}
            style={{margin: 0, padding: 0 }}
            >
              计算方法
            </Button>
          )}
        </div>
        {isChange === 1 && (
          <div>
            <Button type={'link'} onClick={() => setPriceChangListModalVisible(true)}
            style={{margin: 0, padding: 0 }}
            >
              修改记录
            </Button>
          </div>
        )}
      </>

      <Space direction={'vertical'}>
        {freightId === null && <span>待确认运费</span>}
        {OrderStatus.待卖家确认 === orderStatus && freightId === null && (
          <Button type={'link'} onClick={() => setShipPriceInstruction(true)}
          style={{margin: 0, padding: 0 }}
          >
            查看运费说明
          </Button>
        )}
      </Space>

      <ChangeShipPriceModal
        visible={changeShipPriceModalVisible}
        onClose={() => setChangeShipPriceModalVisible(false)}
        goodDetail={props}
      />

      <ShipCalculationMethod
        visible={shipCalculationMethodVisible}
        onClose={() => setShipCalculationMethodVisible(false)}
        id={orderGoodsFreightId}
      />

      <ShipPriceInstruction
        visible={shipPriceInstruction}
        onClose={() => setShipPriceInstruction(false)}
        goodDetail={props}
      />
      <PriceChangListModal
        type={2}
        id={freightId as string}
        visible={priceChangListModalVisible}
        onClose={() => setPriceChangListModalVisible(false)}
      />
    </>
  );
};

export default observer(OrderGoodShipPrice);
