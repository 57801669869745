import React, { FC } from 'react';
import { Col, Statistic } from '@fle-ui/next';
import { OrderItem } from '@/stores/afterSaleListStore';

type Props = {
  // 栅格
  span: number;
  thirdSalePrice: number;
  afterSalesNum: number;
  freightPrice: number;
  afterSalesMethod: number;
};
const OrderPrice: FC<Props & OrderItem> = (props) => {
  const {
    span,
    thirdSalePrice,
    afterSalesNum,
    freightPrice,
    afterSalesMethod,
    afterSalesType,
    finalSalePrice
  } = props;

  return (
    <Col
      span={span}
      className="table_vertical_left"
    >
      {afterSalesMethod == 10 || afterSalesMethod == 40 ? (
        <>
          <Statistic
            valueStyle={{
              fontSize: 14,
              color: '#262626'
            }}
            value={(afterSalesType === 1
              ? ((finalSalePrice || 0) * afterSalesNum + freightPrice) / 100
              : ((thirdSalePrice || 0) * afterSalesNum + freightPrice) / 100
            ).toFixed(2)}
            precision={2}
          />
          <span style={{ fontSize: 14, color: '#a9a9a9' }}>
            {freightPrice === null || freightPrice === 0
              ? '不含运费'
              : '含运费：￥' + (freightPrice / 100).toFixed(2)}
          </span>
        </>
      ) : (
        '---'
      )}
    </Col>
  );
};

export default OrderPrice;
