import React from "react";
import { Icon } from "@/components";

export interface RouterType {
  name: string;
  key: string;
  roleKey?: string;
  isTopMenu?: boolean;
  showLeftMenu?: boolean;
  //除 微应用以外，其它路由 不允许出现包含 /goods 字符串，
  path?: string;
  badgeText?: string;
  icon?: any;
  children?: RouterType[];
  disabled?: boolean; // 是否禁用 -- 禁用后跳到该页面会重定向
  showMenu?: boolean; // 是否显示 -- 可通过history跳转
}

type authorProps = {
  // 是否开通象铺
  shopFlag?: boolean;
  // 当前登陆账号是否为Api类型供应商账号
  isApiSupplier?: boolean;

  isDGSS?: boolean;
  isJD?: boolean;
  isMAOCHAO?: any;
};

type routersProps = (author: authorProps) => RouterType[];

const commodityMenu: routersProps = ({ isApiSupplier, isDGSS, isJD, isMAOCHAO }: authorProps) => {
  console.log(isMAOCHAO,'isMAOCHAO');
  console.log(isJD,'isJD');
  
  const menus: RouterType[] = [
    {
      name: "商品管理",
      icon: <Icon type="icon-shangpinguanli" style={{fontSize: 16}} />,
      key: "goods",
      children: [
        {
          name: "挑选接口商品",
          path: "/commodity/manage/pickJD",
          key: "/commodity/manage/pickJD",
          showMenu: isJD,
        },
        {
          name: "挑选接口商品",
          path: "/commodity/manage/pickDGSS",
          key: "/commodity/manage/pickDGSS",
          showMenu: isDGSS,
        },
        {
          name: "挑选接口商品",
          path: "/commodity/manage/pickMC",
          key: "/commodity/manage/pickMC",
          showMenu: isMAOCHAO,
        },
        {
          name: "发布新商品",
          path: "/module/goods/seller/entry",
          key: "/module/goods/seller/entry",
          showMenu: !isApiSupplier,
        },
        {
          name: "已发布商品",
          path: "/commodity/manage/pub_list",
          key: "/commodity/manage/pub_list",
          showMenu: true,
        },
        // {
        //   name: "已发布商品",
        //   path: "/commodity/manage/pub_list_mc",
        //   key: "/commodity/manage/pub_list_mc",
        //   showMenu: isMAOCHAO,
        // },
        {
          name: "草稿箱",
          path: "/commodity/manage/draft_list",
          key: "/commodity/manage/draft_list",
          showMenu: !isApiSupplier,
        },
      ],
    },
    {
      name: "商品设置",
      icon: <Icon type="icon-shangpingshezhi" style={{fontSize: 16}} />,
      key: "/commodity/setting",
      showMenu: !isApiSupplier,
      children: [
        {
          name: "地址管理",
          path: "/commodity/setting/send_address_list",
          key: "/commodity/setting/send_address_list",
          showMenu: true,
        },
        {
          name: "运费模板",
          path: "/commodity/setting/freightTemplate",
          key: "/commodity/setting/freightTemplate",
          showMenu: true,
        },
      ],
    },
  ];

  return menus
    ?.filter(({ showMenu = true }: any) => !!showMenu)
    ?.map((menuItem: any): RouterType => {
      return {
        ...menuItem,
        children: menuItem.children.filter(({ showMenu = true }: any) => !!showMenu),
      };
    });
};

const shopManageMenu = ({ isApiSupplier, isDGSS, isJD, isMAOCHAO }: authorProps) => {
  const menus = [
    {
      name: "象铺商品",
      icon: <Icon type="icon-xiangpuguanli" style={{fontSize: 16}} />,
      key: "shop_manage",
      children: [
        {
          name: "销售中商品",
          path: "/shop_manage/good/on_sale",
          key: "/shop_manage/good/on_sale",
          showMenu: true,
        },
        {
          name: "未上架商品",
          path: "/shop_manage/good/off_sale",
          key: "/shop_manage/good/off_sale",
          showMenu: true,
        },
      ],
    },
    {
      name: "订单管理",
      icon: <Icon type="icon-dingdanguanli" style={{fontSize: 16}} />,
      key: "shop_manage:order",
      children: [
        {
          name: "集采批发订单",
          path: "/shop_manage/order/pur_wholesale",
          key: "/shop_manage/order/pur_wholesale",
          showMenu: !isDGSS,
          
        },
        {
          name: "集采代发订单",
          path: "/shop_manage/order/pur_proxy",
          key: "/shop_manage/order/pur_proxy",
          showMenu: !isDGSS,
        },
        {
          name: "一件代发订单",
          path: "/shop_manage/order/once_proxy",
          key: "/shop_manage/order/once_proxy",
          showMenu: true,
        },
        {
          name: "售后工单",
          path: "/shop_manage/order/after_sale",
          key: "/shop_manage/order/after_sale",
          showMenu: true,
        },
        //猫超
        // {
        //   name: "猫超-集采批发订单",
        //   path: "/shop_manage/order/pur_wholesaleMaochao",
        //   key: "/shop_manage/order/pur_wholesaleMaochao",
        //   showMenu: isMAOCHAO,
        // },

      ],
    },
  ];

  return menus
    ?.filter(({ showMenu = true }: any) => !!showMenu)
    ?.map((menuItem: any) => {
      return {
        ...menuItem,
        children: menuItem.children.filter(({ showMenu = true }: any) => !!showMenu),
      };
    });

  // return menus;
};

const financeManageMenu = ({ isApiSupplier, isDGSS, isJD }: authorProps) => {
  // if (isJD) return [];

  // if (isDGSS) return [];

  const menus = [
    {
      name: "财务管理",
      icon: <Icon type="icon-caiwuguanli" style={{fontSize: 16}} />,
      key: "finance",
      children: [
        {
          name: "财务概览",
          path: "/finance/normal/manage",
          key: "/finance/normal/manage",
          showMenu: !isDGSS
        },
        {
          name: "可结算账户",
          path: "/finance/normal/manage/can_settlement",
          key: "/finance/normal/manage/can_settlement",
          showMenu: false,
        },
        {
          name: "交易明细",
          path: "/finance/normal/trans_detail",
          key: "/finance/normal/trans_detail",
          showMenu: !isDGSS
        },
        {
          name: "结算账户",
          path: "/finance/normal/settlement",
          key: "/finance/normal/settlement",
          showMenu: !isDGSS
        },
        {
          name: "渠道预付款",
          path: "/finance/dgss/manage",
          key: "/finance/dgss/manage",
          showMenu: isDGSS
        },
      ],
    },
  ];

  return menus
    ?.filter(({ showMenu = true }: any) => !!showMenu)
    ?.map((menuItem: any) => {
      return {
        ...menuItem,
        children: menuItem.children.filter(({ showMenu = true }: any) => !!showMenu),
      };
    });
};

const settingsManageMenu = () => {
  const menus = [
    {
      name: "企业设置",
      icon: <Icon type="icon-qiyeguanli" style={{fontSize: 16}} />,
      key: "settings",
      children: [
        {
          name: "账号信息",
          path: "/settings/company/account",
          key: "/settings/company/account",
        },
        {
          name: "企业信息",
          path: "/settings/company/companyInfo",
          key: "/settings/company/companyInfo",
        },
      ],
    },
  ];

  return menus;
};

export { commodityMenu, shopManageMenu, financeManageMenu, settingsManageMenu,  };
