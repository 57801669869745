import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Modal, Spin, Timeline, Typography } from '@fle-ui/next';
import ExpressStore from '@/stores/expressStore';
import { VoListItemProps } from '@/stores/orderDeliveryStore';
import OrderInfoItem from './OrderInfoItem';
import { WhiteSpace } from '@/components';
import { toJS } from 'mobx';

const { Text } = Typography;

type Props = {
  visible: boolean;
  onCancel: () => void;
  expressNo: string;
  expressName: string;
  expressType: VoListItemProps['expressType'];
};
const ShipInfoModal: FC<Props> = (props: Props) => {
  const { visible, onCancel, expressNo, expressType } = props;

  const { expressInfo, expressName, expressNoStore } = ExpressStore.LogisticByExpressNo;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!visible) {
      return;
    }
    if (expressType === 1) {
      setLoading(true);
      ExpressStore.ajaxGetLogisticByExpressNo({ expressNo }).finally(() => {
        setLoading(false);
      });
    }
  }, [visible, expressNo, expressType]);

  return (
    <Modal title={'物流信息'} visible={visible} onCancel={onCancel} footer={null}>
      <Spin spinning={loading}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <OrderInfoItem label={'快递公司'} value={props.expressName || expressName} />
          <OrderInfoItem label={'快递单号'} value={expressNoStore} />
        </div>

        <WhiteSpace />
        {expressType === 1 && (
          <Timeline style={{ height: '50vh', overflowY: 'auto', paddingTop: 20 }}>
            {expressInfo?.map((item) => (
              <Timeline.Item key={item.time} color={'#37A5D1'}>
                <Text style={{ color: '#37A5D1' }}>
                  {item.time}
                  {item.context}
                </Text>
              </Timeline.Item>
            ))}
          </Timeline>
        )}
      </Spin>
    </Modal>
  );
};

export default observer(ShipInfoModal);
