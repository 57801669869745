type PaginationProps = {
  // 这个 和后台 协商 分页不要超过1000
  total: number;
  pageSize: number;
  current: number;
  onChange?: (pageNo: number, pageSize: number) => void;
  onShowSizeChange?: (pageNo: number, pageSize: number) => void;
};

export const getPagination = (data: PaginationProps): any => {
  // 这行代码只是验证 所加分页器 有效
  return {
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100'],
    defaultPageSize: 20,
    showTotal() {
      return `共 ${data.total} 条数据`;
    },
    ...data,
    total: data.total > 10000 - data.pageSize ? 10000 - data.pageSize + 1 : data.total
  };
};
