/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-24 15:08:19
 */
import {
  makeAutoObservable,
  configure,
  runInAction,
  observable,
  action,
} from "mobx";
import { CommonTypes } from "@/types/store/common.interface";
import { certification } from "@/api/setting";
import httpClient from "@/tools/httpClient";

configure({ enforceActions: "observed" });

class certificationStore  {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }
  // 是否重新编辑并提交认证
  resetCertificationInfo = false;
  /**
   * 获取企业认证信息
   */
  certificationInfo: any = null;
  getCertificationInfo = async () => {
    const data: any = await httpClient.get(
      certification.GET_CERTIFICATION_INFO
    );
    if (data.success) {
      runInAction(() => {
        this.certificationInfo = data.data;
      });
    }

    return data.data
  }

  /**
   * 保存企业认证信息
   */
  saveCertificationInfo = async (params: any) => {
    const res: any = await httpClient.post(certification.SAVE_CERTIFICATION_INFO, {
      ...params,
    });
    const { success } = res;
    return {
      success
    };
  }

  /**
   * 更新企业认证信息
   */
  updateCertificationInfo = async (params: any) => {
    const res: any = await httpClient.post(certification.UPDATE_CERTIFICATION_INFO, {
      ...params,
    });
    const { success } = res;
    return {
      success
    };
  }

  /**
   * 取消企业认证
   */
  cancelCertification = async (params: any) => {
    const res: any = await httpClient.post(certification.CANCEL_CERTIFICATION, {
      ...params,
    });
    const { success } = res;
    return {
      success
    };
  }

  updateState = (params: any) => {
    for (let [key, value] of Object.entries(params)) {
      this[key] = value;
    }
  };

  /**
   * 获取企业认证信息
   */
  companyInfo: any = null;
  getCompanyInfo = async () => {
    const res: any =
      await httpClient.get(
        certification.GET_COMPANY_INFO
      );
    if (res.success) {
      runInAction(() => {
        this.companyInfo = res.data;
        this.resetCompanyInfo = !res.data.provinceCode;
      });
    }
  }

  /**
   * 更新公司信息
   */
  updateCompanyInfo = async (params: any) => {
    const res: any = await httpClient.post(
      certification.UPDATE_COMPANY_INFO, {
      ...params
    }
    );
    const { success } = res;
    return {
      success
    };
  }

  /**
   * 添加新的企业
   */
  addCompnay = async (params: any) =>
    await httpClient.post(certification.ADD_COMPANY, {
      ...params,
      applicationType: 1,
    });

  /**
   * 更新state
   */
  // updateState = (params: CommonTypes) => {
  //   for (let [key, value] of Object.entries(params:any)) {
  //     this[key] = value;
  //   }
  // };
}

export default new certificationStore();
