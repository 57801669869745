/**
 * @Description: 发货弹窗
 * @author 点点
 * @date 2021/6/7
 * @time 15:45
 */

import { OrderShipFormLayout } from '@/layout/formLayout';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Modal, Radio, Space, Table, Typography, Select, message } from '@fle-ui/next';
import { useParams } from 'react-router-dom';
import ExpressStore from '@/stores/expressStore';
import { DetailGoodItemProp } from '@/stores/orderDetailStore';
import GoodDesc from '../../../component/GoodDesc';
import { WhiteSpace } from '@/components';
import OrderInfoItem from '../../../component/OrderInfoItem';
import { RadioChangeEvent } from '@fle-ui/next/lib/radio/interface';
import OrderDeliveryStore, { SendStatus, VoListItemProps } from '@/stores/orderDeliveryStore';
import _ from 'lodash';
import { replaceDoubleCharacters } from '@/tools/replaceStr';

const { Paragraph, Text, Title } = Typography;
const { Column } = Table;
const { Option } = Select;
const FormItem = Form.Item;

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: () => void;
  // 确认改价
  data: VoListItemProps;
};
const OrderShipModal: FC<Props> = (props: Props) => {
  const { id } = useParams<{ id: string }>();

  const { visible, onClose, data } = props;
  const { deliveryOrderId, province, city, district, town, address } = data;

  const { list } = ExpressStore.expressData;

  const [form] = Form.useForm();

  const [formValue, setFormValue] = useState([{ ...data }]);

  const [loading, setLoading] = useState(false);

  const [shipType, setShipType] = useState<1 | 2>(1);

  const [goodsStock, setGoodsStock] = useState(0);

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (data) => {
        const params = {
          deliveryOrderId,
          itemId: props.data.id,
          stockType: formValue[0].stockType,
          ...data
        };
        const res = await OrderDeliveryStore.ajaxCosign(params);
        if (res) {
          message.success('发货成功');
          onClose();
        } else {
          message.error('发货失败');
        }
        await OrderDeliveryStore.ajaxGetOrderInfo({ deliveryOrderId: id });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(false);
    if (!visible) {
      return;
    }
    getExpress(0)('');
    OrderDeliveryStore.ajaxGetGoodsStock({ itemId: data.id }).then((res) => setGoodsStock(res));
  }, [visible, data.id]);

  const getExpress = (delay: number) =>
    _.debounce(async (value = '') => {
      await ExpressStore.ajaxGetExpress({ expressName: value, pageNo: 1, pageSize: 30 });
    }, delay);

  return (
    <Modal
      width={1000}
      title={'发货操作'}
      open={visible}
      onCancel={onClose}
      confirmLoading={loading}
      destroyOnClose
      onOk={handleOk}
      okText={'确认发货'}
    >
      <Table dataSource={formValue} pagination={false} rowKey={'id'}>
        <Column<DetailGoodItemProp>
          title={'商品名称/规格'}
          render={(text, record) => <GoodDesc {...record} />}
        />

        <Column title={'数量'} dataIndex={'number'} />
        <Column
          title={'状态'}
          dataIndex={'sendStatus'}
          render={(sendStatus) => SendStatus[sendStatus]}
        />
        <Column title={'当前库存'} render={() => goodsStock} />

        <Column<VoListItemProps>
          width={200}
          title={'库存扣减'}
          render={(id, record, index) => {
            return (
              <Radio.Group
                value={record.stockType || 1}
                onChange={(e) => {
                  formValue[index].stockType = e.target.value;
                  setFormValue([...formValue]);
                }}
              >
                <Radio value={1}>优先扣减现货库存</Radio>
                <Radio value={2}>不扣减现货库存</Radio>
              </Radio.Group>
            );
          }}
        />
      </Table>

      <WhiteSpace />
      <Paragraph>
        <Text>
          本次发货已勾选
          <Text type={'danger'}>1</Text> 种商品，共
          <Text type={'danger'}>{[data].length}</Text>
          件，请选择发货快递公司、填写快递单号。
        </Text>
      </Paragraph>

      <Form
        {...OrderShipFormLayout}
        form={form}
        preserve={false}
        initialValues={{ expressType: shipType }}
      >
        <FormItem label={'发货方式'} name={'expressType'} rules={[{ required: true }]}>
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              setShipType(e.target.value);
              form.setFieldsValue({ expressNo: undefined });
            }}
            value={shipType}
          >
            <Radio value={1}>快递</Radio>
            <Radio value={2}>物流</Radio>
          </Radio.Group>
        </FormItem>

        {shipType === 1 && (
          <FormItem label={'快递公司'} required>
            <Space>
              <FormItem
                noStyle
                name={'expressCode'}
                rules={[{ required: true, message: '请选择快递公司' }]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="搜索查找快递公司"
                  optionFilterProp="children"
                  onFocus={() => getExpress(0)('')}
                  onSearch={getExpress(200)}
                >
                  {list.map((item) => (
                    <Option value={item.expressCode} key={item.expressCode}>
                      {item.expressName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <Text>支持1000+快递公司信息同步，请下拉/搜索快递公司。</Text>
            </Space>
          </FormItem>
        )}

        {shipType === 2 && (
          <FormItem label={'物流公司'} name={'expressName'} rules={[{ required: true }]}>
            <Input placeholder={'请输入物流公司'} />
          </FormItem>
        )}

        <FormItem
          label={'物流单号'}
          name={'expressNo'}
          rules={[{ required: shipType === 1 }]}
          normalize={replaceDoubleCharacters}
        >
          <Input placeholder={'请输入物流单号'} maxLength={32} />
        </FormItem>
      </Form>

      <Title level={5}>请核对用户收货地址：</Title>
      <Space direction={'vertical'}>
        <OrderInfoItem label={'收货人'} value={data.consignee} />
        <OrderInfoItem
          label={'收货地址'}
          value={[province, city, district, town, address].filter((item) => item).join(' ')}
        />
        <OrderInfoItem label={'手机'} value={data.mobile} />
      </Space>
    </Modal>
  );
};

export default observer(OrderShipModal);
