/**
 * @Description: 代发价
 * @author 点点
 * @date 2021/6/10
 * @time 09:12
 */
import { QuestionCircleFilled } from '@ant-design/icons';
import { Popover } from '@fle-ui/next';
import React from 'react';
import { GoodItemProps } from '@/types/store/goods';
import { CommonStore } from '@/stores';

const PoolMallAvailableStatus = (text: string, record: GoodItemProps) => {
  const content = CommonStore.accountInfo.isApiSupplier ? (
    <div>
      <p>商品因会造成亏本售卖被冻结，冻结的商品将会无法进行交易，</p>
      <p>销售价格大于协议价格且小于市场价格时会自动解冻。也可以通过修改商品销售价格进行解冻。</p>
    </div>
  ) : (
    <div>
      <p>商品因会造成亏本售卖被冻结，冻结的商品将会无法进行交易，</p>
      <p>销售价格大于成本价格且小于市场价格时会自动解冻。也可以通过修改商品销售价格进行解冻。</p>
    </div>
  );
  return (
    <>
      {record?.poolMallAvailableStatus == 10 && <div style={{ color: '#70B603' }}>有效</div>}
      {record?.poolMallAvailableStatus == -10 && (
        <div style={{ color: '#F59A23' }}>
          冻结&nbsp;
          <Popover content={content} trigger="hover">
            <QuestionCircleFilled />
          </Popover>
        </div>
      )}
      {record?.poolMallAvailableStatus == -20 && <div style={{ color: '#AB0090' }}>失效</div>}
    </>
  );
};

export default PoolMallAvailableStatus;
