import { Space, Typography } from '@fle-ui/next';
import React, { FC } from 'react';
import { DetailGoodItemProp } from '@/stores/orderDetailStore';

const { Paragraph } = Typography;

const GoodDesc: FC<DetailGoodItemProp & { paddingLeft?: number }> = (props) => {
  const { goodsImage, specList, goodsName, paddingLeft } = props;
  const data = JSON.parse(specList || '[]')
    .map((item: { name: string; value: string }) => `${item['name']}:${item['value']}`)
    .join('');
  return (
    <div style={{ textAlign: 'left' }}>
      <Space align={'start'}>
        <Space
          direction={'vertical'}
          align={'center'}
          style={{ color: '#262626', textAlign: 'left' }}
        >
          {/* 
          <Typography.Text style={{ width: 180 }} ellipsis={{ tooltip: goodsName }}>
            {goodsName}
          </Typography.Text> */}

          <Paragraph
            ellipsis={{
              rows: 3,
              tooltip: <>{goodsName}</>
            }}
            style={{ width: '100%', paddingTop: 14, color: '#262626' }}
          >
            {goodsName}
          </Paragraph>

          <div style={{ color: '#a9a9a9' }}>{data}</div>
        </Space>
      </Space>
    </div>
  );
};

export default GoodDesc;
