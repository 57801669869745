import { Button, Space } from '@fle-ui/next';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import OrderDetailStore, {
  DetailGoodItemProp,
  IsContainsTax,
  OrderStatus
} from '@/stores/orderDetailStore';
import ChangePriceModal from './ChangePriceModal';
import PriceChangListModal from './PriceChangListModal';

const OrderGoodPrice: FC<DetailGoodItemProp> = (props: DetailGoodItemProp) => {
  const { isContainsTax, taxRate, finalSalePrice, isChangePrice, skuId, NoPrice = false } = props;
  const { orderStatus } = OrderDetailStore.orderDetail;
  const [priceChangListModalVisible, setPriceChangListModalVisible] = useState(false);
  const [changPriceModalVisible, setChangPriceModalVisible] = useState(false);
  return (
    <>
      <Space direction={'vertical'}>
        <div>
          {(finalSalePrice / 100).toFixed(2)}（{IsContainsTax[isContainsTax]})
          {[OrderStatus.待卖家确认, OrderStatus.待买家付款].includes(orderStatus) && !NoPrice && (
            <Button type={'link'} onClick={() => setChangPriceModalVisible(true)}>
              修改
            </Button>
          )}
        </div>
        {IsContainsTax.含税 === isContainsTax && (
          <div>
            含税点：￥{(((finalSalePrice / 100 / (1 + taxRate / 100)) * taxRate) / 100).toFixed(2)}
          </div>
        )}
        {isChangePrice === 1 && !NoPrice && (
          <Button type={'link'} onClick={() => setPriceChangListModalVisible(true)}>
            修改记录
          </Button>
        )}
      </Space>

      <PriceChangListModal
        type={1}
        id={skuId}
        visible={priceChangListModalVisible}
        onClose={() => setPriceChangListModalVisible(false)}
      />

      <ChangePriceModal
        visible={changPriceModalVisible}
        onClose={() => setChangPriceModalVisible(false)}
        goodDetail={props}
      />
    </>
  );
};

export default observer(OrderGoodPrice);
