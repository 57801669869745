import React from "react";
import { GoodItemProps } from "@/types/store/goods";
import { Typography } from "@fle-ui/next";
import { getGoodsUrl } from "@/config/url";
import { CommonStore } from "@/stores";

const { Paragraph } = Typography;
const GoodInfo = (_: any, record: GoodItemProps) => {
  const styles = {
    subText: { fontSize: 12, color: "#999" },
  };

  return (
    <div style={{ display: "inline-flex", width: "100%", alignItems: "center" }}>
      <img width={80} src={record.mainImage} alt="" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: 80,
          marginLeft: 10,
        }}>
        {record.goodsStatus === 10 ? (
          <Paragraph
            ellipsis={{ rows: 2, tooltip: false }}
            style={{ color: "#2a8ff7", margin: 0, cursor: "pointer" }}
            onClick={() => window.open(`/home${getGoodsUrl("seller", "detail", record.goodsId)}`)}>
            {record.name}
          </Paragraph>
        ) : (
          <Paragraph ellipsis={{ rows: 2, tooltip: false }}>{record.name}</Paragraph>
        )}
        {[10, 40].indexOf(record.thirdType) >= 0 && (
          <span style={{ ...styles.subText }}>第三方 ID：{record.thirdSku}</span>
        )}
        <span style={{ ...styles.subText }}>商品 ID：{record.goodsId}</span>
        <span style={{ ...styles.subText }}>{record.categoryTreeName}</span>
      </div>
    </div>
  );
};

export default GoodInfo;
