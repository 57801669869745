export const JDApis = {
  POST_GET_JD_GOODS_LIST: '/merchant/supplier/jd/listSelectProduct', //获取京东商品列表
  POST_GET_NEW_JD_GOODS_LIST:'/merchant/jdchannel/jd/listSelectProduct',//新接口，获取京东商品列表
  POST_UPDATE_JD_GOODS_LIST_MARKET_PRICE: '/merchant/supplier/jd/syncMarketPrice', //同步京东商品市场价
  POST_UPDATE_NEW_JD_GOODS_LIST_MARKET_PRICE: '/merchant/jdchannel/jd/updateMarketPrice', //同步京东商品市场价,商品改造新接口
  GET_JD_CATEGORY: '/merchant/supplier/jd/listCategory', //京东商品分类
  GET_NEW_JD_CATEGORY: '/merchant/jdchannel/jd/listCategory', //京东商品分类，改造新接口
  GET_JD_LOCATION: '/merchant/supplier/jd/listArea', //京东商品地区
  GET_NEW_JD_LOCATION: '/merchant/jdchannel/jd/listArea', //京东商品地区，改造新接口
  POST_JD_BATCH_CHECK: '/elebuys/goods/standard/jdBatch', //批量挑选
};
