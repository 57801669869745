import React, { FC, useState } from 'react';
import {
  Space,
  Col,
  Button,
  Modal,
  Typography,
  message
} from '@fle-ui/next';
import { OrderItem } from '@/stores/afterSaleListStore';
import OrderListStore from '@/stores/afterSaleListStore';
import './OrderDeal.less';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import OrderDealOfAudit from './tableBar/OrderDealOfAudit';
import OrderDealOfDispose from './tableBar/OrderDealOfDispose';

type Props = {
  // 删格化布局
  span: number;
  reloadPage: () => void;
};
const { Text } = Typography;
const OrderState: FC<Props & OrderItem> = (props) => {
  const { span, afterSalesStatus, afterSalesNo, afterSalesType } = props;
  const { receivingAfterSales } = OrderListStore;
  const [isModalVisibleOfAudit, setIsModalVisibleOfAudit] = useState<boolean>(false);
  const [isModalVisibleOfDispose, setIsModalVisibleOfDispose] = useState<boolean>(false);

  const formStatusMap = new Map([
    [10, [11]],
    [20, [21, 22, 23, 24]],
    [30, [31, 32, 33]],
    [40, [41, 42, 43, 44, 45]],
    [50, [51, 52]],
    [60, [61, 62, 63]],
    [61, [61, 62, 63]],
    [90, [91, 92, 93, 94, 95, 96]]
  ]);

  const afterSalesFatherStatus = (fatherStatus: number, afterSalesStatus: number) => {
    console.log(fatherStatus, '1');
    console.log(afterSalesStatus, '2');
    
    if (
      formStatusMap.get(fatherStatus)?.some(function (x) {
        return x === afterSalesStatus;
      })
    ) {
      return fatherStatus;
    } else {
      // 子状态和父状态不匹配
      return false;
    }
  };

  // 审核
  const auditCallback = () => {
    setIsModalVisibleOfAudit(true);
  };

  // 确认收货
  const confirmReceiptCallback = () => {
    Modal.confirm({
      title: '确认收货',
      icon: <ExclamationCircleOutlined />,
      content: '是否已确认收到货物',
      async onOk() {
        const params: any = {
          afterSalesNo: props?.afterSalesNo
        };
        const res = await receivingAfterSales(params);
        if (res?.success) {
          message.success('收货成功');
          props.reloadPage();
        } else {
          message.error('收货失败');
        }
      },
      onCancel() {}
    });
  };
  // const receiptGoods = async (resolve: any, reject: any) => {
  //   const params: any = {
  //     afterSalesNo: props?.afterSalesNo
  //   };
  //   receivingAfterSales(params).then((res) => {
  //     resolve(); // 接口响应成功，返回 promise 时 resolve 后自动关闭询问弹窗
  //     if (res.success) {
  //       message.success('收货成功');
  //       props.reloadPage();
  //       // 做一些你需要的操作
  //     } else {
  //       message.error('收货失败');
  //     }
  //   });
  // };
  // 处理
  const disposeCallback = () => {
    setIsModalVisibleOfDispose(true);
  };
  console.log(afterSalesType,'afterSalesType');
  console.log(afterSalesStatus,'afterSalesStatus');
  
  return (
    <Col span={span} className="order-deal table_vertical_left">
      <Space align="start" size="large">
        {afterSalesType === 1 && afterSalesFatherStatus(10, afterSalesStatus) && (
          <Button style={{ margin: 0, padding: 0 }} type="link" onClick={auditCallback}>
            审核
          </Button>
        )}
         {afterSalesType === 5 && afterSalesFatherStatus(10, afterSalesStatus) && (
          <Button style={{ margin: 0, padding: 0 }} type="link" onClick={auditCallback}>
            审核
          </Button>
        )}
         {afterSalesType === 6 && afterSalesFatherStatus(10, afterSalesStatus) && (
          <Button style={{ margin: 0, padding: 0 }} type="link" onClick={auditCallback}>
            审核
          </Button>
        )}
        {afterSalesStatus === 22 && (
          <Button
            style={{ margin: 0, padding: 0 }}
            type="link"
            onClick={() => confirmReceiptCallback()}
          >
            确认收货
          </Button>
        )}
        {afterSalesFatherStatus(30, afterSalesStatus) && (
          <Button style={{ margin: 0, padding: 0 }} type="link" onClick={() => disposeCallback()}>
            处理
          </Button>
        )}
        <Button
          style={{ margin: 0, padding: 0 }}
          type="link"
          onClick={() => window.open('/home/shop_manage/order/after_sale/detail/' + afterSalesNo)}
        >
          详情
        </Button>
        {/* <span style={{ fontSize: 12, color: '#909090' }}>
          {statusMap.get(Number(afterSalesStatus))?.text}
        </span> */}
      </Space>

      <OrderDealOfAudit
        isModalVisibleOfAudit={isModalVisibleOfAudit}
        setIsModalVisibleOfAudit={setIsModalVisibleOfAudit}
        {...props}
      />

      <OrderDealOfDispose
        isModalVisibleOfDispose={isModalVisibleOfDispose}
        setIsModalVisibleOfDispose={setIsModalVisibleOfDispose}
        {...props}
      />
    </Col>
  );
};

export default OrderState;
