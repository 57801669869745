import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { Button, Card, Space, Table } from '@fle-ui/next';
import OrderDetailStore, { DeliveryStatus, DetailGoodItemProp } from '@/stores/orderDetailStore';
import { flatRowSpanMerge } from '@/tools/rowSpanMerge';
import Quantity from '../../component/Quantity';
import OrderStateBoard from '../../component/OrderStateBoard';
import { WhiteSpace } from '@/components';
import GoodDesc from '../../component/GoodDesc';
import OrderGoodPrice from '../../component/OrderGoodPrice';
import OrderGoodShipPrice from '../../component/OrderGoodShipPrice';
import OrderShipModal from '../../component/OrderShipModal';
import ShipOperate from '../../component/ShipOperate';
import OrderInfo from '../../component/OrderInfo';
import ShipStatus from '../../component/ShipStatus';
import { CommonStore } from '@/stores'

const { Column } = Table;

type Props = {};
const WaitSellerShip: FC<Props> = () => {
  const { accountInfo } = CommonStore

  const { groupList } = OrderDetailStore.orderDetail;

  const [orderShipModal, setOrderShipModal] = useState(false);

  return (
    <>
      <OrderStateBoard
        orderStatus={'等待卖家发货'}
        rightButton={
          <Space>
            {
              !accountInfo.isDGSS && !accountInfo.isJD && !accountInfo.isMAOCHAO && <Button type={'primary'} onClick={() => setOrderShipModal(true)}>
                发货
              </Button>
            }
          </Space>
        }
      />

      <WhiteSpace />

      <OrderInfo />

      <WhiteSpace />

      <Card>
        <div
          style={{
            fontSize: 16,
            color: '#262626',
            fontWeight: 600,
            paddingBottom: 8,
            marginBottom: 20,
            borderBottom: '1px solid #EEEEEE'
          }}
        >
          商品列表
        </div>
        <Table bordered dataSource={flatRowSpanMerge(groupList)} rowKey={'id'} pagination={false}>
          <Column<DetailGoodItemProp>
            title={'商品图片'}
            
            width={70}
            render={(text, record) => {return <img src={record.goodsImage} alt="" style={{width:68}}/> }}
          />
          <Column<DetailGoodItemProp>
            title={'商品名称'}
            
            width={270}
            render={(text, record) => <GoodDesc {...record} />}
          />
          <Column<DetailGoodItemProp>
            title={'数量'}
            
            dataIndex={'quantity'}
            render={(text, record) => <Quantity {...record} />}
          />
          <Column<DetailGoodItemProp>
            title={'单价'}
            
            render={(text, record) => <OrderGoodPrice {...record} NoPrice />}
          />
          <Column
            title={'小计'}
            
            dataIndex={'orderPrice'}
            render={(text) => '￥' + (text / 100).toFixed(2)}
          />
          <Column<DetailGoodItemProp>
            title={'运费'}
            
            render={(text, record: any) => {
              return {
                children: <OrderGoodShipPrice {...record} NoPrice />,
                props: { rowSpan: record.rowSpan }
              };
            }}
          />
          <Column
            title={'物流状态'}
            
            dataIndex={'deliveryStatus'}
            render={(text, record: any) => (
              <ShipStatus
                returnStatus={record.returnStatus}
                receiptStatus={record.receiptStatus}
                deliveryStatus={record.deliveryStatus}
              />
            )}
          />
          <Column<DetailGoodItemProp>
            width={180}
            title={'操作'}
            
            dataIndex={'id'}
            render={(id, record) => <ShipOperate {...record} />}
          />
        </Table>
      </Card>

      <OrderShipModal
        visible={orderShipModal}
        onClose={() => setOrderShipModal(false)}
        onOk={(data) => {
          setOrderShipModal(false);
          console.log(data);
        }}
      />
    </>
  );
};

export default observer(WaitSellerShip);
