/**
 * @Description: 集采批发订单交易成功
 * @author 点点
 * @date 2021/6/7
 * @time 17:39
 */

import React, { FC } from 'react';
import OrderDetailStore, { DetailGoodItemProp } from '@/stores/orderDetailStore';
import { flatRowSpanMerge } from '@/tools/rowSpanMerge';
import Quantity from '../../component/Quantity';
import OrderStateBoard from '../../component/OrderStateBoard';
import { Card, Table } from '@fle-ui/next';
import { WhiteSpace } from '../../../../../components';
import GoodDesc from '../../component/GoodDesc';
import OrderGoodPrice from '../../component/OrderGoodPrice';
import OrderGoodShipPrice from '../../component/OrderGoodShipPrice';
import ShipOperate from '../../component/ShipOperate';
import OrderInfo from '../../component/OrderInfo';
import ShipStatus from '../../component/ShipStatus';

const { Column } = Table;

type Props = {};
const OrderSuccess: FC<Props> = () => {
  const { groupList } = OrderDetailStore.orderDetail || {};

  return (
    <>
      <OrderStateBoard orderStatus={'交易成功'} />

      <WhiteSpace />

      <OrderInfo />

      <WhiteSpace />

      <Card>
        <div
          style={{
            fontSize: 16,
            color: '#262626',
            fontWeight: 600,
            paddingBottom: 8,
            marginBottom: 20,
            borderBottom: '1px solid #EEEEEE'
          }}
        >
          商品列表
        </div>
        <Table bordered dataSource={flatRowSpanMerge(groupList)} rowKey={'id'} pagination={false}>
          <Column<DetailGoodItemProp>
            title={'商品图片'}
            
            width={70}
            render={(text, record) => {return <img src={record.goodsImage} alt="" style={{width:68}}/> }}
          />
          {/* <Column<DetailGoodItemProp>
            title={'商品图片'}
            
            width={70}
            render={(text, record) => {return <img src={record.goodsImage} alt="" style={{width:68}}/> }}
          /> */}
          <Column<DetailGoodItemProp>
            title={'商品名称'}
            
            width={270}
            render={(text, record) => <GoodDesc {...record} />}
          />
          <Column<DetailGoodItemProp>
            title={'数量'}
            
            dataIndex={'quantity'}
            render={(text, record) => <Quantity {...record} />}
          />
          <Column<DetailGoodItemProp>
            title={'单价'}
            
            render={(text, record) => <OrderGoodPrice {...record} />}
          />
          <Column
            title={'小计'}
            
            dataIndex={'orderPrice'}
            render={(text) => '￥' + (text / 100).toFixed(2)}
          />
          <Column<DetailGoodItemProp>
            title={'运费'}
            
            render={(text, record) => {
              return {
                children: <OrderGoodShipPrice {...record} />,
                props: { rowSpan: record.rowSpan }
              };
            }}
          />
          <Column
            title={'物流状态'}
            
            dataIndex={'deliveryStatus'}
            render={(text, record: any) => (
              <ShipStatus
                returnStatus={record.returnStatus}
                receiptStatus={record.receiptStatus}
                deliveryStatus={record.deliveryStatus}
              />
            )}
          />
          <Column<DetailGoodItemProp>
            width={180}
            title={'操作'}
            
            dataIndex={'id'}
            render={(id, record) => <ShipOperate {...record} />}
          />
        </Table>
      </Card>
    </>
  );
};

export default OrderSuccess;
