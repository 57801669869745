import qs, { ParsedQs } from 'qs';
import { Modal } from '@fle-ui/next';
import moment from 'moment'

/**
 * 获取路径query参数
 */
export const getQuery = (): ParsedQs => qs.parse(window.location.search.substring(1));

// 去掉url上的token
export const removeUrlToken = () => {
  let str = window.location.origin + window.location.pathname;
  const obj = getQuery();
  const urlKeys = Object.keys(obj);
  let str2 = '';
  urlKeys.forEach((key, index) => {
    if (key !== 'token') {
      if (index === (urlKeys[0] === 'token' ? 1 : 0)) {
        str2 += `?${key}=${obj[key]}`;
      } else {
        str2 += `&${key}=${obj[key]}`;
      }
    }
  });
  str += str2;
  window.history.replaceState({}, '', str);
};

// 将对象转为数组
export function transformArrayToObj<T extends any[]>(array: T, key: string) {
  const record: Record<string, any> = {};
  if (!Array.isArray(array)) {
    return 
  }

  array.forEach(item => {
    record[item[key]] = item;
  });


  return record;
}

// 根据某一个节点的过滤条件，获取其在一个森林的路径。
export function getPathByKey(value: any, key: any, arr: any) {
  let temppath: any = [];
  try {
    const getNodePath = (node: any) => {
      // 这里可以自定义push的内容，而不是整个node,而且这里node也包含了children
      temppath.push(node);
      //找到符合条件的节点，通过throw终止掉递归
      if (node[key] === value) {
        throw 'GOT IT!';
      }
      if (node.children && node.children.length > 0) {
        for (let i = 0; i < node.children.length; i++) {
          getNodePath(node.children[i]);
        }
        //当前节点的子节点遍历完依旧没找到，则删除路径中的该节点
        temppath.pop();
      } else {
        //找到叶子节点时，删除路径当中的该叶子节点
        temppath.pop();
      }
    };

    for (let i = 0; i < arr.length; i++) {
      getNodePath(arr[i]);
    }
  } catch (e) {
    console.log(temppath);

    return temppath;
  }
}

/**
 * 运费模板区域列表格式化
 * @param data
 * @returns {*}
 */
export function formatArea(data: any) {
  return data.map((item: any) => {
    let arr = null;
    if (item.children) {
      arr = formatArea(item.children);
    }
    return {
      id: item.id,
      parentId: item.parentId,
      dictKey: item.dictKey,
      dictValue: item.dictValue,
      children: arr,
      check: false,
      freightIndex: 0
    };
  });
}

/**
 * 获取url参数
 * @returns {*|string}
 * @param name
 */
export function getQueryString(name: string) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

export interface CustomerItem {
  name: string;
  id: number;
  desc: string;
  count: number;
}

export type ValidateFunc = (
  rules: any,
  value: string,
  callback: Function
) => void;

// 校验客户数量是否为0
export const validateCustomersWhether0 = <K extends keyof CustomerItem>(
  idList: number[],
  customers: CustomerItem[],
  key: K
): boolean => {
  for (let i = 0; i < idList.length; i++) {
    const current = customers.find((item) => item.id === idList[i]);
    if (!current) {
      continue;
    }
    if (current[key] > 0) {
      return false;
    }
  }
  return true;
};

export const showConfirm = () => {
  Modal.error({
    title: '删除失败',
    content: '该等级下已有客户，无法删除！',
    okText: '确定'
  });
};

// 校验名字是否已存在
export const validateName = <T extends keyof CustomerItem>(
  customers: CustomerItem[],
  key: T,
  str: string
): ValidateFunc => {
  return (_: any, value: string, callback: any) => {
    const current = customers.find((item) => item[key] === value);
    if (current) {
      callback(new Error(`${str}名称已经存在`));
      return;
    }
    callback();
  };
};


/**
 * 金额格式化
 * @param data
 */
 type moneyType = number | string;

 interface ShowMoneyType {
     (data: moneyType): string;
 }
 export const showMoney: ShowMoneyType = (data?: moneyType) => (+(data || 0) / 100).toFixed(2);
 
 /**
  * 时间格式化
  * @param data
  * @param fmtType
  */
 type timeType = Date | number | string;
 
 interface ShowTimeType {
     (data: timeType, fmtType: string): string
 }
 export const showTime: ShowTimeType = (data, fmtType = 'YYYY-MM-DD HH:mm:ss') =>
     data ? moment(data).format(fmtType) : '';
 
 
 /**
  * 格式化时间
  * @param data
  * @param fmtType
  * @returns {string}
  */
 export function formatTime(data: any, fmtType = 'YYYY-MM-DD HH:mm:ss') {
   return data ? moment(data).format(fmtType) : ''
 }