import React, { FC } from 'react';
import { DetailGoodItemProp } from '@/stores/orderDetailStore';
import { getUnit } from '@/pages/shopManage/order/component/GetUnit';

const Quantity: FC<DetailGoodItemProp> = (props) => {
  const { boxNumber, measureUnit, quantity } = props;
  const realNumber = measureUnit === 1 ? quantity : Math.ceil(quantity / (boxNumber || 1));
  return <>{realNumber + getUnit(props)}</>;
};

export default Quantity;
