import useUrlState from '@ahooksjs/use-url-state';
import React, { FC } from 'react';
import { Button } from '@fle-ui/next';
import OrderDeliveryStore from '@/stores/orderDeliveryStore';
import { useParams } from 'react-router-dom';

type Props = {};
const DownLoadButton: FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const [state] = useUrlState();

  return (
    <Button
      type={'primary'}
      ghost
      onClick={() => OrderDeliveryStore.ajaxDownload({ deliveryOrderId: id, ...state })}
    >
      下载明细
    </Button>
  );
};

export default DownLoadButton;
