import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Modal, Upload, Button, message, Alert } from '@fle-ui/next';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'rc-upload/lib/interface';
import { useParams } from 'react-router-dom';
import { CommonStore } from '@/stores';
import OrderDetailStore from '@/stores/orderDetailStore';
import OrderOperateStore, { ConfirmReceiptParams } from '@/stores/orderOperateStore';
import { WhiteSpace } from '@/components';
import { delay } from '@/tools/delay';

const FormItem = Form.Item;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};

const fileTypeMap = new Map([
  ['image/jpeg', true],
  ['image/png', true],
  ['image/jpg', true],
  ['image/gif', true],
  ['image/bmp', true]
]);

type Props = {
  visible: boolean;
  onCancel: () => void;
  orderNo?: string;
};

const ConfirmOrderModal: FC<Props> = (props: Props) => {
  const { id } = useParams<{ id: string }>();

  const { visible, onCancel } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState('');

  useEffect(() => {
    return () => {
      setFile('');
      setLoading(false);
    };
  }, [visible]);

  const _beforeUpload = async (file: RcFile) => {
    const isAllowFiletype = fileTypeMap.get(file.type);
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isAllowFiletype) {
      message.error('请上传.jpg .jpeg .bmp .gif .png格式的图片！');
    } else if (!isLt5M) {
      message.error('图片大小不得超过5MB！');
    } else {
      setLoading(true);
      const formdata = new FormData(); // FormData 对象
      formdata.append('file', file);
      formdata.append('directory', '/resource/goods/');
      const data: any = await CommonStore.postUploadFile(formdata);
      setFile(data.data);
      setLoading(false);
    }

    return false;
  };
  const uploadProps = {
    name: 'file',
    showUploadList: false,
    accept: '.jpg,.jpeg,.png,.bmp,.gif',
    async beforeUpload(file: RcFile) {
      await _beforeUpload(file);
      return false;
    },
    multiple: false,
    maxCount: 1
  };

  const onHandleOk = () => {
    form
      .validateFields()
      .then(async (data) => {
        setLoading(true);

        const params: ConfirmReceiptParams = {
          ...data,
          receivePaymentImage: file,
          orderNo: props.orderNo || id
        };
        OrderOperateStore.ajaxConfirmReceipt(params)
          .then(async (res) => {
            console.log(res);
            if (res) {
              message.success('确认收款成功');
              await delay();
              onCancel();
            } else {
              message.error('确认收款失败');
            }
            !props.orderNo &&
              OrderDetailStore.ajaxGetOrderDetail({ orderNo: id }).finally(() => setLoading(false));
          })
          .finally(() => setLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      title="提示"
      open={visible}
      onOk={onHandleOk}
      onCancel={onCancel}
      confirmLoading={loading}
      destroyOnClose
    >
      <Alert message="确认已收到买家对公汇款的款项？" type="warning" showIcon />

      <WhiteSpace />

      <Form {...layout} preserve={false} form={form}>
        <FormItem
          label={'收款备注'}
          name={'receivePaymentRemark'}
          rules={[{ type: 'string', required: true }]}
        >
          <Input.TextArea placeholder={'可备注如银行流水/对方户号等'} maxLength={50} showCount />
        </FormItem>
        <FormItem label={'收款凭证'}>
          <Upload {...uploadProps}>
            {file ? (
              <img width={100} src={file} alt="" />
            ) : (
              <Button icon={<UploadOutlined />}>上传收款截图</Button>
            )}
          </Upload>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default observer(ConfirmOrderModal);
