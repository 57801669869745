import React, { FC, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { useMount } from "ahooks";
import { Button, Typography } from "@fle-ui/next";
import { observer } from "mobx-react";
import { PageContainer } from "@fle-ui/pro-layout";
import { useHistory } from "react-router-dom";
import ProCard from "@fle-ui/pro-card";
import FinanceStore from "@/stores/financeStore";
import { RevenueCard, NavigationCard } from "./componts";

import "./index.less";

const { Text, Title } = Typography;

const styles: { [key: string]: React.CSSProperties } = {
  firstCard: {
    height: 206,
    background: "#fff url(https://oss.elebuys.com/resource/fle-ui/202207291010420000201742.png)",
    backgroundPosition: "right bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  miniIcon: {
    fontSize: 16,
  },
};

const Manage: FC = () => {
  const { paymentData, shopAccountStatistic } = FinanceStore;
  const { canWithdraw, revenue, willIncome } = shopAccountStatistic;

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<"daily" | "monthly">("daily");
  const [activeKey, setActiveKey] = useState<string>("1");

  useMount(() => {
    setLoading(true);
    Promise.all([FinanceStore.ajaxGetStatistics(), FinanceStore.ajaxGetPayment()]).finally(() => setLoading(false));
  });

  return (
    <PageContainer ghost loading={loading} spinConfig={{ delay: 200, size: "large" }} className="finace-manager-wrap">
      <ProCard gutter={20} ghost wrap>
        <ProCard colSpan={6} ghost>
          <RevenueCard
            style={{ ...styles.firstCard }}
            itemStyle={{ background: "none", margin: "10px 0 0 -20px" }}
            list={[
              {
                value: revenue,
                name: "总营收",
                contentDirection: "vertical",
                nameTooltip: "买家下单使用飞象线上支付方式，该资金由飞象代收，待买家确认收货后，即可进行提现。",
                button: (
                  <Button type="primary" ghost onClick={() => history.push("/finance/normal/settlement")}>
                    对公收款管理
                  </Button>
                ),
              },
            ]}
          />
        </ProCard>

        <ProCard colSpan={18} ghost>
          <RevenueCard
            gutter={20}
            title="飞象结算账户"
            tooltip="买家下单使用飞象线上支付方式，该资金由飞象代收，待买家确认收货后，即可进行提现。"
            headerBordered
            style={{ height: 206 }}
            itemStyle={{ background: "#FAFAFA" }}
            list={[
              {
                value: canWithdraw,
                icon: "icon-kejiesuanzhuanghu",
                iconColor: "#2b69ff",
                colSpan: 12,
                name: "可结算账户",
                nameTooltip: "买家已确认收货，资金将进入可结算金额。可立即申请提现至对公账户。",
                button: (
                  <Button type="primary" ghost onClick={() => history.push("/finance/normal/manage/can_settlement")}>
                    提现/管理
                  </Button>
                ),
              },
              {
                value: willIncome,
                icon: "icon-jijiangdaozhangzhuanghu",
                iconColor: "#35c626",
                colSpan: 12,
                name: "即将到账金额",
              },
            ]}
          />
        </ProCard>

        <ProCard ghost className="finace-tab-wrap">
          <Button type="link" className="finace-tab-btn" onClick={() => history.push("/finance/normal/trans_detail")}>
            查看更多
            <RightOutlined />
          </Button>
          <ProCard
            style={{ marginTop: 20 }}
            tabs={{
              activeKey,
              onChange: (key) => setActiveKey(key),
            }}>
            <ProCard.TabPane key="1" tab="日收支">
              <RevenueCard
                ghost
                gutter={20}
                itemStyle={{ background: "#FAFAFA" }}
                list={[
                  {
                    value: paymentData.dailyIncome,
                    icon: "icon-shouruzhogji",
                    iconColor: "#37c628",
                    colSpan: 8,
                    valprefix: "+ ¥",
                    name: "收入总计",
                    router: "/finance/normal/trans_detail?flag=0"
                  },
                  {
                    value: paymentData.dailyExpense,
                    icon: "icon-zhichuzhongji",
                    iconColor: "#ff702b",
                    colSpan: 8,
                    valprefix: "- ¥",
                    name: "支出总计",
                    router: "/finance/normal/trans_detail?flag=1"
                  },
                  {
                    value: 0,
                    valtype: "number",
                    icon: "icon-kesuoqufapiao",
                    iconColor: "#2b69ff",
                    colSpan: 8,
                    name: "可索取发票",
                  },
                ]}
              />
            </ProCard.TabPane>
            <ProCard.TabPane key="2" tab="月收支">
              <RevenueCard
                ghost
                gutter={20}
                itemStyle={{ background: "#FAFAFA" }}
                list={[
                  {
                    value: paymentData.monthlyIncome,
                    icon: "icon-shouruzhogji",
                    iconColor: "#37c628",
                    colSpan: 8,
                    valprefix: "+ ¥",
                    name: "收入总计",
                  },
                  {
                    value: paymentData.monthlyExpense,
                    icon: "icon-zhichuzhongji",
                    iconColor: "#ff702b",
                    colSpan: 8,
                    valprefix: "- ¥",
                    name: "支出总计",
                  },
                  {
                    value: 0,
                    valtype: "number",
                    icon: "icon-kesuoqufapiao",
                    iconColor: "#2b69ff",
                    colSpan: 8,
                    name: "可索取发票",
                  },
                ]}
              />
            </ProCard.TabPane>
          </ProCard>
        </ProCard>

        <ProCard style={{ marginTop: 20 }} gutter={20} ghost>
          <ProCard colSpan={8}>
            <NavigationCard
              icon="icon-dadadagsg"
              title="资金流水"
              subtitle="纵览全局"
              list={[{ name: "交易明细", href: "/finance/normal/trans_detail" }]}
            />
          </ProCard>
          <ProCard colSpan={8}>
            <NavigationCard
              icon="icon-dadada"
              title="发票管理"
              subtitle="纵览全局"
              list={[
                { name: "待开发票", href: "", disabled: true },
                { name: "开票记录", href: "", disabled: true },
              ]}
            />
          </ProCard>
          <ProCard colSpan={8}>
            <NavigationCard
              icon="icon-deqeqeqe"
              title="设置"
              subtitle="纵览全局"
              list={[{ name: "对公账户管理", href: "/finance/normal/settlement" }]}
            />
          </ProCard>
        </ProCard>
      </ProCard>
    </PageContainer>
  );
};

export default observer(Manage);
