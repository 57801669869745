import React from 'react';
import { Icon } from '@/components';
export interface OrderItem {
  // 订单创建时间
  createTime: string;
  // 下单账号
  createrId: string;
  goods: any[];
  // 订单号
  orderId: string;
  orderStatus: 1 | 2 | 3 | 4 | 5;
  shopId: string;
  shopName: string;
  // 创建人姓名
  userName: string;
  // 订单确认时间
  confirmTime: string;
  // 发货单笔数
  deliveryCount: number;

  [key: string]: any;
}

export enum OrderCloseType {
  买家超时未支付 = 1,
  卖家主动取消,
  买家主动取消
}

export enum ThirdType {
  平台 = 0,
  京东 = 10,
  苏宁 = 20,
  网易严选 = 30
}

export enum OrderStatus {
  待付款 = 1,
  待发货,
  待收货,
  交易成功,
  交易关闭
}

export enum PaymentMethod {
  余额支付 = 12,
  微信支付 = 32
}

export enum DeductionOfInventory {
  非现货采购,
  现货采购
}

export enum DeliveryStatus {
  默认状态,
  待发货,
  已发货
}

export enum ReceiptStatus {
  默认状态,
  待收货,
  已收货
}

export enum IsContainsTax {
  不含税,
  含税
}

/**
 * 解决方南
 */
export const resolveMap: Map<number, string> = new Map([
  [1, '数字卡券解决方案'],
  [2, '数字福利解决方案'],
  [3, '数字银企解决方案'],
  [4, '数字车企解决方案'],
  [5, '供应链api开放应用']
]);

/**
 * 售后状态
 */
export const statusMap: Map<
  number,
  { text: string; parentText: string; color: string; icon: any }
> = new Map([
  [
    11,
    {
      text: '售后申请提交成功',
      parentText: '等待审核',
      color: '#FF712C',
      icon: <Icon type="icon-daishenhe" />
    }
  ],

  [
    21,
    {
      text: '售后申请审核已完成',
      parentText: '等待商家收货',
      color: '#FF712C',
      icon: <Icon type="icon-dengdaimaijiaquerenshouhuo" />
    }
  ],
  [
    22,
    {
      text: '寄件运单提交成功',
      parentText: '等待商家收货',
      color: '#FF712C',
      icon: <Icon type="icon-dengdaimaijiaquerenshouhuo" />
    }
  ],
  [
    23,
    {
      text: '售后申请审核已完成，等待商家上门取件',
      parentText: '等待商家收货',
      color: '#FF712C',
      icon: <Icon type="icon-dengdaimaijiaquerenshouhuo" />
    }
  ],
  [
    24,
    {
      text: '售后申请审核已完成，等待买家送货至站点',
      parentText: '等待商家收货',
      color: '#FF712C',
      icon: <Icon type="icon-dengdaimaijiaquerenshouhuo" />
    }
  ],

  [
    31,
    {
      text: '商家已经收货完成，正在处理中',
      parentText: '等待商家处理',
      color: '#FF712C',
      icon: <Icon type="icon-daichuli" />
    }
  ],
  [
    32,
    {
      text: '商家已经完成取件，正在处理中',
      parentText: '等待商家处理',
      color: '#FF712C',
      icon: <Icon type="icon-daichuli" />
    }
  ],
  [
    33,
    {
      text: '站点已完成收货，正在处理中',
      parentText: '等待商家处理',
      color: '#FF712C',
      icon: <Icon type="icon-daichuli" />
    }
  ],

  [
    41,
    {
      text: '商品已处理完成，请等待商家退款',
      parentText: '处理完成',
      color: '#36C626',
      icon: <Icon type="icon-chuliwancheng" />
    }
  ],
  [
    42,
    {
      text: '商品退款审核未通过，商品将会发送到返件地址',
      parentText: '处理完成',
      color: '#36C626',
      icon: <Icon type="icon-chuliwancheng" />
    }
  ],
  [
    43,
    {
      text: '商品处理完成，商品将会发送到返件地址',
      parentText: '处理完成',
      color: '#36C626',
      icon: <Icon type="icon-chuliwancheng" />
    }
  ],
  [
    44,
    {
      text: '等待象铺卖家退款',
      parentText: '处理完成',
      color: '#36C626',
      icon: <Icon type="icon-chuliwancheng" />
    }
  ],
  [
    45,
    {
      text: '等待买家会员退款',
      parentText: '处理完成',
      color: '#36C626',
      icon: <Icon type="icon-chuliwancheng" />
    }
  ],

  [
    51,
    {
      text: '商家已发货',
      parentText: '等待用户确认',
      color: '#FF712C',
      icon: <Icon type="icon-daiyonghuqueren" />
    }
  ],
  [
    52,
    {
      text: '商品退款审核未通过，商家已发货，收到商品后请尽快确认收货，在发出7日之后将会自动确认收货',
      parentText: '等待用户确认',
      color: '#FF712C',
      icon: <Icon type="icon-daiyonghuqueren" />
    }
  ],

  [
    61,
    {
      text: '退款已到账',
      parentText: '已完成',
      color: '#36C626',
      icon: <Icon type="icon-jiaoyichenggong" />
    }
  ],
  [
    62,
    {
      text: '商品已签收',
      parentText: '已完成',
      color: '#36C626',
      icon: <Icon type="icon-jiaoyichenggong" />
    }
  ],
  [
    63,
    {
      text: '商品退款审核未通过，已签收返件商品',
      parentText: '已完成',
      color: '#36C626',
      icon: <Icon type="icon-jiaoyichenggong" />
    }
  ],

  [
    91,
    {
      text: '订单已关闭，售后申请审核未通过',
      parentText: '已取消',
      color: '#F42D2D',
      icon: <Icon type="icon-shouhouyiquxiao" />
    }
  ],
  [
    92,
    {
      text: '订单已关闭，未在规定时间内发货',
      parentText: '已取消',
      color: '#F42D2D',
      icon: <Icon type="icon-shouhouyiquxiao" />
    }
  ],
  [
    93,
    {
      text: '订单已关闭，商家未在规定时间内取件',
      parentText: '已取消',
      color: '#F42D2D',
      icon: <Icon type="icon-shouhouyiquxiao" />
    }
  ],
  [
    94,
    {
      text: '订单已关闭，未在规定时间内送货至指定地点',
      parentText: '已取消',
      color: '#F42D2D',
      icon: <Icon type="icon-shouhouyiquxiao" />
    }
  ],
  [
    95,
    {
      text: '用户将售后工单关闭',
      parentText: '已取消',
      color: '#F42D2D',
      icon: <Icon type="icon-shouhouyiquxiao" />
    }
  ],
  [
    96,
    {
      text: '卖家拒绝退款，售后已关闭',
      parentText: '已取消',
      color: '#F42D2D',
      icon: <Icon type="icon-shouhouyiquxiao" />
    }
  ]
]);
export interface DetailGoodItemProp extends Omit<DetailGroupDetailGoodItemProp, 'goodsList'> {
  // 商品图片地址
  goodsImage: string;
  // 商品名称
  goodsName: string;
  // 商品 id
  id: string;
  // skuId
  skuId: string;
  //0、不含税，1、含税
  isContainsTax: 0 | 1;
  // 商品税率(不含税为0)
  taxRate: number;
  // 商品规格
  specList: string;
  // 购买总数
  quantity: number;
  // 0、不扣减库存 非现货，1、扣减库存 现货
  isDeductionOfInventory: 0 | 1;
  // 最终销售价 -> 小计
  finalSalePrice: number;
  // 是否改价过：0、未改价，1、已改价
  isChangePrice: 0 | 1;
  // 订单金额（最终销售价*购买数量）
  orderPrice: number;
  // 收货状态：0-默认状态，1-待收货，2-已收货
  receiptStatus: 0 | 1 | 2;
  // 发货状态：0-默认状态，1-待发货，2-已发货
  deliveryStatus: 0 | 1 | 2;
  // 发货方式：1、快递，2、物流
  deliveryMethod: 1 | 2;
  // 可发货数
  freeNum: number;
  // 已发货数
  deliveryNum: number;
  // 计量单位 1按个销售 2按箱销售
  measureUnit: 1 | 2;
  // 箱规
  boxNumber: number;
  // 单位
  unit: string;
  // orderNo
  orderNo: string;
  //
  shopId: '';
  //orderGoodsFreightId
  orderGoodsFreightId: string;
  // 快递号
  expressNo: string;
  expressCompany: string;

  freightRules: '';
  // 运费类型：1、运费模板运费，2、运费说明，3、卖家自定运费（卖家修改运费）
  freightType: 1 | 2 | 3 | null;
  // 运费说明
  freightDescription: string;

  // 0平台 10京东 20苏宁 30网易严选
  thirdType: 0 | 10 | 20 | 30;
  // 是否自有商品 1是 0否
  isSelf: 1 | 0;

  rowSpan?: number;
  // 运费 id  为 null 说名未确认运费
  freightId?: string | null;
  NoPrice: boolean;
}

export type DetailGroupDetailGoodItemProp = {
  goodsList: DetailGoodItemProp[];
  // 最终运费
  finalFreightPrice: number;
  // 运费 ID
  id: string;
  //运费 是否改价过：0、未改价，1、已改价
  isChange: 0 | 1;

  freight: number;
};

export type OrderDetail = {
  orderNo: string | number;
  orderStatus: string | number;
  receiveInfo: {
    name: string | number;
    mobile: string | number;
    address: string;
    province: string;
    city: string;
    district: string;
    town: string | null;
  };
  orderGoods: [
    {
      skuId: string | number;
      goodsName: string;
      salePrice: string | number;
      quantity: string | number;
      specList: string | number;
      goodsImage: string;
      isDelivery: string | number;
      deliveryCode: string | number;
      thirdType: string | number;
      isSelf: string | number;
    }
  ];
  createTime: string | number;
  payMethod: string | number | null;
  payBillId: string | number | null;
  payTime: string | number | null;
  remark: string | number | null;
  account: string | number;
  actualAccount: string | number;
  actualFreight: string | number;
  couponPayment: string | number;
  scoreCard: string | number;
  discountPayment: string | number;
  siteId: string | number;
  siteName: string;
  couponId: string | number | null;
  couponName: string | number | null;
  closeReason: string | number | null;
  extra: string | number | null;
};

export interface OrderCardInfos {
  couponId: string | number | null;
  couponName: string | number | null;
  couponPayment: number | null;
}
