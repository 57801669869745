import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import Account from './account';
import Certification from './certification';
import CompanyInfo from './companyInfo';

type Props = {};
const CompanyModule: FC<Props> = (props: Props) => {
  return (
    <Switch>
      <Route path="/settings/company/account" exact component={Account} />
      <Route path="/settings/company/account/certification" exact component={Certification} />
      <Route
        path="/settings/company/companyInfo"
        exact
        component={CompanyInfo}
      />
    </Switch>
  );
};

export default CompanyModule;
