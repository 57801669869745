import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { Button, Card, Space, Table } from '@fle-ui/next';
import { useParams } from 'react-router-dom';
import OrderDetailStore, { DetailGoodItemProp } from '@/stores/orderDetailStore';
import { flatRowSpanMerge } from '@/tools/rowSpanMerge';
import Quantity from '../../component/Quantity';
import OrderStateBoard from '../../component/OrderStateBoard';
import GoodDesc from '../../component/GoodDesc';
import OrderGoodPrice from '../../component/OrderGoodPrice';
import OrderGoodShipPrice from '../../component/OrderGoodShipPrice';
import { WhiteSpace } from '@/components';
import ConfirmOrderShipModal from '../../component/ConfirmOrderShipModal';
import CancelOrderModal from '../../component/CancelOrderModal';
import { CancelOrderReason } from '@/tools/selectOption';
import PurchaseType from '../../component/PurchaseType';
import { getTimeDiff } from '@/tools/getTimeDiff';
import OrderInfo from '../../component/OrderInfo';

const { Column } = Table;

type Props = {};
const WaitConfirmOrder: FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const { createTime, groupList } = OrderDetailStore.orderDetail;
  const [confirmOrderShipModalVisible, setConfirmOrderShipModalVisible] = useState(false);
  const [CancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);
  return (
    <>
      <OrderStateBoard
        orderStatus={'等待卖家确认订单'}
        orderInfo={getTimeDiff(createTime, 3) + '后卖家未确认，订单将自动取消'}
        rightButton={
          <Space>
            <Button onClick={() => setCancelOrderModalVisible(true)}>取消订单</Button>

            <Button type={'primary'} onClick={() => setConfirmOrderShipModalVisible(true)}>
              确认订单
            </Button>
          </Space>
        }
      />

      <WhiteSpace />

      <OrderInfo />

      <WhiteSpace />

      <Card>
        <div
          style={{
            fontSize: 16,
            color: '#262626',
            fontWeight: 600,
            paddingBottom: 8,
            marginBottom: 20,
            borderBottom: '1px solid #EEEEEE'
          }}
        >
          商品列表
        </div>
        <Table bordered dataSource={flatRowSpanMerge(groupList)} rowKey={'id'} pagination={false}>
          <Column<DetailGoodItemProp>
            title={'商品图片'}
            
            width={70}
            render={(text, record) => {return <img src={record.goodsImage} alt="" style={{width:68}}/> }}
          />
          <Column<DetailGoodItemProp>
            title={'商品名称'}
            
            width={270}
            render={(text, record) => <GoodDesc {...record} />}
          />

          <Column
            title={'采购类型'}
            
            dataIndex={'isDeductionOfInventory'}
            render={(text) => <PurchaseType type={text} />}
          />
          <Column<DetailGoodItemProp>
            title={'数量'}
            
            dataIndex={'quantity'}
            render={(text, record) => <Quantity {...record} />}
          />
          <Column<DetailGoodItemProp>
            title={'单价'}
            
            render={(text, record) => <OrderGoodPrice {...record} />}
          />
          <Column
            title={'小计'}
            
            dataIndex={'orderPrice'}
            render={(text) => '￥' + (text / 100).toFixed(2)}
          />
          <Column<DetailGoodItemProp>
            title={'运费'}
            
            render={(text, record) => {
              return {
                children: <OrderGoodShipPrice {...record} />,
                props: { rowSpan: record.rowSpan }
              };
            }}
          />
          <Column width={180} title={'操作'}  />
        </Table>
      </Card>

      <ConfirmOrderShipModal
        visible={confirmOrderShipModalVisible}
        onClose={() => setConfirmOrderShipModalVisible(false)}
        onOk={async () => {
          await OrderDetailStore.ajaxGetOrderDetail({ orderNo: id });
        }}
        id={id}
      />

      <CancelOrderModal
        cancelOrderReason={CancelOrderReason}
        visible={CancelOrderModalVisible}
        onCancel={() => setCancelOrderModalVisible(false)}
      />
    </>
  );
};

export default observer(WaitConfirmOrder);
