/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-24 14:04:36
 */
import {
  makeAutoObservable,
  configure,
  runInAction,
  toJS,
} from "mobx";
import { useAccount } from "@/api/setting";
import httpClient from "@/tools/httpClient";

configure({ enforceActions: "observed" });

class accountStore {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }

  account = {
    account: "",
    companyId: "",
    companyName: "",
    departmentId: "",
    departmentName: "",
    email: "",
    id: "",
    mobile: "",
    name: "",
    supplierFlag: false,
  };

  get userInfo() {
    return toJS(this.account);
  }
  set userInfo(value) {
    this.account = value;
  }

  /**
   * 获取用户信息
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  getUserAccount = async () => {
    const data: any = await httpClient.get(
      useAccount.POST_GET_USER_ACCOUNT
    );
    if (data?.success) {
      this.userInfo = data.data;
      // this.getCompanyList({ mobile: data.data.mobile, isPartner: true });
      return data.data;
      // 修改主题色
      // this.userInfo.plusFlag &&
      //   window.less
      //     .modifyVars({
      //       "@primary-color": "#CD9450",
      //     })
      //     .then(() => {
      //       console.log("change theme success");
      //     })
      //     .catch((error) => {
      //     });
    }
  }

  /**
   * 获取该手机号下所有公司列表
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  companyList = [];
  getCompanyList = async (params: any) => {
    const data: any = await httpClient.get(
      useAccount.GET_COMPANY_LIST,
      {
        params,
      }
    );

    runInAction(() => {
      if (data?.success && data?.data && data?.data.companyNameList)
        this.companyList = data?.data.companyNameList;
    });
  }

  /**
   * 切换企业
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */

  changeCompany = async (params: any) => {
    const data: any = await httpClient.post(useAccount.CHANGE_COMPAY, {
      ...params,
    });
    if (data?.success) {
      sessionStorage.setItem("historyRouter", JSON.stringify([]));
      window.location.href = `/admin/purchase/home?token=${data?.data.authorityToken}`;
    }
  }

  /**
   * 修改密码
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  editPassword = async (params: any) => {
    const data = await httpClient.post(useAccount.POST_EDIT_PASSWORD, {
      ...params,
    });
    return data;
  }

  /**
   * 修改手机号
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  editMobile = async (params: any) => {
    const data = await httpClient.post(useAccount.POST_EDIT_MOBILE, {
      ...params,
    });
    return data;
  }

  /**
   * 发送验证码
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  sendCode = async (params: any) => {
    const data = await httpClient.post(useAccount.POST_SEND_CODE, {
      ...params,
    });
    return data;
  }

  /**
   * 校验短信验证码（校验后验证码仍有效）
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  checkSmsCode = async (params: any) => {
    const data = await httpClient.post(useAccount.CHECK_SMS_CODE, {
      ...params,
    });
    return data;
  }

  // 获取商家端口列表
  getList = async (params: any) => {
    return await httpClient.post(useAccount.GET_LIST, { ...params });
  }

  // 新增商品标签
  updataShopFlag = async (params: any) => {
    return await httpClient.post(useAccount.UPDATA_SHOP_FLAG, { ...params });
  }

  // 编辑商家标签
  editShopFlag = async (params: any) => {
    return await httpClient.post(useAccount.EDIT_SHOP_FLAG, { ...params });
  }

  //删除商家标签
  deleteShopFlag = async (params: any) => {
    return await httpClient.get(useAccount.DELETE_SHOP_FLAG, { params });
  }
  /**
   * 更新state
   */
  updateState = (params: any) => {
    for (let [key, value] of Object.entries(params)) {
      this[key] = value;
    }
  };
}

export default new accountStore();
