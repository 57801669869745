import React, { FC } from 'react';
import { Space, Col } from '@fle-ui/next';
import { OrderItem } from '@/stores/orderListStore';
import { getTimeDiff } from '@/tools/getTimeDiff';

type Props = {
  // 删格化布局
  span: number;
};
const OrderState: FC<Props & OrderItem> = (props) => {
  const { span, confirmTime, orderStatus, createTime } = props;

  const PurWholesaleOrderStates = [
    { value: '1', label: '等待卖家确认订单', color: '#FF712C' },
    { value: '2', label: '等待买家付款', color: '#FF712C' },
    { value: '3', label: '等待卖家发货', color: '#FF712C' },
    { value: '4', label: '等待买家确认收货', color: '#FF712C' },
    { value: '5', label: '交易成功', color: '#36C626' },
    { value: '6', label: '交易关闭', color: '#F42D2D' }
  ];
  return (
    <Col
      span={span}
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Space direction={'vertical'} align="start">
        <span>
          <span style={{ color: PurWholesaleOrderStates[orderStatus - 1]?.color }}>
            {PurWholesaleOrderStates[orderStatus - 1]?.label}
          </span>
          {orderStatus === 10 && <span style={{ color: '#36C626' }}>交易成功</span>}
          {orderStatus === 20 && <span style={{ color: '#F42D2D' }}>交易关闭</span>}
        </span>
        {[1, 2].includes(orderStatus) && (
          <span style={{ fontSize: 14, color: '#a9a9a9' }}>
            {orderStatus === 1 && <>{getTimeDiff(createTime, 3)}后卖家未确认</>}
            {orderStatus === 2 && <>{getTimeDiff(confirmTime || createTime, 3)}后买家未付款 </>}
            , <br />
            订单将自动取消
          </span>
        )}
      </Space>
    </Col>
  );
};

export default OrderState;
