import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Form, Input, InputNumber, message, Modal } from '@fle-ui/next';
import { useDebounceEffect } from 'ahooks';
import OrderDetailStore, { DetailGoodItemProp, IsContainsTax } from '@/stores/orderDetailStore';
import OrderOperateStore from '@/stores/orderOperateStore';
import PriceConfig from '@/tools/priceConfig';

const FormItem = Form.Item;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: () => void;
  goodDetail: DetailGoodItemProp;
};
const ChangePriceModal: FC<Props> = (props: Props) => {
  const { visible, onClose, goodDetail } = props;
  const { finalSalePrice, isContainsTax, taxRate, skuId, orderNo } = goodDetail;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({ changePrice: 0, changeReason: '' });
  const [priceError, setPriceError] = useState(true);
  const [validateStatus, setValidateStatus] = useState({});

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (Object.keys(changedValues).includes('changeReason')) {
      return;
    }
    setLoading(true);
    setFormValue(allValues);
  };

  useEffect(() => {
    return () => {
      setLoading(false);
      setFormValue({ changePrice: 0, changeReason: '' });
      setValidateStatus({});
      setPriceError(true);
    };
  }, [visible]);

  useDebounceEffect(
    () => {
      if (!formValue.changePrice) {
        setValidateStatus({});
        return;
      }
      OrderOperateStore.ajaxLossCheck({
        skuId,
        orderId: orderNo,
        changePrice: formValue.changePrice * 100
      })
        .then((res) => {
          setValidateStatus({});
          setPriceError(false);

          if (res.thirdType === 10 && res.priceDifference < 0) {
            setPriceError(true);
            setValidateStatus({
              validateStatus: 'error',
              help: '金额小于京东协议价，请重新输入'
            });
          }

          if (res.priceDifference < 0) {
            setValidateStatus({
              validateStatus: 'error',
              help: '会亏本，确认改价吗'
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    },
    [formValue.changePrice],
    { wait: 500 }
  );

  const onOk = () => {
    if (loading) {
      return;
    }
    form.validateFields().then((values) => {
      setLoading(true);
      OrderOperateStore.ajaxGoodsChange({
        ...values,
        orderNo,
        skuId,
        changePrice: values.changePrice * 100
      })
        .then(async (res) => {
          if (!res) {
            message.error('改价失败');
            return;
          }
          message.success('改价成功');
          OrderDetailStore.ajaxGetOrderDetail({ orderNo }).finally(onClose);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Modal
      title={'修改单价'}
      open={visible}
      onCancel={onClose}
      destroyOnClose
      okButtonProps={{ disabled: priceError || !formValue.changePrice }}
      onOk={onOk}
    >
      <Form {...layout} form={form} preserve={false} onValuesChange={onValuesChange}>
        <FormItem label={'原价'}>
          ￥{finalSalePrice / 100}
          {IsContainsTax.含税 === isContainsTax && (
            <div>
              (含税点：￥
              {(((finalSalePrice / 100 / (1 + taxRate / 100)) * taxRate) / 100).toFixed(2)})
            </div>
          )}
        </FormItem>
        <FormItem label={'改价'} required name={'changePrice'} {...validateStatus}>
          <InputNumber
            style={{ width: 200 }}
            type={'number'}
            placeholder={'输入金额'}
            min={PriceConfig.Min}
            max={PriceConfig.Max}
          />
        </FormItem>
        <FormItem name={'changeReason'} label={'修改原因'}>
          <Input.TextArea maxLength={50} showCount />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default observer(ChangePriceModal);
