import React, { FC } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Space, Statistic, Tooltip } from "@fle-ui/next";
import ProCard from "@fle-ui/pro-card";
import type { ProCardProps } from "@fle-ui/pro-card";
import { Icon } from "@/components";
import { showMoney } from "@/tools/filters";
import { useHistory } from "react-router-dom";

interface StatisticCardProps {
  value: number | string;
  icon?: string;
  iconColor?: string;
  name?: string;
  nameTooltip?: string;
  valtype?: "number" | "money";
  valprefix?: React.ReactNode;
  router?: string;
}

declare type RenderFunction = () => React.ReactNode;
interface CardItemPtops extends ProCardProps, StatisticCardProps {
  button?: React.ReactNode | RenderFunction;
  contentDirection?: "horizontal" | "vertical";
}

interface RevenueCardProps extends ProCardProps {
  list?: CardItemPtops[];
  itemStyle?: React.CSSProperties;
}

const ContentDirectionMap = new Map([
  ["horizontal", "flex-space-between"],
  ["vertical", "fle-start-vertical"],
]);

const ValueTypeMap = new Map([
  ["number", { precision: 0, prefix: null }],
  ["money", { precision: 2, prefix: "￥" }],
]);

export const StatisticCard = ({ value, icon, iconColor, name, nameTooltip, valtype = "money", valprefix, router }: StatisticCardProps) => {
  const history = useHistory();

  return (
    <Space direction="vertical">
      <Statistic
        value={valtype === "money" ? showMoney(value) : value}
        precision={ValueTypeMap.get(valtype)?.precision || 0}
         // @ts-ignore
        prefix={valprefix || ValueTypeMap.get(valtype)?.prefix || ""}
      />
      <Space>
        {!!icon && <Icon type={icon} style={{ color: iconColor || "#666", paddingLeft: 2 }} />}
        {!!name && (<span className={!!router ? "router-btn" : ""} onClick={() => !!router && history.push(router)}>{name}</span>)}
        {!!nameTooltip && (
          <Tooltip title={nameTooltip}>
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </Space>
    </Space>
  );
};

const RevenueCard: FC<RevenueCardProps> = ({ list, itemStyle, ...other }) => {
  return (
    <ProCard {...other}>
      {list?.map(
        (
          {
            value,
            icon,
            iconColor,
            name,
            nameTooltip,
            button,
            valprefix,
            router,
            contentDirection = "horizontal",
            valtype = "money",
            ...other
          },
          index
        ) => {
          return (
            <ProCard style={itemStyle} key={index} {...other}>
              <Space className={ContentDirectionMap.get(contentDirection) || ""}>
                <StatisticCard
                  value={value}
                  icon={icon}
                  iconColor={iconColor}
                  name={name}
                  valtype={valtype}
                  valprefix={valprefix}
                  router={router}
                  nameTooltip={nameTooltip}
                />
                <div>{button}</div>
              </Space>
            </ProCard>
          );
        }
      )}
    </ProCard>
  );
};

export default RevenueCard;
