/**
 * @Description: 京东和自有共用一个交易详情
 * @author 点点
 * @date 2021/6/21
 * @time 11:28
 */

import useUrlState from '@ahooksjs/use-url-state';
import { DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Statistic,
  Table
} from '@fle-ui/next';
import { RadioChangeEvent } from '@fle-ui/next/lib/radio/interface';
import { PageContainer } from '@fle-ui/pro-layout';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TradeStore, {
  BizType,
  FeeType,
  Flag,
  FlagSymbol,
  QuerySupplierTradeParams,
  TradeItemProps
} from '@/stores/tradeStore';
import { getPagination } from '@/tools/getPagination';
import { PageProps } from '@/types/commom';
import { dataList } from '../settings/rangesData';

const { Column } = Table;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select;

const initValues: Partial<QuerySupplierTradeParams> = {
  pageNo: 1,
  pageSize: 20,
  keyType: 1,
  date: [moment(), moment()],
  payTimeStart: moment().format('YYYY-MM-DD'),
  payTimeEnd: moment().format('YYYY-MM-DD')
};

type Props = {};
const TransDetail: FC<Props> = () => {
  const history = useHistory();
  const { pageNo, pageSize, list, total } = TradeStore.tradeList;
  const { expense, expenseCount, income, incomeCount } = TradeStore.supplierTradeAmount;
  const [form] = Form.useForm();
  const [urlParams] = useUrlState({ flag: undefined }, { navigateMode: 'replace' });
  const [page, setPage] = useState<PageProps>({ pageNo: 1, pageSize: 20 });
  const [formData, setFormData] = useState<Partial<QuerySupplierTradeParams>>({
    ...urlParams,
    ...initValues
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const params = { ...formData, ...page };
    if (formData?.date) {
      const [start, end] = formData.date;
      params.payTimeStart = start.format('YYYY-MM-DD');
      params.payTimeEnd = end.format('YYYY-MM-DD');
    }
    TradeStore.ajaxGetSupplierTradeAmount(params).finally(() => {});
    TradeStore.ajaxGetTradeList(params).finally(() => setLoading(false));
  }, [formData, page]);

  const onFinish = (values: Partial<QuerySupplierTradeParams>) => {
    setFormData(values);
  };

  //详情
  const goToDetail = (item: TradeItemProps) => {
    if (String(item.feeType) === '501001') {
      // 商品货款
      const firstChar = item.orderNo.replace(/[^a-zA-Z]/g, '');
      switch (firstChar) {
        case 'P':
          // 集采批发
          history.push(`/settings/order/pur_wholesale/detail/${item.orderNo}`);
          break;
        case 'D':
          // 集采代发发
          history.push(`/settings/order/pur_proxy/detail/${item.orderNo}`);
          break;
        case 'Y':
          // 一代发发
          history.push(`/settings/order/once_proxy/detail/${item.orderNo}`);
          break;
        case 'YAF':
          // 一件代发售后
          history.push(`/settings/order/after_sale/detail/${item.orderNo}`);
          break;
        default:
          break;
      }
    } else if (String(item.feeType) === '501010') {
      // 发货单
      history.push(`/settings/order/pur_proxy/invoice_detail/detail/${item.orderNo}`);
    }
  };

  const onChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ date: e.target.value });
  };

  return (
    <PageContainer padding={0}>
      <Card>
        <Row gutter={8} align={'middle'}>
          <Col span={18}>
            <Form form={form} onFinish={onFinish} initialValues={formData}>
              <Row gutter={8}>
                <Col>
                  <FormItem label={'交易时间'} name={'date'}>
                     {/* @ts-ignore */}
                    <RangePicker
                      allowClear={false}
                      disabledDate={(current) => current && current > moment().endOf('day')}
                    />
                  </FormItem>
                </Col>
                <Col>
                  <Radio.Group onChange={onChange}>
                    {dataList.map((item) => (
                      <Radio.Button value={item.value} key={item.label}>
                        {item.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={4}>
                  <FormItem name={'flag'}>
                    <Select allowClear placeholder={'收支类型'}>
                      <Option value={'0'}>收入</Option>
                      <Option value={'1'}>支出</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem name={'bizType'}>
                    <Select allowClear placeholder={'交易类型'}>
                      <Option value={106001}>销售</Option>
                      <Option value={104001}>退款</Option>
                      <Option value={101101}>提现</Option>
                      <Option value={105001}>服务费</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem>
                    <Input.Group compact>
                      <FormItem noStyle name={'keyType'}>
                        <Select style={{ width: '50%' }}>
                          <Option value={1}>搜索交易流水号（精准）</Option>
                          <Option value={2}>搜索订单号（精准）</Option>
                        </Select>
                      </FormItem>
                      <FormItem name={'keyword'}>
                        <Input style={{ width: '100%' }} placeholder={'输入关键字查询'} />
                      </FormItem>
                    </Input.Group>
                  </FormItem>
                </Col>

                <Col span={4}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'}>
                      查询
                    </Button>
                    <Button onClick={() => TradeStore.exportSupplierTradeExcel(formData)}>
                      <DownloadOutlined />
                      下载
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col flex={1} push={1} span={5}>
            <Row
              gutter={0}
              align={'middle'}
              style={{ height: 90, marginBottom: 25, backgroundColor: '#E6F5FF' }}
            >
              <Col push={2} span={10}>
                <Statistic
                  title="收入"
                  value={income / 100}
                  precision={2}
                  valueStyle={{ color: '#238000', fontSize: 14 }}
                  suffix="元"
                />
                <div style={{ marginTop: 6, textIndent: 2 }}>{incomeCount}笔</div>
              </Col>
              <Col push={6} span={10}>
                <Statistic
                  title="支出"
                  value={expense / 100}
                  precision={2}
                  valueStyle={{ color: '#F66604', fontSize: 14 }}
                  suffix="元"
                />
                <div style={{ marginTop: 6, textIndent: 2 }}>{expenseCount}笔</div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Table
          scroll={{ x: 1000 }}
          dataSource={list}
          loading={loading}
          rowKey={'serialNo'}
          pagination={getPagination({
            total,
            current: pageNo,
            pageSize,
            onChange: (pageNo: number, pageSize: number) => setPage({ pageNo, pageSize }),
            onShowSizeChange: (pageNo: number, pageSize: number) => setPage({ pageNo: 1, pageSize })
          })}
        >
          <Column title={'交易流水号'} width={250} dataIndex={'serialNo'} />
          <Column<TradeItemProps>
            width={200}
            align={'center'}
            title={'交易时间'}
            dataIndex={'payTime'}
            render={(payTime) => moment(payTime).format('YYYY-MM-DD HH:mm:ss')}
          />
          <Column
            width={100}
            align={'center'}
            title={'收支类型'}
            dataIndex={'flag'}
            render={(flag) => Flag[flag]}
          />
          <Column
            width={100}
            align={'center'}
            title={'交易类型'}
            dataIndex={'bizType'}
            render={(bizType) => BizType[bizType]}
          />
          <Column
            width={100}
            align={'center'}
            title={'费用科目'}
            dataIndex={'feeType'}
            render={(feeType) => FeeType[feeType]}
          />
          <Column width={150} align={'center'} title={'交易账户'} render={() => '飞象结算账户'} />
          <Column width={250} title={'订单号/售后单号'} dataIndex={'orderNo'} />
          <Column width={250} align={'center'} title={'交易备注'} dataIndex={'description'} />
          <Column<TradeItemProps>
            width={140}
            align={'center'}
            title={'交易金额（元）'}
            dataIndex={'amount'}
            render={(amount, { flag }) => {
              return (
                <span style={{ color: flag == 0 ? '#299224' : '#F40005' }}>
                  {FlagSymbol[flag]}￥{amount / 100}
                </span>
              );
            }}
          />
          <Column
            width={140}
            align={'center'}
            title={'账户余额（元）'}
            dataIndex={'restAmount'}
            render={(restAmount) => <>￥{restAmount / 100}</>}
          />
          <Column<TradeItemProps>
            align={'center'}
            width={100}
            fixed={'right'}
            title={'操作'}
            render={(_, row) => {
              return (
                ['501010', '501001'].includes(row.feeType) &&
                row.orderNo && (
                  <Button type={'link'} onClick={() => goToDetail(row)}>
                    详情
                  </Button>
                )
              );
            }}
          />
        </Table>
      </Card>
    </PageContainer>
  );
};

export default observer(TransDetail);
