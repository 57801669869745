import { Typography } from '@fle-ui/next';
import React, { FC } from 'react';

const { Text } = Typography;

type Props = {
  // 标签
  label: string | React.ReactNode;
  // 值
  value: string | React.ReactNode;
  colon?: boolean;
};
const OrderInfoItem: FC<Props> = (props: Props) => {
  const { label, value, colon = true } = props;
  if (!value) {
    return null;
  }
  return (
    <Text
      ellipsis={{
        tooltip: value
      }}
      style={{ width: 280, color: '#262626' }}
    >
      <span>
        {label}
        {colon && '：'}
      </span>

      {value}
    </Text>
  );
};

export default OrderInfoItem;
