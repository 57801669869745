import { Button, Space, Typography } from '@fle-ui/next';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { CommonStore } from '@/stores';
import { DeliveryStatus, DetailGoodItemProp, ReceiptStatus } from '@/stores/orderDetailStore';
import ChangeShipNumberModal from './ChangeShipNumberModal';
import ShipInfoModal from './ShipInfoModal';

const { Text } = Typography;

const ShipOperate: FC<DetailGoodItemProp> = (props) => {
  const { receiptStatus, deliveryStatus } = props;
  const [shipInfoModalVisible, setShipInfoModalVisible] = useState(false);
  const [ChangeShipNumberModalVisible, setChangeShipNumberModalVisible] = useState(false);

  if (deliveryStatus === 1) {
    return null;
  }
  return (
    <>
      <Space direction={'vertical'} size={1}>
        {deliveryStatus === DeliveryStatus.已发货 && (
          <Button type={'link'} size={'small'} onClick={() => setShipInfoModalVisible(true)}
          style={{margin: 0, padding: 0 }}
          >
            查看物流
          </Button>
        )}
        {receiptStatus === ReceiptStatus.待收货 && !CommonStore.accountInfo.isJD && (
          <Button
            type={'link'}
            size={'small'}
            onClick={() => setChangeShipNumberModalVisible(true)}
            style={{margin: 0, padding: 0 }}
          >
            修改快递单号
          </Button>
        )}
        {receiptStatus === ReceiptStatus.已收货 && !CommonStore.accountInfo.isJD && (
          <Text type="secondary">已收货不可修改单号</Text>
        )}
        {receiptStatus === ReceiptStatus.已收货 && <Text type="secondary">买家已确认收货</Text>}
        {receiptStatus === ReceiptStatus.待收货 && <Text type="secondary">待买家确认收货</Text>}
      </Space>

      <ShipInfoModal
        visible={shipInfoModalVisible}
        onCancel={() => setShipInfoModalVisible(false)}
        expressNo={props.expressNo as string}
        expressName={props.expressCompany}
        expressType={props.deliveryMethod}
      />

      <ChangeShipNumberModal
        visible={ChangeShipNumberModalVisible}
        onClose={() => setChangeShipNumberModalVisible(false)}
        data={props as any}
      />
    </>
  );
};

export default observer(ShipOperate);
