import React, { FC } from 'react';
import { Col, Statistic } from '@fle-ui/next';
import { OrderItem } from '@/stores/orderListStore';

type Props = {
  // 栅格
  span: number;
};
const OrderPrice: FC<OrderItem & Props> = (props) => {
  const { span, currentActualPaymentPrice, currentFreightPrice, currentOrderPrice } = props;
  return (
    <Col
      span={span}
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Statistic
        valueStyle={{
          color: '#262626',
          fontWeight: 400,
          fontSize: 16
        }}
        value={(currentActualPaymentPrice || currentOrderPrice) / 100}
        precision={2}
      />
      {window.location.pathname !== '/shop_manage/order/pur_proxy' && (
        <span style={{ color: '#a9a9a9' }}>
          {currentFreightPrice === null
            ? '不含运费'
            : '含运费：￥' + (currentFreightPrice / 100).toFixed(2)}
        </span>
      )}
    </Col>
  );
};

export default OrderPrice;
