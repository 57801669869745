/**
 * @Description: 订单操作
 * @author 点点
 * @date 2021/6/25
 * @time 16:29
 */
import { OrderApis } from '@/api/orderApis';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import httpClient from "@/tools/httpClient";

export interface LossCheckParams {
  changePrice: number;
  orderId: string;
  skuId: string;
  orderType?: string;
  companyId?: string;
  userId?: string;
}

export interface LossCheckResult {
  // 商品所在订单号
  orderNo: string;
  // 改价差额 小于0，京东不可改；平台二次提醒，可修改。大于0 均可
  priceDifference: number;
  skuId: string;
  // 第三方商品类型 0平台 10京东 20苏宁 30网易严选
  thirdType: 0 | 10 | 20 | 30;
}

export interface GoodsChangeParams {
  //改价后价格
  changePrice: number;
  //变更原因
  changeReason: string;
  // 订单号
  orderNo: string;
  //变更的商品ID
  skuId: string;
}

export interface GoodsFreightChangeParams extends Omit<GoodsChangeParams, 'skuId'> {
  //变更的商品ID
  goodsFreightId: string;
}

export interface CancelOrderParams {
  // 订单编号
  orderNo: string;
  // 取消订单理由
  reason: string;
  // 请选择取消订单的理由
  key: string;
}

export interface ConfirmReceiptParams {
  orderNo: string;
  receivePaymentImage: string;
  receivePaymentRemark: string;
}

type goodsFreightItemProps = {
  freight: number;
  skuId: string;
};

export interface ConfirmFreightParams {
  goodsFreightList?: goodsFreightItemProps[];
  orderNo: string;
  totalFreight?: number;
}

export interface GoodsFreightDescVO {
  finalFreightPrice: number;
  freightRules: string;
  freightType: number;
  freightTypeDesc: string;
  initialFreightPrice: number;
  transactionFreightPrice: number;
}

export interface DeliverGoodsReq {
  //商品skuId
  skuId: string[];
  //订单号
  orderNo: string;
  // 发货方式：1、快递，2、物流
  deliveryMethod: 1 | 2;
  //快递（物流）公司名称
  expressCompany?: string;
  //快递公司code，选择物流方式时 不注入该值只注入快递（物流）公司名称
  expressCompanyCode?: string;
  //物流单号
  expressNo: string;
  //用户id
  outUserId?: string;
  // 公司id
  companyId?: string;
}

interface ModifyRecordParams {
  orderNo: string;
  changeNo: string;
  changeType: 1 | 2;
}

interface ModifyRecord {
  after: '';
  before: '';
  changeNo: '';
  changeReason: '';
  changeType: 0;
  companyId: '';
  createTime: '';
  orderNo: '';
  userId: '';
}

export interface ChangeFreightInfoParams {
  changeReason: '';
  companyId: '';
  //  发货方式：1、快递，2、物流
  deliveryMethod: 1 | 2;
  expressCompany: '';
  expressCompanyCode: '';
  expressNo: '';
  orderNo: '';
  skuId: '';
  userId: '';
}

interface OrderOperateStoreProps {
  // 改价亏本校验
  ajaxLossCheck: (params: LossCheckParams) => Promise<LossCheckResult>;
  // 商品改价
  ajaxGoodsChange: (params: GoodsChangeParams) => Promise<boolean>;
  // 修改运费
  ajaxGoodsFreightChange: (params: GoodsFreightChangeParams) => Promise<boolean>;
  // 运费计算方法
  ajaxGetFreight: (params: { orderGoodsFreightId: string }) => void;
  goodsFreightDescVO: GoodsFreightDescVO;
  // 取消订单
  ajaxCancelOrder: (params: CancelOrderParams) => Promise<boolean>;
  // 确认收款
  ajaxConfirmReceipt: (params: ConfirmReceiptParams) => Promise<boolean>;
  // 确认运费
  ajaxConfirmFreight: (params: ConfirmFreightParams) => Promise<boolean>;
  // 商品发货
  ajaxDeliverGoods: (params: DeliverGoodsReq) => Promise<boolean>;
  // 查看修改记录
  modifyRecord: ModifyRecord[];
  ajaxGetModifyRecord: (params: ModifyRecordParams) => void;
}

class OrderOperateStore implements OrderOperateStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @action
  async ajaxLossCheck(params: LossCheckParams): Promise<LossCheckResult> {
    const res = await httpClient.post(OrderApis.get('lossCheck'), params);
    return res?.success && res.data;
  }

  @action
  async ajaxGoodsChange(params: GoodsChangeParams): Promise<boolean> {
    const res = await httpClient.post(OrderApis.get('goodsChange'), params);
    return res?.success;
  }

  @action
  async ajaxGoodsFreightChange(params: GoodsFreightChangeParams): Promise<boolean> {
    const res = await httpClient.post(OrderApis.get('goodsFreightChange'), params);
    return res?.success;
  }

  @action
  async ajaxCancelOrder(params: CancelOrderParams): Promise<boolean> {
    const res = await httpClient.post(OrderApis.get('cancelOrder'), params);
    return res?.success;
  }

  @action
  async ajaxConfirmReceipt(params: ConfirmReceiptParams): Promise<boolean> {
    const res = await httpClient.post(OrderApis.get('confirmReceipt'), params);
    return res?.success;
  }

  @action
  async ajaxConfirmFreight(params: ConfirmFreightParams): Promise<boolean> {
    const res = await httpClient.post(OrderApis.get('confirmFreight'), params);
    return res?.success;
  }

  @observable
  goodsFreightDescVO: GoodsFreightDescVO = {
    finalFreightPrice: 0,
    freightRules: '',
    freightType: 0,
    freightTypeDesc: '',
    initialFreightPrice: 0,
    transactionFreightPrice: 0
  };

  @action
  async ajaxGetFreight(params: { orderGoodsFreightId: string }) {
    const res = await httpClient.get(OrderApis.get('getFreight'), { params });
    if (res?.success) {
      runInAction(() => (this.goodsFreightDescVO = res.data));
    }
  }

  @action
  async ajaxDeliverGoods(params: DeliverGoodsReq): Promise<boolean> {
    const res = await httpClient.post(OrderApis.get('deliverGoods'), params);
    return res?.success;
  }

  @observable
  modifyRecord: ModifyRecord[] = [];

  @action
  async ajaxGetModifyRecord(params: ModifyRecordParams) {
    const res = await httpClient.post(OrderApis.get('modifyRecord'), params);
    if (res?.success) {
      runInAction(() => (this.modifyRecord = res.data));
    }
  }

  @action
  async ajaxChangeFreightInfo(params: ChangeFreightInfoParams): Promise<boolean> {
    const res = await httpClient.post(OrderApis.get('changeFreightInfo'), params);
    return res?.success;
  }

  @action
  async ajaxGetFreightDescription(params: any) {
    const res = await httpClient.post(OrderApis.get('getFreightDescription'), params);
    return res?.success && res.data;
  }
}

export default new OrderOperateStore();
