/**
 * @Description: 财务概览
 * @author 点点
 * @date 2021/6/24
 * @time 17:02
 */
import { FinanceApis } from '@/api/financeApis';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { CommonStore } from '@/stores/index';
import httpClient from "@/tools/httpClient";
import { ListDetailProps, PageProps } from '@/types/commom';

export enum AuditStatus {
  平台审核中,
  资金已到账,
  申请被驳回
}

export interface Serial {
  // 收支金额（单位：分），前端需要根据type自适应显示 +/-
  amtPay: number;
  // 收入或支出，0-收入，1-支出
  flagDc: 0 | 1;
  //交易类型
  feeType: number;
}

export interface PaymentData {
  // 日支出
  dailyExpense: number;
  // 日支出明细 - 仅展示最近两条
  dailyExpenseSerial: Serial[];
  // 日收入
  dailyIncome: number;
  // 日收入明细 - 仅展示最近两条
  dailyIncomeSerial: Serial[];
  // 月支出
  monthlyExpense: number;
  // 月支出明细 - 仅展示最近两条
  monthlyExpenseSerial: Serial[];
  // 月收入
  monthlyIncome: number;
  // 月收入明细 - 仅展示最近两条
  monthlyIncomeSerial: Serial[];
}

export interface ShopAccountStatisticBO {
  // 可提现金额，单位：分
  canWithdraw: 0;
  //象铺id
  id: '';
  // 预付货款，单位：分
  prepay: 0;
  //总营收，单位：分
  revenue: 0;
  // 即将到账金额，单位：分
  willIncome: 0;
}

export interface ShopWithdrawStatisticBO {
  //可提现金额，单位：分
  canWithdraw: number;
  //是否有进行中的提现申请
  haveWaitApply: boolean;
  //预付货款，单位：分
  withdrwaLock: number;
  // 手续费利率
  withdrwaFeeRate: number;
}

export interface WithdrawDepositRecordBO {
  arrivalAmount: 0;
  auditContent: string;
  auditStatus: number;
  auditTime: number;
  bankAccountName: string;
  bankAccountNo: string;
  bankName: string;
  companyId: string;
  createTime: number;
  handlingFee: number;
  id: string;
  remittanceReceipt: string;
  shopId: string;
  withdrawAmount: number;
}

export interface ApplyWithdrawDepositQuery {
  bankCardAccountId: string;
  companyId: string;
  shopId: string;
  withdrawAmount: number;
}

interface FinanceStoreProps {
  paymentData: PaymentData;
  shopAccountStatistic: ShopAccountStatisticBO;
  shopWithdrawStatistic: ShopWithdrawStatisticBO;
  withdrawDepositRecord: ListDetailProps<WithdrawDepositRecordBO>;
  ajaxGetPayment: () => void;
  ajaxGetStatistics: () => void;
  ajaxGetWithdrawStatistics: () => void;
  ajaxGetPage: (params: PageProps) => void;
  ajaxGetApply: (params: ApplyWithdrawDepositQuery) => Promise<boolean>;
}


class FinanceStore implements FinanceStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  paymentData: PaymentData = {
    dailyExpense: 0,
    dailyExpenseSerial: [],
    dailyIncome: 0,
    dailyIncomeSerial: [],
    monthlyExpense: 0,
    monthlyExpenseSerial: [],
    monthlyIncome: 0,
    monthlyIncomeSerial: []
  };

  @action
  async ajaxGetPayment() {
    const shopId = await CommonStore.getShopId();
    const res = await httpClient.get(FinanceApis.get('payment'), {
      params: { shopId }
    });
    if (res?.success) {
      runInAction(() => {
        this.paymentData = res.data;
      });
    }
  }

  @observable
  shopAccountStatistic: ShopAccountStatisticBO = {
    canWithdraw: 0,
    id: '',
    prepay: 0,
    revenue: 0,
    willIncome: 0
  };

  @action
  async ajaxGetStatistics() {
    const shopId = await CommonStore.getShopId();
    const res = await httpClient.get(FinanceApis.get('statistics'), {
      params: { shopId }
    });
    if (res?.success) {
      runInAction(() => {
        this.shopAccountStatistic = res.data;
      });
    }
  }

  @observable
  shopWithdrawStatistic: ShopWithdrawStatisticBO = {
    canWithdraw: 0,
    haveWaitApply: true,
    withdrwaLock: 0,
    withdrwaFeeRate: 0
  };

  @action
  async ajaxGetWithdrawStatistics() {
    const shopId = await CommonStore.getShopId();
    const res = await httpClient.get(FinanceApis.get('withdraw-statistics'), {
      params: { shopId }
    });
    if (res?.success) {
      runInAction(() => {
        this.shopWithdrawStatistic = res.data;
      });
    }
  }

  withdrawDepositRecord: ListDetailProps<WithdrawDepositRecordBO> = {
    list: [],
    pageNo: 1,
    pageSize: 20,
    total: 0
  };

  @action
  async ajaxGetPage(params: PageProps) {
    const shopId = await CommonStore.getShopId();
    const res = await httpClient.get(FinanceApis.get('page'), {
      params: { shopId, ...params }
    });
    if (res?.success) {
      runInAction(() => {
        this.withdrawDepositRecord = res.data;
      });
    }
  }

  @action
  async ajaxGetApply(params: ApplyWithdrawDepositQuery): Promise<boolean> {
    const res = await httpClient.post(FinanceApis.get('apply'), params);
    return res?.success;
  }

  // 获取蛋糕叔叔余额
  async getDgssBalance() {
    return await httpClient.get(FinanceApis.get('getDgssBalance'))
  }

  financeDgssLoading: boolean = false

  // 蛋糕叔叔余额变动明细
  async getDgssBalanceChange(params: any) {
    this.financeDgssLoading = true
    try {
      const res = await httpClient.post(FinanceApis.get('getDgssBalanceChange'), params)
      if (res?.success) {
        return {
          total: res.data.total,
          data: res.data.list,
          success: res.success
        }
      }
      return {
        success: false
      }
    } finally {
      this.financeDgssLoading = false
    }
  }

}

export default new FinanceStore();
