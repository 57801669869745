/**
 * @Description: 集采批发订单交易成功
 * @author 点点
 * @date 2021/6/7
 * @time 17:39
 */

import { observer } from 'mobx-react';
import React, { FC } from 'react';
import OrderDetailStore, { DetailGoodItemProp } from '@/stores/orderDetailStore';
import OrderGoodPrice from '@/pages/shopManage/order/component/OrderGoodPrice';
import Quantity from '../../component/Quantity';
import OrderStateBoard from '../../component/OrderStateBoard';
import { Card, Table } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import GoodDesc from '../../component/GoodDesc';
import OrderInfo from '../../component/OrderInfo';

const { Column } = Table;

type Props = {};
const OrderSuccess: FC<Props> = () => {
  const { goodsList } = OrderDetailStore.orderDetail;

  return (
    <>
      <OrderStateBoard orderStatus={'交易成功'} />

      <WhiteSpace />

      <OrderInfo />

      <WhiteSpace />

      <Card>
        <div
          style={{
            fontSize: 16,
            color: '#262626',
            fontWeight: 600,
            paddingBottom: 8,
            marginBottom: 20,
            borderBottom: '1px solid #EEEEEE'
          }}
        >
          商品列表
        </div>
        <Table bordered dataSource={goodsList} rowKey={'id'} pagination={false}>
          <Column<DetailGoodItemProp>
            title={'商品图片'}
            
            width={70}
            render={(text, record) => {return <img src={record.goodsImage} alt="" style={{width:68}}/> }}
          />
          <Column<DetailGoodItemProp>
            title={'商品名称'}
            
            width={270}
            render={(text, record) => <GoodDesc {...record} />}
          />
          <Column<DetailGoodItemProp>
            title={'购买总数'}
            
            dataIndex={'quantity'}
            render={(text, record) => <Quantity {...record} />}
          />
          <Column<DetailGoodItemProp>
            title={'发货数'}
            
            dataIndex={'deliveryNum'}
            render={(text, record) => text + record.unit}
          />
          <Column<DetailGoodItemProp>
            title={'可发货数'}
            
            dataIndex={'freeNum'}
            render={(text, record) => text + record.unit}
          />
          <Column<DetailGoodItemProp>
            title={'单价'}
            
            render={(text, record) => <OrderGoodPrice {...record} />}
          />
          <Column
            title={'小计'}
            
            dataIndex={'orderPrice'}
            render={(text) => '￥' + (text / 100).toFixed(2)}
          />
        </Table>
      </Card>
    </>
  );
};

export default observer(OrderSuccess);
