/**
 * @Description: 价格配置
 * @author 点点
 * @date 2021/6/30
 * @time 11:23
 */
interface PriceConfigProps {
  // 最低价格
  Min: 0.01;
  // 最高价格
  Max: 999999999;
}

const PriceConfig: PriceConfigProps = {
  Min: 0.01,
  Max: 999999999
};

export default PriceConfig;
