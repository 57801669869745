/**
 * @Description: 结算账户
 * @author 点点
 * @date 2021/6/16
 * @time 09:31
 */

import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, Card, message, Modal, Table } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import AccountStore, { SelectAccountItemProps } from '@/stores/accountStore';
import CountModal from './countModal';
import { PageContainer } from '@fle-ui/pro-layout';

const { Column } = Table;

type Props = {};
const Settlement: FC<Props> = () => {
  const { selectAccountList } = AccountStore;

  const [addCountModal, setAddCountModal] = useState(false);
  const [selectId, setSelectId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [cur, setCur] = useState<Partial<SelectAccountItemProps>>({});

  const refreshPage = async (res: boolean) => {
    setLoading(true);
    res ? message.success('操作成功') : message.error('操作失败');
    setRefresh((refresh) => refresh + 1);
  };

  const setDefault = async (id: number) => {
    setLoading(true);
    const res = await AccountStore.ajaxDefault({ id });
    await refreshPage(res);
  };

  const deleteAccount = async (id: number) => {
    setLoading(true);
    const res = await AccountStore.ajaxDelete({ id });
    await refreshPage(res);
  };

  useEffect(() => {
    setLoading(true);
    AccountStore.ajaxSelect({}).finally(() => setLoading(false));
  }, [refresh]);
  return (
    <PageContainer padding={0}>
      <Card>
        <Alert
          message={
            <>
              <h3>温馨提示：</h3>
              <p>
                1、结算账户是平台方用来与供应商进行资金结算时使用，提供结算账户，方便平台方为供应商结算；
              </p>
              <p>2、设为默认账户后，默认账户将在买家支付时，向买家展示汇款账户；</p>
              <p>3、最多可保留10条结算账户。</p>
            </>
          }
          type="warning"
        />

        <WhiteSpace />

        <Button
          type={'primary'}
          onClick={() => {
            setSelectId(-1);
            setAddCountModal(true);
            setCur({});
          }}
          disabled={AccountStore.selectAccountList.length >= 10}
        >
          新增结算账户
        </Button>

        <WhiteSpace />

        <Table dataSource={selectAccountList} rowKey={'id'} loading={loading} pagination={false}>
          <Column title={'开户名'} dataIndex={'accountName'} />
          <Column title={'账号'} dataIndex={'bankCode'} />
          <Column title={'开户行'} dataIndex={'bankName'} />
          <Column<SelectAccountItemProps>
            width={300}
            dataIndex={'id'}
            title={'操作'}
            render={(id: number, record) => {
              return (
                <>
                  <Button
                    type={'link'}
                    onClick={() => {
                      setSelectId(id);
                      setAddCountModal(true);
                      setCur(record);
                    }}
                  >
                    编辑
                  </Button>
                  -
                  <Button
                    type={'link'}
                    onClick={() => {
                      Modal.confirm({ title: '确定删除？', onOk: () => deleteAccount(id) });
                    }}
                  >
                    删除
                  </Button>
                  -
                  <Button
                    type={'link'}
                    disabled={record.isDefault === 1}
                    onClick={() => setDefault(id)}
                  >
                    设为默认收款
                  </Button>
                </>
              );
            }}
          />
        </Table>

        <CountModal
          visible={addCountModal}
          onClose={() => setAddCountModal(false)}
          onOk={refreshPage}
          id={selectId}
          item={cur}
        />
      </Card>
    </PageContainer>
  );
};

export default observer(Settlement);
