/**
 * @Description: 发货单管理详情页面表单
 * @author 点点
 * @date 2021/6/9
 * @time 14:00
 */

import useUrlState from '@ahooksjs/use-url-state';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Button, Form, Input, Select, Space } from '@fle-ui/next';
import SiteSelect from '@fle-ui/pro-address-selection';
import { useParams } from 'react-router-dom';
import OrderDeliveryStore, { SearchOrderParams } from '@/stores/orderDeliveryStore';

const FormItem = Form.Item;
const { Option } = Select;

const FilterForm: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [, setState] = useUrlState({}, { navigateMode: 'replace' });

  const getData = (formData: Partial<SearchOrderParams>) => {
    const params = {
      ...formData,
      deliveryOrderId: id,
      areaCode: formData.areaCode
    };
    setState(params);
    OrderDeliveryStore.ajaxSearchOrderItem(params).catch((err) => {
      console.log(err);
    });
  };

  const onSubmit = async () => {
    const result = await form.validateFields();
    if (result.areaCode) {
      result.areaCode = result.areaCode.join('_');
    }
    getData(result);
  };

  const onReset = async () => {
    form.resetFields();
    await onSubmit();
  };

  return (
    <Form form={form} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Space>
        <FormItem name={'receiveStatus'}>
          <Select style={{ width: 100 }} placeholder={'收货状态'}>
            <Option value={0}>未收货</Option>
            <Option value={1}>已收货</Option>
          </Select>
        </FormItem>
        <FormItem name={'sendStatus'}>
          <Select style={{ width: 100 }} placeholder={'发货状态'}>
            <Option value={0}>未发货</Option>
            <Option value={1}>已发货</Option>
          </Select>
        </FormItem>
        <FormItem name={'mobile'}>
          <Input style={{ width: 150 }} placeholder={'搜索收货手机号'} />
        </FormItem>
        <FormItem name={'consignee'}>
          <Input style={{ width: 150 }} placeholder={'搜索收货人姓名'} />
        </FormItem>
        <Form.Item name={'areaCode'}>
          <SiteSelect level={4} placeholder="请选择地址" />
        </Form.Item>
        <FormItem>
          <Space>
            <Button type={'primary'} onClick={onSubmit}>
              搜索
            </Button>
            <Button onClick={onReset}>重置</Button>
          </Space>
        </FormItem>
      </Space>
    </Form>
  );
};

export default observer(FilterForm);
