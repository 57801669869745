import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Card } from '@fle-ui/next';
import OrderDetailStore from '@/stores/orderDetailStore';
import { Icon } from '@/components';

type Props = {
  // 当前订单状态
  orderStatus: string;
  // 订单备注
  orderInfo?: string | React.ReactNode;
  // 右侧操作按钮
  rightButton?: React.ReactNode;
};
const OrderStateBoard: FC<Props> = (props: Props) => {
  const { orderStatusDesc, orderStatus } = OrderDetailStore.orderDetail;
  const { orderInfo, rightButton } = props;
  const orderStatusMap: any = new Map([
    [
      1,
      [
        '#F42D2D',
        <Icon type="icon-dengdaimaijiaquerendingdan" className="ant-menu-order-icon" />
      ]
    ],
    [2, ['#FF712C', <Icon type="icon-dengdaimaijiafukuan" className="ant-menu-order-icon" />]],
    [3, ['#FF712C', <Icon type="icon-dengdaimaijiafahuo" className="ant-menu-order-icon" />]],
    [
      4,
      [
        '#FF712C',
        <Icon type="icon-dengdaimaijiaquerenshouhuo" className="ant-menu-order-icon" />
      ]
    ],
    [5, ['#36C626', <Icon type="icon-jiaoyichenggong" className="ant-menu-order-icon" />]],
    [6, ['#F42D2D', <Icon type="icon-jiaoyiguanbi" className="ant-menu-order-icon" />]],
    [10, ['#36C626', <Icon type="icon-jiaoyichenggong" className="ant-menu-order-icon" />]],
    [20, ['#F42D2D', <Icon type="icon-jiaoyiguanbi" className="ant-menu-order-icon" />]]
  ]);

  return (
    <Card bordered={false}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <div
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: orderStatusMap.get(orderStatus)[0],
              marginTop: 4,
              marginBottom: 4
            }}
          >
            {orderStatusMap.get(orderStatus)[1]}
            &nbsp;&nbsp;
            <span>{orderStatusDesc}</span>
          </div>
          <div style={{ color: '#787878', fontSize: 14 }}> {orderInfo} </div>
        </div>
        <div style={{ marginLeft: 'auto' }}>{rightButton}</div>
      </div>
    </Card>
  );
};

export default observer(OrderStateBoard);
