/**
 * @Description:  物流相关
 * @author 点点
 * @date 2021/6/22
 * @time 10:05
 */

type Key = 'pageQuery' | 'queryLogisticByExpressNo';

export const ExpressApis: Map<Key, string> = new Map([
  // 物流公司分页查询
  ['pageQuery', '/merchant/basic/express/pageQuery'],
  // 物流查询
  ['queryLogisticByExpressNo', '/merchant/basic/express/queryLogisticByExpressNo']
]);
