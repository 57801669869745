import React, { useRef, useState, useEffect, useCallback } from "react";
import { Select, Button, Typography, Cascader, Checkbox, DatePicker } from "@fle-ui/next";
import type { ProColumns, ActionType } from "@fle-ui/pro-table";
import ProTable from "@fle-ui/pro-table";
import { observer } from "mobx-react";

import { CommonStore, jdStore, goodsStore, maochaoStore } from "@/stores";
import { showMoney, formartNum } from "@/tools/filters";
import { formatTime } from "@/tools/helpers";
import RangeInput from "@fle-ui/pro-range-input";
import { useHistory } from "react-router-dom";
import { getGoodsUrl } from "@/config/url";
import moment from "moment";
const { RangePicker } = DatePicker;
type ColumnsItem = {
  imagePath: string;
  skuId: number;
  name: string;
  categoryLevel1Name: string;
  categoryLevel2Name: string;
  categoryLevel3Name: string;
  marketPrice: number;
  minPrice: number;
  maxPrice: number;
  stockStateId: number;
  rate?: number;
  selectStatus: number;
  selectTime: number;
};

const styles = {
  subText: { fontSize: 12, color: "#999" },
};

const pickStatusMap: any = new Map([
  [1, { name: "已挑选", text: "已挑选" }],
  [2, { name: "未挑选", text: "挑选" }],
]);

const orderNameMap = new Map([
  ["agreementPrice", "agree_mentPrice"],
  ["selectTime", "select_time"],
]);

//处理供应价列数据
const handlePrice = (record: ColumnsItem) => {
  if (typeof record.minPrice !== "number" && typeof record.maxPrice !== "number") {
    return "-";
  }
  if (record.minPrice === record.maxPrice) {
    return `¥${showMoney(record.maxPrice)}`;
  }
  return `¥${showMoney(record.minPrice)}-¥${showMoney(record.maxPrice)}`;
};

const { Paragraph } = Typography;


const PickDGSS: React.FC = () => {
  const history = useHistory();
  const actionRef = useRef<ActionType>();

  const [categoryData, setCategoryData] = useState([]);

  const [locationData, setLocationData] = useState([]);

  const [categoryList, setCategoryList] = useState([]) //类目

  const {} = maochaoStore;
  const [refresh, setRefresh] = useState(false);
  const [label, setlabel] = useState<any>([])



  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

    //获取类目
    const getCategoryList = async () => {
      const res: any = await maochaoStore.listCategory({
        parentCode: null
      })
      if (res?.success) {
        let list: any = []
        list = res.data.map((item: any) => ({
          label: item.categoryName,
          value: item.categoryCode,
          isLeaf: false,
        }));
        setCategoryList(list)
      }
    }


    useEffect(() => {
      getCategoryList()
    }, [])
    
  const initJdLocation = async () => {
    let locationData;
    let loc = await maochaoStore.getNewJdLocation({
      parentCode: 0,
    });
    locationData = loc?.map((item: { categoryName: any; categoryCode: any; level: string | number }) => ({
      label: item.categoryName,
      value: item.categoryCode,
      isLeaf: +item.level >= 2,
    }));
    setLocationData(locationData);
  };

  const loadData = async (selectedOptions: any[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    let res: any = await maochaoStore.listCategory({
      parentCode: targetOption.value,
    })

    if (res.data?.length) {
      targetOption.children = res.data?.map((item: any) => ({
        label: item.categoryName,
        value: item.categoryCode,
        isLeaf: +item.level === 4,
      }));
      targetOption.loading = false;
      setCategoryList([...categoryList]);
      setRefresh(true);
    } else {
      targetOption.loading = false;
      targetOption.isLeaf = true;
      setCategoryList([...categoryList]);
      setRefresh(true);
    }
  };

  const ona = (a: any, b: any, c: any) => {
    const list = b.map((item: any) => item.label)
    setlabel(list)
  }

  

  const initCategory = async () => {
    let categoryData;
    let cate1 = await goodsStore.getCategory({
      parentId: 0,
    });

    categoryData = cate1?.map((item: any) => ({
      label: item.categoryName,
      value: item.categoryId,
      isLeaf: false,
    }));
    setCategoryData(categoryData);
  };

  useEffect(() => {
    !categoryData.length && initCategory();
    !locationData.length && initJdLocation();
  }, []);

  const columns: ProColumns<ColumnsItem>[] = [

    {
      title: "商品信息",
      dataIndex: "goodsInfo",
      key: "context",
      width: 300,
      fixed: 'left',
      fieldProps: {
        placeholder: "输入商品名称/商品ID",
        allowClear: true,
      },
      render: (_, record:any) => (
        <div style={{ display: "inline-flex", width: "100%", alignItems: "center" }}>
          <img width={80} src={record.mainPicturePath} alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: 80,
              marginLeft: 10,
            }}>
            <Paragraph
              ellipsis={{ rows: 2, tooltip: true }}
              style={{ color: record.selectStatus === 2 ?  "#2a8ff7" : 'rgb(153, 153, 153)', margin: 0, cursor: "pointer" }}
              onClick={() =>
               record.selectStatus === 2 &&  window.open(`/home${getGoodsUrl(CommonStore.accountInfo.source, "detail", record.goodsCode)}`)
              }>
              {record.title}
            </Paragraph>
            <p style={{ ...styles.subText }}>第三方 ID：{record.goodsCode}</p>
            <p style={{ ...styles.subText }}>
              {[record.categoryLevel1Name, record.categoryLevel2Name, record.categoryLevel3Name]
                .filter((item) => item)
                .join(">")}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "商品类目",
      dataIndex: "category_code",
      key: "category_code",
      align: "center",
      fieldProps: {
        placeholder: "输入商品名称/商品ID",
        allowClear: true,
      },
      renderFormItem: () => <Cascader
          options={categoryList}
          changeOnSelect
          // fieldNames={{ label: "name", value: "catId" }} 
          placeholder='请选择'
          loadData={loadData}
          onChange={ona as any}
          allowClear={false}
        />,
        render:(_:any, record:any) => <>{record.categoryTreeName}</>
    },
    {
      title: "供应价",
      dataIndex: "agreementPrice",
      key: "agreementPrice",
      align: "center",
      sorter: true,
      renderFormItem: () => <RangeInput placeholder={["起始", "结束"]} addonBefore="¥" />,
      render: (_, record:any) => (record.price / 100).toFixed(2),
    },
    {
      title: "库存",
      dataIndex: "stockStateId",
      key: "stockStateId",
      align: "center",
      hideInSearch: true,
      render: (_, { stockStateId }) => (
        <span style={{ color: stockStateId === 0 ? "red" : "green" }}>{stockStateId === 0 ? "无货" : "有货"}</span>
      ),
    },
    {
      title: "挑选时间",
      dataIndex: "selectTime",
      key: "selectTime",
      align: "center",
      sorter: true,
      hideInSearch: true,
      render: (_, { selectStatus, selectTime }) => (selectStatus === 1 ? formatTime(selectTime) : null),
    },
    {
      title: "挑选状态",
      dataIndex: "selectStatus",
      key: "selectStatus",
      align: "center",
      initialValue: "",
      renderFormItem: (_, { type, defaultRender, ...rest }) => {
        return (
          <Select
            options={[
              {
                label: "全部",
                value: "",
              },
              {
                label: "已挑选",
                value: 1,
              },
              {
                label: "未挑选",
                value: 2,
              },
            ]}
          />
        );
      },
      render: (_, { selectStatus }) => (selectStatus === 1 ? "已挑选" : "未挑选"),
    },
  
   
    {
      title: "操作",
      dataIndex: "option",
      key: "option",
      width: 100,
      fixed: 'right',
      align: "center",
      valueType: "option",
      render: (_, record:any) => (
        <Button
          type="link"
          disabled={record.selectStatus === 1}
          onClick={() => history.push("/module/goods/mc/entry/" + record.goodsCode)}>
          {pickStatusMap.get(record.selectStatus)?.text}
        </Button>
      ),
    },
  ];

  return (
    <ProTable<ColumnsItem>
      columns={columns}
      actionRef={actionRef}
      onReset={() => setlabel([])}
      resizable={false}
      bordered={true}
      scroll={{ x: 1300 }}
      request={async (params = {}, sort) => {
        const sortName = sort ? Object.keys(sort)[0] : undefined;
        
        const _params:any = {
          ...params,
          pageNo: params.current,
          category_code_name: label.length > 0 ? label.join('>>') : undefined,
          price_min: params.agreementPrice?.[0] ? params.agreementPrice?.[0] * 100 : undefined,
          price_max: params.agreementPrice?.[1] ? params.agreementPrice?.[1] * 100 : undefined,
          select_source: 'pangu',
          order: sort && sortName ? orderNameMap.get(sortName) + " " + sort[sortName]?.slice(0, -3).toUpperCase() : undefined,
          select_status: params.selectStatus ? Number(params.selectStatus) : undefined,
        };

        

        delete _params.selectTime
        delete _params.selectStatus
        delete _params.current
        delete _params.agreementPrice
        return await maochaoStore.getNewMCGoodsList(_params);
      }}
      rowKey="goodsCode"
      search={{
        labelWidth: "auto",
        defaultCollapsed: false,
      }}
      pagination={{
        pageSize: 10,
      }}
      dateFormatter="string"
      options={{ setting: false, fullScreen: true }}
    />
  );
};

export default observer(PickDGSS);
