import React, { useRef, useState, useEffect, useCallback } from "react";
import { Select, Button, Typography, Cascader, Checkbox } from "@fle-ui/next";
import type { ProColumns, ActionType } from "@fle-ui/pro-table";
import ProTable from "@fle-ui/pro-table";
import { observer } from "mobx-react";

import { CommonStore, jdStore, goodsStore } from "@/stores";
import { showMoney, formartNum } from "@/tools/filters";
import { formatTime } from "@/tools/helpers";
import RangeInput from "@fle-ui/pro-range-input";
import { useHistory } from "react-router-dom";
import { getGoodsUrl } from "@/config/url";

type ColumnsItem = {
  imagePath: string;
  skuId: number;
  name: string;
  categoryLevel1Name: string;
  categoryLevel2Name: string;
  categoryLevel3Name: string;
  marketPrice: number;
  minPrice: number;
  maxPrice: number;
  stockStateId: number;
  rate?: number;
  selectStatus: number;
  selectTime: number;
};

const styles = {
  subText: { fontSize: 12, color: "#999" },
};

const pickStatusMap: any = new Map([
  [1, { name: "已挑选", text: "已挑选" }],
  [2, { name: "未挑选", text: "挑选" }],
]);

const orderNameMap = new Map([
  ["marketPrice", "jd_goods_market_price"],
  ["agreementPrice", "jd_goods_min_price "],
  ["rate", "jd_goods_min_profit_rate"],
  ["selectTime", "jd_goods_select_time"],
]);

//处理供应价列数据
const handlePrice = (record: ColumnsItem) => {
  if (typeof record.minPrice !== "number" && typeof record.maxPrice !== "number") {
    return "-";
  }
  if (record.minPrice === record.maxPrice) {
    return `¥${showMoney(record.maxPrice)}`;
  }
  return `¥${showMoney(record.minPrice)}-¥${showMoney(record.maxPrice)}`;
};

const { Paragraph } = Typography;

const PickDGSS: React.FC = () => {
  const history = useHistory();
  const actionRef = useRef<ActionType>();

  const [categoryData, setCategoryData] = useState([]);
  const [stockType, setStockType] = useState(false);
  const [vagueLocation] = useState("15_1213_0_0");
  const [hasStock, setHasStock] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [location, setLocation] = useState<any>(undefined);

  const {} = jdStore;

  const getMoreLocation = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let arr = await jdStore.getNewJdLocation({
      parentCode: targetOption.value,
    });
    targetOption.children = arr.map((item: { name: any; code: any; level: string | number }) => ({
      label: item.name,
      value: item.code,
      isLeaf: +item.level >= 3,
    }));
    targetOption.loading = false;
    setLocationData([...locationData]);
  };

  const initJdLocation = async () => {
    let locationData;
    let loc = await jdStore.getNewJdLocation({
      parentCode: 0,
    });
    locationData = loc?.map((item: { name: any; code: any; level: string | number }) => ({
      label: item.name,
      value: item.code,
      isLeaf: +item.level >= 2,
    }));
    setLocationData(locationData);
  };

  const getMoreCate = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let arr = await goodsStore.getCategory({
      parentId: targetOption.value,
    });
    if (arr.length) {
      targetOption.children = arr.map((item: any) => ({
        label: item.categoryName,
        value: item.categoryId,
        isLeaf: true,
      }));
    }

    targetOption.loading = false;
    setCategoryData([...categoryData]);
  };

  const initCategory = async () => {
    let categoryData;
    let cate1 = await goodsStore.getCategory({
      parentId: 0,
    });

    categoryData = cate1?.map((item: any) => ({
      label: item.categoryName,
      value: item.categoryId,
      isLeaf: false,
    }));
    setCategoryData(categoryData);
  };

  useEffect(() => {
    !categoryData.length && initCategory();
    !locationData.length && initJdLocation();
  }, []);

  const columns: ProColumns<ColumnsItem>[] = [
    {
      dataIndex: "categoryId",
      key: "categoryId",
      align: "center",
      hideInTable: true,
      renderFormItem: () => {
        return (
          <>
            <Checkbox onChange={(e) => setStockType(e.target.checked)} checked={stockType} style={{ width: "30%" }}>
              精准库存
            </Checkbox>
            {stockType ? (
              <Cascader
                placeholder="选择地区"
                style={{ width: "70%" }}
                options={locationData}
                loadData={getMoreLocation}
                onChange={(value: any) => {
                  setLocation(value);
                }}
                value={location}
                changeOnSelect
              />
            ) : (
              <>
                <Select value={vagueLocation} style={{ width: 100, marginRight: 16 }}>
                  <Select.Option value="15_1213_0_0">杭州</Select.Option>
                </Select>
                <Checkbox onChange={(e) => setHasStock(e.target.checked)} checked={hasStock}>
                  仅看有货
                </Checkbox>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "商品信息",
      dataIndex: "goodsInfo",
      key: "skuOrProductName",
      width: 300,
      fieldProps: {
        placeholder: "输入商品名称/京东SKUID",
        allowClear: true,
      },
      render: (_, record) => (
        <div style={{ display: "inline-flex", width: "100%", alignItems: "center" }}>
          <img width={80} src={record.imagePath} alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: 80,
              marginLeft: 10,
            }}>
            <Paragraph
              ellipsis={{ rows: 2, tooltip: true }}
              style={{ color: record.selectStatus === 2 ? "#2a8ff7" : 'rgb(153, 153, 153)', margin: 0, cursor: "pointer" }}
              onClick={() =>
                record.selectStatus === 2 && window.open(`/home${getGoodsUrl(CommonStore.accountInfo.source, "detail", record.skuId)}`)
              }>
              {record.name}
            </Paragraph>
            <p style={{ ...styles.subText }}>第三方 ID：{record.skuId}</p>
            <p style={{ ...styles.subText }}>
              {[record.categoryLevel1Name, record.categoryLevel2Name, record.categoryLevel3Name]
                .filter((item) => item)
                .join(">")}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "分类",
      dataIndex: "categoryId",
      key: "categoryId",
      align: "center",
      hideInTable: true,
      renderFormItem: () => <Cascader placeholder="请选择分类" options={categoryData} loadData={getMoreCate} />,
    },
    {
      title: "市场价",
      dataIndex: "marketPrice",
      key: "marketPrice",
      align: "center",
      sorter: true,
      hideInSearch: true,
      render: (_, record) => `¥${showMoney(record.marketPrice)}`,
    },
    {
      title: "供应价",
      dataIndex: "agreementPrice",
      key: "agreementPrice",
      align: "center",
      sorter: true,
      renderFormItem: () => <RangeInput placeholder={["起始", "结束"]} addonBefore="¥" />,
      render: (_, record) => handlePrice(record),
    },
    {
      title: "库存",
      dataIndex: "stockStateId",
      key: "stockStateId",
      align: "center",
      hideInSearch: true,
      render: (_, { stockStateId }) => (
        <span style={{ color: stockStateId === 0 ? "red" : "green" }}>{stockStateId === 0 ? "无货" : "有货"}</span>
      ),
    },
    {
      title: "假设利润率",
      dataIndex: "rate",
      key: "rate",
      align: "center",
      sorter: true,
      renderFormItem: () => <RangeInput placeholder={["起始", "结束"]} addonAfter="%" />,
      render: (_, { rate }) => (typeof rate === "number" ? `${rate}%` : "-"),
    },
    {
      title: "挑选时间",
      dataIndex: "selectTime",
      key: "selectTime",
      align: "center",
      sorter: true,
      hideInSearch: true,
      render: (_, { selectStatus, selectTime }) => (selectStatus === 1 ? formatTime(selectTime) : null),
    },
    {
      title: "挑选状态",
      dataIndex: "selectStatus",
      key: "selectStatus",
      align: "center",
      initialValue: "",
      renderFormItem: (_, { type, defaultRender, ...rest }) => {
        return (
          <Select
            options={[
              {
                label: "全部",
                value: "",
              },
              {
                label: "已挑选",
                value: 1,
              },
              {
                label: "未挑选",
                value: 2,
              },
            ]}
          />
        );
      },
      render: (_, { selectStatus }) => (selectStatus === 1 ? "已挑选" : "未挑选"),
    },
    {
      title: "操作",
      dataIndex: "option",
      key: "option",
      width: 100,
      align: "center",
      valueType: "option",
      render: (_, { selectStatus, skuId }) => (
        <Button
          type="link"
          disabled={selectStatus === 1}
          onClick={() => history.push("/module/goods/jd/entry/" + skuId)}>
          {pickStatusMap.get(selectStatus)?.text}
        </Button>
      ),
    },
  ];

  return (
    <ProTable<ColumnsItem>
      columns={columns}
      actionRef={actionRef}
      resizable={false}
      bordered={true}
      scroll={{ x: 1300 }}
      request={async (params = {}, sort) => {
        const sortName = sort ? Object.keys(sort)[0] : undefined;
        const _params = {
          ...params,
          pageNo: params.current,
          stockCacheType: +stockType + 1,
          stock: +hasStock,
          areaCode: stockType ? (location?.length === 3 ? location.join("_") + "_0" : undefined) : vagueLocation,
          categoryId: params.categoryId ? params.categoryId.filter((item: any) => item).reverse()[0] : undefined,
          categoryLevel: params.categoryId?.length
            ? params.categoryId.filter((item: any) => item).length - 1
            : undefined,
          minPrice: params.agreementPrice?.[0] ? params.agreementPrice?.[0] * 100 : undefined,
          maxPrice: params.agreementPrice?.[1] ? params.agreementPrice?.[1] * 100 : undefined,
          minRate: params.rate?.[0] ? +params.rate?.[0] : undefined,
          maxRate: params.rate?.[1] ? +params.rate?.[1] : undefined,
          // skuOrProductName: keyword,
          order: sort && sortName ? orderNameMap.get(sortName) + " " + sort[sortName]?.slice(0, -3).toUpperCase() : undefined,
          source: "elemall",
        };

        return await jdStore.getNewJdGoodsList(_params);
      }}
      rowKey="skuId"
      search={{
        labelWidth: "auto",
        defaultCollapsed: false,
      }}
      pagination={{
        pageSize: 10,
      }}
      dateFormatter="string"
      options={{ setting: false, fullScreen: true }}
    />
  );
};

export default observer(PickDGSS);
