import { OrderApis } from '@/api/afterSaleApis';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import httpClient from "@/tools/httpClient";

export enum OrderCloseType {
  卖家超时未确认 = 1,
  买家超时未支付
}

export enum OrderStatus {
  待卖家确认 = 1,
  待买家付款,
  待卖家发货,
  待买家收货,
  交易成功,
  交易关闭
}

export enum PaymentMethod {
  预存货款扣款 = 1,
  对公汇款
}

export enum DeductionOfInventory {
  非现货采购,
  现货采购
}

export enum DeliveryStatus {
  默认状态,
  待发货,
  已发货
}

export enum ReceiptStatus {
  默认状态,
  待收货,
  已收货
}

export enum IsContainsTax {
  不含税,
  含税
}

interface OrderPayInfoProps {
  // 实际需支付费用
  actualPaymentPrice: number;
  // 运费现价（订单含待确认商品时为0，订单无需支付运费时为0）
  currentFreightPrice: number;
  // 订单现价（不含运费）
  currentOrderPrice: number;
  // 支付方式：1、预存货款扣款，2、对公汇款
  paymentMethod: 1 | 2;
  // 支付时间
  paymentTime: string;
  // 收款凭证地址(只有对公汇款会有数据)
  receivePaymentImage: string;
  // 收款备注
  receivePaymentRemark: string;
  // 创建时间
  createTime: string;
  // 支付订单号
  payBillNo: string;
}

type OrderReceivingInfoProps = {
  // 详细地址
  address: string;
  // 地区码
  areaCode: string;
  // 备注
  buyerRemark: string;
  // 省
  province: string;
  // 市
  city: string;
  // 区
  district: string;
  // 镇
  town: string;
  // 收货人
  consignee: string;
  id: '';
  // 收货联系方式
  mobileNumber: string;
};

export interface DetailGoodItemProp {
  // 商品图片地址
  goodsImage: string;
  // 商品名称
  goodsName: string;
}

export type DetailGroupDetailGoodItemProp = {
  goodsList: DetailGoodItemProp[];
  // 最终运费
  finalFreightPrice: number;
  // 运费 ID
  id: string;
  //运费 是否改价过：0、未改价，1、已改价
  isChange: 0 | 1;

  freight: number;
};

export type OrderDetail = {
  afterSalesGoodsResp: {
    afterSalesNum: number;
    goodsId: string;
    goodsImage: string;
    goodsInfos: [
      {
        amountPayable: number;
        discountWayList: [
          {
            id: string;
            name: string;
            value: number;
          }
        ];
        freight: number;
        goodsInfoType: number;
        refundAmount: number;
        returnPaymentInfos: [
          {
            name: string;
            type: number;
            value: number;
          }
        ];
        salePrice: number;
      }
    ];
    goodsName: string;
    goodsNo: string;
    isContainsTax: number;
    receivingTime: string;
    skuId: string;
    spuId: string;
    successfulAfterSalesNum: number;
    taxRate: number;
  };
  afterSalesProgressRespList: [
    {
      afterSalesProgressAdditionalInfo: {
        addressInfo: {
          address: string;
          city: string;
          consignee: string;
          district: string;
          mobileNumber: string;
          province: string;
          town: string;
        };
        pickUpTimeDown: string;
        pickUpTimeUp: string;
        sendExpressCompany: string;
        sendExpressNo: string;
        sendFreight: number;
      };
      afterSalesStatus: number;
      createTime: string;
    }
  ];
  afterSalesResp: {
    address: string;
    afterSalesApplicant: string;
    afterSalesApplicantName: string;
    afterSalesCertificate: any[];
    afterSalesDescription: string;
    afterSalesMethod: number;
    afterSalesNo: string;
    afterSalesProcessor: string;
    afterSalesProcessorName: string;
    afterSalesReason: string;
    afterSalesSource: number;
    afterSalesStatus: number;
    afterSalesBasicType: number;
    city: string;
    consignee: string;
    createTime: string;
    createUserNo: string;
    district: string;
    mobileNumber: string;
    orderNo: string;
    pickUpMethod: number;
    pickUpTimeDown: string;
    pickUpTimeUp: string;
    province: string;
    shopId: string;
    shopName: string;
    siteId: string;
    siteName: string;
    town: string;
    userAfterSalesNo: string;
    userOrderNo: string;
    closeReason: string;
    afterSalesType: number;
  };
};

interface GetOrderDetailProps {
  afterSalesNo: string;
}

interface OrderDetailStoreProps {
  orderDetail: OrderDetail;
  // 所有商品详情都用这个
  ajaxGetOrderDetail: (params: GetOrderDetailProps) => void;
}

class OrderDetailStore implements OrderDetailStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  orderDetail: OrderDetail = {
    afterSalesGoodsResp: {
      afterSalesNum: 0,
      goodsId: '',
      goodsImage: '',
      goodsInfos: [
        {
          amountPayable: 0,
          discountWayList: [
            {
              id: '',
              name: '',
              value: 0
            }
          ],
          freight: 0,
          goodsInfoType: 0,
          refundAmount: 0,
          returnPaymentInfos: [
            {
              name: '',
              type: 0,
              value: 0
            }
          ],
          salePrice: 0
        }
      ],
      goodsName: '',
      goodsNo: '',
      isContainsTax: 0,
      receivingTime: '',
      skuId: '',
      spuId: '',
      successfulAfterSalesNum: 0,
      taxRate: 0
    },
    afterSalesProgressRespList: [
      {
        afterSalesProgressAdditionalInfo: {
          addressInfo: {
            address: '',
            city: '',
            consignee: '',
            district: '',
            mobileNumber: '',
            province: '',
            town: ''
          },
          pickUpTimeDown: '',
          pickUpTimeUp: '',
          sendExpressCompany: '',
          sendExpressNo: '',
          sendFreight: 0
        },
        afterSalesStatus: 0,
        createTime: ''
      }
    ],
    afterSalesResp: {
      address: '',
      afterSalesApplicant: '',
      afterSalesApplicantName: '',
      afterSalesCertificate: [],
      afterSalesDescription: '',
      afterSalesMethod: 0,
      afterSalesNo: '',
      afterSalesProcessor: '',
      afterSalesProcessorName: '',
      afterSalesReason: '',
      afterSalesSource: 0,
      afterSalesStatus: 0,
      afterSalesBasicType: 1,
      city: '',
      consignee: '',
      createTime: '',
      createUserNo: '',
      district: '',
      mobileNumber: '',
      orderNo: '',
      pickUpMethod: 0,
      pickUpTimeDown: '',
      pickUpTimeUp: '',
      province: '',
      shopId: '',
      shopName: '',
      siteId: '',
      siteName: '',
      town: '',
      userAfterSalesNo: '',
      userOrderNo: '',
      closeReason: '',
      afterSalesType: 1
    }
  };

  @action
  async ajaxGetOrderDetail(params: GetOrderDetailProps) {
    const res = await httpClient.get(OrderApis.get('info'), { params });
    if (res?.success) {
      runInAction(() => {
        this.orderDetail = res.data;
      });
    }
  }

  @action
  async ajaxQueryLogisticByOrderNo(params: { afterSaleNo: any }): Promise<any> {
    const res: any = await httpClient.get('/fleshopy/order/after/v1/progress', { params });
    return res?.success && res.data;
  }
}

export default new OrderDetailStore();
