import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  message,
  Statistic,
  Descriptions,
  Spin,
  Alert,
  Typography,
  Row,
  Col,
  Divider,
} from "@fle-ui/next";
import { PageContainer } from "@fle-ui/pro-layout";
import { observer } from "mobx-react";
import { Icon } from "@/components";
import md5 from "md5";
import { accountStore, certificationStore } from "@/stores/setting";
import "./index.less";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
// import "@fle-ui/next/es/descriptions/style/index.less";

const { Countdown } = Statistic;
const colorMap = new Map([
  [0, "#faad14"],
  [1, "#52c41a"],
]);

const { Title } = Typography;

const AccountInfo: React.FC = () => {
  const [userInfo, setUserInfo] = useState<any>();

  const [visiblePasswordModal, setVisiblePasswordModal] = useState<boolean>(false);
  const [visibleMobileModal, setVisibleMobileModal] = useState<boolean>(false);
  const [sendCodeLoading, setSendCodeLoading] = useState<boolean>(false);
  const [newSendCodeLoading, setNewSendCodeLoading] = useState<boolean>(false);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [newShowCountdown, setNewShowCountdown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [payPwdLoading, setPayPwdLoading] = useState<boolean>(false);
  const [phoneLoading, setPhoneLoading] = useState<boolean>(false);
  const [newCountdownTimer, setNewCountdownTimer] = useState(0);
  const [countdownTimer, setCountdownTimer] = useState(0);
  const [checkOldPhone, setCheckOldPhone] = useState<boolean>(false);
  const [addCompanyVisible, setAddCompanyVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [pswForm] = Form.useForm();
  const history = useHistory();
  const formItemLayout = {
    labelCol: { sm: { span: 6 } },
    wrapperCol: { sm: { span: 16 } },
  };

  // 显示||隐藏 密码弹窗
  const handlePasswordModal = () => {
    setVisiblePasswordModal(!visiblePasswordModal);
  };

  // 获取手机号
  const getMobile = (phone: any) =>
    new Promise(async (resolve, reject) => {
      let mobile = "";
      let code = "";
      if (phone) {
        setSendCodeLoading(true);
        resolve(phone);
      } else {
        !checkOldPhone && userInfo?.mobile
          ? await accountStore
              .checkSmsCode({
                code,
                mobile: userInfo?.mobile,
              })
              .then((res: any) => {
                if (res.success) {
                  setNewSendCodeLoading(true);
                  setCheckOldPhone(true);
                  resolve(mobile);
                } else {
                  reject(false);
                }
              })
          : resolve(mobile);
      }
    });

  // 发送验证码
  const sendCode = async (phone: any) => {
    getMobile(phone).then((mobile: any) => {
      accountStore.sendCode({ mobile }).then((res: any) => {
        if (1) {
          message.success("发送成功！");
          if (phone) {
            setSendCodeLoading(false);
            setShowCountdown(true);
            setCountdownTimer(Date.now() + 1000 * 60);
          } else {
            setNewSendCodeLoading(false);
            setNewShowCountdown(true);
            setNewCountdownTimer(Date.now() + 1000 * 60);
          }
        } else {
          setSendCodeLoading(false);
        }
      });
    });
  };

  // 确认修改密码
  const editPassword = async (e: any) => {
    // this.setState({ payPwdLoading: true })
    setPayPwdLoading(true);
    const data: any = await accountStore.editPassword({
      smsCode: e.smsCode,
      newPassword: md5(e.pwd),
      confirmPassword: md5(e.confirmPwd),
    });
    if (data?.success) {
      message.success("修改成功！");
      handlePasswordModal();
      // this.setState({ showCountdown: false, payPwdLoading: false })
      setShowCountdown(false);
      setPayPwdLoading(false);
      accountStore.getUserAccount();
    } else {
      setPayPwdLoading(false);
    }
  };

  // 确认密码验证
  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    console.log(pswForm.getFieldValue("pwd"));

    if (value && value !== pswForm.getFieldValue("pwd")) {
      callback("两次输入的密码不一致");
    } else {
      callback();
    }
  };

  const onFinish = () => {
    setShowCountdown(false);
    setCountdownTimer(0);
  };

  // 密码弹窗
  const editPasswordModal = () => {
    const mobile = userInfo?.mobile;
    const formItemLayout = {
      labelCol: { xs: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { xs: { span: 16 }, sm: { span: 16 } },
    };

    return (
      <Modal
        title="修改密码"
        open={visiblePasswordModal}
        onCancel={handlePasswordModal}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={handlePasswordModal}>
            取消
          </Button>,
          <Button key="ok" type="primary" onClick={editPassword} loading={payPwdLoading}>
            {payPwdLoading ? "提交中" : "确定"}
          </Button>,
        ]}>
        <Form form={pswForm} {...formItemLayout}>
          <Form.Item
            label="新密码"
            name="pwd"
            rules={[
              { required: true, message: "请输入新密码!" },
              {
                pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/,
                message: "字母、数字、字符其中至少两种且长度只能在8-20个字符之间",
              },
            ]}
            validateTrigger="onBlur">
            <Input type="password" maxLength={20} placeholder="数字与字母组合8-16的字符" />
          </Form.Item>
          <Form.Item
            label="确认密码"
            colon={false}
            name="confirmPwd"
            rules={[{ required: true, message: "请再次输入密码！" }, { validator: compareToFirstPassword }]}
            validateTrigger="onBlur">
            <Input type="password" maxLength={20} placeholder="请再次输入密码" />
          </Form.Item>
          <Form.Item
            label="短信验证码"
            name="smsCode"
            rules={[{ required: true, message: "请输入验证码！" }]}
            validateTrigger="onBlur">
            <Input type="tel" maxLength={6} style={{ width: 180 }} placeholder="请输入验证码" />
            <Button
              disabled={showCountdown}
              loading={sendCodeLoading}
              style={{ width: 114, marginLeft: 20 }}
              onClick={() => sendCode.bind(mobile)}>
              {sendCodeLoading ? (
                "正在发送"
              ) : showCountdown ? (
                <Countdown value={countdownTimer} onFinish={onFinish} format="s秒" />
              ) : (
                "发送验证码"
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  // 显示||隐藏 手机弹窗
  const handleMobileModal = () => {
    setVisibleMobileModal(!visibleMobileModal);
  };

  // 修改手机号
  const editMobile = async (e: any) => {
    setPhoneLoading(true);
    const data: any = await accountStore.editMobile({ ...e });
    if (data?.success) {
      await accountStore.getUserAccount();
      message.success("修改成功！");
      handleMobileModal();
      setShowCountdown(false);
      setPhoneLoading(false);
    } else {
      setPhoneLoading(false);
    }
  };

  // 新手机号是否跟原来的相同
  const compareToPhone = (rule: any, value: any, callback: any) => {
    const mobile = userInfo?.mobile;
    if (value && value === mobile) {
      callback("新手机号不能与旧手机号相同！");
    } else {
      callback();
    }
  };

  const onFinishNew = () => {
    setNewShowCountdown(false);
    setNewCountdownTimer(0);
  };

  // 手机弹窗
  const editMobileModal = () => {
    const mobile = userInfo?.mobile;
    const formItemLayout = {
      labelCol: { xs: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { xs: { span: 16 }, sm: { span: 16 } },
    };

    return (
      <Modal
        title="修改安全手机号"
        open={visibleMobileModal}
        onCancel={handleMobileModal}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={handleMobileModal}>
            取消
          </Button>,
          <Button key="ok" type="primary" onClick={editMobile} loading={phoneLoading}>
            {phoneLoading ? "提交中" : "确定"}
          </Button>,
        ]}>
        <Form form={form} {...formItemLayout}>
          <Form.Item label="原手机号">
            {userInfo?.mobile && userInfo?.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}
          </Form.Item>
          <Form.Item
            label="原手机验证码"
            name="oldMobileCode"
            rules={[
              { required: true, message: "请输入旧手机号验证码！" },
              { pattern: /^[0-9]{6}$/, message: "请输入正确的验证码！" },
            ]}
            validateTrigger="onBlur">
            <Input type="tel" maxLength={6} style={{ width: 180 }} placeholder="请输入旧手机号验证码！" />
            <Button
              disabled={showCountdown}
              loading={sendCodeLoading}
              style={{ width: 114, marginLeft: 20 }}
              onClick={() => sendCode.bind(mobile)}>
              {sendCodeLoading ? (
                "正在发送"
              ) : showCountdown ? (
                <Countdown value={countdownTimer} onFinish={onFinish} format="s秒" />
              ) : (
                "发送验证码"
              )}
            </Button>
          </Form.Item>
          <Form.Item
            label="变更后的手机号"
            name="newMobile"
            rules={[
              { required: true, message: "请输入变更后的手机号!" },
              { pattern: /^1[0-9]{10}$/, message: "请输入正确的手机号！" },
              { validator: compareToPhone },
            ]}
            validateTrigger="onBlur">
            <Input type="tel" maxLength={11} placeholder="请输入变更后的手机号！" />
          </Form.Item>
          <Form.Item
            label="新手机验证码"
            name="newMobileCode"
            rules={[
              { required: true, message: "请输入新手机号验证码！" },
              { pattern: /^[0-9]{6}$/, message: "请输入正确的验证码！" },
            ]}
            validateTrigger="onBlur">
            <Input type="tel" maxLength={6} style={{ width: 180 }} placeholder="请输入新手机号验证码！" />
            <Button
              disabled={newShowCountdown}
              loading={newSendCodeLoading}
              style={{ width: 114, marginLeft: 20 }}
              onClick={(e) => {
                sendCode(e);
              }}>
              {newSendCodeLoading ? (
                "正在发送"
              ) : newShowCountdown ? (
                <Countdown value={newCountdownTimer} onFinish={onFinishNew} format="s秒" />
              ) : (
                "发送验证码"
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const addCompnay = async (e: any) => {
    setLoading(true);
    const data: any = await certificationStore.addCompnay({
      ...e,
      source: 0,
    });
    setLoading(false);

    if (data?.success) {
      accountStore.getCompanyList({
        mobile: accountStore.userInfo?.mobile,
        isPartner: true,
      });
      onCallbackAddCompany(data?.data);
    }
  };

  // 增加企业
  const addCompanyModal = () => {
    return (
      <Modal
        title="创建企业"
        open={addCompanyVisible}
        onCancel={checkAddCompanyModal}
        maskClosable={false}
        className="add-compay-modal"
        footer={null}>
        <Form {...formItemLayout} form={form} onFinish={addCompnay}>
          <Form.Item
            label="姓名"
            name="memberName"
            rules={[
              { required: true, message: "请输入姓名" },
              {
                pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
                message: "仅支持中英文及数字",
              },
            ]}
            validateTrigger="onBlur">
            <Input type="text" placeholder="请输入姓名" />
          </Form.Item>
          <Form.Item
            label="企业/个体名称"
            name="name"
            rules={[
              { required: true, message: "请输入企业/个体名称" },
              { min: 2, max: 20, message: "长度只能在2-20个字符之间" },
              {
                pattern: /^[\u4e00-\u9fa5a-zA-Z()（）]+$/,
                message: "仅支持中英文及括号",
              },
            ]}
            validateTrigger="onBlur">
            <Input type="text" placeholder="请输入企业/个体名称" />
          </Form.Item>

          <Form.Item>
            <Button onClick={checkAddCompanyModal} key="cancel" style={{ marginRight: "10px" }}>
              取消
            </Button>
            <Button type="primary" loading={loading} onClick={addCompnay} htmlType="submit" key="submit">
              {loading ? "正在创建中" : "确认创建"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  // 关闭或显示添加企业弹窗
  const checkAddCompanyModal = () => {
    setAddCompanyVisible(!addCompanyVisible);
  };

  const onCallbackAddCompany = (e: any) => {
    checkAddCompanyModal();
    Modal.confirm({
      icon: <PlusOutlined type="check-circle" style={{ color: "#87d068" }} />,
      title: "创建新企业成功，是否切换到新的企业？",
      okText: "切换新企业",
      onOk: () => changeCompany(e.companyId, e.outUserId),
    });
  };

  // 切换企业
  const changeCompany = (siteCompanyId: any, userId: any) => {
    accountStore.changeCompany({
      authType: 8,
      customerType: 2,
      siteCompanyId,
      siteType: 1,
      userId,
    });
  };

  const goRouter = (pathname: any, state?: any) => history.push({ pathname, state });

  const getUserAccount = async () => {
    const data = await accountStore.getUserAccount();

    setUserInfo(data);
  };

  const getCertificationInfo = async () => await certificationStore.getCertificationInfo();

  useEffect(() => {
    getUserAccount();
    getCertificationInfo();
  }, []);

  const render = () => {
    const { certificationInfo: certificationInfoList } = certificationStore;
    const certificationInfo = certificationInfoList?.[0] || null;

    return (
      <PageContainer>
        <div className="my-accountInfo-wrap">
          <Spin spinning={loading}>
            <Typography>
              <Title level={4}>账号信息</Title>
            </Typography>
            <Divider />
            <Row>
              <Col offset={1}>
                <Descriptions title="基础信息" column={{ md: 1 }}>
                  <Descriptions.Item label="账号ID">{userInfo?.id}</Descriptions.Item>
                  <Descriptions.Item label="姓名">{userInfo?.name}</Descriptions.Item>
                  <Descriptions.Item label="企业/个体名称">
                    <span>{userInfo?.companyName}</span>
                    {/* 新平台不能创建企业 */}
                    {/* <Button type='link' onClick={this.checkAddCompanyModal}>创建新企业</Button> */}
                  </Descriptions.Item>
                  <Descriptions.Item label="手机号">
                    <span>{userInfo?.mobile && userInfo?.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}</span>
                    {/* <Button type='link' onClick={handleMobileModal}>修改安全手机号</Button> */}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="密码">
                <span>******</span>
                <Button type='link' onClick={handlePasswordModal}>修改密码</Button>
              </Descriptions.Item> */}
                </Descriptions>

                <Descriptions title="认证信息" column={{ md: 1 }}>
                  <Descriptions.Item>
                    <div className="cert-wrap">
                      {(!!userInfo && !!userInfo?.certificationStatus) || !!certificationInfo ? (
                        <div className="certification-info-wrap">
                          <div className="fx-flexbox">
                            <Icon
                              type="iconrenzhengyonghu"
                              style={{
                                fontSize: 30,
                                color: colorMap.get(certificationInfo?.auditStatus),
                              }}></Icon>
                            <span className="certification-info-title">
                              {!!userInfo && userInfo?.certificationStatus === 1 && certificationInfo?.auditStatus === 1
                                ? "企业认证成功！"
                                : (!!userInfo && userInfo?.certificationStatuss === 0) ||
                                  certificationInfo?.auditStatus === 0
                                ? "正在审核中！"
                                : "审核未通过！"}
                            </span>
                          </div>
                          <div className="fx-flexbox tips">
                            <span>企业名称：{!!userInfo && userInfo?.companyName}</span>
                            <span className="certification-info-text">
                              营业执照号：
                              {certificationInfo?.registryNumber.replace(/(\d{6})\d{4}(\d)/, "$1****$2")}
                            </span>
                          </div>
                          <Button type="primary" onClick={() => goRouter("/settings/company/account/certification")}>
                            查看认证信息
                          </Button>
                        </div>
                      ) : (
                        !!userInfo &&
                        !userInfo?.certificationStatus && (
                          <div>
                            <Alert
                              message="完成企业认证，开启飞象企服之旅！"
                              description={
                                <div>
                                  <p className="tips">
                                    企业认证后，将有利于提升您的采购权限及保障您的交易安全，同时您将具备采购或交易时签订电子合同的权限；认证完成后，您也将具备成为飞象供应商的权限；赶快认证吧！
                                  </p>
                                  <Button
                                    type="primary"
                                    onClick={() => goRouter("/settings/company/account/certification")}>
                                    前往认证
                                  </Button>
                                </div>
                              }
                              type="info"
                              showIcon
                              style={{ width: 700 }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Spin>

          {editPasswordModal()}
          {editMobileModal()}
          {addCompanyModal()}

          {/* <AddCompanyModal
          visible={addCompanyVisible}
          onCancel={checkAddCompanyModal.bind(this)}
          onCallback={onCallbackAddCompany}
        /> */}
        </div>
      </PageContainer>
    );
  };

  return render();
};

export default observer(AccountInfo);
