import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Button, Descriptions, Modal } from '@fle-ui/next';
import { DetailGoodItemProp } from '@/stores/orderDetailStore';

type Props = {
  visible: boolean;
  // 关闭弹窗
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  // id
  goodDetail: DetailGoodItemProp;
};
const ShipPriceInstruction: FC<Props> = (props: Props) => {
  const { visible, onClose, goodDetail } = props;
  const { finalFreightPrice, freightDescription, freightId } = goodDetail;

  return (
    <Modal
      title={!freightId ? '运费说明' : '运费计算方法'}
      open={visible}
      destroyOnClose
      footer={
        <Button type={'primary'} onClick={onClose}>
          确认
        </Button>
      }
      onCancel={onClose}
      onOk={onClose}
    >
      <>
        {!freightId && <>{freightDescription}</>}
        {freightId && (
          <Descriptions column={1}>
            <Descriptions.Item label="运费收取方式">卖家定义运费</Descriptions.Item>
            <Descriptions.Item label="运费规则">卖家定义运费</Descriptions.Item>
            <Descriptions.Item label="运费金额">
              ￥{(finalFreightPrice / 100).toFixed(2)}
            </Descriptions.Item>
          </Descriptions>
        )}
      </>
    </Modal>
  );
};
export default observer(ShipPriceInstruction);
