import useUrlState from '@ahooksjs/use-url-state';
import { Button, Cascader, Col, DatePicker, Form, Input, Row, Select, Space } from '@fle-ui/next';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import OrderListStore from '@/stores/afterSaleListStore';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select;
type Props = {
  onSearch: (values: any) => void;
  setPage: (values: any) => void;
  page: {
    pageNo: number;
    pageSize: number;
  };
  orderStatsList: any[];
  ref: any;
};
type GetOrderListParams = any;

const OrderListForm = (props: Props, ref: any) => {
  const { onSearch, setPage, page } = props;
  const [form] = Form.useForm();
  const [selectVip, setSelectVip] = useState<string>('0');
  const [vipName, setVipName] = useState<any>('');
  const [selectData, setSelectData] = useState<any[]>([]);
  const formStatusMap = new Map([
    [10, [11]],
    [20, [21, 22, 23, 24]],
    [30, [31, 32, 33]],
    [40, [41, 42, 43, 44, 45]],
    [50, [51, 52]],
    [60, [61, 62, 63]],
    [90, [91, 92, 93, 94, 95, 96]]
  ]);
  const [urlParams, setUrlParams] = useUrlState(
    { tab: 'default', reset: false },
    { navigateMode: 'replace' }
  );

  useImperativeHandle(ref, () => ({
    reset: () => setForm()
  }));

  const onFinish = useCallback(async () => {
    try {
      const values: Partial<GetOrderListParams> = await form.validateFields();

      // 日期
      if (values.date) {
        const [start, end] = values.date;
        values.startTime = start.startOf('day').valueOf();
        values.endTime = end.endOf('day').valueOf();
        delete values.date;
      }

      // 售后状态
      if (urlParams.tab !== 'default') {
        values.afterSalesStatus = urlParams.tab;
      }

      if (values.afterSalesStatus !== '') {
        values.afterSalesStatus = formStatusMap.get(Number(values.afterSalesStatus));
      } else {
        values.afterSalesStatus = [];
      }

      // 售后单与订单号选择
      if (values.orderName == 20) {
        values.orderNo = values.afterSalesNo;
        delete values.afterSalesNo;
      }

      //会员名称
      if (values.selectVip == 20) {
        values.afterSalesApplicant = values.vipName;
      }
      delete values.selectVip;
      delete values.vipName;
      delete values.date;
      delete values.orderName;
      setPage({ ...page, pageNo: 1 });

      onSearch(values);
    } catch (e) {}
  }, [onSearch, form, urlParams]);

  const setForm = async () => {
    setSelectVip('0');
    form.resetFields();
    await onFinish();
  };

  const vipNameSelectCallback = (value: any) => {
    setSelectVip(value);
  };

  const vipNameInputCallback = (val: string | number) => {
    setVipName(val);
  };

  const vipNameInputSearch = (value: any) => {
    if (value) {
      getVipNameData(value);
    } else {
      setSelectData([]);
    }
  };

  const getVipNameData = (value: any) => {
    let timeout;
    let currentValue: any;
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fake = async () => {
      try {
        const { list } = (await OrderListStore.getVipName({
          name: currentValue,
          pageNo: 1,
          pageSize: 10000,
          type: 2
        })) as any;
        setSelectData(list);
      } catch (error) {
        console.log(error);
      }
    };
    timeout = setTimeout(fake, 700);
  };

  useEffect(() => {
    if (!urlParams.tab) {
      return;
    }

    onFinish();
  }, [form, onFinish, urlParams]);

  return (
    <div className="after_sale_form">
      <Form form={form}>
        <Row gutter={8}>
          <Col span={7} style={{ display: 'flex' }}>
            <Form.Item name="orderName" initialValue="10">
              <Select placeholder="请选择">
                <Option value="10">售后单号</Option>
                <Option value="20">订单编号</Option>
              </Select>
            </Form.Item>
            <FormItem name={'afterSalesNo'} style={{ width: '100%' }}>
              <Input placeholder={'请输入售后单号/订单编号'} />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem label="商品名称" name={'goodsName'}>
              <Input placeholder={'商品名名称'} />
            </FormItem>
          </Col>
          <Col span={7} style={{ display: 'flex' }}>
            <FormItem name="selectVip" initialValue="0">
              <Select placeholder="请选择" onChange={vipNameSelectCallback}>
                <Option value="0">会员id</Option>
                <Option value="20">会员名称</Option>
              </Select>
            </FormItem>
            {selectVip == '0' && (
              <Form.Item name="afterSalesApplicant" style={{ width: '100%' }}>
                <Input placeholder="请输入会员id" />
              </Form.Item>
            )}
            {selectVip == '20' && (
              <Form.Item name="vipName" style={{ width: '100%' }}>
                <Select
                  allowClear={true}
                  showSearch={true}
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  value={vipName}
                  placeholder="请输入并选择会员名称"
                  onChange={(val) => vipNameInputCallback(val)}
                  onSearch={vipNameInputSearch}
                >
                  {selectData.map((item: any, index: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col span={3} style={{ textAlign: 'right' }}>
            <Space style={{ float: 'right' }}>
              <Button
                type={'primary'}
                htmlType={'submit'}
                onClick={async () => {
                  await onFinish();
                }}
              >
                搜索
              </Button>
              <Button onClick={setForm}>重置</Button>
            </Space>
          </Col>

          <Col span={7}>
            <FormItem label="售后类型" name={'afterSalesMethod'} style={{ marginBottom: 0 }}>
              <Select placeholder={'请选择售后类型'}>
                <Option value="" key="1">
                  全部
                </Option>
                <Option value="10" key="2">
                  退货
                </Option>
                <Option value="20" key="3">
                  换货
                </Option>
                <Option value="30" key="4">
                  维修
                </Option>
                <Option value="40" key="5">
                  退款
                </Option>
              </Select>
            </FormItem>
          </Col>
          {urlParams.tab === 'default' && (
            <Col span={7}>
              <FormItem label="售后状态" name={'afterSalesStatus'} style={{ marginBottom: 0 }}>
                <Select placeholder="请选择售后状态">
                  <Option value="">全部</Option>
                  <Option value="10">等待审核</Option>
                  <Option value="20">等待商家收货</Option>
                  <Option value="30">等待商家处理</Option>
                  <Option value="40">处理完成</Option>
                  <Option value="50">等待用户确认</Option>
                  <Option value="60">已完成</Option>
                  <Option value="90">已取消</Option>
                </Select>
              </FormItem>
            </Col>
          )}
          <Col span={7}>
            <FormItem label={'申请时间'} name={'date'} style={{ marginBottom: 0 }}>
               {/* @ts-ignore */}
              <RangePicker style={{ width: '100%' }} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default observer(forwardRef(OrderListForm));
