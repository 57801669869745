/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:46:13
 */
const account: Map<string, string> = new Map([
  // 获取账号信息
  ['member_info', '/elebuys/member/employee/member-info'],
  // 查询象铺信息-根据企业id查询
  ['by-company-id', '/elebuys/member/shop/detail/by-company-id'],
  // 退出登录
  ['logout', '/elebuys/right/login/logout'],
  // 获取登录信息
  ['login-type', '/psm/login-page/query']
]);

export default account;
