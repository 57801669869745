/**
 * @Description: 延迟函数
 * @author 点点
 * @date 2021/6/30
 * @time 15:23
 */

export const delay = (time = 1000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};
