import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Card, Table } from '@fle-ui/next';
import { DetailGoodItemProp } from '@/stores/orderDetailStore';
import { rowSpanMerge } from '@/tools/rowSpanMerge';
import BuyerAddressInfo from '@/pages/shopManage/order/purProxy/invoiceDetail/components/BuyerAddressInfo';
import OrderStateBoard from './components/OrderStateBoard';
import { WhiteSpace } from '@/components';
import GoodDesc from '../../component/GoodDesc';
import ShipOperate from './components/ShipOperate';
import FilterForm from './components/FilterForm';
import DownLoadButton from './components/DownLoadButton';
import OrderDeliveryStore, {
  RenderList,
  SendStatus,
} from '@/stores/orderDeliveryStore';

const { Column } = Table;

type Props = {};
const WaitSellerShip: FC<Props> = () => {
  const { itemVoList } = OrderDeliveryStore.orderDetail;

  return (
    <>
      <OrderStateBoard orderStatus={'待卖家发货'} />

      <WhiteSpace />

      <Card title={'订单明细'} extra={<DownLoadButton />}>
        <FilterForm />
        <Table
          bordered
          dataSource={rowSpanMerge(itemVoList, 'md5ReceiverInfo')}
          rowKey={'id'}
          pagination={false}
        >
          <Column<DetailGoodItemProp>
            title={'商品/规格'}
            width={240}
            align={'center'}
            render={(text, record) => <GoodDesc {...record} />}
          />
          <Column title={'商品ID'} width={140} align={'center'} dataIndex={'skuId'} />
          <Column title={'数量'} width={60} align={'center'} dataIndex={'number'} />
          <Column
            title={'发货状态'}
            align={'center'}
            width={100}
            dataIndex={'sendStatus'}
            render={(sendStatus) => SendStatus[sendStatus]}
          />
          <Column<RenderList>
            title={'收货人信息'}
            ellipsis
            width={120}
            align={'center'}
            render={(text, record) => {
              return {
                children: <BuyerAddressInfo {...record} />,
                props: { rowSpan: record.rowSpan }
              };
            }}
          />

          <Column<RenderList>
            title={'运费金额'}
            width={100}
            align={'center'}
            dataIndex={'freight'}
            render={(text, record) => {
              return {
                children: '￥' + (text / 100).toFixed(2),
                props: { rowSpan: record.rowSpan }
              };
            }}
          />
          <Column title={'快递公司'} width={100} align={'center'} dataIndex={'expressName'} />
          <Column title={'快递单号'} width={100} align={'center'} dataIndex={'expressNo'} />
          <Column<RenderList>
            title={'操作'}
            width={160}
            align={'center'}
            dataIndex={'id'}
            render={(_, record) => {
              return <ShipOperate {...record} />;
            }}
          />
        </Table>
      </Card>
    </>
  );
};

export default observer(WaitSellerShip);
