/**
 * @Description: 代发价
 * @author 点点
 * @date 2021/6/10
 * @time 09:12
 */
import React from 'react';
import { GoodItemProps } from '@/types/store/goods';

const OncePrice = (_: any, record: GoodItemProps) => {
  if (record.isOnce === 0) {
    return null;
  }

  // 草稿箱，商品未填写代发价，应显示为空
  if (record.isOnce === 1 && (!record.onceInfo.salePriceMin || !record.onceInfo.salePriceMax)) {
    return null;
  }

  return (
    <>
      {record.isOnce === 1 && record.onceInfo.salePriceMin === record.onceInfo.salePriceMax ? (
        <>
          ￥{(record.onceInfo.salePriceMin / 100).toFixed(2)}/{record.unit}
        </>
      ) : (
        '￥' +
        (record.onceInfo.salePriceMin / 100).toFixed(2) +
        '/' +
        record.unit +
        '-' +
        '￥' +
        (record.onceInfo.salePriceMax / 100).toFixed(2) +
        '/' +
        record.unit
      )}
    </>
  );
} ;

export default OncePrice
