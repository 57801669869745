import moment from "moment";

/**
 * 金额格式化
 * @param data
 */
type moneyType = number | string;
interface ShowMoneyType {
  (data: moneyType): string;
}
export const showMoney: ShowMoneyType = (data?: moneyType) => (+(data || 0) / 100).toFixed(2);

/**
 * 时间格式化
 * @param data
 * @param fmtType
 */
type timeType = Date | number | string;

interface ShowTimeType {
  (data: timeType, fmtType: string): string;
}
export const showTime: ShowTimeType = (data, fmtType = "YYYY-MM-DD HH:mm:ss") =>
  data ? moment(data).format(fmtType) : "";

export const checkMoney = (
  str: string,
  pointNum: number = 2,
  maxNum: number = 999999999
) => {
  if (+str <= maxNum) {
    const strArr = str?.split(".");
    if (strArr.length === 2) {
      return !!pointNum
        ? strArr[0] + "." + strArr[1].slice(0, pointNum)
        : strArr[0];
    } else {
      return str;
    }
  } else {
    return maxNum;
  }
};


/**
 * 格式化
 */
export const formartNum = (data: moneyType, len: number = 2): number | "" => {
  return data?.toString()
    ? (+data *
        +Array(Math.min(9, (len + 1)))
          .fill("0")
          .reduce((str, num) => str + num, 1)) / 10
    : "";
};



/**
 * 逆转格式化
 */
export const reverseNum = (data: moneyType, len: number = 2): number | "" => {
  return data?.toString()
    ? +data /
        +Array(Math.min(9, len))
          .fill("0")
          .reduce((str, num) => str + num, 1)
    : "";
};
