import useUrlState from '@ahooksjs/use-url-state';
import { useDebounceEffect } from 'ahooks';
import { Button, Card, Col, Empty, Pagination, Row, Space, Spin, Tabs } from '@fle-ui/next';
import { WhiteSpace } from '@/components';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { IsContainsTax, OrderStatus } from '@/stores/orderDetailStore';
import OrderListStore, { GetOrderListParams } from '@/stores/orderListStore';
import { getPagination } from '@/tools/getPagination';
import { OnceProxyOrderStates } from '@/tools/selectOption';
import { PageProps } from '@/types/commom';
import { getUnit } from '@/pages/shopManage/order/component/GetUnit';
import OrderListForm from '@/pages/shopManage/order/component/OrderListForm';
import Quantity from '@/pages/shopManage/order/component/Quantity';
import CancelOrderModal from '../component/CancelOrderModal';
import GoodDesc from '../component/GoodDesc';
import OrderPrice from '../component/OrderPrice';
import OrderState from '../component/OrderState';

import { OrderListStyle } from '@/pages/shopManage/order/orderListStyle';
import { PageContainer } from '@fle-ui/pro-layout';

const { TabPane } = Tabs;

type Props = {};

const OnceProxy: FC<Props> = () => {
  const { list, pageNo, pageSize, total } = OrderListStore.orderList;
  const { paymentCount, shippedCount, sendCount } = OrderListStore.orderStatusCount;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<PageProps>({ pageNo: 1, pageSize: 20 });
  const [formData, setFormData] = useState<Partial<GetOrderListParams>>({ ...page });
  const [refresh, setRefresh] = useState(0);
  const [curOrderNo, setCurOrderNo] = useState('');
  const [params, setParams] = useUrlState(
    { tab: 'default', reset: false },
    { navigateMode: 'replace' }
  );
    // console.log(params.tab,'params.tab');
    
  useDebounceEffect(
    () => {
      setLoading(true);
      OrderListStore.ajaxGetOnceProxyOrderList({ ...formData, ...page,  orderStatus: params.tab === 'default' ? undefined : [Number(params.tab) as any]}).finally(() =>
        setLoading(false)
      );
      OrderListStore.ajaxGetOrderStatusCount({ orderType: 4 }).catch((err) => {
        console.log(err);
      });
    },
    [formData, page, refresh],
    { wait: 100 }
  );

  useEffect(() => {
    setPage({ pageNo: 1, pageSize: 20 });
  }, [params]);

  const [CancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);

  return (
    <PageContainer padding={0}>
      <Card>
        <Spin spinning={loading}>
          <Tabs
            defaultActiveKey={params.tab}
            activeKey={params.tab}
            type="card"
            onChange={(tab) => {
              const params = { tab, reset: false };
              if (tab === 'default') {
                params.reset = true;
              }
              setParams(params);
              tab !== params.tab && setLoading(true);
            }}
          >
            <TabPane tab={'全部订单'} key={'default'} />
            <TabPane
              tab={
                <>
                  待付款 <span style={{ color: 'red' }}>{paymentCount || ''}</span>
                </>
              }
              key={'2'}
            />
            <TabPane
              tab={
                <>
                  待发货 <span style={{ color: 'red' }}>{sendCount || ''}</span>
                </>
              }
              key={'3'}
            />
            <TabPane
              tab={
                <>
                  已发货 <span style={{ color: 'red' }}>{shippedCount || ''}</span>
                </>
              }
              key={'4'}
            />
            <TabPane tab={'交易成功'} key={'5'} />
          </Tabs>

          <OrderListForm onSearch={setFormData} orderStatsList={OnceProxyOrderStates}  />

          <Row style={OrderListStyle.orderListHeader}>
            <Col span={2} style={{ ...OrderListStyle.tableVerticalLeft, paddingLeft: 20 }}>
              商品图片
            </Col>
            <Col span={4} style={{ ...OrderListStyle.tableVerticalLeft }}>
              商品名称
            </Col>
            <Col span={3} style={OrderListStyle.tableVerticalLeft}>
              单价（元）
            </Col>
            <Col span={3} style={OrderListStyle.tableVerticalLeft}>
              数量
            </Col>
            <Col span={4} style={OrderListStyle.tableVerticalLeft}>
              应付金额（元）
            </Col>
            <Col span={5} style={OrderListStyle.tableVerticalLeft}>
              订单状态
            </Col>
            <Col span={3} style={OrderListStyle.tableVerticalLeft}>
              操作
            </Col>
          </Row>

          {list.map((order, index) => (
            <div key={order.orderNo + '_' + index} style={OrderListStyle.boxWrap}>
              <Row gutter={8} style={OrderListStyle.orderDetailHeader}>
                <Col>
                  <Space size={'large'}>
                    <span style={{ color: '#262626' }}>订单号：{order.orderNo}</span>
                    <span style={{ color: '#262626' }}>买家：{order.buyerCompanyName}</span>
                    <span style={{ color: '#a9a9a9' }}>{order.createTime}</span>
                  </Space>
                </Col>
              </Row>
              <Row style={{ textAlign: 'left' }}>
                <Col span={12}>
                  {order.mallOrderGoodsVOList.map((good, index) => (
                    <Row
                      key={order.orderNo + '_' + index}
                      style={{
                        padding: '25px 0',
                        textAlign: 'left'
                      }}
                    >
                      <Col span={4}>
                        <img src={good?.goodsImage} alt="" style={{ marginLeft: 20, width: 61 }} />
                      </Col>
                      <Col span={8} style={{ textAlign: 'left',display: 'flex',alignItems: 'center' }}>
                        <GoodDesc {...good} />
                      </Col>
                      <Col span={6} style={OrderListStyle.goodPrice}>
                        <div>
                          {(good.finalSalePrice / 100).toFixed(2)}/{getUnit(good)}（
                          {IsContainsTax[good.isContainsTax]}) <br />
                        </div>
                        <div>
                          {IsContainsTax.含税 === good.isContainsTax && (
                            <span style={{ color: '#a9a9a9' }}>
                              含税点：￥
                              {(
                                ((good.finalSalePrice / 100 / (1 + good.taxRate / 100)) *
                                  good.taxRate) /
                                100
                              ).toFixed(2)}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col span={6} style={OrderListStyle.goodQuantity}>
                        <Quantity {...good} />
                      </Col>
                    </Row>
                  ))}
                </Col>

                <OrderPrice span={4} {...order} />

                <OrderState span={5} {...order} />

                <Col span={3} style={OrderListStyle.orderStatus}>
                  <Space align="start" size="large">
                    {[OrderStatus.待卖家确认, OrderStatus.待买家付款].includes(
                      order.orderStatus
                    ) && (
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() => {
                          setCurOrderNo(order.orderNo);
                          setCancelOrderModalVisible(true);
                        }}
                        style={{ margin: 0, padding: 0 }}
                      >
                        取消订单
                      </Button>
                    )}
                    <Button
                      type={'link'}
                      size={'small'}
                      onClick={() =>
                        window.open('/home/shop_manage/order/once_proxy/detail/' + order.orderNo)
                      }
                      style={{ margin: 0, padding: 0 }}
                    >
                      详情
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          ))}

          <WhiteSpace />
          <WhiteSpace />
          {total > 0 && (
            <Row justify={'end'}>
              <Col>
                <Pagination
                  {...getPagination({
                    total,
                    current: pageNo,
                    pageSize,
                    onChange: (pageNo: number, pageSize: number) => setPage({ pageNo, pageSize }),
                    onShowSizeChange: (pageNo: number, pageSize: number) =>
                      setPage({ pageNo: 1, pageSize })
                  })}
                />
              </Col>
            </Row>
          )}

          {total === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

          <CancelOrderModal
            onCancel={async () => {
              setCancelOrderModalVisible(false);
              setCurOrderNo('');
              setRefresh((refresh) => refresh + 1);
            }}
            orderNo={curOrderNo}
            visible={CancelOrderModalVisible}
          />
        </Spin>
      </Card>
    </PageContainer>
  );
};

export default observer(OnceProxy);
