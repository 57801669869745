import React  from 'react';
import { GoodItemProps } from "../../types/store/goods";


const SaleType= (_: any, record:GoodItemProps) => {
  return (
    <>
      { record.isPurchase === 1 && '集采批发/代发' } <br/>
      { record.isOnce === 1 && '一件代发' }
    </>
  );
} ;

export default SaleType
