import { createFromIconfontCN } from "@ant-design/icons";

/* 采用iconfont Symbol模式
每次更新icon后，记得复制最新的地址哦～ */

const Icon = createFromIconfontCN({
  scriptUrl: [
    // '//at.alicdn.com/t/font_3347162_iab45h06s8k.js',
    "//oss.elebuys.com/resource/fle-ui/202207281556160000201673.js", // 最新卖家中心图标库，后续有新增都放到这里
    "//at.alicdn.com/t/font_1557419_kughl0mwhpa.js",
    "//at.alicdn.com/t/font_3051549_rb7otz4guz.js",
    // 订单优化
    "//at.alicdn.com/t/font_3051549_p1sb7oua629.js",
  ],
});

export default Icon;
