import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import Manage from "./manage";

type Props = {};
const FinanceDgssModule: FC<Props> = (props: Props) => {
  return (
    <Switch>
      <Route path="/finance/dgss/manage" exact component={Manage} />
    </Switch>
  );
};

export default FinanceDgssModule;
